import { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'
import { useSnackbar } from 'notistack'
import { AppContext } from 'app/context/AppContext'
import queryString from 'query-string'
import { useZoom } from 'common/hooks/useZoom'
import { useNotificationTopBannerIsVisible } from 'app/components/NotificationTopBanner'
import { useInterval, useKeyPress } from 'react-use'
import { useLocation, useParams } from 'react-router-dom'
import { useMemoCompare } from 'common/hooks/useMemoCompare'
import { snakeCase, debounce } from 'lodash'
import config from 'config'
import { coreApiClient } from 'util/coreApiClient'
import useSWR from 'swr'

const { WebPageViewedV1Event } = WebEvents
const MOD_INTERACTION = 100

const HIDE_DURATION = 5000

const getParsedToken = () => {
  const parsedSession = JSON.parse(localStorage.getItem(config.localStorageKey) || '{}')
  return parsedSession?.idToken
}

export const DevModeContext = createContext()
export const DevModeProvider = ({ children }) => {
  const {
    activeOrg: { orgId, orgName },
    user,
  } = useContext(AppContext)
  const [interacted, setInteracted] = useState(0)
  const [showEnableDevMode, setShowEnableDevMode] = useState(false)
  const [openStillWorking, setOpenStillWorking] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { notificationBarHeight } = useNotificationTopBannerIsVisible()
  const { zoom, incrementZoom, decrementZoom } = useZoom()
  const [timeFrame, setTimeFrame] = useState()
  const [headerHeight, setHeaderHeight] = useState(100)
  const [openConnection, setOpenConnection] = useState(true)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [socketToken, setSocketToken] = useState()
  const [token, setToken] = useState(getParsedToken())
  const [clearLogs, setClearLogs] = useState(false)
  useSWR(['orgId', orgId], () => coreApiClient.get(`/identity/orgs/${orgId}`), {
    refreshInterval: 5000,
  })

  /**
   * Since the idToken and refresh token are being updated outside of react there may be other browser tabs or whatever
   * that update the token. Normally we wouldn't care since we are using axios but dev mode needs to refresh its connection
   * whenever the token changes and axios does not support websockets. So we just need to be sure that this context is updated.
   */
  useInterval(() => {
    try {
      const idToken = getParsedToken()
      if (idToken !== token) {
        setToken(idToken)
      }
    } catch (error) {}
  }, 10000)

  // This will reset our interaction timer back another 2 hours every time this is called
  const logInteraction = useMemo(
    () =>
      debounce(() => {
        setInteracted((interacted + 1) % MOD_INTERACTION)
      }, 500),
    []
  )

  const { pathname } = useLocation()
  const params = useParams()
  const finalParams = useMemoCompare(
    params,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
  )

  const queryParams = useMemo(
    () => queryString.parse(window.location.search),
    [window.location.search]
  )

  /**
   * When the inspector is opened we want to publish an event
   */
  const publishSidebarToggle = useCallback(
    async ({ opened }) => {
      // Convert path to relative path
      const path = Object.keys(finalParams).reduce((str, key) => {
        const value = finalParams[key]
        return str.replace(value, `:${key}`)
      }, pathname)

      const session = JSON.parse(window.localStorage.getItem(config.localStorageKey) || '{}')
      const slsCoreEventsClient = new SlsCoreEventsClient(
        config.platform.eventsUrl,
        session?.idToken
      )
      const activity = new WebPageViewedV1Event({
        orgUid: orgId,
        path: path,
        url: pathname,
        userId: user?.userId,
        params: Object.keys(finalParams || {}).reduce(
          (obj, key) => ({
            ...obj,
            [snakeCase(key)]: finalParams[key],
          }),
          {}
        ),
        queryParameters: {
          ...Object.keys(queryParams || {}).reduce(
            (obj, key) => ({
              ...obj,
              [snakeCase(key)]: queryParams[key],
            }),
            {}
          ),
          devModeSidebarOpen: opened,
        },
      })
      await slsCoreEventsClient.publish(activity).catch((error) => console.error(error))
      return 0
    },
    [user, orgId, pathname, finalParams, queryParams]
  )

  // Reset the timer when the connection is open
  // and when the user interacts with the page
  useEffect(() => {
    let interval
    if (openConnection) {
      // We want to repeat this process every 2 hours
      interval = setTimeout(() => {
        setOpenConnection(false)
        // Show modal asking if they are still there
        setOpenStillWorking(true)
      }, 1000 * 60 * 60 * 2) // 2 hours

      return () => clearTimeout(interval)
    }
  }, [openConnection, interacted])

  useEffect(() => {
    let key = 'dev-mode'
    if (openConnection) {
      closeSnackbar(`${key}-paused`)
      key += '-active'
      enqueueSnackbar('Live streaming activated. Awaiting new activity...', {
        key,
        autoHideDuration: HIDE_DURATION,
        variant: 'info',
      })
    } else if (!openConnection) {
      closeSnackbar(`${key}-active`)
      key += '-paused'
      enqueueSnackbar('Live streaming paused.', {
        key,
        autoHideDuration: HIDE_DURATION,
        variant: 'info',
      })
    }
    // Ensure snackbar closes even if the screen is not focused
    const timer = setTimeout(() => closeSnackbar(key), HIDE_DURATION)
    return () => clearTimeout(timer)
  }, [openConnection])

  useEffect(() => {
    const retrieveSocketToken = async () => {
      try {
        if (!token) return
        const { socketToken } = await coreApiClient({
          baseURL: config.platform.devModeUrl,
          url: `/token/${orgId}?whatever=man`,
        })
        setSocketToken(socketToken)
      } catch (error) {}
    }

    retrieveSocketToken()
  }, [orgId, token])

  // Send clear logs event
  useKeyPress((e) => (e.ctrlKey || e.metaKey) && e.key === 'k' && setClearLogs(Date.now()))

  return (
    <DevModeContext.Provider
      value={{
        eventToken: token,
        openStillWorking,
        user,
        orgId,
        orgName,
        openConnection,
        setOpenConnection,
        socketToken,
        headerHeight: notificationBarHeight ? headerHeight + notificationBarHeight : headerHeight,
        setHeaderHeight,
        incrementZoom,
        decrementZoom,
        zoom,
        timeFrame,
        setTimeFrame,
        clearLogs,
        setClearLogs: () => setClearLogs(Date.now()),
        selectedActivity,
        setSelectedActivity,
        publishSidebarToggle,
        showEnableDevMode,
        setShowEnableDevMode,
        logInteraction,
        closeStillWorking: () => {
          setOpenConnection(true)
          setOpenStillWorking(false)
        },
      }}
    >
      {children}
    </DevModeContext.Provider>
  )
}
