import { startOfDay } from 'date-fns'
import { getFirstItem } from 'util/param'

/**
 *  Hubspot class for tracking users client-side
 */
const _hsq = (window._hsq = window._hsq || [])
const pageviewWhitelist = [undefined, 'login', 'register']

class Hubspot {
  trackRegistration(user, utms = {}) {
    const registrationProperties = {
      email: user.email,
      userid: user.userUid,
      hs_marketable_status: true,
      lead_source: 'Console Signup',
      console_activation_date: startOfDay(new Date()).getTime(),
    }

    this.#pushIdentification(registrationProperties)
    this.#pushPageview('/', utms)
  }

  trackLogin(user, utms = {}) {
    const loginProperties = {
      email: user.email,
      userid: user.userUid,
    }

    this.#pushIdentification(loginProperties)
    this.#pushPageview('/login', utms)
  }

  trackPageview(view, utms = {}) {
    let path = ''
    if (!pageviewWhitelist.includes(getFirstItem(view))) return
    if (!view) path = '/login'
    else path = `/${view}`
    this.#pushPageview(path, utms)
  }

  #pushIdentification(payload) {
    // Push identification
    _hsq.push([
      'identify',
      {
        ...payload,
      },
    ])
  }

  #pushPageview(path, utms = {}) {
    let fullPath = path

    // Pull params from the utms object
    // Format key from referrerCampaign -> utm_campaign
    const params = Object.keys(utms).map((key) => {
      if (utms[key]) {
        const hubspotSafeKey = key.replace('referrer', 'utm')
        return hubspotSafeKey + '=' + getFirstItem(utms[key])
      }

      return false
    })

    // Filter out empty params and join with &
    const utmString = params
      .filter((param) => {
        if (param) return param

        return false
      })
      .join('&')

    // Add utms to the full path if we have them
    if (utmString !== '') {
      fullPath = `${fullPath}?${utmString}`
    }

    // Set the path and track the pageview
    _hsq.push(['setPath', fullPath])
    _hsq.push(['trackPageView'])
  }

  #pushEvent(eventName) {
    _hsq.push([
      'trackEvent',
      {
        id: eventName,
      },
    ])
  }
}

export const hubspot = new Hubspot()
