import { SvgIcon } from '@mui/material'

const IconAwsLambda = ({ size, ...props }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }} viewBox="0 0 10 10" {...props}>
      <path d="M5.20215 0.416667H2.16756V2.5H3.90162L7.36972 9.58333H9.53728V7.91667L8.67025 7.5L5.20215 0.416667Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75375 9.33333H0.89658L3.00856 5.30633L3.93835 7.15067L2.75375 9.33333ZM3.31826 4.39167C3.25999 4.27667 3.13896 4.20367 3.00613 4.20367H3.00509C2.87157 4.204 2.75053 4.278 2.69331 4.39367L0.0340622 9.523C-0.0176125 9.62633 -0.00998247 9.74767 0.0534838 9.84433C0.11695 9.94133 0.227582 10 0.346885 10H2.97328C3.10749 10 3.22957 9.92567 3.28714 9.80933L4.63648 7.29167C4.68122 7.20067 4.68087 7.09433 4.6351 7.00367L3.31826 4.39167ZM9.30638 9.33333H7.41557L3.97799 2.23433C3.92111 2.117 3.79834 2.04167 3.66343 2.04167H2.41387L2.41526 0.666667H4.91923L8.34017 7.765C8.39704 7.88267 8.51947 7.95833 8.65472 7.95833H9.30638V9.33333ZM9.65319 7.29167H8.87634L5.4554 0.193333C5.39818 0.0756665 5.27575 0 5.1405 0H2.0688C1.87736 0 1.72233 0.149 1.72199 0.333L1.71991 2.37467C1.71991 2.46333 1.75632 2.548 1.82152 2.61067C1.88638 2.67333 1.97447 2.70833 2.06672 2.70833H3.44251L6.8801 9.80733C6.93697 9.92467 7.0594 10 7.19431 10H9.65319C9.84498 10 10 9.851 10 9.66667V7.625C10 7.44067 9.84498 7.29167 9.65319 7.29167Z"
      />
    </SvgIcon>
  )
}

export { IconAwsLambda }
