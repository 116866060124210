import { useContext, useEffect, useState } from 'react'
import {
  styled,
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from '@mui/material'
import { Button } from 'common/components/Button'
import { useSnackbar } from 'notistack'

import config from 'config'
import { validateEmail } from 'util/email'
import { AppContext } from 'app/context/AppContext'
import { TeamContext } from 'settings/context/Team.context'
import { useTheme } from '@mui/styles'
import CloseButton from 'common/components/CloseButton'
import { coreApiClient } from 'util/coreApiClient'

const roles = Object.keys(config.platform.roles).map((key) => ({
  key,
  ...config.platform.roles[key],
}))

export const AddMember = ({ close }) => {
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const { palette } = useTheme()
  const { addInvite } = useContext(TeamContext)
  const { enqueueSnackbar } = useSnackbar()
  const [formData, setFormData] = useState({
    submitting: false,
    email: '',
    role: 'contributor',
  })
  const [validEmail, setValidEmail] = useState(false)

  useEffect(() => {
    if (validateEmail(formData.email)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }, [formData.email])

  const handleEmailChange = (event) => {
    setFormData({
      ...formData,
      email: event.target.value,
    })
  }

  const handleRoleChange = (event) => {
    setFormData({
      ...formData,
      role: event.target.value,
    })
  }

  const submitInvitation = async () => {
    setFormData({
      ...formData,
      submitting: true,
    })
    try {
      const invitation = await coreApiClient.post(`/identity/invitations`, {
        orgId,
        inviteeEmail: formData.email,
        inviteeRole: formData.role,
      })
      addInvite(invitation)
      close()
    } catch (error) {
      // Show error
      enqueueSnackbar('Failed to submit invitation', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    } finally {
      setFormData({
        ...formData,
        submitting: false,
      })
    }
  }

  return (
    <Box
      width="100%"
      height="100%"
      padding="30px 60px"
      borderLeft={`1px solid ${palette.border.main}`}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
        <Typography variant="h2">Add Member</Typography>

        <CloseButton size="large" onClick={close} aria-label="close" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl sx={{ width: '100%' }} variant="standard">
            <TextField
              label="Email"
              onKeyUp={(event) => {
                if (event.code === 'Enter' && validEmail) {
                  submitInvitation()
                }
              }}
              onChange={handleEmailChange}
              placeholder="Email"
              id="email-input"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }} variant="standard">
            <InputLabel>Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              variant="outlined"
              sx={{ '& .MuiSelect-select': { padding: '8px 9px' } }}
              value={formData.role}
              onChange={handleRoleChange}
            >
              {roles.map(({ key, name }) => (
                <StyledMenuItem key={key} value={key}>
                  {name}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        marginTop="25px"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        marginBottom="10px"
      >
        <Button sx={{ marginRight: '10px' }} onClick={close} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={submitInvitation}
          disabled={!validEmail}
          variant="contained"
          loading={formData.submitting}
        >
          Invite
        </Button>
      </Box>

      <Box marginTop="20px" border={`1px solid ${palette.border.main}`} borderRadius="4px" p={2}>
        <Typography variant="h3" gutterBottom>
          Roles Information
        </Typography>

        <Typography gutterBottom variant="textPrimary">
          {config.platform.roles.owner.name}
        </Typography>
        <Typography variant="textSecondary" color="text.secondary">
          {config.platform.roles.owner.description}
        </Typography>

        <Typography gutterBottom variant="textPrimary">
          {config.platform.roles.contributor.name}
        </Typography>
        <Typography variant="textSecondary" color="text.secondary">
          {config.platform.roles.contributor.description}
        </Typography>
      </Box>
    </Box>
  )
}

const StyledMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
  },
})
