import { useEffect } from 'react'
import { Button } from 'common/components/Button'
import Box from '@mui/material/Box'
import { GitHub, Google } from '@mui/icons-material'
import { Divider, FormControl, styled, TextField, Typography } from '@mui/material'

export default function Login({
  submitText = 'Login',
  onSubmit = () => {},
  footer = () => <></>,
  isLoading,
  email,
  setEmail,
  password,
  setPassword,
}) {
  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  // Handle form submission on Enter/Return
  useEffect(() => {
    const listener = async (e) => {
      if (
        (e.code === 'Enter' || e.code === 'NumpadEnter') &&
        (email || '').trim() !== '' &&
        (password || '').trim() !== ''
      ) {
        e.preventDefault()
        onSubmit({
          isSocial: false,
          email,
          password,
        })
      }
    }

    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [email, password, onSubmit])

  return (
    <Box width="100%">
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}
      >
        <Button
          size="large"
          loading={isLoading?.github}
          fullWidth
          onClick={() => onSubmit({ isSocial: true, socialProvider: 'github' })}
        >
          <GitHub sx={{ margin: '0 12px 0 0' }} /> Github
        </Button>
        <Button
          color="primary"
          size="large"
          loading={isLoading?.google}
          fullWidth
          onClick={() => onSubmit({ isSocial: true, socialProvider: 'google-oauth2' })}
        >
          <Google sx={{ margin: '0 12px 0 0' }} /> Google
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: '10px',
          margin: '25px 0 15px 0',
        }}
      >
        <Separator />
        <SeparatorText variant="body1">or</SeparatorText>
        <Separator />
      </Box>
      <FormControl sx={{ width: '100%' }} variant="standard">
        <TextField
          label="Email"
          id="email-input"
          onChange={handleEmailChange}
          value={email}
          placeholder="Email"
        />
      </FormControl>
      <FormControl sx={{ width: '100%', mt: 3, mb: 3 }} variant="standard">
        <TextField
          label="Password"
          onChange={handlePasswordChange}
          placeholder="Password"
          type="password"
          value={password}
          id="password-input"
        />
      </FormControl>
      <Button
        color="primary"
        size="large"
        fullWidth
        loading={isLoading?.email}
        onClick={() =>
          onSubmit({
            isSocial: false,
            email,
            password,
          })
        }
      >
        {submitText}
      </Button>
      {footer}
    </Box>
  )
}

const Separator = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey.light,
  width: '45%',
}))

const SeparatorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}))
