import { ChevronDown, ChevronLeft } from 'lucide-react'

export const DropdownIcon = ({ isOpen, style = {}, isHorizontal, marginLeft = 10, ...rest }) => {
  const Component = isHorizontal ? ChevronLeft : ChevronDown
  return (
    <Component
      style={{
        marginLeft,
        transition: 'all .1s ease-in-out',
        transform: isOpen ? 'rotateZ(180deg)' : 'none',
        ...style,
      }}
      size={21}
      {...rest}
    />
  )
}
