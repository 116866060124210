import { useContext } from 'react'
import { AppContext } from 'app/context/AppContext'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { useNavigate } from 'react-router-dom'
import { parameterise } from 'util/param'
import { Box } from '@mui/material'
import { Link } from 'common/components/Link'
import { useTheme } from '@mui/styles'
import { BooleanParam, useQueryParam } from 'use-query-params'
import { hasConfiguredIntegration } from '../util/hasConfiguredIntegration'
import config from 'config'

/**
 * A hook that is used to show the onboarding notification banner for different cases.
 * @returns:
 * - message: The message to display in the notification banner.
 * - onClick: Notification banner on click event.
 * - isVisible: Boolean flag indicating whether the notification is visible or not.
 */
export const useOnboardingTopBanner = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [, setOpenCreateIntegration] = useQueryParam('createIntegration', BooleanParam)

  const { activeOrg } = useContext(AppContext)

  const { orgId, orgName } = activeOrg || {}

  /**
   * Get all integrations
   */
  const { data: integrationsData } = useSWR(
    ['integrations', orgId],
    () =>
      coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/?orgId=${orgId}`,
      }),
    {
      shouldRetryOnError: false,
    }
  )
  const { integrations } = integrationsData || {}

  /**
   * Show on all pages except for connect page
   */
  // const isAllowedPage = !pathname.includes('/connect')

  const hasOneCompletedIntegration = hasConfiguredIntegration(integrations)

  /**
   * "Previously activated" is any org that deployed and ingested data on instrumentation v1
   */
  const isPreviouslyActivated = !hasOneCompletedIntegration

  /**
   * Go to integrations page if there are more than one integration
   * otherwise go to resources list of that only integration.
   * Used in the notification banner onClick event.
   */
  const params =
    integrations && integrations?.length === 1
      ? `?${parameterise({
          editIntegration: 1,
          integrationId: integrations && integrations[0]?.integrationId,
          integrationType: 'aws',
        })}`
      : ''

  /**
   * Check if at least one integration have an enabled resource.
   */

  /* const checkEnabledResources = async () => {
    try {
      const urls = integrations.map(
        ({ integrationId }) =>
          `/inventories/?integrationId=${integrationId}&instrumentMatchKey=aws_true`
      )
      const res = await Promise.all(
        urls.map((url) => coreApiClient(url).then((res) => res?.inventories?.length))
      )

      setHasEnabledResources(res?.some((i) => i))
    } catch (err) {}
  } */
  /**
   * Query enabled resources for all integrations when:
   * 1. There's a change in integrations list.
   * 2. Allowed page change.
   * 3. When integration dialog is opened/closed.
   */
  /* useEffect(() => {
    if (hasOneCompletedIntegration) {
      checkEnabledResources()
    }
  }, [integrations, isAllowedPage, editIntegration]) */

  /**
   * Shows one of three cases:
   * 1. Show an 'upgrade' message for orgs that are previously activated
   * 2. Show a message if org has a bucket for v2 to enable resources
   * 3. Show a message if bucket is still being created to enable resources
   */
  if (isPreviouslyActivated) {
    return {
      message: (
        <Box>
          Welcome to the new Serverless Console.{' '}
          <Link
            onClick={() => setOpenCreateIntegration(true)}
            style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }}
          >
            Connect your AWS account
          </Link>{' '}
          to get started. Watch the overview video{' '}
          <Link
            to="https://www.loom.com/share/bfedf4f4644f4e85b1adc5f4d66f414e"
            style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }}
            target="_blank"
          >
            here
          </Link>
          .
        </Box>
      ),
      onClick: () => {
        setOpenCreateIntegration(true)
      },
      isVisible: true,
    }
  }
  return {
    message:
      'Welcome! Metrics may take 3 minutes to load initially. Click here to enable Logging, Tracing & Dev Mode on your functions while you wait.',
    onClick: () => {
      navigate(`/${orgName}/settings/integrations${params}`)
    },
    isVisible: false,
  }
}
