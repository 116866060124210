import { createContext, useEffect, useRef, useState, useCallback } from 'react'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'
import { defaultTheme } from '../utils/theme'
import config from 'config'

const { WebDevModeActivityV1Event } = WebEvents

export const ActivityStreamContext = createContext()
export const ActivityStreamProvider = ({
  eventsUrl,
  eventToken,
  token: incomingToken,
  theme: incomingTheme,
  containerStyle = {},
  openConnection = true,
  userId,
  orgId,
  children,
}) => {
  const counterRef = useRef({
    logs: 0,
    spans: 0,
    requests: 0,
    responses: 0,
    token: incomingToken,
  })
  const [token, setToken] = useState(incomingToken)
  const [theme, setTheme] = useState(incomingTheme || defaultTheme.light)

  /**
   * Send Usage Activity
   */
  const sendActivity = useCallback(async () => {
    const session = JSON.parse(window.localStorage.getItem(config.localStorageKey) || '{}')
    const slsCoreEventsClient = new SlsCoreEventsClient(eventsUrl, session?.idToken)
    const activity = new WebDevModeActivityV1Event({
      orgUid: orgId,
      userId,
      logBatches: counterRef.current.logs,
      spansBatches: counterRef.current.spans,
      events: counterRef.current.requests,
      responses: counterRef.current.responses,
      source: 'web:console',
    })
    if (openConnection) {
      await slsCoreEventsClient
        .publish(activity)
        .catch((error) => console.error(error))
        .finally(() => {
          counterRef.current.logs = 0
          counterRef.current.spans = 0
          counterRef.current.requests = 0
          counterRef.current.responses = 0
        })
    }
    return 0
  }, [eventsUrl, eventToken, counterRef.current.token, openConnection, userId, orgId])
  useEffect(() => {
    // Fire event every 60 seconds
    const interval = setInterval(sendActivity, 1000 * 60)
    return () => clearInterval(interval)
  }, [sendActivity])

  // Update theme if the incoming theme changes.
  // This can happen when switching from light to dark mode.
  useEffect(() => {
    if (theme !== incomingTheme) {
      setTheme(incomingTheme)
    }
  }, [incomingTheme])

  // Update token if the incoming token changes
  useEffect(() => {
    if (token !== incomingToken) {
      setToken(incomingToken)
      counterRef.current.token = incomingToken
    }
  }, [incomingToken])

  const style = {
    width: '100%',
    height: '100%',
    ...containerStyle,
    '--activityPrimary': theme.palette.primary.main,
    '--activitySecondary': theme.palette.secondary.main,
    '--activityRed': theme.palette.colors.redPrimary,
    '--activityGreyDark': theme.palette.grey.dark,
    '--activityGreyMedium': theme.palette.grey.medium,
    '--activityGreyLight': theme.palette.grey.light,
    '--activityBlackUltraLight': theme.palette.colors.blackUltraLight,
    '--activityBorderMain': theme.palette.border.main,
  }

  return (
    <ActivityStreamContext.Provider
      value={{
        counterRef,
        theme,
        token,
      }}
    >
      <div style={style}>{children}</div>
    </ActivityStreamContext.Provider>
  )
}
