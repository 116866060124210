import { defaultExplorerFilterString, defaultMetricsFilterString } from 'filter/util/filters'
import { groupByMap } from './groupByMap'
import { stringifyUrl } from 'query-string'

export const getLink = ({ type = '', queryArr = [], orgName }) => {
  try {
    /**
     * Determine page and default query parameters
     */
    let page = ''
    if (type === 'metrics') {
      page = `metrics/awsLambda?${defaultMetricsFilterString}`
    } else if (type === 'explorer') {
      page = `explorer?${defaultExplorerFilterString}`
    } else {
      page = `dev-mode`
    }
    let query

    query = queryArr.reduce(
      (acc, item) => ({
        ...acc,
        [groupByMap[item.type]?.paramName]: [
          ...(acc?.[groupByMap[item.type]?.paramName] || []),
          item.name,
        ],
      }),
      {}
    )
    return stringifyUrl({
      url: `/${orgName}/${page}`,
      query,
    })
  } catch (err) {
    return ''
  }
}

export const addLinksToItem = ({ item = {}, orgName }) => {
  const type = item.type
  const name = item?.aws_lambda_name || item?.id
  return {
    metricsLink: getLink({
      type: 'metrics',
      orgName,
      queryArr: [
        {
          type,
          name,
        },
      ],
    }),
    devModeLink: getLink({
      type: 'dev-mode',
      orgName,
      queryArr: [
        {
          type,
          name,
        },
      ],
    }),
    explorerLink: getLink({
      type: 'explorer',
      orgName,
      queryArr: [
        {
          type,
          name,
        },
      ],
    }),
  }
}
