import { useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useSnackbar } from 'notistack'
import LoadingPage from 'app/components/LoadingPage'
import { AppContext } from 'app/context/AppContext'
import { coreApiClient } from 'util/coreApiClient'
import useSWR from 'swr'

const AcceptInvitation = () => {
  const [inviteToken] = useQueryParam('token', StringParam)
  const { enqueueSnackbar } = useSnackbar()
  const { changeOrg, refreshTokenNow, token, userOrgs } = useContext(AppContext)

  useSWR(token && `/identity/invitations/${inviteToken}/org`, async () => {
    if (!inviteToken) {
      changeOrg(userOrgs[0])
    }
    try {
      // We need to get org details before we accept the invite :shrug:
      const { orgId, orgName } = await coreApiClient.get(`/identity/invitations/${inviteToken}/org`)
      const { inviteeRole } = await coreApiClient.post(
        `/identity/invitations/${inviteToken}/accept`
      )

      enqueueSnackbar(`🎉 Successfully joined ${orgName}!`)
      const newAuthData = await refreshTokenNow({
        defaultOrgName: orgName,
      })
      await changeOrg(
        {
          orgId,
          orgName,
          memberRole: inviteeRole,
          isOwner: inviteeRole === 'owner',
        },
        newAuthData
      )
      return true
    } catch (error) {
      enqueueSnackbar(`Invitation token is invalid`, {
        variant: 'error',
        autoHideDuration: 5000,
      })
      changeOrg(userOrgs[0])
      return false
    }
  })

  return <LoadingPage type="spin3D" />
}

export default AcceptInvitation
