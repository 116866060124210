import { Box } from '@mui/system'
import { forwardRef, memo, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { AppContext } from 'app/context/AppContext'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { Divider, Menu, MenuItem, Skeleton, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { IconDangerTriangle } from 'common/icons/IconDangerTriangle'
import { get, range, truncate } from 'lodash'
import { motion } from 'framer-motion'
import ErrorState from 'common/components/ErrorState'
import { format } from 'date-fns'
import { updateFilters } from 'metrics/hooks/useQuery'
import Button from 'common/components/Button'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { Link } from 'common/components/Link'
import ChartHeader from './ChartHeader'
import { HelpCircle } from 'lucide-react'
import { ChevronRight } from 'lucide-react'
import { FilterContext } from 'filter/context/FilterContext'
import { getEventInfo } from 'common/utils/getEventInfo'
import { Activity as ActivityIcon } from 'lucide-react'
import { SimplePagination } from 'explorer/components/ExplorerTable/SimplePagination'
import { ActivitiesGraph } from './ActivitiesGraph'
import { AlertDialogButton } from 'settings/components/alerts/useAlertDialog'

const padding = 20

const Event = memo(
  forwardRef(({ activity, setSelectedActivity, selectedActivity }, outerRef) => {
    const theme = useTheme()

    const { title, color } = getEventInfo(activity)

    const formatter = Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1,
    })

    const formattedOccurrences = formatter.format(activity?.occurrences || 0)

    const firstSeenDate = format(new Date(activity?.first_seen), 'h:mm:ss aa, MMMM dd')
    const lastSeenDate = format(new Date(activity?.last_seen), 'h:mm:ss aa, MMMM dd')

    const borderRadius = 5
    const extraAreaWidth = 15
    return (
      <Box mb="20px" ref={outerRef}>
        <Link onClick={() => setSelectedActivity(activity)} underline="none">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              marginBottom: '8px',
              color: 'text.secondary',
              '& span': {
                lineHeight: 1,
              },
            }}
          >
            <Typography variant="textTertiary">{firstSeenDate}</Typography>
            <Typography variant="textTertiary">-</Typography>
            <Typography variant="textTertiary">{lastSeenDate}</Typography>
            <Tooltip title="First Seen - Last Seen" enterDelay={1000} leaveDelay={0}>
              <HelpCircle style={{ marginLeft: 1 }} size={11} />
            </Tooltip>
          </Box>
          <Box
            sx={{
              borderRadius: `${borderRadius}px`,

              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              borderWidth: `1px`,
              borderStyle: `solid`,
              padding: '10px 10px',
              gap: '10px',

              borderColor:
                !selectedActivity || selectedActivity?.id !== activity.id
                  ? 'border.main'
                  : 'border.light',
              transition: 'all 0.3s ease',

              '&:hover': {
                borderColor: 'border.light',
                ' .activity-item-arrow-hover': {
                  opacity: 1,
                },
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <IconDangerTriangle
                style={{
                  fill: get(theme.palette, color),
                  fontSize: 18,
                }}
              />
              <Typography
                variant="textSecondary"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  color,
                  lineHeight: 1,
                }}
              >
                {title}
              </Typography>
              <Tooltip title="Occurrences" enterDelay={1000} leaveDelay={0}>
                <Typography variant="textPrimary" lineHeight={1}>
                  {formattedOccurrences}
                </Typography>
              </Tooltip>
            </Box>
            {!!activity?.affected_resources?.length && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1,
                  color: 'text.secondary',
                  position: 'relative',
                }}
              >
                <IconAwsLambda size={11} sx={{ margin: '0 3px 0 5px' }} />
                <Typography
                  variant="textTertiary"
                  sx={{
                    lineHeight: 1,
                  }}
                >
                  {truncate(activity?.affected_resources?.slice(0, 2).join(' • '), {
                    length: 35,
                    separator: '...',
                  })}
                </Typography>
                {activity?.affected_resources?.length > 2 && (
                  <Typography
                    variant="textTertiary"
                    sx={{
                      lineHeight: 1,
                      position: 'absolute',
                      right: '-4px',
                      bottom: '-2px',
                      backgroundColor: 'secondary.main',
                      padding: '2px 5px',
                      filter: `drop-shadow(-15px 0px 5px ${theme.palette.background.default})`,
                    }}
                  >
                    and {activity?.affected_resources?.length - 2} more
                  </Typography>
                )}
              </Box>
            )}
            <Box
              className="activity-item-arrow-hover"
              sx={{
                opacity: selectedActivity?.id === activity.id ? 1 : 0,
                height: '100%',
                position: 'absolute',
                right: `-${extraAreaWidth + 5}px`,
                top: 0,
                marginLeft: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: `0 ${borderRadius}px ${borderRadius}px 0`,
                transition: 'all 0.3s ease',
              }}
            >
              <ChevronRight size={14} />
            </Box>
          </Box>
        </Link>
      </Box>
    )
  })
)

const size = 15
const sortOptions = [
  {
    label: 'Severity',
    by: 'severity',
    order: 'desc',
  },
  {
    label: 'Most Occurrences',
    by: 'occurrences',
    order: 'desc',
  },
  {
    label: 'Least Occurrences',
    by: 'occurrences',
    order: 'asc',
  },
  {
    label: 'Latest',
    by: 'last_seen',
    order: 'desc',
  },
]
const graphHeight = 130
export const Activities = ({ setSelectedActivity, selectedActivity }) => {
  const theme = useTheme()
  const { currentTimeFrame, queryApiTags } = useContext(FilterContext)
  const { activeOrg } = useContext(AppContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [activities, setActivities] = useState([])
  const [activeMenu, setActiveMenu] = useState()
  const [activeSort, setActiveSort] = useState(sortOptions[0])
  const [data, setData] = useState()

  const { orgId } = activeOrg || {}

  const url = `/activity/${orgId}/events`
  const initialFilters = {
    time: {
      from: currentTimeFrame?.startTime,
      to: currentTimeFrame?.stopTime,
      zone: queryApiTags?.timezone,
    },
    sort: {
      by: activeSort?.by,
      order: activeSort?.order,
    },
    page: {
      from: (currentPage - 1) * size || 0, // 'from' is zero indexed
      size,
    },
  }

  const payload = updateFilters({ initialFilters, filters: queryApiTags?.filters })

  const swrKey = JSON.stringify({ url, payload })

  const {
    data: fetchedData,
    error,
    mutate,
    isValidating,
  } = useSWR(
    swrKey,
    () =>
      coreApiClient({
        url,
        method: 'post',
        data: payload,
      }),
    { shouldRetryOnError: false }
  )
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData)
    }
  }, [fetchedData])
  useEffect(() => {
    if (data) {
      // Add a uniq id to each item
      setActivities(
        data?.results
          ?.filter((activity) => !!activity.error || !!activity.warning)
          ?.map((activity, idx) => {
            const id = `activity-${activity.fingerprint}-${activity.first_seen}-${activity?.last_seen}-${activity?.occurrences}-${idx}`
            return {
              ...activity,
              id,
              eventId: id, // Only used to show in messages in Inspector
            }
          })
      )
    }
  }, [data])

  const onMenuClose = (sortOption) => {
    setActiveMenu(null)
    if (sortOption) {
      setActiveSort(sortOption)
    }
  }

  const loading = !data && !error

  const currentSize = activities?.length || 0
  const items = range(activities?.length || 20)

  const headingStats = [
    {
      value: (
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button
            aria-controls="sort-menu"
            aria-haspopup="true"
            onClick={(e) => setActiveMenu(e?.currentTarget)}
            size="small"
            variant="outlined"
            endIcon={<DropdownIcon isOpen={!!activeMenu} style={{ marginLeft: 0 }} />}
            sx={{
              fontSize: theme.typography.textTertiary.fontSize,
              color: theme.palette.text.secondary,
            }}
          >
            Sort by {activeSort?.label}
          </Button>
          <AlertDialogButton />
        </Box>
      ),
    },
  ]

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: `${graphHeight + 90}px auto 50px`,
        height: '100%',
        minHeight: '90vh',
        overflowY: 'auto',
        minWidth: 350,
      }}
    >
      <Box
        sx={{
          padding: `${padding}px`,
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <ChartHeader
          title="Events"
          description="Events (errors & warnings)"
          stats={headingStats}
          isValidating={isValidating}
          loading={loading}
          error={error}
          showIcon={false}
          icon={<ActivityIcon size={17} />}
          hideActionsOnLoading={false}
          sx={{ marginBottom: 0 }}
        />

        <Menu
          id="sort-menu"
          anchorEl={activeMenu}
          keepMounted
          open={!!activeMenu}
          onClose={() => onMenuClose()}
        >
          {sortOptions?.map((sortOption, id) => (
            <MenuItem
              onClick={() => onMenuClose(sortOption)}
              key={`metrics-functions-list-${id}-${sortOption?.by}`}
            >
              <Typography variant="textSecondary">Sort by {sortOption.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <ActivitiesGraph height={graphHeight} />
        <Divider sx={{ marginTop: `auto` }} />
      </Box>
      {loading || (!error && activities?.length) ? (
        <Box
          sx={{
            width: '100%',
            overflowY: 'auto',
            padding: `0 ${padding}px`,
            height: '100%',
          }}
        >
          {items?.map((index) => {
            if (loading) {
              return (
                <Box
                  key={`metrics-activities-skeleton-loading-${index}`}
                  component={motion.div}
                  initial={{ y: 5, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { delay: `0.0${index}`, ease: 'easeInOut' },
                  }}
                >
                  <Skeleton variant="rect" width="50%" height={10} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant="rect" width="100%" height={80} sx={{ marginBottom: '10px' }} />
                </Box>
              )
            }
            if (activities[index]?.id) {
              return (
                <Event
                  key={activities[index]?.id}
                  activity={activities[index]}
                  setSelectedActivity={setSelectedActivity}
                  selectedActivity={selectedActivity}
                />
              )
            }
            return null
          })}
        </Box>
      ) : error ? (
        <ErrorState onReload={mutate} isColumnDirection fullWidth />
      ) : !activities?.length ? (
        <Typography
          variant="textSecondary"
          color="text.secondary"
          textAlign="center"
          sx={{ marginBottom: 'auto', marginTop: '70%' }}
        >
          No data found within this period.
        </Typography>
      ) : null}
      <Box
        sx={{
          marginTop: 'auto',
          padding: `10px ${padding}px`,
          borderTop: `1px solid ${theme.palette.border.main}`,
        }}
      >
        <SimplePagination
          currentSize={currentSize}
          size={size}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isValidating={isValidating}
          loading={loading}
        />
      </Box>
    </Box>
  )
}
