import { Tooltip } from '@mui/material'
import { forwardRef, memo, useMemo, useState } from 'react'
import classes from './ExplorerTable.module.css'
import clsx from 'clsx'
import { debounce } from 'lodash'

export const EventsCell = memo(
  forwardRef((params, ref) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const debouncedHandleMouseEnter = debounce((field) => handleShowPopper(field), 0)
    const handleShowPopper = (field) => {
      setShowTooltip(field)
    }

    const handleMouseLeave = () => {
      setShowTooltip(null)
      debouncedHandleMouseEnter.cancel()
    }

    const items = useMemo(
      () => [
        {
          headerName: 'Uncaught Errors',
          field: 'uncaught_errors',
          value: params.value.uncaught_errors || 0,
          //   Add class name if has value
          className: params.value.uncaught_errors && classes.isEventError,
        },
        {
          headerName: 'Caught Errors',
          field: 'caught_errors',
          value: params.value.caught_errors || 0,
          //   Add class name if has value
          className: params.value.caught_errors && classes.isEventPrimary,
        },
        {
          headerName: 'Warnings',
          field: 'warnings',
          value: params.value.warnings || 0,
          className: params.value.warnings && classes.isEventWarning,
        },
        {
          headerName: 'SDK Errors',
          field: 'sdkErrors',
          value: params.value.sdk_user_errors || 0,
        },
        {
          headerName: 'SDK Warnings',
          field: 'sdkWarnings',
          value: params.value.sdk_user_warnings || 0,
        },
      ],
      [params]
    )

    return (
      <div className={classes.explorerTableCellEventsWrapper}>
        {items.map((item) => {
          // Only show tooltip on hover to improve performance

          return (
            <div
              className={clsx(classes.explorerTableCellEventsNumber, item.className)}
              key={`traces-events-stats-${item.field}`}
              onMouseEnter={() => debouncedHandleMouseEnter(item.field)}
              onMouseLeave={handleMouseLeave}
            >
              {showTooltip === item.field ? (
                <Tooltip title={item.headerName} open={!!showTooltip}>
                  <div>{item.value || '-'}</div>
                </Tooltip>
              ) : (
                item.value || '-'
              )}
            </div>
          )
        })}
      </div>
    )
  })
)
