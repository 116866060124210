import { styled } from '@mui/material'

export const CountTag = styled('div')(({ theme, size }) => {
  const sizePx = size === 'small' ? '15px' : size === 'large' ? '28px' : '22px'
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontSize: '10px',
    fontWeight: '800',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    minWidth: sizePx,
    minHeight: sizePx,
    aspectRatio: '1/1',
    marginLeft: '10px',
    padding: '3px',
  }
})
