import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { Box, CssBaseline, Popper, Typography, alpha, useMediaQuery } from '@mui/material'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { colors } from './colors'
import { typography, fontWeight, fontFamily, fontSize } from './typography'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { LicenseInfo } from '@mui/x-license-pro'

import 'allotment/dist/style.css'
import './Calendar.css'
import './fonts.css'
import './index.css'

LicenseInfo.setLicenseKey(
  '098df756aecbba785a0ef83f8d5852a2Tz02NDgzMixFPTE3MTM1NjY1MjkzNTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const DEFAULT_BORDER_RADIUS = 6

/**
 * Material UI Custom Theme
 */

export const Theme = ({ children }) => {
  const { pathname } = useLocation()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const systemMode = prefersDarkMode ? 'dark' : 'light'
  const [savedMode, setSavedMode] = useLocalStorage('serverless-theme-mode', 'dark')

  const mode = savedMode === 'system' ? systemMode : savedMode
  const chatWidget = document.getElementById('hubspot-messages-iframe-container')
  const mainAppClass = `app-${mode}-mode`

  /**
   * Add class to chat widget
   */

  useEffect(() => {
    document.body.classList.add(mainAppClass)

    if (
      /dev-mode/.test(pathname) ||
      /accept-invitation/.test(pathname) ||
      ['/', '/callback', '/password/reset', '/password/edit'].includes(pathname)
    ) {
      chatWidget?.classList?.add('dev-mode')
    } else if (!/dev-mode/.test(pathname)) {
      chatWidget?.classList?.remove('dev-mode')
    }
  }, [pathname, chatWidget, mode])

  const isDarkMode = mode === 'dark'

  /**
   * Colors
   */

  const primary = {
    main: isDarkMode ? colors.white : colors.blackDark,
    light: isDarkMode ? colors.greyDark : colors.blackLight,
    dark: isDarkMode ? colors.white : colors.blackDark,
    contrastText: isDarkMode ? colors.blackDark : colors.white,
  }

  const secondary = {
    main: isDarkMode ? colors.blackDark : colors.white,
    light: isDarkMode ? colors.blackMedium : colors.greyMedium,
    dark: isDarkMode ? colors.blackDark : colors.white,
    contrastText: isDarkMode ? colors.white : colors.blackDark,
  }

  const error = {
    main: colors.redPrimary,
    light: colors.redLight,
    dark: colors.redDark,
    contrastText: colors.white,
  }

  const info = { ...primary }

  const border = {
    main: isDarkMode ? colors.blackLight : colors.greyMedium,
    light: isDarkMode ? colors.blackUltraLight : colors.greyUltraDark,
    dark: isDarkMode ? colors.blackMediumLight : colors.greyLight,
  }

  const shadow = {
    main: isDarkMode ? 'rgba(255,255,255,0.125)' : 'rgba(0,0,0,0.15)',
  }

  const grey = {
    main: isDarkMode ? colors.blackMediumLight : colors.greyMedium,
    dark: isDarkMode ? colors.blackMedium : colors.greyLight,
    medium: isDarkMode ? colors.blackLight : colors.greyMedium,
    light: isDarkMode ? colors.blackUltraLight : colors.greyDark,
  }

  const text = {
    primary: primary.main,
    secondary: isDarkMode ? colors.greyUltraDark : colors.blackUltraLight,
    red: error.main,
  }

  const background = {
    default: secondary.main,
    paper: secondary.main,
  }

  const cssVars = {
    [`.${mainAppClass}`]: {
      '--primary': primary.main,
      '--secondary': secondary.main,
      '--red': colors.redPrimary,
      '--white': colors.white,
      '--black': colors.blackDark,
      '--greyDark': grey.dark,
      '--greyMedium': grey.medium,
      '--greyLight': grey.light,
      '--borderMain': border.main,
      '--borderDark': border.dark,

      // These borders are for the split view panels used by allotment
      '--focus-border': border.main,
      '--separator-border': border.main,

      '--text-primary-color': text.primary,
      '--text-secondary-color': text.secondary,
      '--text-primary-size': typography.textPrimary.fontSize + 'px',
      '--text-secondary-size': typography.textSecondary.fontSize + 'px',
      '--text-tertiary-size': typography.textTertiary.fontSize + 'px',
    },
  }

  const borderRadius = DEFAULT_BORDER_RADIUS

  const theme = createTheme({
    palette: {
      systemMode,
      isDarkMode,
      savedMode,
      mode,
      setSavedMode,
      colors,
      primary,
      secondary,
      error,
      info,
      border,
      shadow,
      grey,
      text,
      background,
      action: {
        disabledBackground: grey.medium,
      },
    },
    typography,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: background.default,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& a': {
              textDecoration: 'none',
            },

            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
              {
                outline: 'none !important',
              },
            '& .MuiToolbar-root .MuiTablePagination-displayedRows': {
              color: text.secondary,
            },
            '& .MuiToolbar-root .MuiTablePagination-actions': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-iconButtonContainer': {
              marginLeft: '8px',
              '& button': {
                fontSize: '1.1rem',
              },
            },

            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: grey.dark,
            },

            '& .MuiDataGrid-cell': {
              borderColor: 'grey.dark',
              color: text.secondary,
            },

            '& .MuiDataGrid-cellContent': {
              color: text.secondary,
            },

            '& .MuiDataGrid-columnHeaderTitleContainer': {
              color: text.secondary,
            },
            '& .MuiDataGrid-pinnedColumnHeaders': {
              background: 'secondary.main',
              backgroundImage: 'none',
            },
            '& .MuiDataGrid-pinnedColumns': {
              background: 'secondary.main',
              backgroundImage: 'none',
            },
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            '& .MuiBadge-badge': {
              border: `2px solid ${secondary.main}`,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 6,
          },
          outlinedInfo: {
            borderColor: grey.light,
            display: 'flex',
            alignItems: 'center',
            '& .MuiAlert-icon': {
              color: primary.main,
            },
          },
        },
      },

      MuiCircularProgress: {
        defaultProps: {
          color: 'primary',
        },
      },
      MuiLinearProgress: {
        defaultProps: {
          color: 'primary',
        },
        styleOverrides: {
          root: {
            height: 6,
            borderRadius: 4,
            backgroundColor: grey.medium,
          },
        },
      },
      /**
       * Used for checkbox with label
       */
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: 0.6,
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          icon: <CheckBoxOutlineBlankIcon sx={{ color: grey.light }} />,
          checkedIcon: <CheckBoxIcon sx={{ color: primary.main }} />,
        },
        styleOverrides: {
          root: {
            borderRadius: 4,
            transition: 'all 0.3s ease',
            padding: 6,
            '&:hover': {
              backgroundColor: 'none',
              '& svg': {
                fill: primary.main,
              },
            },
          },
        },
      },
      /**
       * Divider border color is either light or secondary. It's light by default.
       */
      MuiDivider: {
        defaultProps: {
          light: true,
        },
        styleOverrides: {
          root: {
            borderColor: primary.main,
          },
          light: {
            borderColor: grey.medium,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
          IconComponent: (props) => (
            <DropdownIcon
              size={15}
              style={{ marginLeft: 0, color: grey.medium }}
              isOpen={props?.className.includes('MuiSelect-iconOpen')}
              {...props}
            />
          ),
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: primary.main,
            height: '2px',
          },
          rail: {
            height: '2px',
          },

          valueLabel: {
            backgroundColor: primary.main,
            color: secondary.main,
          },
          mark: {
            backgroundColor: secondary.main,
            height: '4px',
            borderRadius: 0,
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <CloseIcon />,
        },
        styleOverrides: {
          root: {
            height: 'auto',
            minWidth: 41,
            alignItems: 'center',
            borderRadius: 3,
            fontSize: 11,
            '&.MuiChip-colorDefault': {
              backgroundColor: grey.medium,
            },
            '&.MuiChip-colorError': {
              backgroundColor: colors.redPrimary,
            },
          },
          label: {
            paddingTop: '4px',
            paddingBottom: '4px',
          },
          deleteIcon: {
            marginLeft: 0,
            marginRight: 8,
            fontSize: 14,
            fill: colors.greyDark,
            '&:hover': {
              fill: isDarkMode ? colors.greyMedium : colors.greyUltraDark,
            },
          },
        },
      },

      MuiSwitch: {
        defaultProps: {
          color: 'secondary',
        },
        styleOverrides: {
          root: {
            width: 40,
            height: 16,
            padding: 0,
            display: 'flex',
            '&:active': {
              '& .MuiSwitch-thumb': {
                width: 15,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(17px)',
              },
            },
            '& .MuiSwitch-switchBase': {
              padding: 2,
              transform: 'translateX(1px)',
              color: colors.white,
              '&.Mui-disabled': {
                color: colors.white,
                '& + .MuiSwitch-track': {
                  backgroundColor: grey.light,
                },
              },
              '&.Mui-checked': {
                transform: 'translateX(24px)',
                color: secondary.main,
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: primary.main,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
              width: 11,
              height: 11,
              marginTop: '0.25px',
              borderRadius: 6,
              transition: 'width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            '& .MuiSwitch-track': {
              borderRadius: 16 / 2,
              opacity: 1,
              backgroundColor: isDarkMode ? colors.greyUltraDark : colors.greyDark,
              boxSizing: 'border-box',
            },
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          focusRipple: false,
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'primary',
          variant: 'contained',
          size: 'medium',
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            textTransform: 'initial',
            padding: '5px 10px',
            fontWeight,
            boxShadow: 'none',
            border: ownerState.color === 'secondary' ? `1px solid ${border.main}` : 'none',
            '&:hover': {
              backgroundColor: theme.palette[ownerState.color].light,
              border: ownerState.color === 'secondary' ? `1px solid ${border.light}` : 'none',
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              transform: 'scale(0.97)',
            },
            '&.Mui-disabled': {
              border:
                ownerState.color === 'secondary' ? `1px solid ${border.main}!important` : 'none',
              '&.MuiButtonGroup-grouped': {
                color: theme.palette.text.secondary,
                background: theme.palette.grey.medium,
                '& .MuiButton-endIcon': {
                  pointerEvents: 'auto',
                },
              },
            },
          }),
          contained: ({ theme, ownerState }) => ({
            '&.Mui-disabled': {
              color: theme.palette[ownerState.color].contrastText,
              backgroundColor: grey.light,
              border:
                ownerState.color === 'secondary' ? `1px solid ${border.main}!important` : 'none',
            },
          }),
          text: ({ theme, ownerState }) => ({
            color: theme.palette.secondary.contrastText,
            border: `none`,
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
              border: `none`,
            },
          }),
          outlined: () => ({
            color: theme.palette.secondary.contrastText,
            border: `1px solid ${isDarkMode ? border.main : border.main}`,
            '&:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${primary.main}`,
              boxShadow: 'none',
            },
          }),
          startIcon: {
            color: 'inherit',
          },
          sizeLarge: {
            minWidth: 180,
            minHeight: 41,
            fontSize: 15,
          },
          sizeMedium: {
            minWidth: 115,
            minHeight: 33,
            fontSize: 14,
          },
          sizeSmall: {
            fontSize: 13,
          },
          iconSizeMedium: {
            '& svg': {
              width: '18px !important',
            },
          },
          iconSizeSmall: {
            '& svg': {
              width: '15px !important',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            transition: 'all .2s ease-in-out',
            '&.Mui-disabled': {
              color: colors.greyUltraDark,
            },
          },
        },
      },

      MuiTabs: {
        defaultProps: {
          indicatorColor: 'primary',
        },
      },

      MuiTab: {
        defaultProps: {
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            padding: 0,
            minWidth: 60,
            margin: '0 20px 0 0',
            fontSize: typography.textPrimary.fontSize,
            transition: 'all .2s ease-in-out',
            '&.Mui-selected': {
              color: primary.main,
            },
            '&:hover': {
              color: primary.main,
            },
          },
        },
      },

      MuiMenu: {
        defaultProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          transitionDuration: 0,
        },

        styleOverrides: {
          paper: {
            borderRadius: 4,
          },
        },
      },

      MuiTooltip: {
        defaultProps: {
          placement: 'top',
          arrow: true,
          enterDelay: 500,
          TransitionProps: {
            timeout: 0,
          },
        },

        styleOverrides: {
          tooltip: {
            backgroundColor: primary.main,
            color: isDarkMode ? colors.blackDark : colors.white,
            fontSize: 11,
            padding: '8px 16px',
            fontWeight: 'bold',
            borderRadius: 2,
          },
          arrow: {
            color: primary.main,
          },
        },
      },

      MuiPaper: {
        defaultProps: {
          square: true,
          elevation: 0,
        },
      },

      MuiNativeSelect: {
        defaultProps: {
          disableUnderline: true,
        },
      },

      MuiAutocomplete: {
        defaultProps: {
          noOptionsText: 'No options',
          disableClearable: true,
          limitTags: 2,
          size: 'small',
          freeSolo: true,
          filterSelectedOptions: true,
          ChipProps: {
            deleteIcon: <CloseIcon style={{ fontSize: 15 }} />,
            size: 'small',
            style: { cursor: 'pointer' },
          },
          PopperComponent: (props) => {
            return (
              <Popper
                {...props}
                style={{ ...props.style }}
                children={
                  !props.children.props.children.some((child) => child) ? (
                    <Box
                      sx={{
                        border: `1px solid ${border.main}`,
                        p: 1,
                        bgcolor: 'secondary.main',
                        width: '100%',
                        minWidth: '100%',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant="textTertiary">No options</Typography>
                    </Box>
                  ) : (
                    props.children
                  )
                }
              />
            )
          },
        },
        styleOverrides: {
          root: {
            padding: 0,
            width: '100%',
            border: 'none',

            '& .MuiAutocomplete-tag': {
              '&:not(.MuiButtonBase-root)': {
                fontSize: typography.textSecondary.fontSize,
              },
            },

            '& .MuiInputBase-root': {
              '& .MuiAutocomplete-input': {},
              '&.Mui-focused': {
                position: 'relative',
                '& .MuiAutocomplete-input': {},
              },
            },
          },
          paper: {
            borderRadius: 4,
            maxWidth: '100%',
            marginTop: 1,
            border: `1px solid ${border.main}`,
          },

          listbox: {
            padding: 0,
            boxSizing: 'border-box',
            '& li': {
              color: text.secondary,
              fontSize: typography.textSecondary.fontSize,
              fontWeight: 100,
              padding: '10px 20px !important',

              '&[aria-disabled="true"]': {
                opacity: '0.6 !important',
              },
              '&:hover': {
                color: text.primary,
              },
              '& .Mui-checked': {
                '& svg': {
                  color: primary.main,
                },
              },
            },
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
          noOptions: {
            color: '#777',
            padding: '6px 16px',
            fontSize: typography.textPrimary.fontSize,
            fontStyle: 'italic',
          },
        },
      },

      MuiPopover: {
        defaultProps: {
          transitionDuration: 0,
        },
      },

      MuiDialog: {
        defaultProps: {
          transitionDuration: 0,
        },
        styleOverrides: {
          root: {
            backgroundColor: alpha(background.default, 0.5),
            backdropFilter: 'blur(5px)',
          },
          backdrop: {
            backgroundColor: 'blue',
          },
          paper: {
            padding: 8,
            borderRadius: 4,
            background: secondary.main,
            border: `1px solid ${grey.medium}`,
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: typography.h2.fontSize,
          },
        },
      },
      MuiDialogContentText: {
        defaultProps: {
          component: Typography,
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '8px 24px 24px',
          },

          spacing: {
            '& > :not(:first-of-type)': {
              marginLeft: '16px !important',
            },
          },
        },
      },

      MuiGrid: {
        styleOverrides: {
          container: {
            '&$spacing-xs-1': {
              width: `calc(100% + ${2.5 * 1}px)`,
              margin: -2.5,
              '& $item': {
                padding: 2.5,
              },
            },
          },
        },
      },

      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
          },
        },
      },

      MuiListItem: {
        styleOverrides: {
          root: {
            transition: 'all 0.3s ease',
            padding: 5,
            borderRadius: 4,
            /**
             * On hover but not selected
             */
            '&:not(.Mui-selected):hover': {
              // paddingLeft: 18,
              backgroundColor: grey.dark,
            },
            '&.Mui-selected': {
              backgroundColor: grey.dark,
              '&:hover': {
                backgroundColor: grey.dark,
              },
            },
          },

          divider: {
            borderColor: grey.light,
          },
        },
      },

      MuiInputBase: {
        defaultProps: {},
        styleOverrides: {
          input: {
            fontSize: 14,
            minHeight: 23,
            '&::placeholder': {
              opacity: 1,
              color: isDarkMode ? colors.blackUltraLight : colors.greyUltraDark,
              fontWeight,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
              WebkitBoxShadow: `0 0 0px 100000px ${grey.dark} inset`,
              WebkitTextFillColor: primary.main,
            },
            '& .Mui-disabled ': {
              opacity: 0.4,
            },
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
          disableAnimation: true,
          color: 'primary',
        },
        styleOverrides: {
          root: {
            position: 'relative',
            transform: 'none',
            fontSize: 13,
            marginBottom: 8,
            color: primary.main,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            width: 'auto',
            height: 'auto',

            '& svg': {
              stroke: isDarkMode ? colors.blackUltraLight : colors.greyUltraDark,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            border: `1px solid ${isDarkMode ? colors.blackLight : colors.greyDark}`,
            transition: 'all ease 0.25s',
            '&.MuiInputBase-adornedEnd': {
              paddingRight: '4px',
            },
            '&.Mui-focused': {
              border: `1px solid ${primary.main}!important`,
            },
          },
          input: {
            fontSize,
            padding: '6px 9px',
            '&.MuiInputBase-inputAdornedStart': {
              padding: '6px 2px',
            },
          },
          notchedOutline: {
            border: 'none',
          },
          adornedStart: {},
        },
      },

      MuiAvatar: {
        styleOverrides: {
          root: {
            position: 'initial',
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: primary.main,
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              marginLeft: '2px !important',
              color: '#fd5750 !important',
            },
          },

          contained: {
            marginLeft: 0,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: 14,
            fontWeight,
            textRendering: 'optimizeLegibility',
            mozOsxFontSmoothing: 'grayscale',
            webkitFontSmoothing: 'antialiased',
            fontFamily,
            /**
             * These variables are used in different files such as the
             * index.css, Calendar.css to style colors for both themes
             */
            ...cssVars,
          },

          'body, #root': {
            height: '100%',
            fontSize: 14,
            fontWeight,
            fontFamily,
          },

          'html, body, div, h1, h2, h3, h4, h5, h6, p, span, input, a, li': {
            fontFamily,
          },

          '*::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },

          '*::-webkit-scrollbar': {
            width: 6,
            height: 6,
          },

          '*::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent',
          },

          '*::-webkit-scrollbar-thumb': {
            background: grey.medium,
            borderRadius: 4,
          },

          '.ellipsis': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },

          '.hidden-scrollbars': {
            scrollbarWidth: 'none',

            '&::-webkit-scrollbar': {
              width: 0,
              height: 0,
            },
          },
        },
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ThemeProvider>
  )
}
