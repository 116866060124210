import { getDuration } from 'util/duration'
import { formatDuration } from 'util/format'
import { attachMetaTags } from 'common/packages/ActivityStream/utils/helper'

/**
 * Enrich, sanitize Span data
 */

export const enrichSpan = (span, rootSpan) => {
  try {
    span.spanId = span?.spanId || null
    span.parentSpanId = span?.parentSpanId && span?.parentSpanId !== '' ? span?.parentSpanId : null
    span.timestamp = span?.timestamp || null
    span.startTime = span?.startTime || null
    span.endTime = span?.endTime || null

    // Only depend on startTime and endTime for duration
    if (span.startTime && span.endTime) {
      span.duration = getDuration(span.startTime, span.endTime)
      span.durationFormatted = formatDuration(span.duration)
    }

    if (rootSpan) {
      span = attachMetaTags(span, rootSpan.tags)
    }

    // Format all child spans similarly
    if (span?.spans) {
      span.spans = span.spans.map((s) => {
        return enrichSpan(s, rootSpan || span)
      })
    }
    return span
  } catch (err) {
    return null
  }
}
