import { useTheme } from '@mui/styles'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { convertMessage } from 'common/utils/instrumentationErrorMessages'

export const InstrumentationFailureList = ({
  expanded,
  incompleteInventories,
  includeLeftPad = false,
  invertColors = false,
  maxWidth,
}) => {
  const theme = useTheme()
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Paper
        sx={{
          maxWidth,
          ...(includeLeftPad ? { padding: '0px 8px' } : { padding: '0' }),
          background: 'inherit !important',
        }}
      >
        {incompleteInventories.map((inventory, index) => (
          <Box key={inventory?.resourceKey}>
            <Box display="flex" alignItems="center" gap="15px">
              {includeLeftPad && <Box minWidth="18px" />}
              <Box overflow="hidden">
                <Typography
                  color={invertColors ? 'secondary' : 'primary'}
                  sx={{
                    ...(includeLeftPad ? {} : { marginBottom: '0px !important' }),
                    overflow: 'hidden',
                    textWrap: 'no-wrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {inventory?.resourceKey?.split('_').pop()}
                </Typography>
                <Typography color={theme.palette.grey.light} gutterBottom>
                  {convertMessage(inventory.failReason)}
                </Typography>
              </Box>
            </Box>
            {index < incompleteInventories.length - 1 && (
              <Divider sx={{ mb: 1, borderColor: theme.palette.grey.light }} />
            )}
          </Box>
        ))}
      </Paper>
    </Collapse>
  )
}
