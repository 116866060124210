import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
import { motion } from 'framer-motion'

export const ItemsCounter = ({ count }) => {
  const theme = useTheme()
  const size = '18px'
  if (!count) {
    return null
  }
  return (
    <Box
      sx={{
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        fontSize: '10px !important',
        fontWeight: '800',
        background: theme.palette.primary.main,
        borderRadius: '50%',
        width: size,
        height: size,
        marginLeft: '15px',
        padding: '0 0 0 0.5px',
      }}
      component={motion.div}
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1, transition: { duration: 0.25, ease: 'easeOut' } }}
    >
      {count}
    </Box>
  )
}
