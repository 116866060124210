import { IconAWSLambda } from './../../icons/IconAWSLambda'
import classes from './ReqResMessage.module.css'

export const ReqResMessage = ({ activity }) => {
  const message =
    activity.type === 'aws-lambda-response'
      ? 'AWS LAMBDA - INVOCATION ENDED'
      : 'AWS LAMBDA - INVOCATION STARTED'
  const hasError = !!activity?.tags?.error && Object.keys(activity?.tags?.error || {}).length > 0
  const environment = activity?.tags?.environment ? (
    <div className={classes.column}>
      <div className={`${classes.columnHeader} ${hasError && classes.error}`}>Environment</div>
      <div className={`${classes.columnValue} ${hasError && classes.error}`}>
        {activity?.tags?.environment}
      </div>
    </div>
  ) : null
  const namespace = activity?.tags?.namespace ? (
    <div className={classes.column}>
      <div className={`${classes.columnHeader} ${hasError && classes.error}`}>Namespace</div>
      <div className={`${classes.columnValue} ${hasError && classes.error}`}>
        {activity?.tags?.namespace}
      </div>
    </div>
  ) : null
  const resourceName = activity?.tags?.aws?.resourceName
  const region = activity?.tags?.aws?.region
  return (
    <div className={classes.reqResContainer}>
      <div className={`${classes.header} ${hasError && classes.error}`}>
        <IconAWSLambda
          style={{
            fill: hasError ? 'var(--activityRed)' : 'var(--activityPrimary)',
            width: '24px',
          }}
        />
        {message}
      </div>
      <div className={classes.meta}>
        <div className={classes.column}>
          <div className={`${classes.columnHeader} ${hasError && classes.error}`}>Function</div>
          <div className={`${classes.columnValue} ${hasError && classes.error}`}>
            {resourceName}
          </div>
        </div>
        {environment}
        {namespace}
        <div className={classes.column}>
          <div className={`${classes.columnHeader} ${hasError && classes.error}`}>Region</div>
          <div className={`${classes.columnValue} ${hasError && classes.error}`}>{region}</div>
        </div>
        {activity?.tags?.error?.name === 'function_timeout' && (
          <div className={classes.column}>
            <div className={`${classes.columnHeader} ${hasError && classes.error}`}>Timeout</div>
            <div className={`${classes.columnValue} ${hasError && classes.error}`}>
              {activity?.tags?.error?.message}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
