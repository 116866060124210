import { styled, Box } from '@mui/material'

export const LoadingSoundwave = () => {
  return (
    <Box
      height="18px"
      width="auto"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Bar sx={{ animationDelay: '0s' }} />
      <Bar sx={{ animationDelay: '0.1s' }} />
      <Bar sx={{ animationDelay: '0.25s' }} />
      <Bar sx={{ animationDelay: '0.4s' }} />
      <Bar sx={{ animationDelay: '0.6s' }} />
    </Box>
  )
}

const Bar = styled('div')(({ theme }) => ({
  transform: 'scaleY(0.6)',
  height: '100%',
  width: '2.5px',
  background: theme.palette.primary.main,
  margin: '0 8px 0 0',
  animationDuration: '1.35s',
  animationName: 'barPulse',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
  borderRadius: '12px',
}))
