import { Box, Typography } from '@mui/material'
import { CustomPaper } from 'common/components/CustomPaper'
import { TagSection } from '../alerts/Alerts'
import { formatDistance } from 'date-fns'
import Button from 'common/components/Button'
import { types } from 'settings/util/secretTypes'

export const SecretRow = ({ secret, onClick, deleteSecret }) => {
  const dates = {
    createdAt:
      secret?.createdAt &&
      new Date(secret?.createdAt) &&
      formatDistance(new Date(secret?.createdAt), new Date(), {
        addSuffix: true,
      }),
    updatedAt:
      secret?.updatedAt &&
      new Date(secret?.updatedAt) &&
      formatDistance(new Date(secret?.updatedAt), new Date(), {
        addSuffix: true,
      }),
  }

  const secretOption = types?.find((type) => {
    if (secret?.provider) {
      return type.provider?.providerType === secret?.provider?.providerType
    }
    return type.valueType === secret?.valueType
  })

  return (
    <CustomPaper
      variant="outlined"
      allowHover
      sx={{
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '2fr 2fr 1fr 1fr',
        gap: '20px',
        mb: 2,
        textAlign: 'left',
      }}
      onClick={() => onClick(secret)}
    >
      <Box
        marginLeft="0"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '20px',
          textAlign: 'left',
        }}
      >
        <Box
          sx={{
            width: '20%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {secretOption?.icon}
        </Box>
        <Box width="100%">
          <Typography
            variant="textTertiary"
            color="text.secondary"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {secretOption?.label}
          </Typography>
          <Typography
            variant="textPrimary"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {secret?.path}/{secret?.name}
          </Typography>
        </Box>
      </Box>

      <TagSection
        title={
          secret?.provider?.accessKeyId ? 'Access Key' : secret?.provider?.arn ? 'ARN' : 'Value'
        }
        value={
          secret?.provider?.accessKeyId ||
          secret?.provider?.arn ||
          '●●●●●●●●●●●●●●●●●●●●●●●●' ||
          '-'
        }
        sx={{ width: '100%' }}
      />

      <TagSection title="Updated" value={dates?.updatedAt} />
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="text"
          size="small"
          sx={{
            minWidth: 70,
          }}
          onClick={(e) => {
            e?.stopPropagation()
            deleteSecret(secret)
          }}
        >
          Remove
        </Button>
        <Button variant="outlined" size="small" sx={{ margin: 'auto 0' }} onClick={onClick}>
          Edit
        </Button>
      </Box>
    </CustomPaper>
  )
}
