import { useForkRef } from '@mui/material'
import { isValid } from 'date-fns'
import { stringifyUrl } from 'query-string'
import { forwardRef, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { StringParam } from 'use-query-params'

const traceParams = [
  {
    key: 'explorerTraceId',
    value: 'trace_id',
    type: StringParam,
  },
  {
    key: 'explorerTraceTime',
    value: 'start_time',
    type: StringParam,
  },
]

/**
 * This is used to wrap each row with a link component instead of wrapping each cell.
 */
export const CustomRow = forwardRef((props, ref) => {
  const { Component, orgName, search, ...other } = props

  const rootRef = useRef()
  const handleRef = useForkRef(rootRef, ref)

  const link = useMemo(
    () =>
      stringifyUrl({
        url: `/${orgName}/explorer${search}`,
        query: traceParams.reduce(
          (acc, param) => ({
            ...acc,
            [param.key]:
              param.key === 'explorerTraceTime' && isValid(new Date(props?.row?.[param.value]))
                ? new Date(props?.row?.[param.value])?.toISOString()
                : props?.row?.[param.value],
          }),
          {}
        ),
      }),
    [props.rowId, search]
  )

  return (
    <Link to={link}>
      <Component ref={handleRef} {...other} />
    </Link>
  )
})
