import { useContext, useRef, useState } from 'react'
import { styled, Box } from '@mui/material'
import { FilterContext, FilterProvider } from 'filter/context/FilterContext'
import { ExplorerSidebar } from 'explorer/components/ExplorerSidebar'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { ModalTrace } from 'trace/components/ModalTrace'
import { EnableBulkInstrumentation } from 'common/components/EnableBulkInstrumentation'
import { AppContext } from 'app/context/AppContext'
import InvocationsGraph from 'widgets/components/InvocationsGraph'
import { useQuery } from 'metrics/hooks/useQuery'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { Allotment } from 'allotment'
import Button from 'common/components/Button'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { ExplorerTable } from './ExplorerTable/ExplorerTable'
import { CountTag } from 'common/components/CountTag'

const initialPage = withDefault(NumberParam, 1)
const Pane = Allotment.Pane

const Explorer = () => {
  usePageTitle(`Explorer`)
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const { data: hasData } = useQuery({ query: 'metrics_exist' })
  const ref = useRef()

  const [currentPage, setCurrentPage] = useQueryParam('explorerPage', initialPage)
  const { filtersCount } = useContext(FilterContext)
  const [isSidebarVisible, setIsSidebarVisible] = useState(true)
  return (
    <EnableBulkInstrumentation
      orgId={orgId}
      hasExistingData={hasData}
      bypassKey="sls-prod-mode-prompt"
      instrumentationType="prod"
    >
      <Container>
        <Allotment
          horizontal
          ref={ref}
          onVisibleChange={(_index, value) => setIsSidebarVisible(value)}
        >
          <Pane snap preferredSize={300} visible={isSidebarVisible}>
            <ExplorerSidebar setCurrentPage={setCurrentPage} />
          </Pane>
          <Pane minSize={500}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'grid',
                gridTemplateRows: '310px minmax(50%,1fr)',
                gridTemplateColumns: 'minmax(50%,1fr)',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  padding: '20px',
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <InvocationsGraph showRefreshButton height={170} />
              </Box>

              <ExplorerTable currentPage={currentPage} setCurrentPage={setCurrentPage} />

              {/* Toggle sidebar button with a selected filters count that shows when sidebar is collapsed */}
              <Button
                variant="outlined"
                sx={{
                  marginTop: 'auto',
                  position: 'absolute',
                  left: '-3px',
                  bottom: '10px',
                  minWidth: 33,
                  borderRadius: '0 4px 4px 0',
                  color: 'text.secondary',
                }}
                size="small"
                onClick={() => setIsSidebarVisible((prev) => !prev)}
              >
                {filtersCount && !isSidebarVisible ? (
                  <CountTag size="small">{filtersCount}</CountTag>
                ) : null}
                <DropdownIcon isOpen={!isSidebarVisible} isHorizontal style={{ marginLeft: 0 }} />
              </Button>
            </Box>
          </Pane>
        </Allotment>
      </Container>
    </EnableBulkInstrumentation>
  )
}

export const ExplorerWrapper = () => {
  return (
    <ErrorBoundary>
      <FilterProvider page="explorer">
        <Explorer />
        <ModalTrace />
      </FilterProvider>
    </ErrorBoundary>
  )
}

const Container = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: `100%`,
  width: '100%',
  boxSizing: 'border-box',
  minWidth: '800px',
  maxWidth: 'calc(100vw - 80px)', // view width minus sidebar
  overflowY: 'hidden',
  overflowX: 'overlay',
}))

export default ExplorerWrapper
