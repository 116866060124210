/**
 * Font families
 */
export const fontFamily = ['Muller', 'sans-serif'].join(',')
export const fontFamilyMonospace = ['Menlo', 'Monaco', 'Courier New', 'monospace'].join(',')

export const fontSize = 14
export const fontWeight = 500

export const typography = {
  fontFamily,
  lineHeight: 1,
  fontSize: 14,
  fontWeight, // we only use medium muller font

  h1: {
    fontSize: 21,
    lineHeight: '30px',
    fontWeight,
  },
  h2: {
    fontSize: 19,
    lineHeight: '28px',
    fontWeight,
  },
  h3: {
    fontSize: 17,
    lineHeight: '26px',
    fontWeight,
  },
  h4: {
    fontSize: 15,
    lineHeight: '25px',
    fontWeight,
  },
  h5: {
    fontSize: 15,
    lineHeight: '25px',
    fontWeight,
  },
  h6: {
    fontSize: 15,
    lineHeight: '25px',
    fontWeight,
  },
  textPrimary: {
    fontSize: 14,
    lineHeight: '23px',
    display: 'block',
  },
  textSecondary: {
    fontSize: 12,
    lineHeight: '21px',
    display: 'block',
  },
  textTertiary: {
    fontSize: 11,
    lineHeight: '20px',
    display: 'block',
  },
  linkPrimary: {
    fontSize: 14,
    lineHeight: '23px',
    textDecoration: 'underline',
  },
  linkSecondary: {
    fontSize: 11,
    lineHeight: '21px',
    textDecoration: 'underline',
  },
}
