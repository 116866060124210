import { objectify } from 'util/param'
import { hubspot } from 'common/hubspot'
import { parseRef } from 'common/tracking'

export const helperExtractInitialParams = (search = window.location.search) => {
  // Required for cheap route identification
  const pathname = window.location.pathname

  // Extract needed params from query string, treated as an object
  const params = objectify(search)

  const {
    // This query parameter is the Auth0 callback flow 'code'.
    // If provided, this is the callback flow from Auth0.
    code,

    // The type of 'client' that is logging in (e.g. 'web', 'cli')
    // The command preceding the login
    client = 'web',
    clientOriginCommand,
    clientVersion,

    // Error coming back from auth0 if 3rd party cookies are disabled
    error_description,

    // This is for non-browser clients trying to log in (e.g cli or electron app)
    transactionId,

    // (Optional)
    // Can be either 'github' or 'google-oauth2'. If specified, then the social
    // auth button is immediately invoked.
    socialAuthType,

    // UTM tracking parameters
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,

    // Ref tags
    // Should only be one of the following
    ref_cli,
    ref_website,
    ref_social,
    ref_email,
    email_source,

    state,
  } = params

  if (window.location.pathname !== '/accept-invitation') {
    // Cleanse the query string without invoking a refresh
    window.history.replaceState({}, document.title, pathname)
  }

  // Parse ref tags
  const ref = parseRef({
    ref_cli,
    ref_website,
    ref_social,
    ref_email,
    client,
    clientOriginCommand,
  })

  // Here we normalise the casing of query parameters for better recall
  const query = {
    client,
    clientOriginCommand,
    clientVersion,
    code: Array.isArray(code) ? code[0] : code,
    errorDescription: error_description,
    socialAuthType,
    transactionID: transactionId,
    utmCampaign: utm_campaign,
    utmContent: utm_content,
    utmMedium: utm_medium,
    utmSource: utm_source,
    utmTerm: utm_term,
    ref,
    email_source,
    state,
  }

  /**
   * Track initial pageview with UTMs
   */
  hubspot.trackPageview(query.view, {
    referrer_source: query.utmSource || null,
    referrer_medium: query.utmMedium || null,
    referrer_campaign: query.utmCampaign || null,
    referrer_content: query.utmContent || null,
    referrer_term: query.utmTerm || null,
  })

  return query
}
