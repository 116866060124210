import {
  styled,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { ChevronRight } from 'lucide-react'
import { LogItem } from 'common/components/LogItem'

export const SectionOutput = ({ output = null, ...props }) => {
  return (
    <StyledAccordion {...props}>
      <StyledAccordionSummary>
        <Typography>Output</Typography>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <LogItems>
          <Box sx={{ padding: '20px 0 0 15px' }}>
            <LogItem logBody={output} isClickable={false} />
          </Box>
        </LogItems>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: '0',
  margin: '0',
  maxHeight: '80%',
  overflow: 'hidden',
  fontFamily: "'Roboto Mono', monospace",
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& .MuiCollapse-entered': {
    height: '100%!important',
    overflow: 'hidden',
  },
  '& .MuiCollapse-wrapper': {
    height: '100%!important',
    padding: '0',
    overflow: 'hidden',
  },
  '& .MuiCollapse-wrapperInner': {
    height: '100%!important',
    padding: '0',
    overflow: 'hidden',
  },
  '& .MuiAccordion-region': {
    height: '100%!important',
    padding: '0',
    overflow: 'hidden',
  },
}))

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRight size={14} />} {...props} />
))(({ theme }) => ({
  minHeight: '40px',
  padding: '0 0 0 20px',
  margin: '0',
  flexDirection: 'row-reverse',
  alignContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  '& svg': {
    color: theme.palette.text.secondary,
  },
  '& p': {
    fontSize: '13px',
    fontFamily: "'Roboto Mono', monospace",
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 6px',
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  height: '100%',
  padding: '0px',
}))

const LogItems = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: '1000!important',
  display: 'block',
  width: '100%',
  height: '93%',
  padding: '0 20px 60px 13px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  overflowY: 'scroll!important',
}))
