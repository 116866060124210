import { Paper } from '@mui/material'
import { styled } from '@mui/styles'
import { merge } from 'lodash'
import { forwardRef } from 'react'

export const CustomPaper = forwardRef(
  ({ children, allowHover, variant = 'outlined', sx = {}, ...rest }, outerRef) => {
    return (
      <StyledPaper
        ref={outerRef}
        variant={variant}
        sx={merge(
          {
            padding: '20px',
            transition: 'all .3s ease-in-out',
            cursor: allowHover ? 'pointer' : 'default',
            '&:hover': {
              borderColor: allowHover ? (theme) => theme.palette.primary.main : 'auto',
            },
          },
          sx
        )}
        {...rest}
      >
        {children}
      </StyledPaper>
    )
  }
)

const StyledPaper = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: 5,
  borderColor: theme.palette.border.main,
}))
