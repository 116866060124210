export const isValidArn = (value) =>
  value && /arn:aws:iam::\d{12}:role\/?[a-zA-Z_0-9+=,.@\-_/]+/.test(value)
export const validateSecret = (values) => {
  if (!values?.name) {
    throw new Error('Name is required')
  }
  if (values?.valueType === 'PARAMETER' && !values?.parameter?.value) {
    throw new Error('Value is required')
  }
  if (
    values?.valueType === 'PROVIDER' &&
    values?.provider?.providerType === 'SECRET' &&
    (!values?.provider?.accessKeyId || !values?.provider?.secretAccessKey)
  ) {
    throw new Error('Access/Secret Keys are required')
  }
  if (
    values?.valueType === 'PROVIDER' &&
    values?.provider?.providerType === 'ARN' &&
    !isValidArn(values?.provider?.arn)
  ) {
    throw new Error('ARN is not valid')
  }
}
