import { memo } from 'react'
import classes from './SpanMessage.module.css'
import { getSelectedSpanTags } from '../../utils/helper'

export const SpanMessage = memo(function SpanMessage({ activity }) {
  const uiData = getSelectedSpanTags({
    activity,
    includeDefaultTags: false,
    includeDuration: true,
  })
  return (
    <div className={`${classes.spanContainer} ${uiData.hasError ? classes.error : ''}`}>
      <div className={classes.header}>
        {uiData?.icon}
        {uiData?.name}
      </div>
      <div className={classes.meta}>
        {(uiData?.tags || []).map((tag, i) => {
          return (
            <div className={classes.column} key={tag.label}>
              <div className={classes.columnHeader}>{tag.label}</div>
              <div className={classes.columnValue}>{tag.value}</div>
            </div>
          )
        })}
      </div>
      {uiData?.hasError && <pre className={classes.error}>{uiData?.errorMessage}</pre>}
    </div>
  )
})
