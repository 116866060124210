import { Box, Skeleton, Typography } from '@mui/material'
import { isNumber, random, range } from 'lodash'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import { coreApiClient } from 'util/coreApiClient'
import ErrorState from 'common/components/ErrorState'
import CustomResponsiveContainer from './CustomResponsiveContainer'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts'
import CustomTooltip from './CustomTooltip'
import { useTheme } from '@mui/styles'
import CustomizedTicks from './CustomizedTicks'
import { FilterContext } from 'filter/context/FilterContext'
import { getIntervalDate } from '../helpers/getIntervalDate'
import { getRectanglePath } from '../helpers/graph-helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { stringifyUrl } from 'query-string'
import { AppContext } from 'app/context/AppContext'
import { eventsOptions } from 'filter/util/filters'
import { updateFilters } from 'metrics/hooks/useQuery'

const maxBarSize = 8
const tooltipWidth = 300

/**
 * Skeleton loading based on items count in page
 */
const SkeletonLoading = ({ size = 15, height } = {}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      margin: '20px 0 0 0',
    }}
  >
    {range(size)?.map((num) => (
      <Box
        key={`metrics-request-skeleton-loading-${num}`}
        component={motion.div}
        initial={{ y: 5, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
        }}
      >
        <Skeleton variant="rect" width={maxBarSize} height={height - random(10, 80)} />
      </Box>
    ))}
  </Box>
)

const renderShape =
  ({ valueKey, hoverColor, eventsBarColor, focusedBarIndex, onClick }) =>
  (props) => {
    let { x, y, width, height, radius, index } = props

    /**
     * Handle bar color
     */
    const isFocusedOnOthers = isNumber(focusedBarIndex) && focusedBarIndex !== index
    const fill = isFocusedOnOthers ? hoverColor : eventsBarColor

    if (props[valueKey] === 0) {
      height = 0
    }

    return (
      <>
        {/* Invisible bar to make clickable area bigger and easier to click*/}
        <path
          onClick={() => onClick({ data: props, index })}
          style={{
            cursor: 'pointer',
          }}
          fillOpacity="0"
          d={getRectanglePath(x - 10, y, width + 20, height, radius)}
        />
        {/* Successful bar */}
        <path
          onClick={() => onClick({ data: props, index })}
          style={{
            cursor: 'pointer',
            transition: 'all 1s easeInOut',
          }}
          fill={fill}
          d={getRectanglePath(x, y, width, height, radius)}
        />
      </>
    )
  }

export const ActivitiesGraph = ({ height }) => {
  const [focusedBarIndex, setFocusedBarIndex] = useState(null)
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgName, orgId } = activeOrg
  const { currentTimeFrame, queryApiTags, getFilterValue } = useContext(FilterContext)
  const [data, setData] = useState()
  const navigate = useNavigate()
  const location = useLocation()

  const initialFilters = useMemo(
    () => ({
      time: {
        from: currentTimeFrame?.startTime,
        to: currentTimeFrame?.stopTime,
        interval: currentTimeFrame?.interval,
        zone: queryApiTags?.timezone,
      },
    }),
    [currentTimeFrame, queryApiTags]
  )
  const [filters, setFilters] = useState(initialFilters)
  const url = `/activity/${orgId}/events/metrics`
  useEffect(() => {
    const newFilters = updateFilters({ initialFilters, filters: queryApiTags?.filters })
    setFilters(newFilters)
  }, [queryApiTags, currentTimeFrame])
  const swrKey = JSON.stringify({ url, filters })

  const {
    data: fetchedData,
    error,
    mutate,
    isValidating,
  } = useSWR(
    swrKey,
    () =>
      coreApiClient({
        url,
        method: 'post',
        data: filters,
      }),
    { shouldRetryOnError: false }
  )
  const loading = !data && !error
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData)
    }
  }, [fetchedData])
  const events = data?.results
  let interval = events?.length >= 10 ? 3 : 0
  const labelColor = theme.palette.text.secondary
  const eventsBarColor = theme.palette.border.light
  const eventsBarHoverColor = theme.palette.grey.main

  const getLink = (timeframe) =>
    stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        awsLambdaEvents: eventsOptions?.filter((opt) => !opt.hideFilterList).map((opt) => opt.id),
        globalTimeFrame: timeframe || getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })

  const onClick = (props) => {
    const { startDate, endDate } = getIntervalDate({
      currentDate: props.data.time,
      index: props.index,
      data: events,
    })
    const link = getLink(`${startDate?.getTime()},${endDate?.getTime()}`)

    navigate(link)
  }
  return (
    <Box
      sx={{
        minHeight: height,
        width: '100%',
        // borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
      }}
    >
      {loading || isValidating ? (
        <SkeletonLoading height={height} />
      ) : error ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: height,
          }}
        >
          <ErrorState onReload={mutate} isColumnDirection fullWidth />
        </Box>
      ) : !events?.length ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: height,
          }}
        >
          <Typography
            variant="textSecondary"
            color="text.secondary"
            textAlign="center"
            sx={{ margin: 'auto' }}
          >
            No data found within this period.
          </Typography>
        </Box>
      ) : (
        <CustomResponsiveContainer width="100%" height={height}>
          <BarChart
            data={events}
            margin={{ top: 10 }}
            onMouseMove={(state) => {
              if (state.activeTooltipIndex >= 0) {
                setFocusedBarIndex(state.activeTooltipIndex)
              } else {
                setFocusedBarIndex(null)
              }
            }}
            onMouseLeave={() => setFocusedBarIndex(null)}
          >
            <Tooltip
              content={<CustomTooltipComponent focusedBarIndex={focusedBarIndex} data={events} />}
              cursor={{ fill: theme.palette.secondary.main }}
              wrapperStyle={{
                outline: 'none',
                top: height - 10,
                zIndex: 99999,
              }}
            />
            <XAxis
              dataKey="time"
              hide={!events?.length ? true : false}
              axisLine={false}
              tickLine={false}
              allowDataOverflow={false}
              height={20}
              width={150}
              interval={interval}
              padding={{ left: 15, right: 15 }}
              tick={
                <CustomizedTicks
                  labelColor={labelColor}
                  currentTimeFrame={currentTimeFrame}
                  dy={10}
                  dx={5}
                />
              }
            />

            <YAxis hide dataKey="events" />
            <Bar
              dataKey="events"
              radius={[4, 4, 4, 4]}
              maxBarSize={maxBarSize}
              shape={renderShape({
                valueKey: 'events',
                hoverColor: eventsBarHoverColor,
                eventsBarColor,
                focusedBarIndex,
                onClick,
              })}
            />
          </BarChart>
        </CustomResponsiveContainer>
      )}
    </Box>
  )
}

const CustomTooltipComponent = ({ active, payload, focusedBarIndex, data }) => {
  if (!active || !payload?.length) return null
  const item = payload?.[0]
  const { time, events } = item?.payload || {}

  const value = (events || 0)?.toLocaleString('en-US') || '-'

  const { startDate, endDate } = getIntervalDate({
    currentDate: time,
    index: focusedBarIndex,
    formatString: 'MMMM dd h:mmaa',
    data,
  })

  const title = `${startDate} - ${endDate}`
  const items = [
    {
      title: 'Events',
      value,
    },
  ]
  return <CustomTooltip width={tooltipWidth} title={title} items={items} isActivityGraph />
}

export default memo(ActivitiesGraph)
