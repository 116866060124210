import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryParams } from 'use-query-params'
import cloneDeep from 'lodash.clonedeep'
import { getTimeFrame } from 'filter/util/time'
import { AppContext } from 'app/context/AppContext'
import { isEmpty, isEqual, isNil, keys, union, values, xor, xorWith } from 'lodash'
import { useLocation } from 'react-router-dom'
import { HiddenFilters, filterList, pageFilters, queryStringTypes } from '../util/filters'
import { defaultDurations } from '../util/duration'

/**
 * Get current filters count. If `savedFilters` is provided then compare current filters with saved filters,
 * otherwise count current filters only
 */
export const getFiltersCount = (filters = [], savedFilters = []) => {
  let count = 0
  filters?.forEach((f, idx) => {
    // Get saved filter value
    const savedFilterValue = savedFilters?.[idx]?.value || undefined
    // Ignore if value is `null` or `undefined`
    if (isNil(f.value) && isNil(savedFilterValue)) {
      return
    }
    // If the current value is equal to it's default value
    // we ignore this from our count
    if (f.defaultValue && f.value && JSON.stringify(f.defaultValue) === JSON.stringify(f.value)) {
      return
    }

    // Skip hidden filters
    if (HiddenFilters.includes(f.filter)) {
      return
    }
    // Skip empty arrays
    if (f.type === 'array' && !f.value?.length && !savedFilterValue) {
      return
    }

    // Skip empty objects
    if (f.type === 'keyValue' && isEmpty(f.value) && !savedFilterValue) {
      return
    }

    // Skip false booleans
    if (f.type === 'boolean' && !f.value && !savedFilterValue) {
      return
    }

    // Start counting

    if (f.type === 'array') {
      // Get difference between saved and unsaved filters
      const valuesToCount = xor(f.value, savedFilterValue)

      // Add every array item to the count
      count = count + valuesToCount?.length
      return
    }

    // Skip empty objects
    if (f.type === 'keyValue') {
      let valuesToCount
      // If `customTags` filter then compare keys and values changes
      if (f.name === 'customTags') {
        valuesToCount = xorWith(
          keys(f.value).map((key) => ({ [key]: f.value?.[key] })),
          keys(savedFilterValue).map((key) => ({ [key]: savedFilterValue?.[key] })),
          isEqual
        )
        // If `http` filter then compare and count differences with saved and unsaved values
      } else if (f.name === 'http') {
        valuesToCount = xor(
          union.apply(null, values(f.value)),
          union.apply(null, values(savedFilterValue))
        )
      } else if (f.name === 'duration') {
        valuesToCount = []

        if (f.value?.min !== savedFilterValue?.min && f.value?.min !== defaultDurations?.min) {
          valuesToCount.push(0)
        }
        if (f.value?.max !== savedFilterValue?.max && f.value?.max !== defaultDurations?.max) {
          valuesToCount.push(1)
        }
      } else {
        valuesToCount = xor(values(f.value), values(savedFilterValue))
      }

      // Count every key
      count = count + valuesToCount?.length
      return
    }
    if (f.value !== savedFilterValue) {
      // Otherwise increase count
      count++
    }
  })
  return count
}
/**
 * Filter Context
 *
 * This Context controls all global and Scope-specific Filters throughout Console and does the following:
 * - Holds the list of all possible all global and Scope-specific Filters.
 * - Exposes only Filters relevant to the current Scope & Page the user is viewing.
 * - Exposes easy ways to get and set all Filter Values.
 * - Uses query params as state (exclusively).  It readys query params on every update and repopulates the state.  Setting the filters simply updates the query params to trigger an update.
 * - Offers a minimal, universal type system which maps Filters to UI elements and URL query param types.
 * - Auto-updates the query parameters in the URL upon changing the Filters.
 * - Auto-maps the Filters to OTEL Tags for the Query API.
 */

/**
 * Filter Context & Provider
 */

export const FilterContext = createContext({})

export const FilterProvider = ({ page, children }) => {
  const { pathname } = useLocation()
  const { activeOrg } = useContext(AppContext)
  const [aliasMap, setAliasMap] = useState({})
  const [query, setQuery] = useQueryParams(queryStringTypes)

  const [currentTimeFrameInit, setCurrentTimeFrameInit] = useState(false)

  const [currentTimeFrame, setCurrentTimeFrame] = useState(getTimeFrame(query.globalTimeFrame))

  const currentQueryFilters = useRef()
  const filters = cloneDeep(pageFilters[`${page}#${query.globalScope || ''}`]) || []

  const refreshTimeFrame = () => {
    currentQueryFilters.current = queryApiTags.filters
    if (currentTimeFrameInit && query.globalTimeFrame) {
      setCurrentTimeFrame(getTimeFrame(query.globalTimeFrame))
    } else if (query.globalTimeFrame) {
      setCurrentTimeFrameInit(true)
    } else if (currentTimeFrame) {
      setCurrentTimeFrameInit(false)
    }
  }

  /**
   * Get a Value of a specific current Filter by Filter name
   * @param {*} filterName
   * @returns
   */
  const getFilterValue = (filterName) => {
    const currentFilter = filters.find(({ filter }) => filter === filterName)
    // We want zero values to still return
    if (currentFilter?.value === 0) return currentFilter?.value
    return currentFilter?.value || null
  }

  /**
   * Sets the value of a specific Filter by name and value, and auto-updates the query params in the URL and Query API query
   * @param {*} filterName
   * @returns
   */
  const setFilterValue = (filterName, newValue) => {
    const currentFilter = filters.find((f) => f.filter === filterName)
    if (!currentFilter) {
      throw new Error(
        `Could not find filter: "${filterName}".  Are you sure it's available in the current Scope and page?`
      )
    }
    currentFilter.value = newValue
    setAllFilterValues(filters)
  }

  /**
   * Set all Filter Values, simultaneously.
   * Adds a default "globalTimeFrame", if missing
   * @param {*} newFilters
   */
  const setAllFilterValues = (newFilters = []) => {
    const newQuery = cloneDeep(query)
    // Update query params with the new values
    newFilters.forEach((f) => {
      if (f.value !== undefined) {
        newQuery[f.filter] = f.value
      }
      // If the Filter Value is nonexistant, be sure to set it as undefined or it will still show up in the URL query params
      if (f.value === undefined || f.value === 'undefined' || f.value === null) {
        newQuery[f.filter] = undefined
      }
    })

    // If TimeFrame is missing (always the case on first load), default to 24 hours
    if (
      (!newQuery.globalTimeFrame || newQuery.globalTimeFrame === 'undefined') &&
      !/dev-mode/.test(pathname) &&
      !/explorer/.test(pathname) &&
      !/integrations/.test(pathname)
    ) {
      newQuery.globalTimeFrame = '24h'
    }

    setQuery(newQuery, 'push')
  }

  /**
   * Fetch/save alias values for a specific filter
   * @param {filter} filter
   */
  const loadAliasForFilter = async (filter) => {
    const filterMap = await filter.fetchAlias({ orgId: activeOrg.orgId })
    setAliasMap({
      ...aliasMap,
      ...filterMap,
    })
  }

  /**
   * Users the "filters" data from the FilterContext as a closure and structures the data for saving
   * to the Metrics Views API in a simple {filter:value} format. Skips TimeFrame, and anything that
   * doesn't have a value, to minimize what's saved, and reduce mirgration headaches if the Filter data evolves.
   *
   * @param optionalFilters By default this uses the currently set Filters, but you can pass in some optional
   * Filters to use instead.
   */
  const serializeFiltersForStorage = (optionalFilters) => {
    optionalFilters = optionalFilters || filters

    return optionalFilters
      .filter(({ filter, value }) => {
        if (filter === 'globalTimeFrame') {
          return false
        }
        if (value === undefined || value === null) {
          return false
        }
        if (Array.isArray(value) && !value.length) {
          return false
        }
        return true
      })
      .map(({ filter, value }) => {
        return { [filter]: value }
      })
  }

  /**
   * Creates a new Filters array for Filter Context that is enriched with serialized
   * Filter Value data from the Metrics View.  This does not update the update the FilterContext
   * automatically, so you will still have to use setAllFilterValues() with this
   *
   * @param serializedFilters Filter and Value data stored in the Metrics View API to use to enrich
   * the Filters in Filter Context
   */
  const deserializeFiltersFromStorage = (serializedFilters) => {
    // Clone, don't mutate the "filtersList" directly
    const clonedFilterList = cloneDeep(filterList)
    serializedFilters = serializedFilters.map((sf) => {
      let key = Object.keys(sf)[0]
      // Protects against changing names from what's been saved in the back-end
      if (!clonedFilterList[key]) {
        console.warn(
          `Warning, the following key ${key} is not in the filter list.  It must have been changed on the client-side, but not in previously saved records.`
        )
        return []
      }
      const filter = clonedFilterList[key]
      filter.value = sf[key]
      return filter
    })
    return serializedFilters
  }

  // Add values from the updated query string to the appropriate filters
  for (const filter of filters) {
    if (query[filter.filter] !== undefined) {
      filter.value = query[filter.filter]
    }
  }

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Map the Filters to OTEL Resource Tags to use for requests with the Query API
  const queryApiTags = {
    ...currentTimeFrame,
    timezone,
    filters: {},
  }

  filters?.forEach((filter) => {
    if (!filter.queryApiTagName) return
    if (filter.type === 'string' && query[filter.filter]) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'boolean' && query[filter.filter] === true) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'array' && query[filter.filter] && query[filter.filter].length > 0) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'autocomplete' && query[filter.filter] && query[filter.filter].length > 0) {
      queryApiTags.filters[filter.queryApiTagName] = query[filter.filter]
    }
    if (filter.type === 'keyValue' && query[filter.filter] && !isEmpty(query[filter.filter])) {
      queryApiTags.filters[filter.queryApiTagName] = keys(query[filter.filter]).reduce(
        (acc, key) => ({ ...acc, [key]: query[filter.filter][key] }),
        {}
      )
    }
  })

  /**
   * This effect keeps our current time frame in sync with the query params.
   * We also want to ignore the first run of this effect since currentTimeFrame is already
   * initialized to the query params.
   */
  useEffect(() => {
    refreshTimeFrame()
  }, [query.globalTimeFrame, query.explorerSubScope, query.globalScope])
  useEffect(() => {
    if (!isEqual(queryApiTags.filters, currentQueryFilters.current)) {
      refreshTimeFrame()
    }
  }, [queryApiTags.filters])

  // Fetch all alias values when the context is first loaded
  useEffect(() => {
    const loadAliases = async () => {
      const keys = Object.keys(query)
      for (let key of keys) {
        const list = query[key]
        const filter = filters.find(({ filter }) => filter === key)
        if (list && filter && 'fetchAlias' in filter) {
          await loadAliasForFilter(filter)
        }
      }
    }
    loadAliases()
  }, [])

  /**
   * Filter Bubble: Listen to Filters and count the number of Filters
   * with Values currently applied to show in the nav bar
   */

  const clearFilters = () => {
    const defaultFilters = filters
      .filter(({ filter }) => !HiddenFilters.includes(filter))
      .map(({ value, ...rest }) => ({ ...rest }))

    setAllFilterValues(defaultFilters)
  }

  const filtersChanged = useMemo(
    () => filters?.filter(({ filter }) => !HiddenFilters.includes(filter)).some((e) => e.value),
    [filters]
  )

  const filtersCount = useMemo(() => getFiltersCount(filters || []), [filters])

  return (
    <FilterContext.Provider
      value={{
        filters,
        allFilters: filters.reduce(
          (obj, filter) => ({
            ...obj,
            [filter.filter]: filter.value,
          }),
          {}
        ),
        getFilterValue,
        setFilterValue,
        setAllFilterValues,
        serializeFiltersForStorage,
        deserializeFiltersFromStorage,
        setCurrentTimeFrame,
        queryApiTags,
        currentTimeFrame,
        filtersCount,
        clearFilters,
        filtersChanged,
        query,
        refreshTimeFrame,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
