import { Box } from '@mui/material'
import { formatDuration } from 'date-fns'
import { useTheme } from '@mui/styles'

export const PromoBannerMessage = ({ promoDuration, percentDiscount, onDismiss }) => {
  const theme = useTheme()
  const duration = formatDuration(promoDuration, {
    format: ['months'],
  })
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center' }}>
      {percentDiscount} discount for {duration} if you upgrade for a limited time.
      <Box
        onClick={onDismiss}
        style={{
          cursor: 'pointer',
          color: theme.palette.secondary.main,
          textDecoration: 'underline',
        }}
      >
        Dismiss
      </Box>
    </Box>
  )
}
