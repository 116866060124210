import { Box, Typography } from '@mui/material'
import { addMonths, endOfDay, intervalToDuration, set } from 'date-fns'
import { Title } from 'settings/components/billing/billingUtils'

/**
 * Base plans for all customers
 */
export const basePlans = [
  {
    title: 'free',
    details: [
      {
        name: 'price',
        title: '$0.00 /mo',
        subtitle: 'No fees!',
      },
      {
        name: 'traces',
        title: '100,000',
        subtitle: 'Traces (with logs) limit',
      },
    ],
  },
  {
    title: 'team',
    details: [
      {
        name: 'price',
        title: '$8.00 /mo',
        subtitle: 'Per 100k traces (with logs) per month with 10,000,000 limit',
        paymentTitle: '$8.00 / 100k traces / month',
      },
    ],
    cta: (cb) => () => {
      cb({
        upgradePlan: true,
      })
    },
  },
  {
    title: 'business',
    details: [
      {
        name: 'traces',
        title: 'Discounts available',
        subtitle: 'Unlimited traces (with logs)',
      },
      {
        name: 'logs',
        title: 'Trace & Log sampling available',
        subtitle: '30% min',
        render: <Typography variant="h3">Trace & Log sampling available</Typography>,
      },
    ],
  },
]

/**
 * Current discount codes
 */
export const baseDiscounts = [
  {
    code: 'WELCOME22',
    price: '$4.00 /mo',
    percent: '50%',
    resourcePercent: '0%',
    targetPlan: 'business',
    enabledForPlans: ['free'],
    globalDiscount: true,
    tracePercentNumber: 50,
    resourcePercentNumber: 0,
    promoDuration: intervalToDuration({
      start: new Date(),
      end: addMonths(new Date(), 6),
    }),
    replacedDetails: [
      {
        name: 'discount-available',
        title: 'You have a 50% discount!',
        render: <Typography variant="h4">You have a 50% discount!</Typography>,
      },
      {
        name: 'price',
        title: '$8.00 /mo',
        render: (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <Title title="$8.00 /mo" sx={{ textDecoration: 'line-through' }} />
              <Title title="$4.00 /mo" />
            </Box>
            <Typography color="text.secondary">
              Per 100k traces (with logs) per month with 10,000,000 limit
            </Typography>
          </>
        ),
        subtitle: 'Per 100k traces (with logs) per month with 10,000,000 limit',
      },
      {
        name: 'logs',
        title: 'Trace & Log sampling available',
        subtitle: '30% min',
        render: <Typography variant="h3">Trace & Log sampling available</Typography>,
      },
    ],
    cta: (cb) => () => {
      cb({
        upgradePlan: true,
        discountCode: 'WELCOME22',
      })
    },
    expiration: endOfDay(set(new Date(), { year: 2022, month: 11, date: 31 })),
  },
  {
    code: 'GEARBOX',
    price: '$1.00 /mo',
    resourcePercent: '0%',
    tracePercentNumber: 87.5,
    percent: '87.5%',
    globalDiscount: false,
  },
]
