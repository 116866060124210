export const getMaxSpanVal = (timelineSpans) =>
  timelineSpans &&
  timelineSpans.reduce((max, val) => {
    if (max <= val.chart[0]) {
      max = val.chart[0]
    }
    if (max <= val.chart[1]) {
      max = val.chart[1]
    }
    return max
  }, 0)
