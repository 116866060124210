import { TraceContext } from '../context/Trace.context'
import { forwardRef, memo, useContext, useMemo } from 'react'
import { TreeItem, TreeView, useTreeItem } from '@mui/lab'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { IconDangerTriangle } from 'common/icons/IconDangerTriangle'
import { getSpanDetails, getSpanId } from '../util/getSpanDetails'
import { isContentOverflown } from 'common/utils/isContentOverflown'
import { findDOMNode } from 'react-dom'
import { getEventInfo } from 'common/utils/getEventInfo'
import classes from './TraceSpans.module.css'
import clsx from 'clsx'
import { useTheme } from '@mui/styles'
import { get } from 'lodash'

const SpanTreeItem = memo(
  forwardRef(({ nodeId, currentSpan, selectedSpan, setTooltipAnchor, tooltipAnchor }, ref) => {
    const {
      expanded: isCurrentSpanExpended,
      handleExpansion,
      handleSelection,
    } = useTreeItem(nodeId)
    const { palette } = useTheme()
    const { id, isEvent, childCount, name, parentSpanId, color } = currentSpan
    const selectedSpanId = useMemo(() => getSpanId(selectedSpan), [selectedSpan])
    const hoveredSpanId = useMemo(
      () => getSpanId(tooltipAnchor?.currentSpan),
      [tooltipAnchor?.currentSpan]
    )

    const isSelected = selectedSpanId === id
    const isHovered = hoveredSpanId === id
    const { title, typeFullName } = getEventInfo(currentSpan)
    const label = title || name
    // For event tooltip only show event type, no need to show full event message
    const toolTipLabel = isEvent ? typeFullName : label

    /**
     * On span item hover check if text is overflown or not and set ref accordingly
     * This is to be used in showing the popper and the background hover color.
     */
    const onMouseEnter = () => {
      try {
        const valueNode = findDOMNode(ref.current).getElementsByClassName(
          classes.traceSpansTreeItemLabel
        )
        const isCurrentlyOverflown = isContentOverflown(valueNode?.[0])

        setTooltipAnchor({
          anchorEl: isCurrentlyOverflown && ref.current, // Show tooltip only if label is overflown
          label: toolTipLabel,
          currentSpan,
        })
      } catch (err) {}
    }
    const onMouseLeave = () => {
      setTooltipAnchor(null)
    }
    const isRootSpan = !parentSpanId
    return (
      <div
        className={clsx(
          classes.traceSpansTreeItem,
          isHovered || isSelected ? classes.isActive : null,
          isRootSpan && classes.isRootSpan,
          isRootSpan && !childCount && classes.hideLines // Hide lines on root span
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleSelection}
        ref={ref}
      >
        {isEvent ? (
          <IconDangerTriangle
            style={{
              fill: get(palette, color),
            }}
          />
        ) : childCount ? (
          <div className={classes.traceSpansTreeItemCounterBox} onClick={handleExpansion}>
            {childCount}
            {isCurrentSpanExpended ? <ChevronDown size={12} /> : <ChevronRight size={12} />}
          </div>
        ) : null}

        <span
          className={clsx(
            classes.traceSpansTreeItemLabel,
            isEvent || childCount ? classes.hasIcon : null
          )}
          style={{
            '--color': get(palette, color),
          }}
        >
          {label}
        </span>
      </div>
    )
  })
)

const RenderTreeNodes = memo(({ span, hasSiblings }) => {
  const { timelineSpans, traceEvents, selectedSpan, setTooltipAnchor, tooltipAnchor } =
    useContext(TraceContext)
  const currentSpan = getSpanDetails({ span, timelineSpans, traceEvents })
  const { spans, id, indent } = currentSpan

  return (
    <TreeItem
      key={`trace-spans-tree-span-${id}`}
      ContentComponent={SpanTreeItem}
      nodeId={id}
      ContentProps={{
        currentSpan,
        selectedSpan,
        setTooltipAnchor,
        tooltipAnchor,
      }}
      classes={{
        root: clsx(hasSiblings && classes.hasSiblings),
        group: clsx(classes.traceSpansTreeItemGroup),
      }}
      style={{
        '--indent': indent,
      }}
    >
      {spans?.map((span, i) => (
        <RenderTreeNodes
          key={`trace-spans-tree-span-${id}-${i}`}
          span={span}
          hasSiblings={!!spans[i + 1]}
        />
      ))}
    </TreeItem>
  )
})

export const RenderSpansTree = () => {
  const { trace, timelineSpans, expanded, setExpanded, onSpanClick } = useContext(TraceContext)

  if (!trace || !timelineSpans?.length) {
    return null
  }
  return (
    <TreeView
      aria-label="file system navigator"
      expanded={expanded}
      onNodeToggle={(event, expandedSpanIds) => {
        /**
         * If toggle expanded state
         */

        setExpanded(expandedSpanIds)
      }}
      onNodeSelect={(event, spanId) => onSpanClick(spanId)}
    >
      <RenderTreeNodes span={trace} />
    </TreeView>
  )
}
