import { Box, styled, Typography } from '@mui/material'

const LegendIconCircle = styled(Box)(({ error, theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  marginRight: 8,
}))

const LegendItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 0 20px',
}))

const LegendItemGrid = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: '0',
}))

const InvocationsLegend = () => {
  return (
    <LegendItemGrid>
      <LegendItem>
        <LegendIconCircle
          sx={{
            backgroundColor: 'text.secondary',
          }}
        />
        <Typography variant="textSecondary" color="text.secondary">
          Successful Invocations
        </Typography>
      </LegendItem>
      <LegendItem>
        <LegendIconCircle
          sx={{
            backgroundColor: 'error.main',
          }}
        />
        <Typography variant="textSecondary" color="text.secondary">
          Uncaught Errors
        </Typography>
      </LegendItem>
      <LegendItem>
        <LegendIconCircle
          sx={{
            backgroundColor: 'border.dark',
          }}
        />
        <Typography variant="textSecondary" color="text.secondary">
          Events
        </Typography>
      </LegendItem>
    </LegendItemGrid>
  )
}

export default InvocationsLegend
