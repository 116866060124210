import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Button } from 'common/components/Button'

export const StillWorking = ({ open, close }) => {
  const handleClose = (reenable) => close({ reenable })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Still there?</DialogTitle>
      <DialogContent>
        <DialogContentText color="text.secondary">
          It's been a while. Are you still using Dev Mode?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="medium" onClick={() => handleClose(false)}>
          I'm finished!
        </Button>
        <Button color="primary" size="medium" onClick={() => handleClose(true)}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}
