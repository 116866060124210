import { Box, Typography, styled } from '@mui/material'
import clsx from 'clsx'
import { CustomPaper } from 'common/components/CustomPaper'
import { usd, n1MM, number } from './billingUtils'

export const TierBox = ({ tier, inDialog, onClick }) => {
  let subtitle
  if (tier?.traces === n1MM) {
    subtitle = '10x Invocations Available'
  } else if (tier?.traces === n1MM * 5) {
    subtitle = 'Auto-Sampling Included'
  } else {
    subtitle = 'Volume Discounts Available'
  }
  return (
    <CustomPaper
      className={clsx((tier.isSelected || !onClick) && 'selected')}
      onClick={onClick}
      sx={{
        '&.MuiPaper-root': {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr 1fr 1fr 32px',
          padding: '20px 0 0 0',
          gap: '10px',
          cursor: onClick ? 'pointer' : 'default',
          position: 'relative',
          transition: 'all .2s ease-in',
          '&:hover,&.selected': {
            borderColor: 'primary.main',
          },
          '&:before': {
            content: `"${tier.volumePercentDiscount}% discount"`,
            position: 'absolute',
            display: tier.volumePercentDiscount ? 'block' : 'none',
            zIndex: 5,
            fontSize: (theme) => theme.typography.textTertiary.fontSize,
            color: (theme) => theme.palette.secondary.main,
            backgroundColor: (theme) => theme.palette.primary.main,
            lineHeight: 1,
            padding: '2px 4px',
            borderRadius: '4px',
            top: '-1px',
            // transform: 'translateX(-50%)',
            right: '0',
            opacity: 1,
          },
          '&:after': {
            content: `"Current Plan"`,
            position: 'absolute',
            display: tier.isCurrent ? 'block' : 'none',
            zIndex: 2,
            fontSize: (theme) => theme.typography.textTertiary.fontSize,
            color: (theme) => theme.palette.text.secondary,
            backgroundColor: (theme) => theme.palette.secondary.main,
            lineHeight: 1,
            padding: '2px 4px',
            borderRadius: '4px',
            top: '0%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            opacity: 1,
          },
        },
      }}
    >
      <SubSection>
        {tier.subtotal !== tier.volumeTotal && !tier?.isCustom && (
          <Typography
            variant="textSecondary"
            color="text.secondary"
            sx={{
              textDecoration: 'line-through',
              lineHeight: 1,
              marginTop: '15px',
            }}
          >
            {usd(tier.subtotal)}
          </Typography>
        )}
        <Typography variant="h1">
          {tier?.isCustom
            ? 'Custom'
            : inDialog
            ? usd(tier?.total)
            : tier.volumeTotal
            ? `${usd(tier.volumeTotal)} / mo`
            : 'Free'}
        </Typography>
      </SubSection>

      {tier?.isCustom ? (
        <SubSection>
          <Typography variant="h2">Click to Customize</Typography>
        </SubSection>
      ) : (
        <SubSection>
          <Typography variant="h2">{number(tier.traces)} Traces</Typography>
          <Typography variant="h2">{number(tier.metrics)} Metrics</Typography>
        </SubSection>
      )}
      <SubSection>
        <Typography variant="textPrimary" color="text.secondary">
          Supports {tier?.isCustom ? 'unlimited' : `~${number(tier.invocations)}`} AWS Lambda
          invocations
        </Typography>
      </SubSection>
      {tier?.volumeTotal || tier?.isCustom ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            margin: 'auto 0 0 0',
            borderRadius: '0 0 4px 4px',
            padding: '5px 4px',
            textAlign: 'center',
            fontSize: (theme) => theme.typography.textTertiary.fontSize,
            color: (theme) => theme.palette.secondary.main,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Typography variant="textSecondary">{subtitle}</Typography>
        </Box>
      ) : (
        <div />
      )}
    </CustomPaper>
  )
}

const SubSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px',
}))
