import { useContext, useState } from 'react'
import {
  styled,
  Box,
  List,
  Skeleton,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  CircularProgress,
} from '@mui/material'
import { Button } from 'common/components/Button'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckIcon from '@mui/icons-material/Check'
import { AppContext } from 'app/context/AppContext'
import config from 'config'
import { TeamContext } from 'settings/context/Team.context'
import { colors } from 'theme/colors'

const skeletonList = [1, 2, 3]

export const PendingList = () => {
  const {
    activeOrg: { isOwner },
  } = useContext(AppContext)
  const {
    pendingInviteState: { initialLoading, pendingInvites, startKey },
    moreInvites,
    removePendingInvite,
    removingInvite,
  } = useContext(TeamContext)
  const [inviteExtras, setInviteExtras] = useState({})

  if (initialLoading) {
    return (
      <Box sx={{ padding: '10px 0 20px 0' }}>
        <Typography variant="h4" color="text.secondary">
          Checking for pending invitations...
        </Typography>
      </Box>
    )
  }

  if (!initialLoading && pendingInvites.length === 0) {
    return (
      <Box sx={{ padding: '10px 0 20px 0' }}>
        <Typography color="text.secondary">No pending Invitations</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ padding: '10px 0 20px 0' }}>
      <Typography variant="h4">Pending Invites</Typography>
      <List sx={{ paddingBottom: '20px', marginTop: 1 }}>
        {(pendingInvites || []).map((invite) => {
          return (
            <StyledListItem
              key={invite.token}
              secondaryAction={
                <Box display="flex" alignItems="center" mr={2}>
                  {!!removingInvite[invite.token] ? (
                    <CircularProgress size={25} />
                  ) : (
                    <IconButton
                      disabled={!isOwner}
                      onClick={(event) =>
                        setInviteExtras({
                          ...inviteExtras,
                          [invite?.token]: event.currentTarget,
                        })
                      }
                      edge="end"
                      aria-label="delete"
                      aria-controls={
                        !!inviteExtras[invite?.token] ? `invite-role-${invite?.token}` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={!!inviteExtras[invite?.token] ? 'true' : undefined}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  <Menu
                    anchorEl={inviteExtras[invite?.token]}
                    id={`invite-extras-${invite?.token}`}
                    open={!!inviteExtras[invite?.token]}
                    onClose={() => setInviteExtras({ ...inviteExtras, [invite?.token]: false })}
                    MenuListProps={{
                      'aria-labelledby': 'member-extras',
                    }}
                  >
                    <MenuItem
                      style={{ color: colors.redPrimary }}
                      onClick={() => {
                        setInviteExtras({ ...inviteExtras, [invite?.token]: false })
                        removePendingInvite({ token: invite?.token })
                      }}
                    >
                      Remove
                    </MenuItem>
                  </Menu>
                </Box>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <Skeleton animation={false} variant="circular" width={40} height={40} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={invite?.inviteeEmail} secondary={`${invite?.status}`} />
            </StyledListItem>
          )
        })}
      </List>
      {startKey?.startKey && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <Button onClick={moreInvites} color="secondary">
            Load more Invites
          </Button>
        </Box>
      )}
    </Box>
  )
}

export const TeamList = () => {
  const {
    activeOrg: { isOwner },
    user,
  } = useContext(AppContext)
  const {
    teamMemberState: { initialLoading, teamMembers, startKey },
    moreTeamMembers,
    removeOrgMember,
    updateOrgMember,
    updatingRoles,
  } = useContext(TeamContext)
  const [roleMenus, setRoleMenus] = useState({})
  const [memberExtras, setMemberExtras] = useState({})

  if (initialLoading) {
    return <SkeletonList />
  }

  return (
    <Box sx={{ paddingBottom: '25px' }}>
      <Typography variant="h4" sx={{ padding: '10px 0' }}>
        Added Members
      </Typography>
      <List sx={{ paddingBottom: '20px' }}>
        {(teamMembers || []).map((member) => {
          const { name: roleName } =
            config.platform.roles[member.memberRole.toLowerCase()] || config.roles.contributor
          const roles = Object.keys(config.platform.roles).map((key) => ({
            key,
            ...config.platform.roles[key],
          }))
          return (
            <StyledListItem
              key={member.memberId}
              secondaryAction={
                <Box display="flex" alignItems="center" mr={2}>
                  <Button
                    disabled={
                      user?.userId === member.userId || !isOwner || !!updatingRoles[member.memberId]
                    }
                    onClick={(event) =>
                      setRoleMenus({ ...roleMenus, [member?.memberId]: event.currentTarget })
                    }
                    endIcon={
                      !!updatingRoles[member.memberId] ? (
                        <CircularProgress size={20} sx={{ marginLeft: '5px' }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ color: '#868686' }} />
                      )
                    }
                    variant="text"
                    aria-controls={
                      !!roleMenus[member?.memberId] ? `member-role-${member?.memberId}` : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={!!roleMenus[member?.memberId] ? 'true' : undefined}
                  >
                    {roleName}
                  </Button>
                  <IconButton
                    disabled={user?.userId === member.userId || !isOwner}
                    onClick={(event) =>
                      setMemberExtras({
                        ...memberExtras,
                        [member?.memberId]: event.currentTarget,
                      })
                    }
                    edge="end"
                    aria-label="delete"
                    aria-controls={
                      !!memberExtras[member?.memberId]
                        ? `member-role-${member?.memberId}`
                        : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={!!memberExtras[member?.memberId] ? 'true' : undefined}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    anchorEl={roleMenus[member?.memberId]}
                    id={`member-role-${member?.memberId}`}
                    open={!!roleMenus[member?.memberId]}
                    onClose={() => setRoleMenus({ ...roleMenus, [member?.memberId]: false })}
                    MenuListProps={{
                      'aria-labelledby': 'member-role',
                    }}
                  >
                    {roles.map(({ key, name, description }) => (
                      <StyledMenuItem
                        disabled={key === member.memberRole.toLowerCase()}
                        key={key}
                        onClick={() => {
                          setRoleMenus({ ...roleMenus, [member?.memberId]: false })
                          updateOrgMember({ memberId: member?.memberId, memberRole: key })
                        }}
                      >
                        <Box maxWidth={'350px'} padding="5px 10px">
                          <Box display="flex" alignItems="center">
                            <Typography variant="textPrimary">{name}</Typography>
                            {key === member.memberRole.toLowerCase() && (
                              <CheckIcon sx={{ margin: '0 0 2px 10px' }} />
                            )}
                          </Box>
                          <Typography
                            sx={{ color: '#7C7C7C', whiteSpace: 'initial' }}
                            variant="textSecondary"
                            component="p"
                          >
                            {description}
                          </Typography>
                        </Box>
                      </StyledMenuItem>
                    ))}
                  </Menu>

                  <Menu
                    anchorEl={memberExtras[member?.memberId]}
                    id={`member-role-${member?.memberId}`}
                    open={!!memberExtras[member?.memberId]}
                    onClose={() => setMemberExtras({ ...memberExtras, [member?.memberId]: false })}
                    MenuListProps={{
                      'aria-labelledby': 'member-extras',
                    }}
                  >
                    <MenuItem
                      style={{ color: colors.redPrimary }}
                      onClick={() => {
                        setMemberExtras({ ...memberExtras, [member?.memberId]: false })
                        removeOrgMember({ memberId: member?.memberId })
                      }}
                    >
                      Remove
                    </MenuItem>
                  </Menu>
                </Box>
              }
            >
              <ListItemAvatar sx={{ margin: '0 0 0 10px' }}>
                <Avatar
                  alt={member?.user?.userEmail}
                  src={member?.user?.profilePictureUrl}
                  imgProps={{
                    draggable: false,
                  }}
                />
              </ListItemAvatar>
              <ListItemText primary={member?.user?.userEmail} secondary={member?.memberRole} />
            </StyledListItem>
          )
        })}
      </List>
      {startKey?.startKey && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <Button
            fullWidth
            onClick={moreTeamMembers}
            color="secondary"
            sx={{ margin: '20px 0 0 0' }}
          >
            Load more members
          </Button>
        </Box>
      )}
    </Box>
  )
}

const SkeletonList = () => {
  return (
    <List>
      {skeletonList.map((val) => (
        <StyledListItem
          key={val}
          secondaryAction={
            <IconButton disabled edge="end" aria-label="delete">
              <MoreHorizIcon />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <Skeleton variant="circular" width={40} height={40} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton width={250} variant="text" />}
            secondary={<Skeleton width={150} variant="text" />}
          />
        </StyledListItem>
      ))}
    </List>
  )
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-disabled': {
    opacity: 1,
    backgroundColor: theme.palette.grey.medium,
  },
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.grey.dark,
  borderRadius: '6px',
  marginBottom: '10px',
}))
