import Auth0 from 'auth0-js'
import config from 'config'

const auth0 = new Auth0.WebAuth({
  domain: config.auth0.domain,
  clientID: config.auth0.clientId,
  redirectUri: `${window.location.origin}${'/callback'}`,
  responseType: 'code',
})

export const loginOrRegisterSocial = ({ provider, queryParameters, from }) =>
  auth0.authorize({
    audience: config.auth0.audience,
    scope: config.auth0.scope,
    connection: provider,
    state: JSON.stringify({
      connection: provider,
      queryParameters,
      from,
    }),
  })

export const loginCredentials = ({ email, password, queryParameters, from }) =>
  new Promise((resolve, reject) => {
    auth0.login(
      {
        email,
        password,
        audience: config.auth0.audience,
        scope: config.auth0.scope,
        state: JSON.stringify({
          connection: 'Username-Password-Authentication',
          queryParameters,
          from,
        }),
      },
      (error, result) => {
        if (error) return reject(error)
        resolve(result)
      }
    )
  })

export const registerCredentials = ({ email, password }) =>
  new Promise((resolve, reject) => {
    auth0.signup(
      {
        email,
        password,
        connection: 'Username-Password-Authentication',
        scope: config.auth0.scope,
      },
      (error, result) => {
        if (error) reject(error)
        else resolve(result)
      }
    )
  })

export const requestPasswordReset = async ({ email }) => {
  const handler = () =>
    new Promise((resolve, reject) => {
      auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (error, result) => {
          if (error) return reject(error)
          return resolve(result)
        }
      )
    })

  const response = await handler()
  return response
}
