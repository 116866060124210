import { forwardRef, memo, useMemo, useState } from 'react'
import { groupByMap } from './util/groupByMap'
import { useSearch } from 'common/hooks/useSearch'
import { DEFAULT_PAGE_SIZE } from './GlobalSearchDialog'
import { range } from 'lodash'
import { addLinksToItem } from './util/links'
import clsx from 'clsx'
import classes from './GlobalSearchDialog.module.css'
import { Checkbox } from '../Checkbox'
import { DropdownIcon } from '../DropdownIcon'
import { Divider, LinearProgress } from '@mui/material'
import { Link } from '../Link'
import { IconMetrics } from 'common/icons/IconMetrics'
import { IconDevMode } from 'common/icons/IconDevMode'
import { IconExplorer } from 'common/icons/IconExplorer'
import { motion } from 'framer-motion'
import { SkeletonLoading } from './SkeletonLoading'

export const DataRow = memo(
  forwardRef((props, outerRef) => {
    const {
      item,
      groupBy,
      hasSibling,
      isChild = false,
      listResourcesOnly = false,
      checkedItems,
      orgName,
      integrationsAliases,
      search,
      handleCheckItem,
      onMouseEnter,
      onMouseLeave,
    } = props
    const isResource = item?.aws_lambda_name

    const title =
      integrationsAliases?.[item?.id] || item?.aws_lambda_name || item[groupByMap[groupBy]?.field]
    const name = item?.[groupByMap[item.type]?.field]

    const isExpandable = !isChild && !isResource

    const [isExpanded, setIsExpanded] = useState(listResourcesOnly && !!search)
    const isChecked = useMemo(
      () => checkedItems?.some((i) => i.name === name && i.type === item.type),
      [checkedItems]
    )
    const ready = isExpanded && !listResourcesOnly && !isChild && !search
    const disableActions = groupBy === 'instrument_mode' && !isChild
    const query = {
      bool: {
        must: [
          {
            match: {
              type: 'resource_aws_lambda',
            },
          },
          {
            match: {
              [`${groupBy}.keyword`]: item[groupByMap[groupBy]?.field],
            },
          },
        ],
      },
    }
    const sort = [
      {
        [`aws_lambda_name.keyword`]: {
          order: 'desc',
        },
      },
    ]
    /**
     * List inventories with query and sort
     */
    const { data: fetchedData, loading } = useSearch({
      ready, // Only fetch when we have integrationId
      size: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      query,
      sort,
    })
    const children = item.children || fetchedData?.hits
    const items = range(children?.length)
    const links = useMemo(() => {
      return addLinksToItem({ item, orgName })
    }, [item])

    return (
      <div
        ref={outerRef}
        className={clsx(classes.RowWrapper)}
        onClick={(e) => {
          e?.stopPropagation()
          if (isExpandable && e.target.type !== 'checkbox') {
            setIsExpanded((prev) => !prev)
          } else {
            handleCheckItem(item)
          }
        }}
      >
        <div
          className={clsx(
            classes.RowContent,
            isChild && classes.isChild,
            hasSibling && classes.hasSibling,
            isExpandable && classes.isExpandable,
            isExpanded && classes.isSticky,
            isChecked && classes.isChecked
          )}
        >
          {!disableActions && (
            <div className={classes.hoverIcon}>
              <Checkbox checked={isChecked} />
            </div>
          )}
          {isExpandable ? (
            <div className={classes.RowIconWrapper}>
              <DropdownIcon isOpen={isExpanded} size={18} marginLeft={0} />
            </div>
          ) : isChild ? (
            <div />
          ) : null}
          {isExpandable ? (
            <Divider orientation="vertical" className={classes.divider} />
          ) : isChild ? (
            <div />
          ) : null}
          <div className={classes.RowIconWrapper}>{groupByMap[item.type]?.icon(20)}</div>
          <div className={classes.RowText}>
            <span className={classes.RowMainTitle}>
              {title}{' '}
              {(isExpanded && isExpandable && !loading) || children?.length ? (
                <span className={classes.RowTagName}>
                  ({children?.length || 'No resources found'})
                </span>
              ) : null}
            </span>
            {isResource && (
              <div className={classes.RowTags}>
                {item.tag_account_id && (
                  <span
                    className={classes.RowTagName}
                    onMouseEnter={(e) => onMouseEnter(e, 'AWS Account')}
                    onMouseLeave={onMouseLeave}
                  >
                    {integrationsAliases?.[item.tag_account_id] || item.tag_account_id}
                  </span>
                )}
                {item.tag_namespace && (
                  <span
                    className={classes.RowTagName}
                    onMouseEnter={(e) => onMouseEnter(e, 'Namespace')}
                    onMouseLeave={onMouseLeave}
                  >
                    {item.tag_namespace}
                  </span>
                )}
                {item.tag_environment && (
                  <span
                    className={classes.RowTagName}
                    onMouseEnter={(e) => onMouseEnter(e, 'Environment')}
                    onMouseLeave={onMouseLeave}
                  >
                    {item.tag_environment}
                  </span>
                )}
                {item.tag_region && (
                  <span
                    className={classes.RowTagName}
                    onMouseEnter={(e) => onMouseEnter(e, 'Region')}
                    onMouseLeave={onMouseLeave}
                  >
                    {item.tag_region}
                  </span>
                )}
                {item.aws_lambda_runtime && (
                  <span
                    className={classes.RowTagName}
                    onMouseEnter={(e) => onMouseEnter(e, 'Runtime')}
                    onMouseLeave={onMouseLeave}
                  >
                    {item.aws_lambda_runtime}
                  </span>
                )}
              </div>
            )}
          </div>

          <div className={clsx(classes.RowActions, disableActions && classes.disableActions)}>
            <Link
              to={links.metricsLink}
              onMouseEnter={(e) => onMouseEnter(e, 'Metrics')}
              onMouseLeave={onMouseLeave}
            >
              <IconMetrics />
            </Link>
            <Link
              to={links.devModeLink}
              onMouseEnter={(e) => onMouseEnter(e, 'Dev Mode')}
              onMouseLeave={onMouseLeave}
            >
              <IconDevMode />
            </Link>
            <Link
              to={links.explorerLink}
              onMouseEnter={(e) => onMouseEnter(e, 'Explorer')}
              onMouseLeave={onMouseLeave}
            >
              <IconExplorer />
            </Link>
          </div>
          {loading && (
            <LinearProgress
              style={{
                height: '1px',
                position: 'absolute',
                bottom: 0,
                zIndex: 5,
                width: '100%',
              }}
            />
          )}
        </div>

        <motion.div
          animate={{
            height: isExpanded ? 'auto' : 0,
            transition: { ease: 'easeInOut' },
          }}
        >
          {isExpanded &&
            items?.map((index) => {
              if (loading) {
                return <SkeletonLoading key={`metrics-activities-skeleton-loading-${index}`} />
              }
              if (children[index]?.id) {
                return (
                  <DataRow
                    key={`global-search-items-${item.id}-${children[index]?.type}-${children[index]?.id}-${index}`}
                    {...props}
                    item={children[index]}
                    hasSibling={children?.[index + 1]?.[groupBy] === children?.[index]?.[groupBy]}
                    isChild
                  />
                )
              }
              return null
            })}
        </motion.div>
      </div>
    )
  })
)
