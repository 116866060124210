import axios from 'axios'
import mem from 'mem'
import { useRef } from 'react'
import useSWR from 'swr'

import config from 'config'

export const coreApiClient = axios.create({
  baseURL: config.platform.baseUrl,
})

const getSessionData = () => {
  const data = window.localStorage.getItem(config.localStorageKey)
  try {
    const parsedData = JSON.parse(data)
    return parsedData
  } catch (error) {
    return undefined
  }
}

export const updateSessionData = ({
  idToken,
  refreshToken,
  activeOrg,
  user,
  userOrgs,
  loggedIn = true,
  errorClearSession = false,
  enableDispatch = false,
}) => {
  const currentSessionData = getSessionData()
  const sessionData = {
    ...currentSessionData,
    ...JSON.parse(
      JSON.stringify({
        loggedIn,
        idToken,
        activeOrg,
        user,
        userOrgs,
        refreshToken,
      })
    ),
  }
  if (enableDispatch) {
    const event = new CustomEvent(config.sessionUpdateEvent, {
      detail: sessionData,
      errorClearSession,
    })
    document.dispatchEvent(event)
  }
  window.localStorage.setItem(config.localStorageKey, JSON.stringify(sessionData))
  return sessionData
}

const refreshAuthLogic = async () => {
  try {
    const res = await coreApiClient.post('/identity/auth/tokens/refresh', {
      refreshToken: getSessionData()?.refreshToken,
    })
    return updateSessionData({
      idToken: res.idToken,
      refreshToken: res.refreshToken,
    })
  } catch (error) {
    return updateSessionData({
      idToken: null,
      refreshToken: null,
      loggedIn: false,
      errorClearSession: true,
      enableDispatch: true,
    })
  }
}

const maxAge = 30000
export const memoizedRefreshToken = mem(refreshAuthLogic, {
  maxAge,
})

coreApiClient.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: getSessionData()?.idToken,
    },
  }),
  (error) => Promise.reject(error)
)

coreApiClient.interceptors.response.use(
  (response) => response?.data,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const config = error?.config

    if (
      [401, 403].includes(error?.response?.status) &&
      !config?.sent &&
      error.request.responseURL.includes('/identity/auth/tokens/refresh') === false
    ) {
      config.sent = true

      const result = await memoizedRefreshToken()

      if (result?.idToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${result?.idToken}`,
        }
      }

      return coreApiClient(config)
    }
    return Promise.reject(error)
  }
)

/**
 * Return previous results while revalidating
 */
export function useStickySWR(...args) {
  const val = useRef()

  const { data, isValidating, error, ...rest } = useSWR(...args)

  if (data !== undefined) {
    val.current = data
  }

  return {
    ...rest,
    isValidating,
    error,
    data: val.current,
  }
}
