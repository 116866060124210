import { Box, IconButton, TextField, Typography } from '@mui/material'
import { Edit2, Plus, X } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Check } from 'lucide-react'
import { keys } from 'lodash'
import Button from 'common/components/Button'
import { DropdownWrapper } from 'common/components/DropdownWrapper'

const CustomTagRow = ({ isNew, item, onRemove, index, onSave }) => {
  const defaultValues = { index: 0, key: '', value: '' }
  const [isDisabled, setIsDisabled] = useState(!isNew)
  const keyInputRef = useRef()
  const valueInputRef = useRef()
  const [values, setValues] = useState(item || defaultValues)
  useEffect(() => {
    if (!isDisabled) {
      keyInputRef?.current?.focus()
    }
  }, [isDisabled])
  useEffect(() => {
    if (item?.key || item?.value) {
      setValues(item)
    }
  }, [item])
  const save = () => {
    if (isNew) {
      onSave(values)
      setValues(defaultValues)
      keyInputRef?.current?.focus()
    } else {
      onSave(values)
      setIsDisabled((prev) => !prev)
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '15px',
      }}
    >
      <TextField
        inputRef={keyInputRef}
        disabled={isDisabled}
        variant="outlined"
        size="small"
        value={values?.key}
        onChange={(e) => setValues((prev) => ({ ...prev, key: e.target.value }))}
        placeholder={isNew ? 'Key' : ''}
        onKeyUp={(event) => {
          if (event.key === 'Enter' && values.key) {
            valueInputRef?.current?.focus()
          }
        }}
      />
      <Typography
        variant="textSecondary"
        color="text.secondary"
        sx={{
          opacity: isDisabled ? 0.5 : 1,
        }}
      >
        =
      </Typography>
      <TextField
        inputRef={valueInputRef}
        disabled={isDisabled}
        variant="outlined"
        value={values?.value}
        onChange={(e) => setValues((prev) => ({ ...prev, value: e.target.value }))}
        placeholder={isNew ? 'Value' : ''}
        onKeyUp={(event) => {
          if (event.key === 'Enter' && values.key && values.value) {
            save()
          }
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '120px',
          color: 'text.secondary',
        }}
      >
        {isNew ? (
          <Button
            variant="outlined"
            sx={{
              minWidth: '100%',
            }}
            onClick={save}
            disabled={!values.key || !values.value}
          >
            <Plus size={15} />
          </Button>
        ) : (
          <>
            <IconButton
              onClick={() => {
                if (isDisabled) {
                  setIsDisabled((prev) => !prev)
                } else {
                  save()
                }
              }}
            >
              {isDisabled ? <Edit2 size={13} /> : <Check size={15} />}
            </IconButton>
            <IconButton onClick={() => onRemove(values.key)}>
              <X size={15} />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  )
}
export const CustomTagsFilter = ({ filter, onChange, expandedFilter, toggleExpandedFilter }) => {
  const isOpen = expandedFilter === filter.filter

  const tags = useMemo(
    () => keys(filter.value).map((key, idx) => ({ key, value: filter.value?.[key], index: idx })),
    [filter]
  )

  const onRemove = (key) =>
    onChange(
      filter,
      tags.filter((i) => i.key !== key)
    )

  return (
    <DropdownWrapper
      isOpen={isOpen}
      title={filter.label}
      onToggle={() => toggleExpandedFilter(filter.filter)}
      onDelete={(selectedValue) => onRemove(selectedValue?.key)}
      renderChipLabel={(selectedValue) => `${selectedValue?.key}:${selectedValue?.value}`}
      selectedItems={tags}
    >
      <Box
        sx={{
          overflow: 'hidden',
          padding: '0 10px',
        }}
      >
        {tags.map((item, index) => (
          <CustomTagRow
            key={`custom-tags-${index}`}
            item={{ ...item, index }}
            index={index}
            onSave={(newTag) => {
              onChange(
                filter,
                tags.map((tag) =>
                  newTag.index === tag.index
                    ? { key: newTag.key, value: newTag.value, index: newTag.index }
                    : tag
                )
              )
            }}
            onRemove={onRemove}
          />
        ))}
        <CustomTagRow
          isNew
          onSave={(newTag) => {
            if (newTag.key && newTag.value) {
              onChange(filter, [...tags, newTag])
            }
          }}
        />
      </Box>
    </DropdownWrapper>
  )
}
