import shortid from 'shortid'

export const randomFromRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Generate a random id
 */
export const randomId = () => shortid.generate()
