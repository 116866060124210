import { useState } from 'react'
import { coreApiClient } from 'util/coreApiClient'
import chunk from 'lodash.chunk'
import { useIsInstrumenting } from './useIsInstrumenting'
import config from 'config'
import InstrumentationFlowFailureSnackbar from 'common/components/InstrumentationFailureSnackbar'
import { Box, CircularProgress } from '@mui/material'
import { AlertTriangle } from 'lucide-react'
import { useSnackbar } from 'notistack'
import { useTheme } from '@mui/styles'

const DEFAULT_SIZE = 50

export const hasDevEnvironmentTag =
  (compatibility = ['nodejs14.x', 'nodejs16.x', 'nodejs18.x']) =>
  ({ accountId, from = 0, size = 50 }) => ({
    from,
    size,
    query: {
      bool: {
        must: [
          {
            match: { type: 'resource_aws_lambda' },
          },
          {
            match: { tag_account_id: accountId },
          },
          {
            match: { 'tag_environment.keyword': 'dev' },
          },
          {
            terms: {
              'aws_lambda_runtime.keyword': compatibility,
            },
          },
        ],
      },
    },
    sort: [
      {
        'aws_lambda_name.keyword': { order: 'asc' },
      },
    ],
  })

export const hasDevModeFunctionFilter = ({ accountId, from = 0, size = 50 }) => ({
  from,
  size,
  query: {
    bool: {
      must: [
        {
          match: { type: 'resource_aws_lambda' },
        },
        {
          match: { tag_account_id: accountId },
        },
        {
          match: {
            instrument_mode: 'dev',
          },
        },
      ],
    },
  },
  sort: [
    {
      'aws_lambda_name.keyword': { order: 'asc' },
    },
  ],
})

export const prodCompatibleResources =
  (compatibility = ['nodejs14.x', 'nodejs16.x', 'nodejs18.x', 'go1.x', 'python3.8', 'python3.9']) =>
  ({ accountId, from = 0, size = 50 }) => ({
    from,
    size,
    query: {
      bool: {
        must: [
          {
            match: {
              type: 'resource_aws_lambda',
            },
          },
          {
            match: {
              tag_account_id: accountId,
            },
          },
          {
            terms: {
              'aws_lambda_runtime.keyword': compatibility,
            },
          },
        ],
      },
    },
    sort: [
      {
        'aws_lambda_name.keyword': {
          order: 'asc',
        },
      },
    ],
  })
export const canEnableTraces =
  (compatibility = ['nodejs14.x', 'nodejs16.x', 'nodejs18.x', 'go1.x', 'python3.8', 'python3.9']) =>
  ({ accountId, from = 0, size = 50 }) => ({
    from,
    size,
    query: {
      bool: {
        must: [
          {
            match: {
              type: 'resource_aws_lambda',
            },
          },
          {
            match: {
              tag_account_id: accountId,
            },
          },
          {
            match: {
              instrument_mode: 'none',
            },
          },
          {
            terms: {
              'aws_lambda_runtime.keyword': compatibility,
            },
          },
        ],
      },
    },
    sort: [
      {
        'aws_lambda_name.keyword': {
          order: 'asc',
        },
      },
    ],
  })

export const hasTracesEnabled = ({ accountId, from = 0, size = 50, excludeDevMode = true }) => ({
  from,
  size,
  query: {
    bool: {
      must: [
        {
          match: {
            type: 'resource_aws_lambda',
          },
        },
        {
          match: {
            tag_account_id: accountId,
          },
        },
        {
          terms: {
            instrument_mode: excludeDevMode ? ['prod'] : ['prod', 'dev'],
          },
        },
      ],
    },
  },
  sort: [
    {
      'aws_lambda_name.keyword': {
        order: 'asc',
      },
    },
  ],
})

const instrumentationMap = {
  dev: {
    targetInstrumentations: {
      mode: 'dev',
    },
    checkForExistingInstrumentation: hasDevModeFunctionFilter,
    getAllRelatedInventory: hasDevEnvironmentTag,
    compatibleResourcesCheck: hasDevEnvironmentTag,
  },
  prod: {
    targetInstrumentations: {
      mode: 'prod',
    },
    checkForExistingInstrumentation: hasTracesEnabled,
    getAllRelatedInventory: canEnableTraces,
    compatibleResourcesCheck: prodCompatibleResources,
  },
}

export const useEnableBulkInstrumentation = ({
  orgId,
  targetIntegration,
  enableInstrumentationType = 'dev',
  showProgressSnackbar = true,
}) => {
  const { instrumenting } = useIsInstrumenting({
    specificModes: [enableInstrumentationType],
  })
  const theme = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [allIntegrations, setAllIntegrations] = useState([])
  const [loadingFunctionCount, setLoadingFunctionCount] = useState(true)
  const [compatibleFunctionCount, setCompatibleFunctionCount] = useState(0)
  const [canShowWelcome, setCanShowWelcome] = useState(false)
  const [resources, setResources] = useState([])
  const [doesNotHaveInstrumentation, setDoesNotHaveInstrumentation] = useState(false)
  const [waitingForInstrumentation, setWaitingForInstrumentation] = useState(false)
  const [hasNoCompatibleResources, setHasNoCompatibleResources] = useState(false)
  const [instrumentationProgress, setInstrumentationProgress] = useState({
    instrumented: 0,
    total: 0,
  })

  /**
   * Fetches all compatible dev mode functions
   *
   * @param {integrations, fetchAllResources} param If fetchAllResources is set to true then we will paginate through the inventory to build the resources list
   * @returns { hits: [], total: 0 }
   */
  const discoverInventory = async ({
    integrations,
    fetchAllResources,
    inventoryFunction,
    excludeDevMode = true,
  } = {}) => {
    const integrationHitsResult = await Promise.all(
      integrations.map(({ vendorAccount: accountId }) => {
        const findInventory = async (functionList, page) => {
          const { hits, total } = await coreApiClient.post(
            `/search/orgs/${orgId}/search`,
            inventoryFunction({
              accountId,
              from: page * DEFAULT_SIZE,
              size: DEFAULT_SIZE,
              excludeDevMode,
            })
          )
          const newFunctionList = {
            total: functionList.total === 0 ? total : functionList.total,
            hits: [
              ...functionList.hits,
              ...hits.map(({ id, tag_environment, tag_namespace, aws_lambda_name }) => ({
                functionName: aws_lambda_name,
                instrumentations: {
                  mode: instrumentationMap[enableInstrumentationType].targetInstrumentations?.mode,
                },
                resourceKey: id,
                updateFields: {
                  namespace: tag_namespace,
                  environment: tag_environment,
                },
                selected: true,
              })),
            ],
          }
          if (hits.length === 0 || !fetchAllResources) return newFunctionList
          return findInventory(newFunctionList, page + 1)
        }
        return findInventory({ total: 0, hits: [] }, 0)
      })
    )

    const combinedIntegrationResults = integrationHitsResult.reduce(
      (obj, account) => ({
        hits: [...obj.hits, ...(account?.hits || [])],
        total: obj.total + account.total,
      }),
      { hits: [], total: 0 }
    )

    if (fetchAllResources) {
      setResources(combinedIntegrationResults.hits)
    }

    return combinedIntegrationResults
  }

  const getIntegrationList = async () => {
    if (allIntegrations.length > 0) return allIntegrations
    let targetIntegrations = []
    if (targetIntegration) {
      const { integration } = await coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/${targetIntegration}`,
      })
      targetIntegrations = [integration]
    } else {
      const { integrations } = await coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/?orgId=${orgId}`,
      })
      targetIntegrations = integrations
    }
    setAllIntegrations(targetIntegrations)
    return targetIntegrations
  }

  /**
   * Get alls the integrations for a given org
   *
   * @returns
   */
  const checkForInstrumentation = async () => {
    const integrations = await getIntegrationList()
    return {
      integrations,
      allIntegrationsResult: await discoverInventory({
        integrations,
        fetchAllResources: false,
        inventoryFunction:
          instrumentationMap[enableInstrumentationType].checkForExistingInstrumentation,
      }),
    }
  }

  /**
   * This enables logs, tracing, and dev mode on all specified resources
   *
   * @param {resources} param
   */
  const enableTargetFunctions = async ({ incomingResources } = {}) => {
    let completeFunctions = []
    let incompleteFunctions = []
    const syncId = `${Date.now()}`
    if (showProgressSnackbar) {
      enqueueSnackbar(
        <Box display="flex" alignItems="center" gap="15px">
          <CircularProgress color="secondary" size={18} thickness={5} />
          Instrumentation processing. Please wait.
        </Box>,
        { key: syncId, persist: true }
      )
    }

    const focusedResources = (incomingResources || resources)
      .filter(({ selected }) => selected)
      .map(({ functionName, selected, ...rest }) => rest)
    setWaitingForInstrumentation(true)
    const chunks = chunk(focusedResources, 50)
    const ids = []
    for (const chunkedResources of chunks) {
      const { flowId } = await coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/aws/instrumentations`,
        method: 'POST',
        data: {
          orgId,
          resources: chunkedResources,
        },
      })
      if (flowId) {
        ids.push(flowId)
      }
    }

    if (ids.length > 0) {
      let content
      let message = 'Instrumentation completed successfully.'
      let shouldPersist = false
      while (true) {
        const results = await Promise.allSettled(
          ids.map((id) =>
            coreApiClient({
              baseURL: config.platform.integrationsUrl,
              method: 'get',
              url: `/aws/flows/${encodeURIComponent(id)}`,
            })
          )
        )

        const allResults = results
          .filter(({ status }) => status === 'fulfilled')
          .map(({ value }) => value)
        completeFunctions = allResults.reduce((functions, { status, inventories }) => {
          return [...functions, ...inventories.filter(({ status }) => status === 'complete')]
        }, [])
        incompleteFunctions = allResults.reduce((functions, { status, inventories }) => {
          return [...functions, ...inventories.filter(({ status }) => status === 'incomplete')]
        }, [])
        setInstrumentationProgress({
          instrumented: completeFunctions.length,
          total: focusedResources.length,
        })
        const allComplete = allResults.reduce((done, { status }) => {
          if (!done) return done
          return status === 'complete' || status === 'incomplete'
        }, true)
        const allStatues = allResults.reduce((statuses, { status }) => {
          if (!statuses.includes(status)) {
            return [...statuses, status]
          }
          return statuses
        }, [])
        if (allComplete) {
          const allIncompleteInventories = allResults.reduce(
            (incomplete, { inventories }) => [
              ...incomplete,
              ...inventories.filter(({ status }) => status === 'incomplete'),
            ],
            []
          )
          if (allIncompleteInventories.length > 0) {
            message = (
              <Box display="flex" alignItems="center" gap="15px">
                <AlertTriangle
                  style={{ minWidth: 'fit-content' }}
                  size="18"
                  color={theme.palette.error.main}
                />
                Instrumentation completed with errors.
              </Box>
            )
            content = (key, message) => (
              <InstrumentationFlowFailureSnackbar
                id={key}
                message={message}
                incompleteInventories={allIncompleteInventories}
              />
            )
            shouldPersist = true
          } else if (allStatues.some((status) => status === 'incomplete')) {
            message = 'Instrumentation failed. Please try again.'
          }
          break
        }
        await new Promise((resolve) => setTimeout(resolve, 5000))
      }
      closeSnackbar(syncId)
      if (showProgressSnackbar) {
        enqueueSnackbar(message, {
          persist: shouldPersist,
          content,
        })
      }
      setWaitingForInstrumentation(false)
      setDoesNotHaveInstrumentation(false)
    }
    return {
      completeFunctions,
      incompleteFunctions,
    }
  }

  /**
   * Check if the customer has any functions with dev mode enabled.
   *
   * If we do not find any integrations with dev mode enabled and they do not have dev environment
   * tag we will redirect to the integration page
   *
   * If we do not find any integrations with dev mode enabled and they do have dev environment tag
   * then we will show a modal that enables dev mode on all functions.
   *
   * @param {forceDiscovery} param If forceDiscovery is set to true then we will paginate through the inventory to build the resources list
   */
  const beginCheckForFunctions = async (forceDiscovery = false) => {
    const compatibility = await coreApiClient.get('/inventories/compatibility')
    const integrations = await getIntegrationList()
    let totalCompatibleResources = 0
    if (enableInstrumentationType === 'prod') {
      const { total } = await discoverInventory({
        integrations,
        fetchAllResources: false,
        inventoryFunction: instrumentationMap[enableInstrumentationType].compatibleResourcesCheck(
          compatibility?.mode?.[enableInstrumentationType]?.runtimes
        ),
      })
      totalCompatibleResources = total
    }
    const {
      allIntegrationsResult: { total: functionCount },
    } = await checkForInstrumentation()
    if (enableInstrumentationType === 'dev') {
      totalCompatibleResources = functionCount
    }

    const { total: envTotal } = await discoverInventory({
      integrations,
      fetchAllResources: false,
      inventoryFunction: instrumentationMap[enableInstrumentationType].getAllRelatedInventory(
        compatibility?.mode?.[enableInstrumentationType]?.runtimes
      ),
    })

    setCompatibleFunctionCount(envTotal)
    setInstrumentationProgress({
      total: envTotal,
      instrumented: 0,
    })

    const instrumentationCheck =
      enableInstrumentationType === 'prod'
        ? functionCount < envTotal || forceDiscovery
        : functionCount === 0 && envTotal > 0
    const doesNotHaveInstrumentation =
      enableInstrumentationType === 'prod' ? functionCount < envTotal : functionCount === 0

    if (instrumentationCheck) {
      setDoesNotHaveInstrumentation(true)

      const { hits: newResources } = await discoverInventory({
        integrations,
        fetchAllResources: true,
        inventoryFunction: instrumentationMap[enableInstrumentationType].getAllRelatedInventory(
          compatibility?.mode?.[enableInstrumentationType]?.runtimes
        ),
      })

      setLoadingFunctionCount(false)
      setHasNoCompatibleResources(totalCompatibleResources === 0)
      return {
        resources: newResources,
        compatibleFunctionCount: envTotal,
        doesNotHaveInstrumentation,
        hasNoCompatibleResources: totalCompatibleResources === 0,
      }
    } else if (functionCount === 0 && envTotal === 0) {
      setDoesNotHaveInstrumentation(false)
      setCanShowWelcome(true)
    }
    setCompatibleFunctionCount(functionCount)
    setLoadingFunctionCount(false)
    setHasNoCompatibleResources(totalCompatibleResources === 0)
    return {
      resources: [],
      compatibleFunctionCount: functionCount,
      doesNotHaveInstrumentation: functionCount === 0,
      hasNoCompatibleResources: totalCompatibleResources === 0,
    }
  }

  const hasTracesIncludingDevMode = async () => {
    const integrations = await getIntegrationList()
    const { total } = await discoverInventory({
      integrations,
      fetchAllResources: false,
      inventoryFunction: hasTracesEnabled,
      excludeDevMode: false,
    })
    return total === 0
  }

  return {
    currentlyInstrumenting: instrumenting,
    canShowWelcome,
    doesNotHaveInstrumentation,
    waitingForInstrumentation,
    instrumentationProgress,
    loadingFunctionCount,
    compatibleFunctionCount,
    hasNoCompatibleResources,

    // These are there so downstream components can set
    // the resources we want to toggle
    allResources: resources,
    selectedFunctionCount: resources.filter(({ selected }) => selected).length,
    setResources: (newResources) => {
      setResources(newResources)
      setInstrumentationProgress((c) => ({
        ...c,
        total: newResources.filter(({ selected }) => selected).length,
      }))
    },

    enableTargetFunctions,
    setDoesNotHaveInstrumentation,
    setCanShowWelcome,
    beginCheckForFunctions,
    hasTracesIncludingDevMode,
  }
}
