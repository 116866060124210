import {
  Autocomplete,
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { styled } from '@mui/styles'
import CloseButton from 'common/components/CloseButton'
import { useMap } from 'react-use'
import { FiltersWidget } from 'filter/components/FiltersWidget'
import { cloneDeep, isEmpty, isNil, keys } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import useSWR, { mutate } from 'swr'
import { AppContext } from 'app/context/AppContext'
import { validateEmail } from 'util/email'
import { Bell, BellPlus, Slack, Webhook } from 'lucide-react'
import Button from 'common/components/Button'
import { coreApiClient } from 'util/coreApiClient'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { updateFilters } from 'metrics/hooks/useQuery'
import { useSnackbar } from 'notistack'
import {
  HiddenFilters,
  eventsOptions,
  filterList,
  pageFilters,
  queryStringTypes,
} from 'filter/util/filters'
import { Checkbox } from 'common/components/Checkbox'
import config from 'config'
import { FilterSearchDropdown } from 'common/components/FilterSearchDropdown'
import { useQueryParams } from 'use-query-params'
import { Mail } from 'lucide-react'
import { useAlertDialog } from './useAlertDialog'

const notificationTypes = [
  {
    name: 'email',
    label: 'Email',
    description: 'Send alerts via email',
    icon: <Mail size={30} />,
  },
  {
    name: 'slack',
    label: 'Slack',
    description: 'Send alerts to a Slack channel',
    icon: <Slack size={30} />,
    isDisabled: true,
  },
  {
    name: 'webhook',
    label: 'Webhook',
    description: 'Send alerts to external services',
    icon: <Webhook size={30} />,
    isDisabled: true,
  },
]
const deepSearch = (object = {}, key) => {
  const obj = object || {}
  if (obj?.hasOwnProperty(key)) {
    if (key === 'runtime') {
      return obj[key]?.identifier
    }
    if (key === 'custom') {
      return keys(obj[key])?.reduce((acc, k) => ({ ...acc, [k]: obj[key]?.[k]?.[0] }), {})
    }
    if (key === 'http') {
      return {
        method: obj[key]?.method,
        status: obj[key]?.statusCode,
        path: obj?.httpRouter?.path,
      }
    }
    if (key === 'duration') {
      return {
        min: obj[key]?.from,
        max: obj[key]?.to,
      }
    }
    return obj[key]
  }
  if (key === 'http') {
    return {
      path: obj?.aws?.lambda?.httpRouter?.path || [],
      method: obj?.aws?.lambda?.http?.method || [],
      status: obj?.aws?.lambda?.http?.statusCode || [],
    }
  }
  if (key === 'events') {
    return obj?.eventTypes
  }
  for (let i = 0; i < Object.keys(obj).length; i++) {
    let value = obj[Object.keys(obj)[i]]
    if (typeof value === 'object' && !isEmpty(value)) {
      let o = deepSearch(obj[Object.keys(obj)[i]], key)
      if (!isNil(o)) return o
    }
  }
  return null
}

const formatInitialFilters = (filtersObj) => {
  const filtersArray = pageFilters['settings/alerts#']?.filter(
    (filter) => !HiddenFilters.includes(filter.filter)
  )

  return filtersArray?.map((filter) => ({
    ...filter,
    value: deepSearch(filtersObj, filter?.queryApiTagName),
  }))
}
export const AlertDialog = () => {
  const { isOpen, setIsOpen } = useAlertDialog()
  if (!isOpen) return null
  return <AlertDialogContent createAlertParam={isOpen} setCreateAlertParam={setIsOpen} />
}
const AlertDialogContent = ({ createAlertParam, setCreateAlertParam }) => {
  const { user, activeOrg } = useContext(AppContext)
  const userEmail = user?.userEmail
  const orgId = activeOrg?.orgId
  const [query] = useQueryParams(queryStringTypes)
  const filters = cloneDeep(
    pageFilters['settings/alerts#']?.filter((filter) => !HiddenFilters.includes(filter.filter))
  )
  for (const filter of filters) {
    if (query[filter.filter] !== undefined) {
      filter.value = query[filter.filter]
    }
  }

  const isNew = createAlertParam === 'isNew'
  const isOpen = !!createAlertParam
  const {
    data,
    error,
    mutate: mutateCurrent,
  } = useSWR(!isNew && `/alerts/${orgId}}`, () =>
    coreApiClient({
      baseURL: config.platform.alerts,
      url: `/alerts/${orgId}`,
    })
  )

  const existingAlert = data?.alerts?.find((alert) => alert?.alertId === createAlertParam)

  const isLoading = !isNew && !data && !error
  const [isCreating, setIsCreating] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [inviteMembers, setInviteMembers] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const existingFiltersParams = useMemo(() => {
    const obj = {}
    filters?.forEach((filter) => {
      if (!filter.queryApiTagName) return
      if (filter.type === 'string' && query[filter.filter]) {
        obj[filter.queryApiTagName] = query[filter.filter]
      }
      if (filter.type === 'boolean' && query[filter.filter] === true) {
        obj[filter.queryApiTagName] = query[filter.filter]
      }
      if (filter.type === 'array' && query[filter.filter] && query[filter.filter].length > 0) {
        obj[filter.queryApiTagName] = query[filter.filter]
      }
      if (
        filter.type === 'autocomplete' &&
        query[filter.filter] &&
        query[filter.filter].length > 0
      ) {
        obj[filter.queryApiTagName] = query[filter.filter]
      }
      if (filter.type === 'keyValue' && query[filter.filter] && !isEmpty(query[filter.filter])) {
        obj[filter.queryApiTagName] = keys(query[filter.filter]).reduce(
          (acc, key) => ({ ...acc, [key]: query[filter.filter][key] }),
          {}
        )
      }
    })
    return obj
  }, [filters])

  const defaultAlert = {
    name: '',
    orgId,
    eventAlert: {
      filters: existingFiltersParams || {},
      eventTypes: ['ERROR_TYPE_UNCAUGHT'],
    },
    notificationConfigurations: [
      {
        notificationType: 'email',
        emails: [userEmail],
      },
    ],
  }

  const [alert, { set, setAll, reset }] = useMap(defaultAlert)

  const initialFiltersValues = useMemo(
    () => formatInitialFilters(alert?.eventAlert?.filters),
    [createAlertParam, alert?.eventAlert?.filters, isLoading, isOpen, isNew]
  )

  useEffect(() => {
    setAll(!isNew && existingAlert ? existingAlert : defaultAlert)
  }, [existingAlert, data?.alerts, createAlertParam])

  /**
   * Reset error message on alert values change
   */
  useEffect(() => {
    setErrorMessage('')
  }, [alert])

  const closeDialog = () => {
    reset()
    setCreateAlertParam(undefined)
  }

  const saveAlert = async () => {
    setIsCreating(true)
    try {
      if (!alert?.name) {
        throw new Error('Please add an alert name')
      }
      if (!alert?.eventAlert?.eventTypes?.length) {
        throw new Error('Please add at least one event')
      }
      if (!alert?.notificationConfigurations?.[0]?.emails?.length) {
        throw new Error('Please add at least one email notification')
      }
      await coreApiClient({
        baseURL: config.platform.alerts,
        url: `/alerts`,
        method: isNew ? 'post' : 'put',
        data: alert,
      })
      if (inviteMembers?.some((i) => i.checked)) {
        await Promise.all(
          inviteMembers.map(({ email }) =>
            coreApiClient.post(`/identity/invitations`, {
              orgId,
              inviteeEmail: email,
              inviteeRole: 'contributor',
            })
          )
        )
      }
      const newAlertsList = await coreApiClient({
        baseURL: config.platform.alerts,
        url: `/alerts/${orgId}`,
      })

      // Refresh alerts list if on alerts page
      mutate(`/alerts/${orgId}`, newAlertsList)
      mutateCurrent(newAlertsList)

      enqueueSnackbar(`${isNew ? 'Created' : 'Saved'} alert successfully`)

      closeDialog()
    } catch (err) {
      setErrorMessage(err.message)
    }
    setIsCreating(false)
  }

  const { data: membersData, error: membersError } = useSWR(`members`, () =>
    coreApiClient.get(`/identity/orgs/${orgId}/members`)
  )
  const { data: invitesData, error: invitesError } = useSWR(`invites`, () =>
    coreApiClient.get(`/identity/invitations?orgId=${orgId}`)
  )
  const orgMembers = membersData?.members || []
  const orgInvites = invitesData?.pendingInvites || []
  const loadingMembers = !membersData && !membersError
  const loadingInvites = !invitesData && !invitesError
  const loadingNotifications = loadingMembers || loadingInvites

  /**
   * Check if notification emails entered by user are already an org member or have an invite to join the org
   */
  useEffect(() => {
    setInviteMembers(
      alert?.notificationConfigurations?.[0]?.emails.reduce((acc, i) => {
        const email = i?.user?.userEmail || i?.inputValue || i

        if (
          orgMembers?.some((member) => member?.user?.userEmail === email) ||
          orgInvites?.some((invitee) => invitee?.inviteeEmail === email) ||
          email === userEmail
        ) {
          return acc
        }
        return [...acc, { email, checked: false }]
      }, [])
    )
  }, [alert?.notificationConfigurations?.[0]?.emails, existingAlert, loadingNotifications])

  const filter = createFilterOptions()

  const eventsFilter = useMemo(
    () => ({
      ...filterList?.awsLambdaEvents,
      value: alert?.eventAlert?.eventTypes,
      options: eventsOptions.filter((option) => !option.hideFilterList),
    }),
    [alert?.eventAlert?.eventTypes]
  )

  if (!isOpen || (!isNew && isLoading)) return null
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: { padding: 0, height: 'calc(100% - 64px)' },
      }}
      fullWidth
      maxWidth="lg"
    >
      <Header>
        <Box sx={{ marginRight: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '10px',
            }}
          >
            <BellPlus size={20} />
            <Typography variant="h2">{isNew ? 'Create' : 'Edit'} Alert</Typography>
          </Box>
          <SubLabel>Create and manage alerts based on specific events and conditions</SubLabel>
        </Box>
        <CloseButton size="large" onClick={closeDialog} aria-label="close" />
      </Header>

      <StyledDialogContent>
        <Column>
          <Section>
            <LabelWrapper>
              <Label>Alert Name</Label>
              <SubLabel>Choose an alert name</SubLabel>
            </LabelWrapper>
            <TextField
              autoFocus
              value={alert?.name}
              onChange={(e) => set('name', e.target.value)}
              placeholder="Alert name"
              id="alert-name"
            />
          </Section>
          <Section>
            <FilterSearchDropdown
              filter={eventsFilter}
              onChange={(option) => {
                const exists = alert?.eventAlert?.eventTypes?.includes(option?.value)
                set('eventAlert', {
                  filters: alert?.eventAlert?.filters,
                  eventTypes: exists
                    ? alert?.eventAlert?.eventTypes.filter((opt) => opt !== option?.value)
                    : [...alert?.eventAlert?.eventTypes, option?.value],
                })
              }}
              collapsable={false}
              animate={false}
            />
          </Section>
          <Section>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
              {notificationTypes?.map(({ name, label, icon, description, isDisabled }) => (
                <NotificationTypesBox
                  disabled={isDisabled}
                  is-selected={name === 'email' && 'true'}
                  key={`alerts-notifications-types-${name}`}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {icon}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="textSecondary">{label}</Typography>
                    <Typography variant="textTertiary" color="text.secondary">
                      {description}
                    </Typography>
                  </Box>
                </NotificationTypesBox>
              ))}
            </Box>
          </Section>
          <Section>
            <LabelWrapper>
              <Label>Notifications</Label>
              <SubLabel>Emails to send an alert to</SubLabel>
            </LabelWrapper>

            <Autocomplete
              id="alert-notifications"
              loading={loadingNotifications}
              multiple
              selectOnFocus
              clearOnBlur
              freeSolo
              handleHomeEndKeys
              disableCloseOnSelect
              options={orgMembers}
              limitTags={3}
              value={alert?.notificationConfigurations?.[0]?.emails || []}
              onChange={(event, newValues) => {
                set('notificationConfigurations', [
                  {
                    notificationType: 'email',
                    emails: newValues?.map((value) => {
                      if (typeof value === 'string') {
                        return value
                      } else if (value && value.inputValue) {
                        // Create a new value from the user input
                        return value.inputValue
                      } else {
                        return value?.user?.userEmail
                      }
                    }),
                  },
                ])
              }}
              getOptionDisabled={(option) =>
                !validateEmail(option.inputValue) && !validateEmail(option?.user?.userEmail)
              }
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue
                }
                // Regular option
                return option?.user?.userEmail || ''
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options?.some((option) => inputValue === option?.user?.userEmail)
                if (inputValue !== '' && !isExisting) {
                  const extraMsg = !validateEmail(inputValue) ? ' (Not a valid email)' : ''
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"${extraMsg}`,
                  })
                }

                return filtered
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Avatar
                      alt={option?.user?.userEmail}
                      src={option?.user?.profilePictureUrl}
                      sx={{
                        width: 20,
                        height: 20,
                        marginRight: '10px',
                        filter: 'grayscale(100%)',
                      }}
                      imgProps={{
                        draggable: false,
                      }}
                    />
                    {option?.user?.userEmail || option.title || option.inputValue || option}
                  </li>
                )
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Section>
          <Section>
            {inviteMembers?.length ? (
              <Box>
                <Typography variant="textSecondary">
                  Would you like to invite these users to your org as well?
                </Typography>
                {inviteMembers?.map(({ email, checked }) => (
                  <Box key={`notification-invite-users-${email}`} marginTop="15px">
                    <Checkbox
                      label={email}
                      textVariant="textPrimary"
                      checked={checked}
                      onChange={(e) =>
                        setInviteMembers((prev) =>
                          prev?.map((i) =>
                            i.email === email ? { ...i, checked: e.target.checked } : i
                          )
                        )
                      }
                    />
                  </Box>
                ))}
              </Box>
            ) : null}
          </Section>
        </Column>
        <Column no-border="true">
          <Section>
            <LabelWrapper>
              <Label>
                Additional Filters{' '}
                <Typography component="span" color="text.secondary">
                  (optional)
                </Typography>
              </Label>
              <SubLabel>Conditions used to send alerts</SubLabel>
            </LabelWrapper>
            <Box sx={{ margin: '0 -10px' }}>
              <FiltersWidget
                autoSave
                initialValues={initialFiltersValues}
                onSave={(newFilters) => {
                  /**
                   * Set values to be:
                   * {    eventTypes:[],
                   *      filters: {}
                   * }
                   */
                  const filters = newFilters.reduce((acc, filter) => {
                    if (isNil(filter.value)) return acc

                    if (filter.type === 'keyValue') {
                      return {
                        ...acc,

                        [filter.queryApiTagName]: keys(filter.value).reduce(
                          (acc, key) => ({ ...acc, [key]: filter.value?.[key] }),
                          {}
                        ),
                      }
                    }
                    return {
                      ...acc,

                      [filter.queryApiTagName]: filter.value,
                    }
                  }, {})

                  const formatFilters = updateFilters({ filters })

                  set('eventAlert', {
                    eventTypes: alert?.eventAlert?.eventTypes,
                    filters: formatFilters,
                  })
                }}
              />
            </Box>
          </Section>
        </Column>
      </StyledDialogContent>

      <StyledDialogActions disableSpacing>
        <Box sx={{ gridColumn: '1/3', marginTop: '8px' }}>
          <ErrorMessage message={errorMessage} style={{ marginBottom: errorMessage ? '5px' : 0 }} />
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '5px',
              color: 'text.secondary',
            }}
          >
            <Bell size={14} />
            <Typography variant="textSecondary" color="text.secondary">
              Alerts will be sent based on first occurrences in 24 hrs.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button size="small" color="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            loading={isCreating}
            onClick={saveAlert}
            sx={{ minWidth: 200 }}
          >
            Save
          </Button>
        </Box>
      </StyledDialogActions>
    </Dialog>
  )
}

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingBottom: '25px',
  gap: '10px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
  padding: '20px 30px',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.secondary.main,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1.5fr 0.7fr',
  gap: '10px',
  padding: 0,
}))
const Column = styled('div')(({ theme, ...props }) => ({
  borderRight: props['no-border'] ? 'none' : `1px solid ${theme.palette.border.main}`,
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '8px 24px 24px 24px',
  borderTop: `1px solid ${theme.palette.border.main}`,
  gap: '8px 20px',
}))
const Section = styled('div')(({ theme }) => ({
  marginTop: '20px',
  padding: '0 30px',
}))
const LabelWrapper = styled('div')(({ theme }) => ({
  marginBottom: '8px',
}))

const Label = styled((props) => <Typography variant="h4" {...props} />)(({ theme }) => ({}))
const SubLabel = styled((props) => (
  <Typography variant="textSecondary" color="text.secondary" {...props} />
))(({ theme }) => ({}))

const NotificationTypesBox = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  padding: '10px 5px',
  display: 'grid',
  gridTemplateColumns: '0.7fr 2fr',
  cursor: 'pointer',
  position: 'relative',
  '&:hover:not([disabled]), &[is-selected]': {
    border: `1px solid ${theme.palette.primary.main}`,
  },

  '&[disabled]': {
    cursor: 'default',
    color: theme.palette.text.secondary,
  },
  '&[disabled]:before': {
    content: '"soon"',
    position: 'absolute',
    zIndex: 99,
    fontSize: theme.typography.textTertiary.fontSize,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    lineHeight: 1,
    padding: '2px 4px',
    borderRadius: '4px',
    top: '0px',
    right: '0px',
    opacity: 1,
  },
}))
