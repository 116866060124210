import { IconButton, Box, Tooltip } from '@mui/material'
import { useTheme } from '@mui/styles'
import { isNumber } from 'lodash'
import { forwardRef, useRef } from 'react'
import { useKeyPress } from 'react-use'

const CloseButton = forwardRef(
  ({ children, disabled, size = 30, loading, sx, initialColor, onClick, ...props }, outerRef) => {
    const ref = useRef(outerRef)

    const theme = useTheme()

    const sizes = {
      small: 20,
      medium: 25,
      large: 30,
    }
    const buttonSize = isNumber(size) ? size : sizes[size]
    const barSize = 2

    useKeyPress((e) => e.key === 'Escape' && onClick(e))

    const Bar = ({ className }) => (
      <Box
        className={className}
        sx={{
          height: barSize,
          width: '60%',
          position: 'absolute',
          backgroundColor: initialColor || theme.palette.grey.light,
          borderRadius: '2px',
          transform: `rotate(${className === 'rightleft' ? '-45deg' : '45deg'})`,
          transition: 'all .2s ease-in',
        }}
      />
    )
    return (
      <Tooltip title="esc">
        <IconButton
          sx={{
            width: buttonSize,
            height: buttonSize,
            transition: 'all .3s ease-in',
            '&:hover': {
              '& .leftright': {
                transform: 'rotate(-45deg)',
                backgroundColor: initialColor || theme.palette.primary.main,
              },
              '& .rightleft': {
                transform: 'rotate(45deg)',
                backgroundColor: initialColor || theme.palette.primary.main,
              },
            },
            ...sx,
          }}
          onClick={(e) => onClick(e)}
          {...props}
          ref={ref}
        >
          <Bar className="leftright" />
          <Bar className="rightleft" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default CloseButton
