import React from 'react'
import { styled } from '@mui/material/styles'

/* Generic error boundary (for currently uncaught error states) */
export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    }
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback || <Fallback error={this.state.error} />
    }
    return this.props.children
  }
}

export const Fallback = ({ error }) => {
  return (
    <Err.Container>
      <Err.Heading>Something went wrong</Err.Heading>
      <Err.Description>Error message: {`${error}`}</Err.Description>
    </Err.Container>
  )
}

export const Err = {
  Container: styled('div')({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    height: '100%',
  }),
  Heading: styled('h1')({}),
  Description: styled('pre')({}),
}
