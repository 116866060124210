import { Tooltip } from '@mui/material'
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params'
import Button from 'common/components/Button'
import { BellPlus } from 'lucide-react'

/**
 * A custom hook to manage state of Alert dialog, query param type is String, it can be one of the following:
 * - IsNew => To create a new Alert.
 * - <alert-id> => To edit an existing Alert.
 * - undefined => To close the Alert dialog.
 */
export const useAlertDialog = () => {
  const [isOpen, setIsOpen] = useQueryParam('alertDialog', StringParam)
  return {
    isOpen,
    setIsOpen,
  }
}

export const AlertDialogButton = ({ params = {}, sx = {}, showLabel = false }) => {
  const [, setQuery] = useQueryParams()

  return (
    <Tooltip title="Create Alert" enterDelay={0} leaveDelay={0}>
      <Button
        onClick={() =>
          setQuery({
            alertDialog: 'isNew',
            ...params,
          })
        }
        size="small"
        variant="contained"
        startIcon={showLabel && <BellPlus size={15} />}
        sx={{
          minWidth: 40,
          ...sx,
        }}
      >
        {!showLabel && <BellPlus size={15} />}
        {showLabel && 'Create Alert'}
      </Button>
    </Tooltip>
  )
}
