export const IconAWSecretsManager = ({ style = {} }) => {
  return (
    <svg style={style} focusable="false" aria-hidden viewBox="-1 -1 22 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.576 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm1.272-8.248H9.146V9.905h2.702v1.848Zm-.494-2.714H9.646c.055-.918.306-1.271.867-1.271.544 0 .79.353.842 1.271Zm1.36.433a.433.433 0 0 0-.433-.433h-.06C12.18 8.22 11.938 6.9 10.512 6.9c-1.445 0-1.69 1.32-1.733 2.138h-.067a.433.433 0 0 0-.433.433v2.715c0 .239.194.433.433.433h3.569c.24 0 .433-.194.433-.433V9.47Zm-6.262 2.91-.702.508A5.756 5.756 0 0 1 4.68 9.905H4v-.867h.682A5.765 5.765 0 0 1 5.75 6.07l.702.508a4.914 4.914 0 0 0-.903 2.46H6.6v.867H5.548c.076.894.375 1.744.904 2.476Zm4.453 2.005c.878-.074 1.74-.36 2.467-.887l.508.702c-.877.635-1.9.989-2.975 1.067V16h-.867v-.732a5.754 5.754 0 0 1-2.976-1.067l.508-.702c.727.527 1.588.813 2.468.887V13.4h.867v.986Zm-.867-9.813c-.88.074-1.741.36-2.468.887l-.508-.702c.875-.634 1.916-.972 2.976-1.048V3h.867v.71c1.058.076 2.1.414 2.975 1.048l-.508.702c-.727-.526-1.59-.812-2.467-.887V5.6h-.867V4.573Zm5.154 1.497a5.756 5.756 0 0 1 1.068 2.968H17v.867h-.74a5.75 5.75 0 0 1-1.068 2.984l-.702-.51c.529-.729.828-1.579.903-2.474H14.4v-.867h.993a4.906 4.906 0 0 0-.903-2.46l.702-.508Zm.73-1.429-1.715 1.716-.613-.613 1.715-1.715.613.612ZM5.02 14.318l1.714-1.715.613.612-1.714 1.716-.613-.613Zm.155-10.746 3.59 3.589-.613.613-3.59-3.59.613-.612Zm11.482 11.472-2.93-2.93-.612.613 2.928 2.93.614-.613Z"
      />
    </svg>
  )
}
