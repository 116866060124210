import React, { useContext } from 'react'
import { Tooltip, Box } from '@mui/material'
import { FilterContext } from 'filter/context/FilterContext'
import { IconFilter } from 'common/icons/IconFilter'
import { NavBarItem } from '../../../common/components/NavBarItem'
import { FiltersDropdown } from './FiltersDropdown'
import { CountTag } from 'common/components/CountTag'

export const FiltersNavItem = ({
  open,
  setOpen = () => {},
  onSave,
  onCancel,

  ...props
}) => {
  const { filtersCount } = useContext(FilterContext)

  return (
    <NavBarItem
      data-cy="metrics-filter"
      className={open && open !== 'filters' ? `notSelected` : null}
      onClick={setOpen}
      {...props}
    >
      <Tooltip title={`Set Filters`} placement="bottom">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <IconFilter size={22} />
          {filtersCount ? (
            <CountTag
              data-cy="metrics-filter-count"
              className={open && open !== 'filters' ? `notSelected` : null}
            >
              {filtersCount}
            </CountTag>
          ) : null}
        </Box>
      </Tooltip>

      {open && <FiltersDropdown onSave={onSave} onCancel={onCancel} />}
    </NavBarItem>
  )
}
