import React, { useContext, useEffect } from 'react'

import { render } from 'react-dom'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useMatch,
} from 'react-router-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { QueryParamProvider } from 'use-query-params'

import { AppContext, AppProvider } from 'app/context/AppContext'
import { ConfirmProvider } from 'material-ui-confirm'

// Pages
import Metrics from 'metrics/components/Metrics'
import Explorer from 'explorer/components/Explorer'
import LayoutSidebar from 'app/components/LayoutSidebar'
import Settings from 'settings/components/Settings'
import DevMode from 'dev-mode/components/DevMode'

// Auth
import ResetRequestPage from 'app/components/ResetRequest'
import AcceptInvitation from 'app/components/AcceptInvitation'
import { SnackbarProvider } from 'app/context/SnackbarContext'
import LoginContainer from './login/components/LoginContainer'
import Callback from './login/components/Callback'
import { PathReporter } from './common/components/PathReporter'
import OnboardingPage from './app/components/OnboardingPage'
import { Theme } from './theme/theme'

import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { parse, stringify } from 'query-string'
import { SWRConfig } from 'swr'

function RequireAuth({ children }) {
  const location = useLocation()
  const isMatched = useMatch('/:orgName')
  const { loggedIn } = useContext(AppContext)

  if (!loggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to="/" state={{ from: location, queryParams: window.location.search }} replace />
    )
  } else if (isMatched && !['accept-invitation', ''].includes(isMatched?.params?.orgName)) {
    return (
      <Navigate
        to={`/${isMatched?.params?.orgName}/metrics/awsLambda`}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}

function ConsoleRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LoginContainer />} />
      <Route path="callback" element={<Callback />} />
      <Route path="password/reset" element={<ResetRequestPage />} />
      <Route
        path="accept-invitation"
        element={
          <RequireAuth>
            <PathReporter />
            <AcceptInvitation />
          </RequireAuth>
        }
      />
      <Route
        path=":orgName"
        element={
          <RequireAuth>
            <PathReporter />
            <LayoutSidebar>
              <Outlet />
            </LayoutSidebar>
          </RequireAuth>
        }
      >
        <Route path="connect" element={<OnboardingPage />} />
        <Route path="settings/:pageName" element={<Settings />} />
        <Route path="settings/:pageName/:subPageName" element={<Settings />} />
        <Route path="dev-mode" element={<DevMode />} />
        <Route path="explorer" element={<Explorer />} />
        <Route path="metrics" element={<Navigate to="metrics/awsLambda" replace />} />
        <Route path="metrics/:metricsViewUid" element={<Metrics />} />
        <Route path="*" element={<Navigate to="metrics/awsLambda" replace />} />
      </Route>
    </Routes>
  )
}

const App = () => {
  useEffect(() => {
    window.location.href = 'https://app.serverless.com/?console=1 '
  }, [])
  return (
    <Router>
      <Theme>
        <ConfirmProvider>
          <SWRConfig
            value={{
              revalidateIfStale: false,
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              shouldRetryOnError: false,
            }}
          >
            <QueryParamProvider
              adapter={ReactRouter6Adapter}
              options={{
                searchStringToObject: parse,
                objectToSearchString: stringify,
              }}
            >
              <SnackbarProvider>
                <AppProvider>
                  <ConsoleRoutes />
                </AppProvider>
              </SnackbarProvider>
            </QueryParamProvider>
          </SWRConfig>
        </ConfirmProvider>
      </Theme>
    </Router>
  )
}

render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
