import { ArrayParam, BooleanParam, JsonParam, NumberParam, StringParam } from 'use-query-params'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { coreApiClient } from 'util/coreApiClient'
import config from 'config'

export const defaultMetricsFilterString = 'globalScope=awsLambda&globalTimeFrame=24h'
export const defaultExplorerFilterString =
  'explorerSubScope=invocations&globalScope=awsLambda&globalTimeFrame=24h'

/**
 * Defaults & utility functions
 */

export const timeFrameDefaults = [
  {
    value: '15m',
    cy: 'metrics-timeframe-select-15m',
    label: 'Last 15 Minutes',
  },
  {
    value: '1h',
    cy: 'metrics-timeframe-select-1h',
    label: 'Last 1 Hour',
  },
  {
    value: '24h',
    cy: 'metrics-timeframe-select-24h',
    label: 'Last 24 Hours',
  },
  {
    value: '7d',
    cy: 'metrics-timeframe-select-7d',
    label: 'Last 7 Days',
  },
  {
    value: '30d',
    cy: 'metrics-timeframe-select-30d',
    label: 'Last 30 Days',
  },
]

export const eventsOptions = [
  {
    id: 'ERROR_TYPE_UNCAUGHT',
    value: 'ERROR_TYPE_UNCAUGHT',
    label: 'Uncaught Error',
  },
  {
    id: 'ERROR_TYPE_CAUGHT_USER',
    value: 'ERROR_TYPE_CAUGHT_USER',
    label: 'Caught Error',
  },
  { id: 'WARNING_TYPE_USER', value: 'WARNING_TYPE_USER', label: 'Warning' },
  {
    id: 'WARNING_TYPE_SDK_INTERNAL',
    value: 'WARNING_TYPE_SDK_INTERNAL',
    label: 'SDK Internal Warning',
    hideFilterList: true,
  },
  {
    id: 'WARNING_TYPE_SDK_USER',
    value: 'WARNING_TYPE_SDK_USER',
    label: 'SDK Warning',
  },
  {
    id: 'ERROR_TYPE_CAUGHT_SDK_USER',
    value: 'ERROR_TYPE_CAUGHT_SDK_USER',
    label: 'SDK Error',
  },
  {
    id: 'ERROR_TYPE_CAUGHT_SDK_INTERNAL',
    value: 'ERROR_TYPE_CAUGHT_SDK_INTERNAL',
    label: 'SDK Internal Error',
    hideFilterList: true,
  },
  {
    id: 'NOTICE_TYPE_SDK_INTERNAL',
    value: 'NOTICE_TYPE_SDK_INTERNAL',
    label: 'SDK Internal Notice',
    hideFilterList: true,
  },
]
export const runtimeOptions = [
  {
    id: 'nodejs:18',
    value: 'nodejs:18',
    label: 'Nodejs 18',
  },

  {
    id: 'nodejs:16',
    value: 'nodejs:16',
    label: 'Nodejs 16',
  },
  {
    id: 'nodejs:14',
    value: 'nodejs:14',
    label: 'Nodejs 14',
  },
  {
    id: 'nodejs:12',
    value: 'nodejs:12',
    label: 'Nodejs 12',
  },
  {
    id: 'python:3.9',
    value: 'python:3.9',
    label: 'Python 3.9',
  },
  {
    id: 'python:3.8',
    value: 'python:3.8',
    label: 'Python 3.8',
  },
  {
    id: 'go:1',
    value: 'go:1',
    label: 'Go 1',
  },
]

export const globalScopes = [
  {
    name: 'awsLambda',
    displayName: 'AWS Lambda',
  },
]

/**
 * The Filter list.
 * This is a list of all global and Scope-specific Filters.
 * - "filter" is the name of the Filter, which is used in the URL query parameters to store state.
 * - "label" is the way the Filter should be presented in the UI.
 * - "type" is a minimal type-system we created to help present type-specific UI elements to handle the data.
 * - "queryApiTagName" is the OTEL Attribute Tag that the Filter corresponds to and must use in the Query API Request.
 */

export const filterList = {}

// Global
filterList.globalScope = {
  filter: 'globalScope',
  label: 'Scope',
  type: 'string',
}
filterList.globalTimeFrame = {
  filter: 'globalTimeFrame',
  label: 'Timeframe',
  type: 'timeframe',
}

filterList.globalCloudAccountId = {
  filter: 'globalCloudAccountId',
  label: 'Accounts',
  name: 'tag_account_id',
  queryKey: 'account_id',
  type: 'array',
  queryApiTagName: 'accountId',
  searchable: true,
  fetchAlias: async ({ orgId }) => {
    try {
      const { integrations } = await coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/?orgId=${orgId}`,
      })
      return integrations.reduce(
        (obj, { alias, vendorAccount }) => ({
          ...obj,
          [vendorAccount]: alias || vendorAccount,
        }),
        {}
      )
    } catch (error) {
      console.error(error)
    }
    return {}
  },
}
filterList.globalRegions = {
  filter: 'globalRegions',
  name: 'tag_region',
  queryKey: 'region',
  label: 'Regions',
  type: 'array',
  queryApiTagName: 'region',
  searchable: true,
}
filterList.globalEnvironments = {
  filter: 'globalEnvironments',
  name: 'tag_environment',
  queryKey: 'environment',
  label: 'Environments',
  type: 'array',
  queryApiTagName: 'environment',
  searchable: true,
}
filterList.globalNamespace = {
  filter: 'globalNamespace',
  name: 'tag_namespace',
  queryKey: 'namespace',
  label: 'Namespaces',
  type: 'array',
  queryApiTagName: 'namespace',
  searchable: true,
}

// AWS Lambda
filterList.awsLambdaFunctionNames = {
  filter: 'awsLambdaFunctionNames',
  label: 'Resources',
  name: 'resource_aws_lambda', // To use in openSearch query
  queryKey: 'aws_lambda_name', // To get label and value and sort key
  type: 'array',
  queryApiTagName: 'resourceName',
  icon: <IconAwsLambda size={10} />,
  searchable: true,
}
filterList.awsLambdaEvents = {
  filter: 'awsLambdaEvents',
  label: 'Events (Errors & Warnings)',
  name: 'events',
  queryKey: 'value',
  type: 'array',
  queryApiTagName: 'events',
  searchable: false,
  options: eventsOptions.filter((option) => !option.hideFilterList),
}
filterList.awsLambdaColdStart = {
  filter: 'awsLambdaColdStart',
  label: 'Cold Start',
  name: 'coldStart',
  queryKey: 'value',
  type: 'boolean',
  queryApiTagName: 'isColdstart',
  searchable: false,
}
filterList.awsLambdaDuration = {
  filter: 'awsLambdaDuration',
  label: 'Duration',
  name: 'duration',
  queryKey: 'duration',
  type: 'keyValue',
  queryApiTagName: 'duration',
  searchable: false,
}
filterList.awsLambdaCustomTags = {
  filter: 'awsLambdaCustomTags',
  label: 'Custom Tags',
  name: 'customTags',
  queryKey: 'customTags',
  type: 'keyValue',
  queryApiTagName: 'custom',
  searchable: false,
}

filterList.awsLambdaRuntime = {
  filter: 'awsLambdaRuntime',
  label: 'Runtime',
  name: 'runtime',
  queryKey: 'value',
  type: 'array',
  queryApiTagName: 'runtime',
  searchable: false,
  options: runtimeOptions,
}
filterList.awsLambdaHttpTags = {
  filter: 'awsLambdaHttpTags',
  label: 'HTTP',
  name: 'http',
  queryKey: 'value',
  type: 'keyValue',
  queryApiTagName: 'http',
  searchable: true,
}
filterList.instrumentationMode = {
  filter: 'instrumentationMode',
  label: 'Mode',
  name: 'instrumentationMode',
  queryKey: 'value',
  type: 'array',
  queryApiTagName: 'instrument_mode',
  searchable: false,
  options: [
    { id: 'none', value: 'none', label: 'None' },
    { id: 'dev', value: 'dev', label: 'Dev' },
    { id: 'prod', value: 'prod', label: 'Prod' },
  ],
}

// Explorer
// We want to save this in the URL so that is why it is added here :)
// I may end up moving this to a route later but I think it will be easier to star
// as just another filter
filterList.explorerSubScope = {
  filter: 'explorerSubScope',
  label: 'Sub Scope',
  type: 'string',
}
filterList.explorerTraceId = {
  filter: 'explorerTraceId',
  label: 'Trace ID',
  type: 'string',
}

filterList.explorerTraceSpanId = {
  filter: 'explorerTraceSpanId',
  label: 'Trace Span ID',
  type: 'string',
}
filterList.explorerTraceTime = {
  filter: 'explorerTraceTime',
  label: 'Trace Timestamp',
  type: 'string',
}
filterList.explorerPage = {
  filter: 'explorerPage',
  label: 'Explorer Current page',
  type: 'number',
}
filterList.explorerTracesFingerprints = {
  filter: 'explorerTracesFingerprints',
  label: 'Explorer Traces Fingerprints',
  type: 'array',
  name: 'fingerPrints',
  queryKey: 'fingerPrints',
  queryApiTagName: 'fingerprints',
}

// Integrations - These are hidden by default and are used in resources modal
filterList.integrationType = {
  filter: 'integrationType',
  label: 'Integration Type',
  type: 'string',
}
filterList.integrationId = {
  filter: 'integrationId',
  label: 'Integration Id',
  type: 'string',
}
filterList.editIntegration = {
  filter: 'editIntegration',
  label: 'Edit Integration',
  type: 'boolean',
}
filterList.page = {
  filter: 'page',
  label: 'Page',
  type: 'number',
}
filterList.integrationSearch = {
  filter: 'integrationSearch',
  label: 'Search',
  type: 'string',
}
/**
 * List available Filters per each view in the app
 */

const globalFilters = [
  filterList.globalCloudAccountId,
  filterList.globalEnvironments,
  filterList.globalNamespace,
  filterList.globalRegions,
  filterList.awsLambdaFunctionNames,
  filterList.globalScope,
  filterList.globalTimeFrame,
]

export const pageFilters = {
  'metric#awsLambda': [...globalFilters],
  'explorer#awsLambda': [
    ...globalFilters,
    filterList.explorerSubScope,
    filterList.explorerTraceId,
    filterList.explorerTraceSpanId,
    filterList.explorerTraceTime,
    filterList.explorerPage,
    filterList.explorerTracesFingerprints,
    filterList.awsLambdaEvents,
    filterList.awsLambdaCustomTags,
    filterList.awsLambdaDuration,
    filterList.awsLambdaColdStart,
    filterList.awsLambdaRuntime,
    filterList.awsLambdaHttpTags,
  ],
  'devMode#': [...globalFilters],
  'settings/integrations#': [
    filterList.globalEnvironments,
    filterList.globalNamespace,
    filterList.globalRegions,
    filterList.instrumentationMode,
    filterList.integrationType,
    filterList.integrationId,
    filterList.editIntegration,
    filterList.page,
    filterList.integrationSearch,
  ],
  'settings/alerts#': [
    // {
    //   ...filterList.awsLambdaEvents,
    // options: eventsOptions.filter(
    //   (option) => !option.hideFilterList && !option.id.includes('WARNING')
    // ),
    // },
    ...globalFilters,
    filterList.awsLambdaCustomTags,
    filterList.awsLambdaColdStart,
    filterList.awsLambdaRuntime,
    filterList.awsLambdaHttpTags,
  ],
}

// Remove the filters we don't want to show in the UI, right before render
export const HiddenFilters = [
  'globalScope',
  'globalTimeFrame',
  'explorerSubScope',
  'explorerTraceId',
  'explorerTraceSpanId',
  'explorerTraceTime',
  'explorerPage',
  'integrationType',
  'integrationId',
  'editIntegration',
  'page',
  'integrationSearch',
  'explorerTracesFingerprints',
]

/**
 * Set types for URL Query String Parameters
 * Make sure to leave out defaults if they are not specified in Filters.
 * Some Filters in particular (e.g, durations) are known to cause slower query performance.
 * Leaving them out improves the UX.
 */

export const queryStringTypes = {}
Object.keys(filterList).forEach((key) => {
  if (filterList[key].type === 'boolean') {
    queryStringTypes[key] = BooleanParam
  }
  if (filterList[key].type === 'string' || filterList[key].type === 'timeframe') {
    queryStringTypes[key] = StringParam
  }
  if (filterList[key].type === 'array' || filterList[key].type === 'autocomplete') {
    queryStringTypes[key] = ArrayParam
  }
  if (filterList[key].type === 'number') {
    queryStringTypes[key] = NumberParam
  }

  if (filterList[key].type === 'keyValue') {
    queryStringTypes[key] = JsonParam
  }
})
