/**
 * We need to translate backend errors into something more user friendly that way customers are not confused by
 * something like "Cannot reference more than 5 layers"
 *
 * When we discover a new error that has a possible resolution we should add it here
 *
 * @param {string} message The message from the aws integration service
 */
export const convertMessage = (message) => {
  if (/Cannot reference more than 5 layers/.test(message)) {
    return 'Too many layers. Please remove at least one layer from this function and try again.'
  }
  return message
}
