import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { Badge, styled, Switch, Typography } from '@mui/material'
import { Button } from 'common/components/Button'
import { motion } from 'framer-motion'
import cloneDeep from 'lodash.clonedeep'
import { FilterContext, getFiltersCount } from 'filter/context/FilterContext'
import { HiddenFilters } from 'filter/util/filters'
import { isArray, isEmpty, isString } from 'lodash'
import { FilterSearchDropdown } from 'common/components/FilterSearchDropdown'
import FilterDurationWidget from './FilterDurationWidget'
import { CustomTagsFilter } from './CustomTagsFilter'

import { defaultDurations } from '../util/duration'
import { FilterTagSearchDropdown } from 'common/components/FilterTagSearchDropdown'
import { DropdownWrapper } from 'common/components/DropdownWrapper'
import { useDeepCompareEffect } from 'react-use'

export const slugifyString = ({ str = '', lowerCase = true }) => {
  let newString = str
    .replace(/[^a-zA-Z0-9_]+/g, '-') // Replace any run of disallowed chars with a hyphen
    .replace(/^-+/, '') // remove leading hyphens
  if (lowerCase) {
    newString = newString.toLowerCase()
  }
  return newString
}
/**
 * Filters actions section to save and clear values.
 */
const Actions = ({
  handleOnCancel,
  filtersCount,
  isSaving,
  saveFilters,
  clearFilters,
  isFooter,
  hasValues,
}) => {
  const isVisible = filtersCount || hasValues
  return (
    <ActionsContainer is-footer={isFooter} is-visible={isVisible}>
      {!isFooter && <ActionsTitle variant="h2">Filters</ActionsTitle>}
      {isVisible ? (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation()
            clearFilters()
          }}
        >
          Clear Filters
        </Button>
      ) : (
        <Button
          size="small"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation()
            handleOnCancel()
          }}
        >
          Cancel
        </Button>
      )}
      <Badge
        badgeContent={filtersCount}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ breakAfter: 'always' }}
      >
        <Button
          disabled={!filtersCount}
          size="small"
          color="primary"
          loading={isSaving}
          onClick={saveFilters}
          sx={{ width: '100%' }}
        >
          Save
        </Button>
      </Badge>
      {!isFooter && <ActionsDivider />}
    </ActionsContainer>
  )
}

/**
 * Filters Widget
 *
 * This Component is designed as a reusable Filters UI widget which can be embedded in any page, corresponding to any Scope experience.
 * This is tightly coupled with the Filter Context and the data/methods it exports.
 *
 * @param inDropdown If you pass in false this will render a view that is meant to be persistent on the page. So you will see an auto save when you change filters and some styling updates.
 * @param onSave Pass in a function that receives the new Filters and Values when a user clicks "Save" so you can process the save behavior in a custom way.  This defaults to updating the Query URL only.  The default experience does not persist Filter Values anywhere else.
 * @param onCancel Pass in a function that receives the new Filters and Values when a user clicks "Cancel" so you can process the cancel behavior in a custom way.  By default, nothing happens.
 */

export const FiltersWidget = ({
  onSave = null,
  onCancel = () => {},
  onDone,
  autoSave = false,
  showFooter = false,
  showHeader = false,
  allowMulti = true,
  filterListContainerStyles = {},
  initialValues = [],
}) => {
  const { filters, setAllFilterValues } = useContext(FilterContext)
  const uiFilters = useMemo(
    () =>
      (initialValues?.length ? initialValues : filters)?.filter(
        (f) => !HiddenFilters.includes(f.filter)
      ),
    [filters, initialValues]
  )

  const [newFilters, setNewFilters] = useState(cloneDeep(uiFilters))
  const [isSaving, setIsSaving] = useState(false)
  const [expandedFilter, setExpandedFilter] = useState(null)

  const toggleExpandedFilter = (filterName) =>
    setExpandedFilter((prev) => (prev === filterName ? null : filterName))

  // Wrap the onSave function with saving state
  const saveFilters = async (e) => {
    if (e.stopPropagation) {
      e.stopPropagation()
    }
    setIsSaving(true)
    if (onSave) {
      await onSave(newFilters)
    } else {
      setAllFilterValues(newFilters)
    }
    if (onDone) {
      onDone()
    }
    setIsSaving(false)
    if (autoSave && !allowMulti) {
      setExpandedFilter(null)
    }
  }
  const onFilterChange = (filter, option) => {
    const newValue = option?.value
    /**
     * Todo: Simplify this logic to add/remove values
     */
    setNewFilters((prev) =>
      prev.map((f) => {
        if (f.name !== filter.name) return f
        const prevValue = f.value
        let value
        if (!allowMulti) {
          value = newValue && !prevValue?.includes(newValue) ? [newValue] : null
        } else {
          const addedValue = isArray(prevValue)
            ? [...prevValue, newValue]
            : isString(prevValue)
            ? [prevValue, newValue]
            : [newValue]
          value = prevValue?.includes(newValue)
            ? addedValue.filter((i) => i !== newValue)
            : addedValue
        }

        return { ...f, value }
      })
    )
  }

  useEffect(async () => {
    const sameFilters = JSON.stringify(uiFilters) === JSON.stringify(newFilters)
    if (autoSave && !sameFilters) {
      await saveFilters(newFilters)
    }
  }, [newFilters, autoSave])

  useDeepCompareEffect(() => {
    if (!onSave) {
      setNewFilters(cloneDeep(uiFilters))
    }
  }, [uiFilters])

  /**
   * Set the boolean value for the boolean filter
   *
   * @param {string} filter the name of the filter in the filter array
   * @param {*} value the new value for the filter
   */
  const setBooleanFilter = (filter, value) => {
    const tempFilters = [...newFilters]
    tempFilters.forEach((f) => {
      if (f.filter === filter?.filter) {
        f.value = value
      }
    })
    setNewFilters(tempFilters)
  }

  const updateMinMaxValues = (filter, value) => {
    setNewFilters((prev) =>
      prev.map((f) => {
        if (f.name !== filter.name) return f

        // let updatedValue
        const isDefaultValue =
          value?.min === defaultDurations.min && value?.max === defaultDurations.max

        if (isDefaultValue || !value || value === null) {
          delete f.value
        } else {
          f.value = {
            min: !isNaN(value.min) ? value.min : 0,
            max: !isNaN(value.max) ? value.max : 0,
          }
        }

        // Remove filter value if new new value is selected
        return f
      })
    )
  }
  const setKeyValue = (filter, tags) => {
    setNewFilters((prev) =>
      prev.map((f) => {
        if (f.name !== filter.name) return f
        return {
          ...f,
          value: tags?.length
            ? tags.reduce((acc, tag) => ({ ...acc, [tag.key]: tag.value }), {})
            : undefined,
        }
      })
    )
  }
  /**
   * Clear current selected filters (not saved yet)
   */
  const clearFilters = () => {
    const defaultFilters = uiFilters.map(({ value, ...rest }) => ({ ...rest }))
    setNewFilters(defaultFilters)
    setExpandedFilter(null)
  }
  const handleOnCancel = () => onCancel([...newFilters])
  /**
   * Count current selected filters that are NOT saved yet.
   */

  const filtersCount = useMemo(
    () => getFiltersCount(newFilters, uiFilters),
    [newFilters, uiFilters]
  )
  const hasValues = useMemo(
    () => newFilters?.some((f) => f.value || !isEmpty(f.value)),
    [newFilters]
  )

  const getActionsProps = useCallback(
    () => ({
      handleOnCancel,
      filtersCount,
      isSaving,
      saveFilters,
      clearFilters,
      hasValues,
    }),
    [uiFilters, isSaving, newFilters, filtersCount]
  )

  /**
   * Render
   */

  return (
    <>
      <FilterWidgetWrapper
        sx={{ paddingBottom: showFooter ? '50px' : 'auto' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1, duration: 0.2, ease: 'easeOut' } }}
      >
        {showHeader && <Actions {...getActionsProps?.()} />}

        <FilterListContainer sx={filterListContainerStyles}>
          <FilterList>
            {newFilters?.map((filter) => {
              if (filter.type === 'boolean') {
                return (
                  <DropdownWrapper
                    key={`metrics-filters-${filter.name}`}
                    title={filter?.label}
                    collapsable={false}
                    onToggle={() => setBooleanFilter(filter, !filter.value)}
                    dropdownIcon={
                      <Switch
                        checked={Boolean(filter?.value)}
                        onChange={(event) => {
                          event?.stopPropagation()
                          setBooleanFilter(filter, event.target.checked)
                        }}
                        name={filter?.name}
                        inputProps={{ 'aria-label': `${filter?.name} checkbox` }}
                      />
                    }
                  />
                )
              } else if (filter.type === 'keyValue' && filter.name === 'duration') {
                return (
                  <FilterDurationWidget
                    key={`metrics-filters-${filter.name}`}
                    filter={filter}
                    onChange={(...e) => updateMinMaxValues(...e)}
                    expandedFilter={expandedFilter}
                    toggleExpandedFilter={toggleExpandedFilter}
                  />
                )
              } else if (filter.type === 'keyValue' && filter.name === 'customTags') {
                return (
                  <CustomTagsFilter
                    key={`metrics-filters-${filter.name}`}
                    filter={filter}
                    onChange={setKeyValue}
                    expandedFilter={expandedFilter}
                    toggleExpandedFilter={toggleExpandedFilter}
                  />
                )
              } else if (filter.type === 'keyValue') {
                return (
                  <FilterTagSearchDropdown
                    key={`metrics-filters-${filter.name}`}
                    filter={filter}
                    onChange={setKeyValue}
                    expandedFilter={expandedFilter}
                    toggleExpandedFilter={toggleExpandedFilter}
                  />
                )
              } else {
                return (
                  <FilterSearchDropdown
                    key={`metrics-filters-${filter.name}`}
                    filter={filter}
                    onChange={(option) => {
                      onFilterChange(filter, option)
                    }}
                    allowMulti={allowMulti}
                    expandedFilter={expandedFilter}
                    toggleExpandedFilter={toggleExpandedFilter}
                  />
                )
              }
            })}
          </FilterList>
        </FilterListContainer>
      </FilterWidgetWrapper>

      {showFooter && <Actions isFooter {...getActionsProps?.()} />}
    </>
  )
}

/**
 * Styles
 */

const FilterWidgetWrapper = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  position: 'relative',
}))

const ActionsContainer = styled('div')(({ theme, ...props }) => {
  const styles = props['is-footer']
    ? {
        position: 'absolute',
        bottom: props['is-visible'] ? 0 : '-100%',
        width: '100%',
        left: 0,
        marginTop: 'auto',
        borderTop: `1px solid ${theme.palette.border.main}`,
        height: 53,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '33px',
        padding: '9px',
        transition: 'all 0.3s ease-in-out',
      }
    : {
        position: 'sticky',
        top: 0,
        padding: '30px 30px 0px 30px',
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        marginBottom: '10px',
      }
  return {
    zIndex: 2,
    gap: '20px',
    backgroundColor: theme.palette.secondary.main,
    ...styles,
  }
})

const ActionsTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  userSelect: 'none',
}))

const ActionsDivider = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '2px',
  background: theme.palette.grey.medium,
  gridColumn: '1/4',
}))

const FilterListContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  overflow: 'hidden',
}))

const FilterList = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  margin: '0',
  boxSizing: 'border-box',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
