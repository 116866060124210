/**
 * Tracking
 */

export class Event {
  constructor(data = {}) {
    this.event = {}
    this.event.name = data.name || null
    // Remove name from payload (if it exists) and add data
    if (data.name) {
      delete data.name
    }
    this.event.data = Object.assign({}, data)
  }

  set(key, val) {
    if (key === 'name') {
      this.event.name = val
      return this.event
    }
    this.event.data[key] = val
    return this.event
  }

  publish() {
    if (!this.event.name) {
      throw new Error(`An event tracking name param is required`)
    }

    // gtag.js is attached to window in index.html: widown.gtag
    if (window.gtag) {
      window.gtag('event', this.event.name, this.event.data)
    }
  }
}

/**
 * Takes available tracking params, parses them, and returns
 * a ref object with type and value for acquisition tracking.
 */
export const parseRef = (trackingParams) => {
  const ref = {}

  // Formats ref values
  // Accepts the reg object and returns a formatted value
  const formatValue = (ref) => {
    let { type, value } = ref

    // Make sure our value is a string
    value = Array.isArray(value) ? value[0] : value

    // If this is a website, remove the protocol and www.
    // Also remove trailing slashes
    if (value && type === 'website')
      value = value.replace(/^(https?:\/\/)(www.)?/, '').replace(/\/$/, '')

    // If this is social or email, clean up the string
    if (type === 'social' || type === 'email') {
      value = value
        .toLowerCase()
        .replace(/[+[\]()*&^%$#@!~`=|\\/;:'",<>]/g, '')
        .replace(/-/g, '_')
        .replace(/ /g, '_')
    }

    return value
  }

  // If the param has an array value, set the first result as the new value
  Object.keys(trackingParams).forEach((param) => {
    const value = trackingParams[param]
    trackingParams[param] = Array.isArray(value) ? value[0] : value
  })

  // Pull out the params
  const { client, clientOriginCommand, ref_cli, ref_website, ref_social, ref_email } =
    trackingParams

  /**
   * Determine what data we have to work with
   */

  // Check for various versions of CLI logins
  if (client && client === 'cli:serverless') {
    ref.type = 'cli'
    if (clientOriginCommand) ref.value = clientOriginCommand
  }

  if (client && client === 'cli') {
    ref.type = 'cli'
    if (clientOriginCommand) ref.value = clientOriginCommand
  }

  if (ref_cli) {
    ref.type = 'cli'
    ref.value = ref_cli
  }

  if (ref_website) {
    ref.type = 'website'
    ref.value = ref_website
  }

  if (ref_social) {
    ref.type = 'social'
    ref.value = ref_social
  }

  if (ref_email) {
    ref.type = 'email'
    ref.value = ref_email
  }

  // If we have a referrer, use it
  if (!ref.type && document.referrer !== '') {
    ref.type = 'website'
    ref.value = document.referrer
  }

  if (client && client === 'web') ref.type = 'website'
  if (client && client === 'browser') ref.type = 'website'

  // Return the ref object with a formatted value
  return {
    type: ref.type || 'website',
    value: formatValue(ref),
  }
}
