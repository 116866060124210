import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'
import config from 'config'
import { forwardRef } from 'react'

const { WebElementClickedV1Event } = WebEvents

export const Link = forwardRef(
  ({ children, to, onClick, publishEventProps = {}, ...rest }, outerRef) => {
    const isExternal = to?.includes('https')
    const link = isExternal ? { href: to } : { to }
    const sendClickEvent = async () => {
      try {
        const localData = window.localStorage.getItem(config.localStorageKey)
        const storageData = JSON.parse(localData)
        if (
          storageData?.idToken?.trim() === '' ||
          storageData?.user?.userId?.trim() === '' ||
          storageData?.activeOrg?.orgId?.trim() === ''
        ) {
          return
        }
        const token = storageData?.idToken
        const userId = storageData?.user?.userId
        const orgUid = storageData?.activeOrg?.orgId
        const slsCoreEventsClient = new SlsCoreEventsClient(config.platform.eventsUrl, token)
        const clickEvent = new WebElementClickedV1Event({
          orgUid,
          userId,
          elementId: publishEventProps.elementId,
          action: publishEventProps.action,
          actionDetails: publishEventProps.actionDetails,
        })
        await slsCoreEventsClient.publish(clickEvent)
      } catch (error) {
        console.error(error)
      }
    }
    const click = async (e) => {
      e?.stopPropagation()
      if (onClick || !to) {
        e.preventDefault()
      }
      if (onClick) {
        onClick(e)
      }
      if (publishEventProps?.elementId && publishEventProps?.action) {
        await sendClickEvent()
      }
    }
    return (
      <MuiLink
        ref={outerRef}
        component={isExternal ? undefined : RouterLink}
        {...link}
        onClick={click}
        {...(rest.target ? { rel: 'noreferrer' } : {})}
        {...rest}
      >
        {children}
      </MuiLink>
    )
  }
)
