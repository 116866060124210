import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  LinearProgress,
  Tooltip,
  DialogActions,
} from '@mui/material'
import { styled } from '@mui/styles'
import { useDebounce } from 'react-use'
import { isNil, keys, range, groupBy as lodashGroupBy, isEmpty } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { X, SortAsc, SortDesc } from 'lucide-react'
import Button from 'common/components/Button'
import { useSearch } from '../../hooks/useSearch'
import { IconDevMode } from '../../icons/IconDevMode'
import { IconExplorer } from '../../icons/IconExplorer'
import { Search } from 'lucide-react'
import classes from './GlobalSearchDialog.module.css'
import clsx from 'clsx'
import { AppContext } from 'app/context/AppContext'
import { Link } from '../Link'
import { EmptyState } from '../EmptyState'
import ErrorState from '../ErrorState'
import { motion } from 'framer-motion'
import { useGlobalSearchDialog } from './useGlobalSearchDialog'
import { Checkbox } from '../Checkbox'
import { CountTag } from '../CountTag'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { getOS } from 'util/platform'
import { Chip } from '../Chip'
import { Settings } from 'lucide-react'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import config from 'config'
import { GroupByMenu } from './GroupByMenu'
import { groupByMap } from './util/groupByMap'
import { IconMetrics } from 'common/icons/IconMetrics'
import { getLink } from './util/links'
import { SkeletonLoading } from './SkeletonLoading'
import { DataRow } from './DataRow'
import { CustomPopper } from './CustomPopper'

export const DEFAULT_PAGE_SIZE = 1000

const initialGroupBy = withDefault(StringParam, keys(groupByMap)?.[0])

/**
 * Global Search Dialog wrapper to handle open state and only render content when dialog is opened.
 */
export const GlobalSearchDialog = () => {
  const { isOpen, setIsOpen } = useGlobalSearchDialog()
  // Process short cut keys
  const shortCutCommands = useCallback((e) => {
    const cmdSelected = e.ctrlKey || e.metaKey
    if (cmdSelected && e.key === 'k') {
      e.preventDefault()
      setIsOpen(true)
    }
  }, [])

  // Register short cut keys with the keydown event
  useEffect(() => {
    window.addEventListener('keydown', shortCutCommands)
    return () => {
      window.removeEventListener('keydown', shortCutCommands)
    }
  }, [shortCutCommands])

  if (!isOpen) return null
  return <GlobalSearchDialogContent isOpen={isOpen} setIsOpen={setIsOpen} />
}

/**
 * Global Search Dialog content.
 */
const GlobalSearchDialogContent = ({ isOpen, setIsOpen }) => {
  const { activeOrg } = useContext(AppContext)
  const [groupBy, setGroupBy] = useQueryParam('globalSearchGroupBy', initialGroupBy)
  const [search, setSearch] = useQueryParam('globalSearchField', StringParam)
  const [inputSearch, setInputSearch] = useState(search)
  const [currentPage] = useState(1)
  const [order, setOrder] = useState('asc')
  const [checkedItems, setCheckedItems] = useState([])
  const [data, setData] = useState([])
  const [tooltipAnchor, setTooltipAnchor] = useState(null)
  /**
   * Check if current items are resources only view (instead of tags + resources)
   * This is mainly used to handle how we query date.
   */
  const listResourcesOnly = !!search || !groupBy.includes('tag_')
  /**
   * Handle item checkbox click
   */
  const handleCheckItem = useCallback((item) => {
    const name = item?.[groupByMap[item.type]?.field]
    setCheckedItems((prev) =>
      prev.some((i) => i.name === name && i.type === item.type)
        ? prev.filter((i) => i.name !== name)
        : [...prev, { name: name, type: item.type }]
    )
  }, [])
  /**
   * Debounce and update search value
   */
  useDebounce(
    () => {
      setSearch(inputSearch)
    },
    inputSearch ? 700 : 0, // Only use debounce when typing not when clearing
    [inputSearch]
  )

  /**
   * Set query and sort values based on current group by tag and if search input has a value
   */

  let queryObj = {
    query: {},
    sort: {},
  }
  if (listResourcesOnly) {
    queryObj = {
      query: {
        bool: {
          must: [
            {
              match: {
                type: 'resource_aws_lambda',
              },
            },
            {
              multi_match: {
                query: search,
                fields: [
                  'aws_lambda_name',
                  'tag_namespace',
                  'tag_environment',
                  'tag_region',
                  'tag_account_id',
                ],
                type: 'phrase_prefix',
              },
            },
          ],
        },
      },
      sort: [
        {
          [`${groupBy}.keyword`]: {
            order,
          },
        },
        {
          [`aws_lambda_name.keyword`]: {
            order: 'asc',
          },
        },
      ],
    }
  } else {
    queryObj = {
      query: {
        bool: {
          must: [
            {
              terms: {
                type: [groupBy, 'resource_aws_lambda'],
              },
            },
          ],
          must_not: [
            {
              wildcard: {
                [`${groupBy}`]: '*',
              },
            },
          ],
        },
      },
      sort: [
        {
          [`type.keyword`]: {
            order: 'desc',
          },
        },
        {
          [`id.keyword`]: {
            order,
          },
        },
      ],
    }
  }

  /**
   * List inventories with query and sort
   */
  const {
    data: fetchedData,
    error,
    loading,
    isValidating,
    refreshItems,
  } = useSearch({
    ready: true, // Only fetch when we have integrationId
    size: DEFAULT_PAGE_SIZE,
    currentPage,
    ...queryObj,
  })
  const total = useMemo(() => fetchedData?.total, [fetchedData?.total])

  const { data: integrations } = useSWR(['integrations', activeOrg?.orgId], () =>
    coreApiClient({
      baseURL: config.platform.integrationsBase,
      url: `/integrations/?orgId=${activeOrg?.orgId}`,
    })
  )
  const integrationsAliases = useMemo(() => {
    return integrations?.integrations.reduce(
      (acc, item) => ({
        ...acc,
        [item.vendorAccount]: item.alias,
      }),
      {}
    )
  }, [integrations])

  useEffect(() => {
    let arrangedItems = []
    if (listResourcesOnly) {
      const groupedHits = lodashGroupBy(fetchedData?.hits, groupBy)

      arrangedItems = keys(groupedHits)?.reduce((acc, key) => {
        if (key) {
          return [
            ...acc,
            {
              type: groupBy,
              id: key,
              [`${groupByMap[groupBy]?.field}`]: key,
              children: groupedHits[key],
            },
          ]
        } else {
          return [...acc, ...groupedHits[key]]
        }
      }, [])
    } else {
      arrangedItems = fetchedData?.hits
    }

    setData(arrangedItems || [])
  }, [fetchedData?.hits])

  /**
   * On element hover check if text is overflown or not and set ref accordingly
   * This is to be used in showing the popper and the background hover color.
   */
  const onMouseEnter = (e, label) => {
    try {
      setTooltipAnchor({
        anchorEl: e?.target,
        label,
      })
    } catch (err) {}
  }
  const onMouseLeave = () => {
    setTooltipAnchor(null)
  }

  const items = range(data?.length || 250)

  const closeDialog = () => {
    setSearch(undefined)
    setGroupBy(undefined)
    setIsOpen(undefined)
  }
  const pageCount = useMemo(
    () => Math.ceil(total / DEFAULT_PAGE_SIZE) || 1,
    [total, DEFAULT_PAGE_SIZE]
  )

  /** Todo: simplify this logic */
  const currentItemsCount = total > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : total
  const from = (currentPage === 1 && total ? 1 : (currentPage - 1) * DEFAULT_PAGE_SIZE) || 0
  const to =
    currentPage === 1
      ? currentItemsCount
      : currentPage === pageCount
      ? total
      : from + DEFAULT_PAGE_SIZE || 0

  return (
    <StyledDialog
      open={!!isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: { padding: 0, height: 'calc(100% - 64px)' },
        initial: { opacity: 0, scale: 0.95 },
        animate: { opacity: 1, scale: 1, transition: { ease: 'easeInOut' } },
      }}
      fullWidth
      maxWidth="lg"
      PaperComponent={motion.div}
    >
      <CustomPopper tooltipAnchor={tooltipAnchor} />
      <Header>
        <TextField
          value={inputSearch || ''}
          variant="standard"
          onChange={(e) => {
            setInputSearch(e.target.value)
          }}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
            input: {
              minHeight: '4rem',
              marginLeft: '10px',
            },
            '& .MuiInputAdornment-positionStart': {
              '& svg': {
                stroke: (theme) => theme.palette.primary.main,
              },
            },
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              paddingLeft: 25,
              paddingRight: 28,
            },
            autoFocus: true,
            placeholder: 'Search for anything...',
            startAdornment: (
              <InputAdornment position="start">
                <Search size={16} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {listResourcesOnly && (
                  <Box minWidth="120px" textAlign="center">
                    {!loading && !isNil(from) && !isNil(to) && (
                      <Typography variant="textSecondary" color="text.secondary">
                        Showing {`${from?.toLocaleString('en-US')}-${to?.toLocaleString('en-US')}`}
                      </Typography>
                    )}
                  </Box>
                )}
                {search ? (
                  <IconButton onClick={() => setInputSearch(undefined)}>
                    <X size={16} />
                  </IconButton>
                ) : (
                  <CloseChip
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={closeDialog}
                    endIcon={<X size={14} />}
                  >
                    esc
                  </CloseChip>
                )}
              </InputAdornment>
            ),
          }}
        />
        <SearchTagsWrapper>
          <Checkbox
            disabled={!data?.length}
            onChange={(e) =>
              setCheckedItems((prev) =>
                e.target.checked
                  ? data?.map((item) => ({
                      name: item?.[groupByMap[item.type]?.field],
                      type: item.type,
                    }))
                  : []
              )
            }
          />

          <GroupByMenu
            groupBy={groupBy}
            onClick={(key) => {
              setGroupBy(key)
            }}
          />
          <StyledButton
            variant="outlined"
            sx={{ color: 'text.secondary', maxWidth: '50px' }}
            onClick={() => setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))}
          >
            {order === 'asc' ? <SortAsc size={14} /> : <SortDesc size={14} />}
          </StyledButton>
          {!isEmpty(checkedItems) && (
            <HeaderActions>
              <StyledButton variant="text" size="small" onClick={() => setCheckedItems([])}>
                Clear
              </StyledButton>
              <CountTag size="small">{keys(checkedItems)?.length}</CountTag>
              <div className={clsx(classes.RowActions)}>
                <Tooltip title="Metrics" enterDelay={800} leaveDelay={0}>
                  <Link
                    to={getLink({
                      type: 'metrics',
                      queryArr: checkedItems,
                      orgName: activeOrg?.orgName,
                    })}
                  >
                    <IconMetrics />
                  </Link>
                </Tooltip>
                <Tooltip title="Dev Mode" enterDelay={800} leaveDelay={0}>
                  <Link
                    to={getLink({
                      type: 'dev-mode',
                      queryArr: checkedItems,
                      orgName: activeOrg?.orgName,
                    })}
                  >
                    <IconDevMode />
                  </Link>
                </Tooltip>
                <Tooltip title="Explorer" enterDelay={800} leaveDelay={0}>
                  <Link
                    to={getLink({
                      type: 'explorer',
                      queryArr: checkedItems,
                      orgName: activeOrg?.orgName,
                    })}
                  >
                    <IconExplorer />
                  </Link>
                </Tooltip>
              </div>
            </HeaderActions>
          )}
        </SearchTagsWrapper>
        {loading || isValidating ? (
          <LinearProgress
            style={{
              height: '3px',
            }}
          />
        ) : (
          <Box height="3px" width="100%" />
        )}
      </Header>

      <StyledDialogContent>
        {loading || (!error && data?.length) ? (
          <div
            style={{
              width: '100%',
              overflowY: 'auto',
              height: '100%',
              minHeight: '500px',
            }}
          >
            {items?.map((index) => {
              if (loading) {
                return <SkeletonLoading key={`metrics-activities-skeleton-loading-${index}`} />
              }
              if (data[index]?.id) {
                return (
                  <DataRow
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    listResourcesOnly={listResourcesOnly}
                    key={`global-search-items-${data[index]?.type}-${data[index]?.id}-${index}`}
                    item={data[index]}
                    groupBy={groupBy}
                    orgName={activeOrg?.orgName}
                    integrationsAliases={integrationsAliases}
                    search={search}
                    handleCheckItem={handleCheckItem}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />
                )
              }
              return null
            })}
          </div>
        ) : error ? (
          <ErrorState
            onReload={refreshItems}
            isColumnDirection
            fullWidth
            sx={{ margin: 'auto', height: '100%' }}
          />
        ) : !loading && !isValidating ? (
          <EmptyState
            subLabel={`Try a different search`}
            label={`No results found for "${search}"`}
            actions={
              <Button size="small" onClick={() => setInputSearch(undefined)}>
                Clear Search
              </Button>
            }
          />
        ) : null}
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <Chip size="small" label={getOS() === 'macos' || 'ios' ? '⌘' : 'ctrl'} />{' '}
        <Chip size="small" label="K" />
        <Typography variant="textSecondary" color="text.secondary">
          Open search anywhere.
        </Typography>
        <Link to={`/${activeOrg?.orgName}/settings/integrations`} sx={{ marginLeft: 'auto' }}>
          <StyledButton
            color="secondary"
            variant="text"
            size="small"
            startIcon={<Settings size={15} />}
          >
            Manage Integrations
          </StyledButton>
        </Link>
      </StyledDialogActions>
    </StyledDialog>
  )
}

const Header = styled('div')(({ theme }) => ({
  zIndex: 99,
  borderBottom: `1px solid ${theme.palette.border.main}`,
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.secondary.main,
}))

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    padding: 0,
  }
})
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
}))

const SearchTagsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  padding: '10px 10px ',
}))
const StyledButton = styled(Button)(({ theme }) => ({
  lineHeight: 1,
  minWidth: 30,
  fontSize: theme.typography.textTertiary.fontSize,
  color: theme.palette.text.secondary,
}))

const CloseChip = styled(Button)(({ theme }) => ({
  padding: '5px 10px',
  fontSize: theme.typography.textTertiary.fontSize,
  color: theme.palette.text.secondary,
  lineHeight: 1,
  minWidth: '60px',
  cursor: 'pointer',
  transition: 'all 0.1s ease-in-out',
  '& svg': {
    transition: 'all 0.1s ease-in-out',
    color: theme.palette.grey.light,
  },
  '&:hover': {
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.main,
    '& svg': {
      stroke: theme.palette.text.primary,
    },
  },
}))

const HeaderActions = styled('div')(({ theme }) => ({
  marginLeft: 'auto',

  // marginRight: '6px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& svg': {
    color: `${theme.palette.primary.main} !important`,
  },
}))
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.border.main}`,
  gap: '10px',
  padding: '10px',
  '& .MuiChip-root': {
    minWidth: '20px',
    backgroundColor: theme.palette.text.dark,
  },
}))
