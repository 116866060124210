import { useEffect, useState } from 'react'
import { styled, Box, Tooltip, Fade } from '@mui/material'
import { useTheme } from '@mui/styles'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { ChevronRight } from 'lucide-react'
// Span Sections
import { SectionDetails } from './SectionDetails'
import { EventSection } from './EventSection'
import { SectionInput } from './SectionInput'
import { SectionOutput } from './SectionOutput'
import { SectionActivityGroup } from './SectionActivityGroup'
import CloseButton from 'common/components/CloseButton'
import { getSpanTimeValue } from 'common/packages/ActivityStream/utils/helper'
import { CustomTagsSection } from './CustomTagsSection'
import { ActivityAffectedResources } from 'widgets/components/ActivityAffectedResources'

export const Inspector = ({
  parentSpanName = null,
  childSpanName = null,
  selectedSpanName = null,
  timestamp = null,
  startTime = null, // If a Span, this Span's "startTime"
  endTime = null, // If a Span, this Span's "endTime"
  tags = {},
  durations = null, // An array of durations
  activityGroup = null, // All activity (logs, spans) pertaining to a specific Trace
  input = null,
  output = null,
  reselectItems,
  customTags,
  zoom,
  onClose,
  defaultExpanded = 'details',
  span,
  cta,
}) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(defaultExpanded)
  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(defaultExpanded)
    }
  }, [defaultExpanded])
  // Validate
  if (durations && !Array.isArray(durations)) {
    durations = [durations]
  }

  // Handle Accordian Open/Close
  const handleAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  // Prepare Title
  const uiData = {}
  if (parentSpanName) {
    uiData.parentSpan = {
      value: parentSpanName,
      description: 'The name of the root Span in this Trace.',
    }
  }
  if (childSpanName && childSpanName !== 'aws.lambda') {
    uiData.childSpan = {
      value: childSpanName,
      description: 'The name of the currently selected child Span in this Trace.',
    }
  }

  return (
    <SpanContainer>
      <SpanHeader>
        <SpanHeaderText>
          {uiData?.parentSpan && (
            <Tooltip
              title={uiData.parentSpan.description}
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <SpanHeaderTextSection>
                <IconAwsLambda style={{ margin: '0 10px 0 0', fontSize: 15 }} />{' '}
                {uiData.parentSpan.value}
              </SpanHeaderTextSection>
            </Tooltip>
          )}
          {uiData.childSpan && (
            <Tooltip
              title={uiData.childSpan.description}
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <SpanHeaderTextSection>
                <ChevronRight
                  size={18}
                  style={{ margin: '1px 5px 0 8px', color: theme.palette.text.secondary }}
                />{' '}
                {uiData.childSpan.value}
              </SpanHeaderTextSection>
            </Tooltip>
          )}
        </SpanHeaderText>
        {onClose && <CloseButton onClick={onClose} aria-label="close inspector" size="medium" />}
      </SpanHeader>
      <SpanHeader sx={{ padding: '0 25px 0 0' }}>
        {((startTime && endTime) || timestamp) && (
          <SpanHeader sx={{ paddingTop: 0, paddingLeft: '20px' }}>
            <SpanHeaderText>
              {getSpanTimeValue({
                timestamp,
                startTime,
                endTime,
              })}
            </SpanHeaderText>
          </SpanHeader>
        )}
        {cta}
      </SpanHeader>

      <SpanViewContainer>
        {span && span?.eventId && (
          <>
            <EventSection
              event={span}
              expanded={expanded === 'event'}
              onChange={handleAccordion('event')}
            />
            {customTags && (
              <CustomTagsSection
                expanded={expanded === 'customTags'}
                onChange={handleAccordion('customTags')}
                customTags={customTags}
              />
            )}
          </>
        )}

        {tags && (
          <SectionDetails
            timestamp={timestamp}
            startTime={startTime}
            endTime={endTime}
            selectedSpanName={selectedSpanName}
            tags={tags}
            durations={durations}
            expanded={expanded === 'details'}
            onChange={handleAccordion('details')}
            zoom={zoom}
          />
        )}
        {!span?.eventId && customTags && (
          <CustomTagsSection
            expanded={expanded === 'customTags'}
            onChange={handleAccordion('customTags')}
            customTags={customTags}
          />
        )}
        {input && (
          <SectionInput
            input={input}
            expanded={expanded === 'input'}
            onChange={handleAccordion('input')}
            zoom={zoom}
          />
        )}
        {output && (
          <SectionOutput
            output={output}
            expanded={expanded === 'output'}
            onChange={handleAccordion('output')}
            zoom={zoom}
          />
        )}
        {activityGroup && (
          <SectionActivityGroup
            activityGroup={activityGroup}
            expanded={expanded === 'activityGroup'}
            onChange={handleAccordion('activityGroup')}
            zoom={zoom}
            reselectItems={reselectItems}
          />
        )}
        {span?.affected_resources && (
          <ActivityAffectedResources
            activity={span}
            expanded={expanded === 'affectedResources'}
            onChange={handleAccordion('affectedResources')}
          />
        )}
      </SpanViewContainer>
    </SpanContainer>
  )
}

const SpanContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'flex-start',
  height: '100%',
  maxHeight: '100%',
  boxSizing: 'border-box',
  width: '100%',
  overflow: 'hidden',
  padding: '0',
  margin: '0',
  fontFamily: "'Roboto Mono', monospace",
  '& *': {
    fontFamily: "'Roboto Mono', monospace",
  },
}))

const SpanHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 'auto',
  flexShrink: 0,
  overflow: 'auto',
  padding: '15px 25px 15px 20px',
})

const SpanHeaderText = styled(Box)({
  display: 'flex',
  height: 'auto',
  width: '100%',
  overflow: 'hidden',
})

const SpanHeaderTextSection = styled(Box)({
  display: 'flex',
  height: 'auto',
  width: 'auto',
  alignItems: 'center',
  fontFamily: "'Roboto Mono', monospace",
  fontSize: '14px',
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  textTransform: 'uppercase',
})

const SpanViewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'flex-start',
  height: '100%',
  boxSizing: 'border-box',
  width: '100%',
  overflow: 'hidden',
  padding: '0',
  margin: '0',
  '& > div:last-of-type': {
    borderBottom: `1px solid ${theme.palette.border.main}!important`,
  },
}))
