import { memo } from 'react'
import { formatLogDate } from '../../utils/helper'
import classes from './LocalMessage.module.css'

export const LocalMessage = memo(function LocalMessage({ activity }) {
  return (
    <div className={classes.localHeader}>
      <div className={classes.localHeaderText}>{formatLogDate(activity.timestamp || 0)}</div>
      <div
        className={`${classes.localHeaderText} ${
          activity?.includeDots ? classes.localHeaderTextAnimated : ''
        }`}
      >
        {activity?.message}
      </div>
    </div>
  )
})
