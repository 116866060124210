import { useContext, useMemo, useState } from 'react'
import { Typography, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FilterContext } from 'filter/context/FilterContext'
import { timeFrameDefaults, globalScopes as scopes } from 'filter/util/filters'
import { TimeFrame } from 'filter/components/TimeFrame'
import { FiltersWidget } from 'filter/components/FiltersWidget'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { motion } from 'framer-motion'
import { getFormattedTimeFrameRange } from 'filter/util/time'
import { DropdownWrapper } from 'common/components/DropdownWrapper'
import { AlertDialogButton } from 'settings/components/alerts/useAlertDialog'

export const ExplorerSidebar = ({ setCurrentPage }) => {
  const { getFilterValue, setFilterValue } = useContext(FilterContext)
  const [expandedTimeFrame, setExpandedTimeFrame] = useState(false)
  const globalTimeFrame = getFilterValue('globalTimeFrame')
  const selectedTimeFrame = useMemo(
    () => timeFrameDefaults.find(({ value }) => value === globalTimeFrame),
    [globalTimeFrame]
  )

  const timeFrameLabel = selectedTimeFrame
    ? selectedTimeFrame.label
    : getFormattedTimeFrameRange(globalTimeFrame)

  const scope = getFilterValue('globalScope')

  /**
   * Prepare Dropdown for Scope
   */

  // Format data to work with Dropdown component
  let formattedScope
  const formattedScopes = []
  scopes.forEach((s) => {
    formattedScopes.push({
      displayValue: s.displayName,
      icon: s.icon,
      value: s.name,
    })
    if (s.name === scope) {
      formattedScope = {
        displayValue: s.displayName,
        icon: s.icon,
        value: s.name,
      }
    }
  })

  return (
    <SidebarWrapper
      initial={{ x: -20, opacity: 0 }}
      animate={{
        x: 0,
        opacity: 1,
        transition: { delay: 0.25, duration: 0.3, ease: 'easeOut' },
      }}
    >
      <SidebarInner>
        <Box
          paddingLeft="5px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          margin="14px 0 20px 0"
        >
          <IconAwsLambda size={15} />
          <Typography variant="h3" sx={{ margin: '0 auto 0 10px' }}>
            {formattedScope?.displayValue}
          </Typography>
          <AlertDialogButton />
        </Box>
        <Divider />
        <DropdownWrapper
          title={<Typography variant="h3">{timeFrameLabel}</Typography>}
          onToggle={() => setExpandedTimeFrame((prev) => !prev)}
          isOpen={expandedTimeFrame}
        >
          <TimeFrame
            timeFrameDefaults={timeFrameDefaults}
            timeFrameKey="globalTimeFrame"
            globalTimeFrame={globalTimeFrame}
            setTimeFrame={({ key, timeFrameCSV }) => {
              setFilterValue(key, timeFrameCSV)
              setCurrentPage(1)
            }}
            closeDropdown={() => setExpandedTimeFrame((prev) => !prev)}
          />
        </DropdownWrapper>

        <Divider />
        <FiltersWidget
          onDone={() => {
            setCurrentPage(1)
          }}
          showFooter
        />
      </SidebarInner>
    </SidebarWrapper>
  )
}

const SidebarWrapper = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  zIndex: 4,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
  minWidth: 280,
  borderRight: `1px solid ${theme.palette.border.main}`,
  boxSizing: 'border-box',
}))
const SidebarInner = styled('div')(() => ({
  overflowY: 'scroll',
  height: '100%',
  padding: '10px 15px',
}))
