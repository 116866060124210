import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import Button from 'common/components/Button'
import { motion } from 'framer-motion'
import { AppContext } from 'app/context/AppContext'
import { useSnackbar } from 'notistack'
import { IconBox } from '../../IntegrationRow'
import { InfoBox } from '../../InfoBox'
import { ArrowRight } from 'lucide-react'
import { IconServerlessConsole } from 'common/icons/IconServerlessConsole'
import { IconAws } from 'common/icons/IconAws'
import { coreApiClient } from 'util/coreApiClient'
import config from 'config'

export const DialogIntro = ({
  title = 'Connect an AWS Account',
  subTitle = 'Follow this flow to connect a new AWS account to your org.',
  submitText = 'Connect',
  handleNext,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { activeOrg } = useContext(AppContext)
  const orgId = activeOrg?.orgId
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    try {
      const res = await coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/aws/initial?orgId=${orgId}`,
      })
      const integrationId = res?.externalId?.split('/')?.[1]

      // Move to next step once the integration is complete
      handleNext({
        params: {
          integrationId,
          redirectUrl: res?.redirectUrl,
        },
      })
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Failed to initiate AWS integration. Please try again.', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      maxWidth="600px"
      width="100%"
      padding="10px"
      margin="-2.5% auto 0 auto"
      minHeight="calc(var(--app-height) - 118px)"
      gap="25px"
    >
      <motion.div
        initial={{ scale: 1.75, opacity: [0, 1] }}
        animate={{
          scale: [1.75, 1],
          opacity: 1,
          transition: { duration: 0.75, ease: 'easeOut' },
        }}
      >
        <Typography variant="h1">{title}</Typography>
      </motion.div>

      {subTitle && (
        <motion.div
          style={{ margin: '0 0 10px 0' }}
          initial={{ scale: 1.15, opacity: [0, 1] }}
          animate={{
            scale: [1.15, 1],
            opacity: 1,
            transition: { delay: 0.5, duration: 0.75, ease: 'easeOut' },
          }}
        >
          <Typography variant="h3">{subTitle}</Typography>
        </motion.div>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
        <motion.div
          initial={{ scale: 1.15, opacity: 0 }}
          animate={{
            scale: [1.15, 1],
            opacity: [0, 1],
            transition: { delay: 0.75, duration: 0.85, ease: 'easeOut' },
          }}
        >
          <IconBox style={{ height: '55px', width: '55px' }}>
            <IconServerlessConsole size={28} />
          </IconBox>
        </motion.div>

        <motion.div
          initial={{ scale: 1.15, opacity: 0 }}
          animate={{
            scale: [1.15, 1],
            opacity: [0, 1],
            transition: { delay: 1.1, duration: 0.85, ease: 'easeOut' },
          }}
        >
          <ArrowRight size={18} style={{ margin: '0 8px 0 11px' }} />
        </motion.div>

        <motion.div
          initial={{ scale: 1.15, opacity: 0 }}
          animate={{
            scale: [1.15, 1],
            opacity: [0, 1],
            transition: { delay: 1.25, duration: 0.85, ease: 'easeOut' },
          }}
        >
          <IconBox style={{ height: '55px', width: '55px' }}>
            <IconAws size={28} />
          </IconBox>
        </motion.div>
      </Box>

      <motion.div
        style={{
          margin: '20px 0',
        }}
        initial={{ scale: 1.15, opacity: 0 }}
        animate={{
          scale: [1.15, 1],
          opacity: [0, 1],
          transition: { delay: 1.75, duration: 0.85, ease: 'easeOut' },
        }}
      >
        <motion.div
          initial={{ scale: 1 }}
          animate={{
            scale: [1, 1.11, 1],
            transition: { delay: 7, duration: 0.85, ease: 'easeInOut' },
          }}
        >
          <Button size="large" loading={loading} onClick={onClick}>
            {submitText}
          </Button>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 1], transition: { delay: 2.75, duration: 0.75, ease: 'easeOut' } }}
      >
        <InfoBox />
      </motion.div>
    </Box>
  )
}
