import useSWR from 'swr'
import { AppContext } from 'app/context/AppContext'
import { useContext } from 'react'
import { coreApiClient } from 'util/coreApiClient'

export const useSearch = ({
  ready = true,
  type = 'resource_aws_lambda',
  size = 10,
  currentPage = 1,
  sort = [
    {
      updated_at: {
        order: 'desc',
      },
    },
  ],
  query = {
    bool: {
      must: [
        {
          match: {
            type,
          },
        },
      ],
    },
  },
} = {}) => {
  const { activeOrg } = useContext(AppContext)

  const { orgId } = activeOrg || {}
  const url = `/search/orgs/${orgId}/search`

  const fetchItems = async () => {
    return coreApiClient({
      url,
      method: 'post',
      data: {
        from: (currentPage - 1) * size, // 'from' is zero indexed
        size,
        query,
        sort,
      },
    })
  }

  const swrKey = ready ? JSON.stringify({ url, currentPage, ready, query, sort }) : undefined

  const { data, error, mutate, isValidating } = useSWR(swrKey, fetchItems)

  const loading = ready && !data && !error

  return { data, loading, refreshItems: mutate, isValidating, error, swrKey }
}
