import { styled, Typography } from '@mui/material'

const LegendIconCircle = styled('div')(({ error, theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: error ? theme.palette.error.main : theme.palette.text.secondary,
  marginRight: 5,
}))

const LegendDashIcon = styled('div')(({ theme }) => ({
  width: 40,
  borderBottom: `2px dashed ${theme.palette.text.secondary}`,
  marginRight: 5,
}))

const LegendItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '20px',
}))

const LegendItemGrid = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 10,
}))

const LatencyLegend = () => {
  return (
    <LegendItemGrid>
      <LegendItem>
        <LegendIconCircle />
        <Typography variant="textSecondary" color="text.secondary">
          P95
        </Typography>
      </LegendItem>

      <LegendItem>
        <LegendDashIcon />
        <Typography variant="textSecondary" color="text.secondary">
          Average
        </Typography>
      </LegendItem>
    </LegendItemGrid>
  )
}

export default LatencyLegend
