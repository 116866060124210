/**
 * Takes event, or span object and returns these info on event typeName, title, message...etc
 */
export const getEventInfo = (event) => {
  const { type, message } =
    event?.tags?.error || event?.error || event?.tags?.warning || event?.warning || event || {}

  switch (type) {
    case 'ERROR_TYPE_UNCAUGHT': {
      return {
        typeName: `Uncaught`,
        typeFullName: `Uncaught Error`,
        message,
        title: `Uncaught Error • ${message}`,
        color: 'error.main',
        type,
        shouldDisplay: true,
        payload: event?.tags?.error || event?.error || {},
      }
    }
    case 'ERROR_TYPE_CAUGHT_USER': {
      const typeFullName = 'Caught Error'
      return {
        typeName: `Caught`,
        typeFullName,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'primary.main',
        type,
        shouldDisplay: true,
        payload: event?.tags?.error || event?.error || {},
      }
    }
    case 'WARNING_TYPE_USER': {
      const typeFullName = 'Warning'
      return {
        typeName: 'Warning',
        typeFullName,
        isWarning: true,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'text.secondary',
        type,
        shouldDisplay: true,
        payload: event?.tags?.warning || event?.warning || {},
      }
    }
    case 'WARNING_TYPE_SDK_INTERNAL': {
      const typeFullName = 'SDK Internal Warning'
      return {
        typeName: 'Warning',
        typeFullName,
        isWarning: true,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'text.secondary',
        type,
        shouldDisplay: false,
        payload: event?.tags?.warning || event?.warning || {},
      }
    }
    case 'WARNING_TYPE_SDK_USER': {
      const typeFullName = 'SDK Warning'
      return {
        typeName: 'Warning',
        typeFullName,
        isWarning: true,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'text.secondary',
        type,
        shouldDisplay: true,
        payload: event?.tags?.warning || event?.warning || {},
      }
    }
    case 'ERROR_TYPE_CAUGHT_SDK_USER': {
      const typeFullName = 'SDK Error'
      return {
        typeName: `Caught`,
        typeFullName,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'primary.main',
        type,
        shouldDisplay: true,
        payload: event?.tags?.error || event?.error || {},
      }
    }
    case 'ERROR_TYPE_CAUGHT_SDK_INTERNAL': {
      const typeFullName = 'SDK Internal Error'
      return {
        typeName: `Caught`,
        typeFullName,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'primary.main',
        type,
        shouldDisplay: true,
        payload: event?.tags?.error || event?.error || {},
      }
    }
    case 'NOTICE_TYPE_SDK_INTERNAL': {
      const typeFullName = 'SDK Notice'
      return {
        typeName: `Caught`,
        typeFullName,
        message,
        title: `${typeFullName} • ${message}`,
        color: 'primary.main',
        type,
        shouldDisplay: false,
        payload: event?.tags?.notice || event?.notice || {},
      }
    }
    default:
      return {}
  }
}
