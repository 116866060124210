import { useContext, useEffect, useState } from 'react'
import { Label, LabelWrapper, SecretContext, SubLabel } from './SecretsDialog'
import { validateSecret } from 'settings/util/validateSecret'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import { EyeOff } from 'lucide-react'
import { Eye } from 'lucide-react'
import { CopyText } from 'common/components/CopyText'
import { ErrorMessage } from 'common/components/ErrorMessage'
import Button from 'common/components/Button'
import { types } from 'settings/util/secretTypes'

export const RenderFields = ({ fields = [] }) => {
  const {
    secret,
    setErrorMessage,
    selectedSecret,
    save,
    errorMessage,
    loadingSave,
    selectedTypeIndex,
  } = useContext(SecretContext)
  const defaultValues = fields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.key]:
        selectedSecret?.parameter?.[field.key] || selectedSecret?.provider?.[field.key] || '',
    }),
    {}
  )
  const [showValue, setShowValue] = useState(!selectedSecret)
  const [values, setValues] = useState(defaultValues)

  useEffect(() => {
    setErrorMessage('')
  }, [values])
  useEffect(() => {
    setValues(defaultValues)
  }, [selectedTypeIndex])

  /**
   * Get data based on current type selected
   */
  const dataToSave = {
    name: secret?.name || '',
    path: secret?.path || '',
  }
  if (types?.[selectedTypeIndex]?.valueType === 'PARAMETER') {
    dataToSave.valueType = 'PARAMETER'
    dataToSave.parameter = values
  } else {
    dataToSave.valueType = 'PROVIDER'
    dataToSave.provider = {
      providerType: 'SECRET',
      ...values,
    }
  }
  /**
   * Validate inputs before saving
   */
  const handleSave = () => {
    try {
      validateSecret(dataToSave)
      save(dataToSave)
    } catch (err) {
      setErrorMessage(err?.message)
    }
  }
  return (
    <>
      {fields?.map((field, idx) => {
        const value = values?.[field.key] || ''
        return (
          <Box key={`secrets-${selectedTypeIndex}-${field.key}`}>
            <LabelWrapper>
              <Label>{field.label}</Label>
              <SubLabel>{field.subLabel}</SubLabel>
            </LabelWrapper>
            <TextField
              value={value}
              onChange={(e) => setValues((prev) => ({ ...prev, [field.key]: e.target.value }))}
              type={field?.isSensitive && !showValue ? 'password' : 'text'}
              name="password"
              autoComplete="off"
              placeholder={field.placeholder}
              onKeyUp={(event) => {
                /**
                 * If last input and 'Enter' key was pressed
                 */
                if (idx === fields?.length - 1 && event.key === 'Enter') {
                  handleSave()
                }
              }}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {selectedSecret && field?.isSensitive && (
                      <IconButton onClick={() => setShowValue((prev) => !prev)}>
                        {showValue ? <EyeOff size={16} /> : <Eye size={16} />}
                      </IconButton>
                    )}
                    {value && <CopyText value={value} iconSize={15} />}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )
      })}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
          marginTop: 'auto',
        }}
      >
        <Box sx={{ minWidth: '100%' }}>
          <ErrorMessage message={errorMessage} alertProps={{ sx: { marginTop: '0 !important' } }} />
        </Box>

        <Button
          color="primary"
          loading={loadingSave}
          onClick={handleSave}
          sx={{ minWidth: '100%', marginLeft: 'auto' }}
        >
          Save
        </Button>
      </Box>
    </>
  )
}
