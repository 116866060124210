/**
 * Component: Animator
 */

import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

class Animator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      lottie: null,
      scene: this.props.scene,
      scenes: this.props.scenes,
    }

    this.player = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scene !== this.props.scene) {
      this.setState({ scene: this.props.scene }, this.playScene)
    }
  }

  playScene() {
    if (!this.state.scene || !this.state.scenes) return

    let that = this
    let player = this.state.lottie

    if (!player) return

    player.removeEventListener('complete')

    this.state.lottie.addEventListener('complete', () => {
      if (that.state.scenes[that.state.scene].loop) {
        that.playScene.call(that)
        return
      }
    })

    this.state.lottie.playSegments(this.state.scenes[this.state.scene].frames, true)
  }

  render() {
    return (
      <Player
        lottieRef={(instance) => {
          this.setState({ lottie: instance })
        }}
        onEvent={(event) => {
          if (event === 'load') {
            this.playScene()
          }
        }}
        ref={this.player}
        autoplay={false}
        loop={false}
        src={this.props.data}
        style={{ height: this.props.height, width: this.props.width }}
      />
    )
  }
}

export default Animator
