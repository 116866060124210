import { SvgIcon } from '@mui/material'

const IconServerlessFramework = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <path d="M7.08815 23L19.7402 12.0404L18.8131 11.5909L7.98642 20.9593V18.96L18.8131 9.57681V11.5909L19.7402 12.0404V8.14734L17.2187 8.48154L19.4793 4.85562V1.43476L18.9781 1L5.74023 11.928V14.6026L9.98716 13.9155L7.08712 18.615V23H7.08815ZM18.5532 3.18068V4.58944L16.1265 8.62547L14.891 8.78912L18.5532 3.18068ZM6.63025 13.533V12.0887L11.7981 11.4134L10.7132 12.9129L6.63025 13.533Z" />
    </SvgIcon>
  )
}

export default IconServerlessFramework
