import { Box } from '@mui/system'
import { AnimatePresence, motion } from 'framer-motion'
import { useTheme } from '@mui/styles'
import { useOnboardingTopBanner } from '../hooks/useOnboardingTopBanner'
import { useUsageTopBanner } from '../hooks/useUsageTopBanner'

const NOTIFICATION_BAR_ID = 'notification-bar'

export const useNotificationTopBannerIsVisible = () => {
  const notificationBar = document.getElementById(NOTIFICATION_BAR_ID)
  const notificationBarHeight = notificationBar?.offsetHeight || 0

  return {
    isVisible: !!notificationBar,
    notificationBarHeight,
  }
}
/**
 * This handles the logic to determine which notification bar to show for each case.
 */
export const NotificationTopBanner = () => {
  const {
    message: onBoardingBannerMessage,
    onClick: onBoardingBannerOnClick,
    isVisible: isOnBoardingBannerVisible,
  } = useOnboardingTopBanner()
  const {
    message: usageWarningBannerMessage,
    onClick: usageBannerOnClick,
    isVisible: isUsageBannerVisible,
    isWarning: usageBannerIsWarning,
  } = useUsageTopBanner()

  if (isOnBoardingBannerVisible) {
    return (
      <NotificationBar
        message={onBoardingBannerMessage}
        onClick={onBoardingBannerOnClick}
        isWarning={false}
      />
    )
  } else if (isUsageBannerVisible) {
    return (
      <NotificationBar
        message={usageWarningBannerMessage}
        onClick={usageBannerOnClick}
        isWarning={usageBannerIsWarning}
      />
    )
  }
  return null
}
/**
 * Notification bar that shows a message and have a CTA. This can be used by any initiative and in contexts.
 * @param {*} message - A message to display in the notification bar. Can be a string or a react component.
 * @param {function} onClick - Notification bar on click event.
 * @param {Boolean} isVisible - True if the message is visible.
 * @param {Boolean} isWarning - True if the message is a warning and the notification bar should be shown in red.
 */
export const NotificationBar = ({ message, onClick = () => {}, isWarning, isVisible = true }) => {
  const theme = useTheme()
  const backgroundColor = isWarning ? theme.palette.error.main : theme.palette.primary.main
  const color = isWarning ? theme.palette.colors.white : theme.palette.secondary.main

  return (
    <AnimatePresence>
      {isVisible && (
        <Box bgcolor={backgroundColor} zIndex={10} id={NOTIFICATION_BAR_ID}>
          <Box
            bgcolor={backgroundColor}
            component={motion.div}
            initial={{ height: 0, overflow: 'hidden' }}
            exit={{ height: 0, overflow: 'hidden' }}
            animate={{
              height: 'auto',
              overflow: 'visible',
              transition: {
                delay: 3,
                ease: 'easeOut',
              },
            }}
          >
            <Box
              component={motion.div}
              width="100%"
              textAlign="center"
              color={color}
              p={1}
              sx={{
                cursor: 'pointer',
              }}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 3.1,
                  ease: 'easeOut',
                },
              }}
              onClick={onClick}
            >
              {message}
            </Box>
          </Box>
        </Box>
      )}
    </AnimatePresence>
  )
}
