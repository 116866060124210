import { useContext, useEffect } from 'react'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { AppContext } from 'app/context/AppContext'
import { FilterContext } from 'filter/context/FilterContext'
import { useState } from 'react'
import { get, includes, keys, merge, set, union } from 'lodash'
import { useDeepCompareEffect } from 'react-use'

export const updateFilters = ({ initialFilters = {}, filters, query }) => {
  const newFilters = { ...initialFilters }

  if (filters?.resourceName) {
    set(newFilters, 'aws.resourceName', filters?.resourceName)
  }
  if (filters?.isColdstart) {
    set(newFilters, 'aws.lambda.isColdstart', filters?.isColdstart)
  }
  if (filters?.duration) {
    set(newFilters, 'aws.lambda.duration', {
      from: filters?.duration.min,
      to: filters?.duration.max,
    })
  }

  if (filters?.region) {
    set(newFilters, 'aws.region', filters?.region)
  }

  if (filters?.accountId) {
    set(newFilters, 'aws.accountId', filters?.accountId)
  }
  if (includes(filters?.events, 'WARNING_TYPE_USER')) {
    set(
      newFilters,
      'warning.type',
      union(
        get(newFilters, 'warning.type', []),
        filters?.events.filter((i) => i === 'WARNING_TYPE_USER')
      )
    )
  }
  if (includes(filters?.events, 'WARNING_TYPE_SDK_INTERNAL')) {
    set(
      newFilters,
      'warning.type',

      union(
        get(newFilters, 'warning.type', []),
        filters?.events.filter((i) => i === 'WARNING_TYPE_SDK_INTERNAL')
      )
    )
  }
  if (includes(filters?.events, 'WARNING_TYPE_SDK_USER')) {
    set(
      newFilters,
      'warning.type',

      union(
        get(newFilters, 'warning.type', []),
        filters?.events.filter((i) => i === 'WARNING_TYPE_SDK_USER')
      )
    )
  }
  if (includes(filters?.events, 'ERROR_TYPE_CAUGHT_USER')) {
    set(
      newFilters,
      'error.type',
      union(
        get(newFilters, 'error.type', []),
        filters?.events.filter((i) => i === 'ERROR_TYPE_CAUGHT_USER')
      )
    )
  }
  if (includes(filters?.events, 'ERROR_TYPE_UNCAUGHT')) {
    set(
      newFilters,
      'error.type',
      union(
        get(newFilters, 'error.type', []),
        filters?.events.filter((i) => i === 'ERROR_TYPE_UNCAUGHT')
      )
    )
  }
  if (includes(filters?.events, 'ERROR_TYPE_CAUGHT_SDK_USER')) {
    set(
      newFilters,
      'error.type',
      union(
        get(newFilters, 'error.type', []),
        filters?.events.filter((i) => i === 'ERROR_TYPE_CAUGHT_SDK_USER')
      )
    )
  }

  if (filters?.environment) {
    set(newFilters, 'environment', filters?.environment)
  }
  if (filters?.namespace) {
    set(newFilters, 'namespace', filters?.namespace)
  }

  if (filters?.fingerprints) {
    set(newFilters, 'fingerprints', filters?.fingerprints)
  }
  if (filters?.custom) {
    /**
     *  Format custom tags to this format
     *  { key1: [value1], key2: [value2]}
     */
    set(
      newFilters,
      'custom',
      keys(filters?.custom).reduce((acc, key) => ({ ...acc, [key]: [filters?.custom?.[key]] }), {})
    )
  }
  if (filters?.runtime) {
    set(newFilters, 'aws.lambda.runtime.identifier', filters?.runtime)
  }
  if (filters?.http) {
    if (filters?.http.status?.length) {
      set(newFilters, 'aws.lambda.http.statusCode', filters?.http.status)
    }
    if (filters?.http.method?.length) {
      set(newFilters, 'aws.lambda.http.method', filters?.http.method)
    }
    /**
     * HTTP path query is structured differently for traces 'aws_lambda_traces'
     */
    const HTTP_PATH =
      query === 'aws_lambda_traces' ? 'http_router_path' : 'aws.lambda.httpRouter.path'
    if (filters.http.path?.length) {
      set(newFilters, HTTP_PATH, filters?.http.path)
    }
  }

  return newFilters
}

export const useQuery = ({ query = 'aws_lambda_invocations', size, currentPage, sort }) => {
  const [data, setData] = useState()
  const { activeOrg } = useContext(AppContext)

  const { currentTimeFrame, queryApiTags, refreshTimeFrame } = useContext(FilterContext)

  const initialFilters = {
    time: {
      from: currentTimeFrame?.startTime,
      to: currentTimeFrame?.stopTime,
      interval: currentTimeFrame?.interval,
      zone: queryApiTags?.timezone,
    },
    sort,
  }

  const [filters, setFilters] = useState(initialFilters)
  const { orgId } = activeOrg || {}

  const url = `/v3/query/orgs/${orgId}/queries/${query}`

  useDeepCompareEffect(() => {
    const newFilters = updateFilters({ initialFilters, filters: queryApiTags?.filters })
    setFilters(newFilters)
  }, [queryApiTags, currentTimeFrame])
  const payload = merge(filters, {
    page: {
      from: (currentPage - 1) * size || 0, // 'from' is zero indexed
      size,
    },
    sort,
  })
  const fetchItems = async () => {
    return coreApiClient({
      url,
      method: 'post',
      data: payload,
    })
  }

  const swrKey = JSON.stringify({ url, payload })
  const {
    data: fetchedData,
    error,
    // mutate,
    isValidating,
  } = useSWR(swrKey, fetchItems, { shouldRetryOnError: false })
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData)
    }
  }, [fetchedData])
  const refresh = () => {
    refreshTimeFrame()
  }
  const loading = !data && !error
  return {
    data,
    loading,
    error,
    isValidating,
    refresh,
  }
}
