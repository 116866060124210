import { useContext, useEffect, useState, useRef } from 'react'
import { Box, Typography, Tooltip, styled, IconButton, Popover } from '@mui/material'
import { motion } from 'framer-motion'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Plus, Minus, Eraser } from 'lucide-react'
import { IconDevMode } from 'common/icons/IconDevMode'
import { IconPause } from 'common/icons/IconPause'
import { FilterContext } from 'filter/context/FilterContext'
import { DevModeContext } from 'dev-mode/context/DevMode.context'
import { getOS } from 'util/platform'
import { TimeFrame } from 'filter/components/TimeFrame'
import { IconDownload } from 'common/icons/IconDownload'
import { FiltersNavItem } from 'metrics/components/Nav/FiltersNavItem'
import { NavBarItem } from 'common/components/NavBarItem'
import { useTheme } from '@mui/styles'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { AppContext } from 'app/context/AppContext'

const timeFrameDefaults = [
  {
    value: '5m',
    cy: 'dev-mode-timeframe-select-5m',
    label: 'Last 5 Minutes',
  },
  {
    value: '15m',
    cy: 'dev-mode-timeframe-select-15m',
    label: 'Last 15 Minutes',
  },
]

const osKeyBase = getOS() === 'macos' || 'ios' ? '⌘' : 'ctrl'
const hotKeyBase = getOS() === 'macos' || 'ios' ? '⌘+shift' : 'ctrl+shift'
const hotKeyMap = {
  devModeCloudAccountId: {
    hotKey: `${hotKeyBase}+e`,
    hotKeyLabel: `${hotKeyBase}+E`,
  },
  devModeFunctionName: {
    hotKey: `${hotKeyBase}+s`,
    hotKeyLabel: `${hotKeyBase}+S`,
  },
  devZoomIn: {
    hotKey: `${osKeyBase} +`,
    hotKeyLabel: `${osKeyBase} +`,
  },
  devZoomOut: {
    hotKey: `${osKeyBase} -`,
    hotKeyLabel: `${osKeyBase} -`,
  },
  clearLogs: {
    hotKey: `${osKeyBase} k`,
    hotKeyLabel: `${osKeyBase} k`,
  },
}

export const Header = () => {
  const theme = useTheme()
  const headerRef = useRef()
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const {
    headerHeight,
    setHeaderHeight,
    openConnection,
    setOpenConnection,
    incrementZoom,
    decrementZoom,
    setClearLogs,
    timeFrame,
    setTimeFrame,
  } = useContext(DevModeContext)
  const { isOverDevModeLimit } = useOrgUsage({ orgId })
  const { setAllFilterValues } = useContext(FilterContext)

  const [anchorEl, setAnchorEl] = useState(null)
  const [openFilters, setOpenFilters] = useState(false)

  const closeTimeFrameDropdown = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (headerHeight !== headerRef?.current?.clientHeight) {
      setHeaderHeight(headerRef?.current?.clientHeight)
    }
  }, [headerRef?.current?.clientHeight])

  const buttonsProps = {
    size: 'large',
    edge: 'end',
    disabled: isOverDevModeLimit,
    // sx: { pt: 0.1, pl: 0.8 },
  }

  return (
    <StyledAppBar
      ref={headerRef}
      position="sticky"
      initial={{ y: -40, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.2, ease: 'easeOut' } }}
    >
      <Typography
        variant={'h2'}
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          color: theme.palette.primary.main,
          padding: '0 0 0 40px',
        }}
      >
        Dev Mode
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: { sm: 'none', xs: 'none', md: 'flex', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'flex-end',
            color: theme.palette.primary.main,
          }}
        >
          <Tooltip
            title={`Reduce Font Size ( ${hotKeyMap.devZoomOut.hotKeyLabel} )`}
            placement="bottom"
          >
            <NavBarItem
              sx={{ borderLeft: `1px solid ${theme.palette.border.main}` }}
              onClick={decrementZoom}
            >
              <Minus size={22} />
            </NavBarItem>
          </Tooltip>

          <Tooltip
            title={`Increase Font Size ( ${hotKeyMap.devZoomIn.hotKeyLabel} )`}
            placement="bottom"
          >
            <NavBarItem onClick={incrementZoom}>
              <Plus size={22} />
            </NavBarItem>
          </Tooltip>

          <Tooltip
            title={`Clear Activity ( ${hotKeyMap.clearLogs.hotKeyLabel} )`}
            placement="bottom"
          >
            <NavBarItem
              onClick={() => {
                setClearLogs(true)
              }}
            >
              <Eraser size={18} />
            </NavBarItem>
          </Tooltip>

          <FiltersNavItem
            open={openFilters}
            setOpen={() => setOpenFilters(true)}
            onSave={(newFilters) => {
              setAllFilterValues(newFilters)
              setOpenFilters(false)
            }}
            onCancel={() => setOpenFilters(false)}
            disabled={isOverDevModeLimit}
          />

          <Tooltip
            title={openConnection ? `Stop Activity Streaming` : `Start Activity Streaming`}
            placement="bottom"
          >
            <NavBarItem
              sx={{
                color: theme.palette.primary.main,
                borderRight: 'none',
                '&:hover': {
                  '& button:enabled': {
                    transform: 'scale(1.05)',
                    background: theme.palette.primary.main,
                  },
                },
                '& button': {
                  '&:active': {
                    transform: 'scale(0.9)',
                    opacity: 0.8,
                  },
                  '&:disabled': {
                    opacity: 0.8,
                  },
                },
              }}
              onClick={() => {
                if (openConnection) {
                  setOpenConnection(false)
                } else {
                  setTimeFrame(undefined)
                  setOpenConnection(true)
                }
              }}
              disabled={isOverDevModeLimit}
            >
              <StyledPlayPauseButton {...buttonsProps}>
                {openConnection ? (
                  <IconPause size={20} />
                ) : timeFrame && timeFrame?.trim() !== '' ? (
                  <IconDownload size={20} />
                ) : (
                  <IconDevMode size={20} />
                )}
              </StyledPlayPauseButton>
            </NavBarItem>
          </Tooltip>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { sm: 'flex', xs: 'flex', md: 'none', lg: 'none' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Tooltip title={hotKeyMap.devZoomOut.hotKeyLabel} placement="bottom">
            <IconButton
              onClick={decrementZoom}
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          {openConnection ? (
            <StyledPlayPauseButton
              onClick={() => setOpenConnection(false)}
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
            >
              <IconPause size={20} />
            </StyledPlayPauseButton>
          ) : timeFrame && timeFrame?.trim() !== '' ? (
            <StyledPlayPauseButton
              onClick={() => {
                setTimeFrame(undefined)
                setOpenConnection(true)
              }}
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
            >
              <IconDownload style={{ fill: '#000', width: '27px', height: '27px' }} />
            </StyledPlayPauseButton>
          ) : (
            <StyledPlayPauseButton
              onClick={() => {
                setTimeFrame(undefined)
                setOpenConnection(true)
              }}
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
            >
              <IconDevMode size={20} />
            </StyledPlayPauseButton>
          )}
          <Tooltip title={hotKeyMap.devZoomIn.hotKeyLabel}>
            <IconButton
              onClick={incrementZoom}
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Popover
        id={'time-frame-dropdown'}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeTimeFrameDropdown}
        PaperProps={{
          style: {
            backgroundColor: '#242424',
            width: '340px',
            padding: '20px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TimeFrame
          includeCustomTimeFrame={false}
          timeFrameDefaults={timeFrameDefaults}
          darkMode
          buttonColor="primary"
          globalTimeFrame={timeFrame}
          setTimeFrame={({ timeFrameCSV }) => {
            setTimeFrame(timeFrameCSV)
          }}
          closeDropdown={closeTimeFrameDropdown}
        />
      </Popover>
    </StyledAppBar>
  )
}

const StyledAppBar = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secondary.main,
  borderBottom: `1px solid ${theme.palette.border.main}`,
  // boxShadow: `0 6px 25px 4px ${theme.palette.secondary.main}`,
  zIndex: '5',
  height: '72px',
}))

const StyledPlayPauseButton = styled(IconButton)(({ theme }) => ({
  display: 'flex !important',
  alignContent: 'center !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  justifyItems: 'center !important',
  width: '54px',
  height: '54px',
  borderRadius: '50%',
  background: theme.palette.primary.main,
  transition: 'all 0.22s ease !important',
  transform: 'scale(0.96)',
  color: theme.palette.secondary.main,
  margin: 0,
  padding: 0,
  '&.MuiButtonBase-root.MuiIconButton-root': {
    paddingLeft: 0,
  },
  '&:hover': {
    transform: 'scale(1.05)',
    background: theme.palette.primary.main,
  },

  '&:active': {
    transform: 'scale(0.94)!important',
    opacity: 0.8,
  },

  '&:disabled': {
    opacity: 0.8,
  },
}))
