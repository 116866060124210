export const getSpanId = (span) => {
  if (!span) return null
  const isEvent = !!span?.eventId
  const idKey = isEvent ? 'eventId' : 'spanId'
  const id = span[idKey]
  return id
}
/**
 * Check if span is an event or trace span and return correct ids
 * @param {Object} span - span object to get details
 * @param {Array} timelineSpans - timeline spans array to get more enriched details
 * @returns
 */
export const getSpanDetails = ({ span, timelineSpans, traceEvents }) => {
  if (!span) return {}
  const isEvent = !!span?.eventId

  const id = getSpanId(span)
  const currentSpan = timelineSpans?.find((sp) => getSpanId(sp) === id) || span
  const isRootSpan = !currentSpan?.parentSpanId
  const hasWarning = currentSpan?.tags?.warning?.type
  /**
   * Span has error if:
   * Is root span AND has at least one child error event with type error ERROR_TYPE_UNCAUGHT, or
   * Is root span AND has a http tag status code >= 500, or
   * Current span has at least ONE child span with an event error of type ERROR_TYPE_UNCAUGHT
   * Current span/event has a type error of ERROR_TYPE_UNCAUGHT, or
   */
  const hasError =
    (isRootSpan &&
      traceEvents?.data?.some((item) => item?.tags?.error?.type === 'ERROR_TYPE_UNCAUGHT')) ||
    (isRootSpan && currentSpan?.tags?.aws?.lambda?.http?.statusCode >= 500) ||
    currentSpan?.tags?.error?.type === 'ERROR_TYPE_UNCAUGHT' ||
    currentSpan?.spans?.some((span) => span?.tags?.error?.type === 'ERROR_TYPE_UNCAUGHT')
  const color = hasError ? 'error.main' : hasWarning ? 'text.secondary' : 'text.primary'
  return {
    isEvent,
    hasError,
    hasWarning,
    id,
    color,
    ...currentSpan,
  }
}
