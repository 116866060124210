import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import Button from '../Button'
import { DropdownIcon } from '../DropdownIcon'
import { keys } from 'lodash'
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { groupByMap } from './util/groupByMap'

export const GroupByMenu = ({ onClick, groupBy }) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <Button
            aria-controls="group-by-menu"
            size="small"
            variant="outlined"
            endIcon={<DropdownIcon isOpen={popupState.isOpen} style={{ marginLeft: 0 }} />}
            {...bindTrigger(popupState)}
            sx={{
              fontSize: (theme) => theme.typography.textTertiary.fontSize,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Group by {groupByMap[groupBy]?.title}
          </Button>
          <Menu id="group-by-menu" {...bindMenu(popupState)}>
            {keys(groupByMap)?.map(
              (key, idx) =>
                key !== 'resource_aws_lambda' && (
                  <MenuItem
                    onClick={() => {
                      onClick(key)
                      popupState.close()
                    }}
                    sx={{
                      borderBottom: (theme) =>
                        idx === 3 ? `1px solid ${theme.palette.border.main}` : 'none',
                    }}
                    key={`global-search-tags-${key}`}
                  >
                    <ListItemIcon fontSize={10}>{groupByMap[key]?.icon(17)}</ListItemIcon>
                    <Typography variant="textSecondary">{groupByMap[key]?.title}</Typography>
                  </MenuItem>
                )
            )}
          </Menu>
        </>
      )}
    </PopupState>
  )
}
