import { forwardRef, useRef, useState, useLayoutEffect, createElement } from 'react'

export const Collapsible = forwardRef(
  ({ collapsed, as = 'div', expandHeight, ...props }, outerRef) => {
    const ref = useRef(outerRef)
    const [height, setHeight] = useState(collapsed ? 0 : undefined)

    useLayoutEffect(() => {
      if (ref.current === null) {
        return
      }

      if (collapsed) {
        setHeight(0)
        return
      }

      setHeight(expandHeight || ref.current.scrollHeight)
    }, [ref, collapsed])

    return createElement(as, {
      ...props,
      ref,
      style: {
        overflow: 'hidden',
        transition: 'height 300ms ease-in-out',
        height,
        ...props.style,
      },
    })
  }
)
