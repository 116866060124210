export const defaultTheme = {
  dark: {
    palette: {
      mode: 'dark',
      systemMode: 'dark',
      isDarkMode: true,
      savedMode: 'dark',
      colors: {
        redPrimary: '#FD5750',
        redLight: '#FF746F',
        redDark: '#C4413C',
        blackDark: '#000000',
        blackMedium: '#151515',
        blackMediumLight: '#222222',
        blackLight: '#444444',
        blackUltraLight: '#666666',
        greyUltraDark: '#7C7C7C',
        greyDark: '#C7C7C7',
        greyMedium: '#DDDDDD',
        greyLight: '#F8F8F8',
        white: '#FFFFFF',
      },
      primary: {
        main: '#FFFFFF',
        light: '#C7C7C7',
        dark: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000',
        light: '#151515',
        dark: '#000000',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#FD5750',
        light: '#FF746F',
        dark: '#C4413C',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#FFFFFF',
        light: '#C7C7C7',
        dark: '#FFFFFF',
        contrastText: '#000000',
      },
      border: {
        main: '#444444',
        light: '#666666',
        dark: '#222222',
      },
      shadow: {
        main: 'rgba(255,255,255,0.125)',
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        dark: '#151515',
        medium: '#444444',
        light: '#666666',
        A100: '#f5f5f5',
        A200: '#eeeeee',
        A400: '#bdbdbd',
        A700: '#616161',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#7C7C7C',
        red: '#FD5750',
        disabled: 'rgba(255, 255, 255, 0.5)',
        icon: 'rgba(255, 255, 255, 0.5)',
      },
      background: {
        default: '#000000',
        paper: '#000000',
      },
      action: {
        disabledBackground: '#444444',
        active: '#fff',
        hover: 'rgba(255, 255, 255, 0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(255, 255, 255, 0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255, 255, 255, 0.3)',
        disabledOpacity: 0.38,
        focus: 'rgba(255, 255, 255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      common: {
        black: '#000',
        white: '#fff',
      },
      warning: {
        main: '#ffa726',
        light: '#ffb74d',
        dark: '#f57c00',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      success: {
        main: '#66bb6a',
        light: '#81c784',
        dark: '#388e3c',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      divider: 'rgba(255, 255, 255, 0.12)',
    },
  },
  light: {
    palette: {
      mode: 'light',
      systemMode: 'dark',
      isDarkMode: false,
      savedMode: 'light',
      colors: {
        redPrimary: '#FD5750',
        redLight: '#FF746F',
        redDark: '#C4413C',
        blackDark: '#000000',
        blackMedium: '#151515',
        blackMediumLight: '#222222',
        blackLight: '#444444',
        blackUltraLight: '#666666',
        greyUltraDark: '#7C7C7C',
        greyDark: '#C7C7C7',
        greyMedium: '#DDDDDD',
        greyLight: '#F8F8F8',
        white: '#FFFFFF',
      },
      primary: {
        main: '#000000',
        light: '#444444',
        dark: '#000000',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#FFFFFF',
        light: '#DDDDDD',
        dark: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#FD5750',
        light: '#FF746F',
        dark: '#C4413C',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#000000',
        light: '#444444',
        dark: '#000000',
        contrastText: '#FFFFFF',
      },
      border: {
        main: '#DDDDDD',
        light: '#7C7C7C',
        dark: '#F8F8F8',
      },
      shadow: {
        main: 'rgba(0,0,0,0.15)',
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        dark: '#F8F8F8',
        medium: '#DDDDDD',
        light: '#C7C7C7',
        A100: '#f5f5f5',
        A200: '#eeeeee',
        A400: '#bdbdbd',
        A700: '#616161',
      },
      text: {
        primary: '#000000',
        secondary: '#666666',
        red: '#FD5750',
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
      background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
      },
      action: {
        disabledBackground: '#DDDDDD',
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
      common: {
        black: '#000',
        white: '#fff',
      },
      warning: {
        main: '#ed6c02',
        light: '#ff9800',
        dark: '#e65100',
        contrastText: '#fff',
      },
      success: {
        main: '#2e7d32',
        light: '#4caf50',
        dark: '#1b5e20',
        contrastText: '#fff',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      divider: 'rgba(0, 0, 0, 0.12)',
    },
  },
}
