import { Box, ButtonGroup, IconButton, Skeleton, Typography } from '@mui/material'
import { SettingLayout } from '../SettingLayout'
import Button from 'common/components/Button'
import { StringParam, useQueryParam } from 'use-query-params'
import { FolderTree, Info, List, Plus, RefreshCw } from 'lucide-react'
import { SecretsDialog } from './SecretsDialog'
import useSWR from 'swr'
import { AppContext } from 'app/context/AppContext'
import { useContext, useState } from 'react'
import { coreApiClient } from 'util/coreApiClient'
import { range, upperFirst } from 'lodash'
import { motion } from 'framer-motion'
import { SecretRow } from './SecretRow'
import { useConfirm } from 'material-ui-confirm'
import { useTheme } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { Loading } from 'common/components/Loading'
import { DropdownIcon } from 'common/components/DropdownIcon'

const sortSecrets = (alerts) =>
  alerts.sort((d1, d2) => new Date(d2.updatedAt).getTime() - new Date(d1.updatedAt).getTime())

export const useSecretsDialog = () => {
  const [isOpen, setIsOpen] = useQueryParam('secretsDialog', StringParam)
  return {
    isOpen,
    setIsOpen,
  }
}

const RenderTreeNodes = ({ secret, prevPath = '', ...rest }) => {
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const url = `/secrets/${orgId}/secrets/view/tree`
  const { setIsOpen } = useSecretsDialog()
  const [expanded, setExpanded] = useState(false)
  const path = `${prevPath ? `${prevPath}/` : ''}${secret?.name}`
  const { data, error, mutate } = useSWR(expanded && [url, secret?.name, secret?.secretUid], () =>
    coreApiClient({
      url,
      method: 'post',
      data: {
        path,
      },
    })
  )
  const loading = expanded && !data && !error
  const secrets = data?.hierarchy || []
  if (secret?.isSecret) {
    return <SecretRow secret={secret} {...rest} />
  }
  return (
    <Box>
      <Box
        onClick={() => setExpanded((prev) => !prev)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          cursor: 'pointer',
          borderRadius: '5px',
          padding: '10px 0px',
          marginBottom: '10px',
          '&:hover': {
            backgroundColor: 'grey.dark',
          },
        }}
      >
        <DropdownIcon isOpen={expanded} />
        <Typography variant="textPrimary">{secret?.name}</Typography>
        {expanded && !loading && !secrets?.length ? (
          <Typography variant="textTertiary" color="text.secondary">
            ( No secrets found )
          </Typography>
        ) : null}
        <IconButton
          sx={{
            color: 'text.secondary',
          }}
          onClick={(e) => {
            e?.stopPropagation()
            /**
             * Add prev parent path to new path
             */
            setIsOpen(path)
          }}
        >
          <Plus size={15} />
        </IconButton>
        <IconButton
          sx={{
            color: 'text.secondary',
          }}
          onClick={(e) => {
            e?.stopPropagation()
            mutate()
          }}
        >
          <RefreshCw size={13} />
        </IconButton>
        {loading && <Loading size={16} />}
      </Box>
      <Box
        sx={{
          paddingLeft: '25px',
          marginLeft: '20px',
          borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
        }}
      >
        {secrets?.map((childSecret, i) => (
          <RenderTreeNodes
            key={`secrets-tree-view-child-secret-${secret?.uid}-${i}`}
            prevPath={prevPath ? `${prevPath}/${secret?.name}` : secret?.name}
            secret={childSecret}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  )
}

export const Secrets = () => {
  const confirm = useConfirm()
  const theme = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [selectedSecret, setSelectedSecret] = useState(null)
  const [view, setView] = useState('list')
  const { setIsOpen } = useSecretsDialog()
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const { data, error, mutate } = useSWR(`/secrets/${orgId}/secrets/view/${view}`, (url) =>
    coreApiClient({
      url,
      method: 'post',

      data: {},
    })
  )
  const secrets = data?.rows || data?.hierarchy
  const loading = !data && !error

  const deleteSecret = (secret) => {
    confirm({
      title: 'Delete Secret',
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
      content: (
        <Typography variant="textPrimary" color="text.secondary">
          Are you sure you want to delete{' '}
          <span style={{ color: theme.palette.text.primary }}>
            {secret?.path}/{secret?.name}
          </span>
        </Typography>
      ),
    })
      .then(async () => {
        enqueueSnackbar(
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography variant="textSecondary">Deleting secret</Typography>
            <Loading size={16} />
          </Box>,
          { key: 'deleting' }
        )
        await coreApiClient({
          url: `/secrets/${orgId}/secrets/remove?uid=${secret?.uid}`,
          method: 'delete',
        })
        closeSnackbar('deleting')
        mutate()
        enqueueSnackbar(`Deleted secret successfully`, {
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        /* ... */
      })
  }
  const onRowSelect = (secret) => {
    setSelectedSecret(secret)
    setIsOpen('isNew')
  }
  return (
    <SettingLayout style={{ flexDirection: 'column', height: '90%', overflow: 'scroll' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Typography variant="h2">Secrets</Typography>
          <Typography variant="textSecondary" color="text.secondary">
            Parameters are an easy way to store configuration settings and secrets for your
            serverless projects. It works with any infrastructure-as-code tools like <br />
            Serverless Framework, Terraform, SST, CDK, SAM, and Pulumi, and it works with any cloud
            service provider like AWS, Azure, Cloudflare, etc.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <ButtonGroup size="small">
            {['list', 'tree'].map((viewKey) => (
              <Button
                key={`secrets-view-${viewKey}`}
                onClick={() => setView(viewKey)}
                size="small"
                variant={view === viewKey ? 'contained' : 'outlined'}
                startIcon={viewKey === 'list' ? <List /> : <FolderTree />}
                sx={{
                  fontSize: theme.typography.textTertiary.fontSize,
                  padding: '10px 10px',
                  height: '25px',
                  minWidth: '33%',
                  width: 'auto',
                  flex: 1,
                }}
              >
                {upperFirst(viewKey)}
              </Button>
            ))}
          </ButtonGroup>
          <Button
            onClick={() => setIsOpen('isNew')}
            size="small"
            variant="contained"
            startIcon={<Plus size={15} />}
            sx={{
              minWidth: 180,
            }}
          >
            Create Secret
          </Button>
        </Box>
      </Box>
      <SecretsDialog
        selectedSecret={selectedSecret}
        setSelectedSecret={setSelectedSecret}
        refresh={mutate}
      />
      <Box>
        {loading ? (
          range(10)?.map((num) => (
            <Box
              key={`resources-skeleton-loading-${num}`}
              component={motion.div}
              initial={{ y: 5, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
              }}
            >
              <Skeleton variant="rect" width="100%" height="80px" sx={{ marginBottom: '20px' }} />
            </Box>
          ))
        ) : !secrets?.length ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '20px',
              minHeight: '70vh',
              color: 'text.secondary',
            }}
          >
            <Info size={30} />
            <Typography variant="textPrimary" color="text.secondary" sx={{ lineHeight: 1 }}>
              No secrets created yet
            </Typography>
            <Typography
              variant="textPrimary"
              color="text.secondary"
              sx={{ lineHeight: 1, mb: '10px' }}
            >
              Click to create your first secret
            </Typography>
            <Button
              onClick={() => setIsOpen('isNew')}
              size="small"
              variant="contained"
              startIcon={<Plus size={15} />}
              sx={{
                minWidth: 40,
              }}
            >
              Create Secret
            </Button>
          </Box>
        ) : view === 'list' ? (
          sortSecrets(secrets)?.map((secret) => {
            return (
              <SecretRow
                secret={secret}
                key={`secrets-row-${secret?.secretUid}`}
                deleteSecret={deleteSecret}
                onClick={onRowSelect}
              />
            )
          })
        ) : (
          secrets?.map((secret, idx) => (
            <RenderTreeNodes
              secret={secret}
              index={idx}
              key={`secrets-tree-view-${secret?.secretUid + idx}`}
              deleteSecret={deleteSecret}
              onClick={onRowSelect}
            />
          ))
        )}
      </Box>
    </SettingLayout>
  )
}
