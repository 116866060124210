import React, { useContext, useMemo, useState } from 'react'
import isPostalCode from 'validator/lib/isPostalCode'
import countries from 'i18n-iso-countries'
import { motion } from 'framer-motion'
/**
 * Components
 */

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popper,
  Skeleton,
  Slide,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { Button } from 'common/components/Button'
import useSWR from 'swr'
import { AppContext } from 'app/context/AppContext'
import { coreApiClient } from 'util/coreApiClient'
import { compact, upperFirst } from 'lodash'
import { BooleanParam, useQueryParams } from 'use-query-params'
import { SuccessfulMessage } from '../../../common/components/SuccessfulMessage'
import SwipeableViews from 'react-swipeable-views'
import isEmail from 'validator/lib/isEmail'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { animateProps } from './billingUtils'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SkeletonLoading = () => (
  <Box>
    <Skeleton variant="rect" width="20%" height="20px" sx={{ marginBottom: 1 }} />
    <Skeleton variant="rect" width="100%" height="50px" />
  </Box>
)

const initialAddressValue = {
  country: '',
  state: '',
  city: '',
  code: '',
  line1: '',
  line2: '',
}

const isAddressValid = (address) =>
  address &&
  address.line1 &&
  address.city &&
  address.state &&
  address.code &&
  address.country &&
  isPostalCode(address.code, 'any')

const BoxCustom = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

/**
 * Billing Address
 */
export const BillingDetails = () => {
  /**
   * Query param to show/hide upgrade dialog
   */
  const [, setQuery] = useQueryParams({
    isUpdatePayment: BooleanParam,
    upgradePlan: BooleanParam,
  })
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg
  const [isSaving, setIsSaving] = useState(false)
  const [billingEmail, setBillingEmail] = useState('')
  const [billingAddress, setBillingAddress] = useState(initialAddressValue)
  const [billingEmailDialogOpen, setBillingEmailDialogOpen] = useState(false)
  const [billingAddressDialogOpen, setBillingAddressDialogOpen] = useState(false)
  const [clearAddressDialogOpen, setClearAddressDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isDone, setDone] = useState({
    isDoneAddress: false,
    isDoneEmail: false,
  })

  const closeAddressDialog = () => {
    setBillingAddressDialogOpen(false)
    setDone((prev) => ({ ...prev, isDoneAddress: false }))
    setErrorMessage('')
    setBillingAddress(initialAddressValue)
  }
  const closeBillingEmailDialog = () => {
    setBillingEmailDialogOpen(false)
    setDone((prev) => ({ ...prev, isDoneEmail: false }))
    setBillingEmail('')
    setErrorMessage('')
  }
  const countriesCodeList = countries.getNames('en', { select: 'official' })
  const countriesList = useMemo(() =>
    Object.keys(countriesCodeList).map((code) => ({ code, name: countriesCodeList[code] }))
  )
  const { data, error, mutate } = useSWR(`/billing/orgs/${orgId}/details`, coreApiClient)
  const isLoadingDetails = !data && !error
  const { address, card, email } = data || {}
  const formattedAddress =
    compact([
      address?.line1,
      address?.line2,
      address?.city,
      address?.code,
      address?.state,
      address?.country && countriesCodeList[address?.country],
    ]).join(', ') || ''

  /**
   * Update the billing address
   */
  const saveBillingAddress = async () => {
    if (!isAddressValid(billingAddress)) {
      return
    }

    try {
      setIsSaving(true)
      await coreApiClient({
        url: `/billing/orgs/${orgId}/details`,
        method: 'patch',
        data: {
          address: billingAddress,
        },
      })
      setDone((prev) => ({ ...prev, isDoneAddress: true }))
      mutate()
    } catch (err) {
      setErrorMessage(err.response?.data?.error || err.response?.data?.message || err?.message)
    }
    setIsSaving(false)
  }
  /**
   * Update the billing address
   */
  const saveBillingEmail = async () => {
    if (!isEmail(billingEmail)) {
      return
    }

    try {
      setIsSaving(true)
      await coreApiClient({
        url: `/billing/orgs/${orgId}/details`,
        method: 'patch',
        data: {
          email: billingEmail,
        },
      })
      mutate()
      setDone((prev) => ({ ...prev, isDoneEmail: true }))
    } catch (err) {
      setErrorMessage(err.response?.data?.error || err.response?.data?.message || err?.message)
    }
    setIsSaving(false)
  }

  /**
   * Clear the billing address
   */
  const clear = async () => {
    if (!address) {
      return
    }

    try {
      setIsSaving(true)
      await coreApiClient({
        url: `/billing/orgs/${orgId}/details`,
        method: 'patch',
        data: {
          address: initialAddressValue,
        },
      })
      mutate()
      setClearAddressDialogOpen(false)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Box width="100%" component={motion.div} {...animateProps({ delay: `0.${0}` })}>
      <Box display="flex" flexDirection="column" height="100%" gap={4}>
        {isLoadingDetails ? (
          <>
            <SkeletonLoading />
            <SkeletonLoading />
            <SkeletonLoading />
          </>
        ) : (
          <>
            <Box>
              <BoxCustom>
                <Typography variant="h4">Billing Email</Typography>
                <Button
                  onClick={(e) => {
                    setBillingEmailDialogOpen(true)
                  }}
                  size="small"
                  color="secondary"
                  variant="text"
                >
                  Update
                </Button>
              </BoxCustom>
              <Typography variant="textPrimary" color="text.secondary">
                {email || 'No billing email found'}
              </Typography>
            </Box>
            <Box>
              <BoxCustom>
                <Typography variant="h4">Payment Method</Typography>
                <Button
                  size="small"
                  color="secondary"
                  variant="text"
                  onClick={(e) => {
                    setQuery({
                      isUpdatePayment: true,
                      upgradePlan: true,
                    })
                  }}
                >
                  Update
                </Button>
              </BoxCustom>
              <Box display="flex" alignItems="center">
                {card?.brand ? (
                  <>
                    <Typography variant="textPrimary" color="text.secondary">
                      {upperFirst(card?.brand)} ending in {card?.last4}
                    </Typography>
                    <Typography variant="textPrimary" color="text.secondary" ml={5}>
                      {card?.expiration?.month} / {card?.expiration?.year}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="textPrimary" color="text.secondary">
                    No card found
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <BoxCustom>
                <Typography variant="h4">Billing Address</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  {formattedAddress && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="text"
                      onClick={() => setClearAddressDialogOpen(true)}
                      style={{ marginRight: 10 }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    size="small"
                    variant="text"
                    onClick={() => setBillingAddressDialogOpen(true)}
                  >
                    {formattedAddress ? 'Update' : 'Add'}
                  </Button>
                </Box>
              </BoxCustom>
              <Typography variant="textPrimary" color="text.secondary">
                {formattedAddress || 'No address found'}
              </Typography>
            </Box>
          </>
        )}
      </Box>

      {/**
       * Update Email Dialog
       */}
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        transitionDuration={200}
        open={billingEmailDialogOpen}
        onClose={closeBillingEmailDialog}
        maxWidth="sm"
        fullWidth
      >
        {!isDone?.isDoneEmail && <DialogTitle>Update Billing Email</DialogTitle>}

        <DialogContent>
          <SwipeableViews index={isDone?.isDoneEmail ? 1 : 0}>
            <TextField
              margin="dense"
              color="secondary"
              fullWidth
              sx={{
                width: '100%',
              }}
              value={billingEmail}
              onChange={(event) => {
                setBillingEmail(event.target.value)
                setErrorMessage('')
              }}
              placeholder="Enter billing email"
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  saveBillingEmail()
                }
              }}
            />
            {isDone?.isDoneEmail ? (
              <SuccessfulMessage
                closeDialog={closeBillingEmailDialog}
                isDone={isDone?.isDoneEmail}
                message={`Billing email updated successfully`}
              />
            ) : (
              <Box />
            )}
          </SwipeableViews>
          <ErrorMessage message={errorMessage} />
        </DialogContent>

        {!isDone?.isDoneEmail && (
          <DialogActions>
            <Button color="secondary" onClick={closeBillingEmailDialog}>
              Cancel
            </Button>
            <Button loading={isSaving} onClick={saveBillingEmail} disabled={!isEmail(billingEmail)}>
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {/**
       * Clear billing address dialog
       */}
      <Dialog
        open={clearAddressDialogOpen}
        onClose={() => setClearAddressDialogOpen(false)}
        TransitionComponent={Transition}
        keepMounted
        transitionDuration={200}
      >
        <DialogTitle>Clear billing address</DialogTitle>

        <DialogContent>
          <Typography variant="subtitle1">
            Are you sure you want to reset your billing address?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={() => setClearAddressDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" loading={isSaving} onClick={clear}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>

      {/**
       * Update billing address dialog
       */}
      <Dialog
        open={billingAddressDialogOpen}
        onClose={closeAddressDialog}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        keepMounted
        transitionDuration={200}
      >
        {!isDone?.isDoneAddress && <DialogTitle>Edit billing address</DialogTitle>}

        <DialogContent>
          <SwipeableViews index={isDone?.isDoneAddress ? 1 : 0}>
            <Box pb={2}>
              <TextField
                autoFocus
                margin="dense"
                color="secondary"
                value={billingAddress?.line1}
                onChange={(event) =>
                  setBillingAddress((address) => ({ ...address, line1: event.target.value }))
                }
                fullWidth
                placeholder="Address line 1"
              />
              <TextField
                margin="dense"
                color="secondary"
                value={billingAddress?.line2}
                onChange={(event) =>
                  setBillingAddress((address) => ({ ...address, line2: event.target.value }))
                }
                fullWidth
                placeholder="Address line 2"
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    color="secondary"
                    value={billingAddress?.city}
                    onChange={(event) =>
                      setBillingAddress((address) => ({ ...address, city: event.target.value }))
                    }
                    fullWidth
                    placeholder="City"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    margin="dense"
                    color="secondary"
                    fullWidth
                    value={billingAddress?.code}
                    onChange={(event) =>
                      setBillingAddress((address) => ({
                        ...address,
                        code: event.target.value,
                      }))
                    }
                    placeholder="ZIP/Postal Code"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    color="secondary"
                    value={billingAddress?.state}
                    onChange={(event) =>
                      setBillingAddress((address) => ({
                        ...address,
                        state: event.target.value,
                      }))
                    }
                    fullWidth
                    placeholder="State/Province/Region"
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteStyled
                    freeSolo
                    PopperComponent={CustomPopper}
                    options={countriesList}
                    getOptionLabel={(option) => option?.name || ''}
                    disableClearable
                    onChange={(event, option) => {
                      setBillingAddress((address) => ({
                        ...address,
                        country: option?.code,
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        color="secondary"
                        placeholder="Country"
                        variant="outlined"
                        onChange={(event) => {
                          if (!countriesList.some((name) => name === event.target.value)) {
                            setBillingAddress((address) => ({
                              ...address,
                              country: null,
                            }))
                          }
                        }}
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            saveBillingAddress()
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            {isDone?.isDoneAddress ? (
              <SuccessfulMessage
                closeDialog={closeAddressDialog}
                isDone={isDone?.isDoneAddress}
                message={`Address ${formattedAddress ? 'updated' : 'added'} successfully`}
              />
            ) : (
              <Box />
            )}
          </SwipeableViews>
          <ErrorMessage message={errorMessage} />
        </DialogContent>

        {!isDone?.isDoneAddress && (
          <DialogActions>
            <Button color="secondary" onClick={() => setBillingAddressDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              loading={isSaving}
              disabled={!isAddressValid(billingAddress)}
              onClick={saveBillingAddress}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  )
}

const CustomPopper = function (props) {
  return (
    <StyledPopper
      {...props}
      style={{ minWidth: '300px', zIndex: 9999999999, ...(props.style || {}) }}
      placement="bottom-start"
    />
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '259px',
    maxWidth: '259px',
    width: '259px',
  },
}))

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-tag': {
    '&:not(.MuiButtonBase-root)': {
      fontSize: theme.typography.textPrimary.fontSize,
    },
  },
  '& .MuiInputBase-root': {
    '& .MuiAutocomplete-input': {
      fontSize: theme.typography.textPrimary.fontSize,
      borderRadius: '4px',
      border: `1px solid ${theme.palette.border.main}`,
      backgroundColor: theme.palette.secondary.main,
      width: '100%',
      padding: '5px 9px !important',
      opacity: 1,
    },
    '&.Mui-focused': {
      '& .MuiAutocomplete-input': {
        minWidth: '90%',
        maxWidth: '257px',
        padding: '5px 9px !important',
        display: 'block !important',
        opacity: 1,
      },
    },
  },
}))
