import { useRef } from 'react'
import { Slide } from '@mui/material'
import { SnackbarProvider as SnackProvider } from 'notistack'
import { useTheme } from '@mui/styles'
import CloseButton from 'common/components/CloseButton'

export const SnackbarProvider = ({ children }) => {
  const snackRef = useRef()
  const theme = useTheme()

  const onClickDismiss = (key) => () => {
    snackRef.current.closeSnackbar(key)
  }

  return (
    <SnackProvider
      ref={snackRef}
      maxSnack={4}
      autoHideDuration={5000}
      classes={{
        containerRoot: `${theme?.palette?.mode}-snackbar`,
      }}
      variant="primary"
      action={(key) => (
        <CloseButton
          size={20}
          onClick={onClickDismiss(key)}
          aria-label="close"
          initialColor="currentColor"
        />
      )}
      hideIconVariant
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
    >
      {children}
    </SnackProvider>
  )
}
