import React from 'react'
import { useCopyToClipboard, useToggle, useUnmount } from 'react-use'
import { Box, Tooltip } from '@mui/material'
import { Copy } from 'lucide-react'
import { merge } from 'lodash'

let timeout

/**
 * Copied from original frontend codebase
 */
export const CopyText = ({
  id,
  children,
  value,
  hideDelay = 1250,
  copyText = 'copied',
  CopyIconComponent = Copy,
  CopyIconComponentProps = {},
  sx = {},
  iconSize = 12,
  showOnHoverOnly = false,
}) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [tooltipVisible, setTooltipVisible] = useToggle(false)
  const [showIcon, setShowIcon] = useToggle(!showOnHoverOnly)

  const handleCopy = () => {
    copyToClipboard(value)
    setTooltipVisible(true)

    timeout = setTimeout(() => {
      setTooltipVisible(false)
    }, hideDelay)
  }

  useUnmount(() => {
    clearTimeout(timeout)
  })

  return (
    <Box
      lineHeight="1"
      alignItems="center"
      display="flex"
      sx={merge({}, sx)}
      onMouseEnter={() => {
        if (showOnHoverOnly) {
          setShowIcon(true)
        }
      }}
      onMouseLeave={() => {
        if (showOnHoverOnly) {
          setShowIcon(false)
        }
      }}
    >
      {children}

      <Tooltip id={id} arrow open={tooltipVisible} placement="top" title={copyText}>
        <Box ml={1} {...CopyIconComponentProps}>
          <Box
            component={CopyIconComponent}
            size={iconSize}
            onClick={handleCopy}
            sx={{
              opacity: showIcon ? 1 : 0,
              transition: 'all 0.2s ease',
              cursor: 'pointer',
              color: 'text.secondary',
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  )
}
