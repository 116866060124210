import { Box, Tooltip, Typography } from '@mui/material'
import { Button } from 'common/components/Button'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useContext, useState } from 'react'
import { AppContext } from 'app/context/AppContext'
import { HelpCircle } from 'lucide-react'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { useTheme } from '@mui/material/styles'
import { coreApiClient } from 'util/coreApiClient'
import { sleep } from 'common/utils/sleep'
import { TierBox } from './TierBox'

import { useUpgradeDialog } from 'settings/hooks/useUpgradeDialog'
import useSWR, { mutate } from 'swr'
import { LoadingSpinner } from 'common/components/LoadingSpinner'

export const PaymentCheckoutForm = ({ closeDialog, setIsDone, isUpdatePayment }) => {
  const theme = useTheme()

  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}
  const { enrichTier } = useOrgUsage({ orgId })

  /**
   * Load Stripe
   */
  const stripe = useStripe()
  const elements = useElements()

  /**
   * Card inputs are complete and correct
   */
  const [isCardComplete, setIsCardComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { query } = useUpgradeDialog()
  const traces = query?.traces

  /**
   * Get current subscription
   */
  const { refresh } = useOrgUsage({ orgId })

  const { data, error } = useSWR(`/billing/orgs/${orgId}/details`, coreApiClient)
  const isLoadingCard = !data && !error
  const { card } = data || {}

  const handleUpgrade = async () => {
    setLoading(true)

    try {
      /**
       * Checkout with selected traces
       */
      let obj = {
        url: `/billing/orgs/${orgId}`,
        method: 'post',
        data: {
          tier: traces,
        },
      }
      /**
       * Or Update payment card
       */
      if (isUpdatePayment) {
        obj = {
          url: `/billing/orgs/${orgId}/details`,
          method: 'patch',
        }
      }
      /**
       * Get setup intent client secret
       */
      const { clientSecret } = await coreApiClient(obj)

      /**
       * If no status returned it means that we don't have user card stored in Stripe
       */
      if (clientSecret) {
        const result = await stripe?.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements?.getElement(CardElement),
          },
        })

        if (result?.error) {
          throw new Error(result?.error?.message)
        }
      }

      /**
       * Set 2 seconds timeout to get updated response
       */
      await sleep(2000)

      /**
       * Refresh org plans.
       */
      refresh()

      /**
       * Refresh payment method section
       */
      mutate(`/billing/orgs/${orgId}/details`)

      setIsDone(true)
    } catch (err) {
      setErrorMessage(err.response?.data?.error || err.response?.data?.message || err?.message)
    }
    setLoading(false)
  }

  const isUpgradeButtonDisabled = !card && (!stripe || !elements || !isCardComplete)

  const border = `1px solid ${theme.palette.border.main}`
  const tier = enrichTier(query?.traces)
  if (isLoadingCard) {
    return <LoadingSpinner minHeight={300} />
  }
  return (
    <>
      {!isUpdatePayment && <TierBox tier={tier} inDialog />}
      {(!card || isUpdatePayment) && (
        <Box mt={2}>
          <Typography variant="subtitle2" color="text.secondary">
            Add a Card
          </Typography>
          <Box width="100%" p={2} my={0.5} border={border} borderRadius="6px">
            <CardElement
              onReady={(el) => el.focus()}
              onChange={(changeObject) => {
                setErrorMessage('')
                setIsCardComplete(changeObject?.complete)
              }}
              options={{
                style: {
                  base: {
                    fontFamily: 'sans-serif',
                    fontSize: 14,
                    color: theme.palette.text.primary,
                  },
                  invalid: {
                    color: theme.palette.error.main,
                    iconColor: theme.palette.error.main,
                  },
                },
              }}
            />
          </Box>
        </Box>
      )}

      {!isUpdatePayment && (
        <Box sx={{ padding: '0 1px', mt: 1 }} display="flex" alignItems="center">
          <Typography variant="textSecondary" color="text.secondary">
            Your card won't be charged yet. You can cancel anytime.
          </Typography>

          <Tooltip
            placement="top"
            arrow
            title="Your card won't be charged until the end of the month based on your usage."
          >
            <HelpCircle style={{ stroke: '#C7C7C7', marginLeft: 2 }} size={15} />
          </Tooltip>
        </Box>
      )}

      <ErrorMessage message={errorMessage} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          style={{ marginLeft: 16 }}
          onClick={handleUpgrade}
          variant="contained"
          disabled={isUpgradeButtonDisabled}
          loading={loading}
        >
          {isUpdatePayment ? 'Add' : 'Change Plan'}
        </Button>
      </Box>
    </>
  )
}
