import { useState } from 'react'
import { Box, Modal, Grow, styled, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { motion } from 'framer-motion'
import { StringParam, BooleanParam, useQueryParams } from 'use-query-params'
import { DialogIntro } from './steps/aws/DialogIntro'
import { IconAws } from 'common/icons/IconAws'
import { IntegrationCreateSteps } from './IntegrationCreateSteps'
import { DeployRole } from './steps/aws/DeployRole'
import { EnableResources } from './EnableResources'
import { IconBox } from './IntegrationRow'
import { Complete } from './steps/aws/Complete'
import { useEffectOnce } from 'react-use'
import CloseButton from 'common/components/CloseButton'

const headerLogoMap = {
  aws: {
    headerText: 'AWS Integration',
    logo: (
      <IconBox>
        <IconAws />
      </IconBox>
    ),
    steps: [
      {
        label: 'Integrate AWS',
        hiddenStepper: true,
        stepAlias: 'integrate-aws',
        Component: ({ handleNext }) => <DialogIntro handleNext={handleNext} />,
      },
      {
        label: 'Deploy Role',
        hiddenStepper: true,
        stepAlias: 'deploy-role',
        Component: ({ handleNext }) => <DeployRole handleNext={handleNext} />,
      },
      {
        label: 'Integration Complete',
        hiddenStepper: true,
        stepAlias: 'integration-complete',
        Component: ({ handleNext }) => <Complete handleNext={handleNext} />,
      },
      {
        label: 'Enable Resources',
        hiddenStepper: true,
        stepAlias: 'enable-resources',
        Component: ({ handleNext }) => <EnableResources handleNext={handleNext} />,
      },
    ],
  },
}

const defaultSteps = {
  integrationType: 'aws',
}

const HeaderLogo = ({ step }) => (
  <Box display="flex" alignItems="center" gap="20px">
    {headerLogoMap[step.integrationType].logo}
    <Typography variant="h4">{headerLogoMap[step.integrationType].headerText}</Typography>
  </Box>
)
export const IntegrationDialog = () => {
  const theme = useTheme()
  const [allParams, setAllQueryParams] = useQueryParams({
    integrationStep: StringParam,
    integrationId: StringParam,
    integrationType: StringParam,
    redirectUrl: StringParam,
    createIntegration: BooleanParam,
    editIntegration: BooleanParam,
    deployRoleView: StringParam,
  })

  const { integrationType, createIntegration, editIntegration } = allParams
  const [, setIsDone] = useState(false)
  const [step, setStep] = useState({
    ...defaultSteps,
    integrationType: integrationType || defaultSteps.integrationType,
  })

  const closeDialog = () => {
    setAllQueryParams({
      page: undefined,
      integrationStep: undefined,
      integrationId: undefined,
      redirectUrl: undefined,
      deployRoleView: undefined,
      integrationType: undefined,
      createIntegration: undefined,
      editIntegration: undefined,
    })

    // Wait to change UI until after the modal is closed
    setTimeout(() => {
      setIsDone(false)
      setStep(defaultSteps)
    }, 300)
  }

  useEffectOnce(() => {
    setAllQueryParams({ ...allParams })
  })

  return (
    <CustomModal
      open={!!createIntegration || !!editIntegration}
      hideBackdrop
      disableEnforceFocus
      onClose={closeDialog}
      aria-labelledby="modal-integrations"
      aria-describedby="modal-integrations-description"
    >
      <Grow
        in={!!createIntegration || !!editIntegration}
        timeout={300}
        unmountOnExit
        sx={{
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: '6px',
          boxSizing: 'border-box!important',
        }}
      >
        <ModalHolder>
          <motion.div
            sx={{ overflowY: 'scroll' }}
            initial={{ opacity: [0, 1] }}
            animate={{
              opacity: 1,
              transition: { delay: 0.35, duration: 0.75, ease: 'easeOut' },
            }}
          >
            {integrationType ? (
              <Box width="100%">
                <EnableResources handleNext={closeDialog} headerLogo={<HeaderLogo step={step} />} />
              </Box>
            ) : (
              <>
                <NavBar>
                  <HeaderLogo step={step} />
                  <CloseButton onClick={closeDialog} aria-label="close modal" />
                </NavBar>
                <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                  <IntegrationCreateSteps steps={headerLogoMap[step.integrationType].steps} />
                </Box>
              </>
            )}
          </motion.div>
        </ModalHolder>
      </Grow>
    </CustomModal>
  )
}

const NavBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '30px ',
})

const CustomModal = styled(Modal)(() => ({
  margin: 0,
  outline: 0,
}))

const ModalHolder = styled('div')(({ theme }) => ({
  height: 'var(--app-height)',
  backgroundColor: theme.palette.background.paper,
  outline: 0,
}))
