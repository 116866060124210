import { styled, Box, Typography } from '@mui/material'
import { slice } from 'lodash'

const TooltipsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.colors.blackMediumLight,
  color: theme.palette.colors.white,
  padding: 16,
  borderRadius: 6,
  width: '100%',
  position: 'relative',
  outline: 'none',
  border: 'none',
  fontWeight: 100,
  zIndex: 9,
}))
const borderColor = 'grey.dark'
const Divider = styled('div')(({ theme }) => ({
  height: '70px',
  width: '1px',
  backgroundColor: theme.palette.grey.dark,
  margin: 'auto',
}))

const CustomTooltip = ({ items, isLatencyGraph, isActivityGraph, title, hasFooter }) => {
  return (
    <TooltipsWrapper>
      {title ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Typography variant="textPrimary">{title}</Typography>
        </Box>
      ) : null}
      {isActivityGraph ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            border: 'none',
            outline: 'none',
            gap: '20px',
          }}
        >
          <Typography variant="textTertiary">{items[0].title}</Typography>
          <Typography variant="h1">{items[0].value || '-'}</Typography>
        </Box>
      ) : isLatencyGraph ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            border: 'none',
            outline: 'none',
            gap: '20px',
          }}
        >
          {items?.map((item, index) => (
            <Box key={`tooltip-${JSON.stringify(item)}-${index}`}>
              <Typography variant="textTertiary">{item.title}</Typography>
              <Typography
                variant="textPrimary"
                color={item.isError ? 'error.main' : 'colors.white'}
              >
                {item.value || '-'}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            border: 'none',
            outline: 'none',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {slice(items, 0, 3).map((item, index) => (
              <Box
                key={`tooltip-${JSON.stringify(item)}-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '0 20px',
                }}
              >
                <Typography variant="textTertiary">{item.title}</Typography>
                <Typography
                  variant="textPrimary"
                  color={item.isError ? 'error.main' : 'colors.white'}
                >
                  {item.value || '-'}
                </Typography>
              </Box>
            ))}
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {slice(items, 3, items.length).map((item, index) => (
              <Box
                key={`tooltip-${JSON.stringify(item)}-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '0 20px',
                }}
              >
                <Typography variant="textTertiary">{item.title}</Typography>
                <Typography
                  variant="textPrimary"
                  color={item.isError ? 'error.main' : 'colors.white'}
                >
                  {item.value || '-'}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {hasFooter && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            marginRight: 'auto',
            color: 'error.main',
            width: '100%',
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: borderColor,
            paddingTop: '10px',
          }}
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'error.main',
              marginRight: '5px',
            }}
          />
          <Typography variant="textTertiary">P95 is 1.75 more than the average</Typography>
        </Box>
      )}
    </TooltipsWrapper>
  )
}

export default CustomTooltip
