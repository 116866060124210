export const IconAWSSSM = ({ style = {} }) => {
  return (
    <svg style={style} focusable="false" aria-hidden viewBox="-1 -1 22 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.576 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10ZM6.485 7.753a2.864 2.864 0 0 1-.02-.326c0-.979.695-2.031 1.582-2.396 1.54-.637 2.634.198 3.032.587.325.319.582.727.76 1.213a.41.41 0 0 0 .708.104.874.874 0 0 1 .697-.36c.409 0 .907.312.97 1.114a.397.397 0 0 0 .065.305.412.412 0 0 0 .267.167c.493.081 1.64.416 1.64 1.757H17c0-1.45-.988-2.215-2.01-2.483-.188-1.14-1.011-1.663-1.745-1.663-.314 0-.615.088-.877.25a3.841 3.841 0 0 0-.716-.975c-1.07-1.045-2.536-1.328-3.918-.757-1.187.488-2.082 1.836-2.082 3.137v.085C4.612 7.894 4 8.765 4 9.9c0 1.402 1.068 2.345 2.066 2.345h2.845v-.803H6.066c-.593 0-1.253-.633-1.253-1.542 0-1.178.837-1.574 1.348-1.706a.406.406 0 0 0 .324-.442Zm6.651 5.328a.547.547 0 0 0 .538-.552.549.549 0 0 0-.548-.531h-.012a.549.549 0 0 0-.384.165.536.536 0 0 0 .015.767c.106.1.222.174.391.151Zm-.993-1.473a1.36 1.36 0 0 1 .955-.413c.385.002.71.126.972.375s.41.584.417.943c.008.36-.127.7-.38.958a1.36 1.36 0 0 1-.954.413h-.028c-.354 0-.688-.133-.944-.375a1.326 1.326 0 0 1-.418-.943c-.007-.36.128-.7.38-.958Zm2.547 2.149.801.159.368-1.114-.742-.337a.406.406 0 0 1-.218-.242l-.154-.476a.394.394 0 0 1 .034-.319l.398-.7-.96-.69-.546.584a.404.404 0 0 1-.296.13l-.516.003h-.002a.411.411 0 0 1-.3-.13l-.55-.59-.96.689.4.704a.397.397 0 0 1 .033.321l-.155.471a.407.407 0 0 1-.218.24l-.744.338.365 1.114.795-.157a.412.412 0 0 1 .32.071l.416.303c.09.066.15.166.162.276l.094.79 1.187.001.094-.796a.4.4 0 0 1 .164-.277l.41-.296a.415.415 0 0 1 .32-.07Zm.93-1.948.907.413a.4.4 0 0 1 .218.489l-.593 1.793a.406.406 0 0 1-.467.27l-.98-.195-.122.088-.115.978a.405.405 0 0 1-.404.355l-1.912-.002a.405.405 0 0 1-.404-.355l-.114-.973-.129-.093-.973.192a.406.406 0 0 1-.466-.27l-.59-1.794a.4.4 0 0 1 .219-.489l.912-.412.044-.136-.49-.862a.397.397 0 0 1 .115-.522l1.547-1.107a.412.412 0 0 1 .538.053l.673.72h.16l.672-.719a.41.41 0 0 1 .538-.052l1.544 1.109c.167.12.217.344.116.522l-.49.859.046.14Z"
      />
    </svg>
  )
}
