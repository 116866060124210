import {
  Box,
  LinearProgress,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material'
import { styled, useTheme, withStyles } from '@mui/styles'
import Button from 'common/components/Button'
import { range, startCase } from 'lodash'
import { useQuery } from 'metrics/hooks/useQuery'
import { useContext, useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { AppContext } from 'app/context/AppContext'
import { FilterContext } from 'filter/context/FilterContext'
import { Link } from 'common/components/Link'
import CustomTooltip from './CustomTooltip'
import { useMetricsCount } from 'metrics/hooks/useMetricsCount'
import ErrorState from 'common/components/ErrorState'
import ChartHeader from './ChartHeader'
import { stringifyUrl } from 'query-string'
import { useLocation } from 'react-router-dom'
import { CustomPagination } from 'common/components/CustomPagination'

/**
 * Skeleton loading based on items count in page
 */
const SkeletonLoading = ({ size = 10 } = {}) => (
  <Box>
    {range(size)?.map((num) => (
      <Box
        key={`resources-skeleton-loading-${num}`}
        component={motion.div}
        initial={{ y: 5, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
        }}
      >
        <Skeleton variant="rect" width="40%" height="15px" sx={{ marginBottom: 1.5 }} />
        <Skeleton variant="rect" width="100%" height="15px" sx={{ marginBottom: 1.5 }} />
      </Box>
    ))}
  </Box>
)

const FunctionsListItem = ({ item, index }) => {
  const { function_name: label } = item
  const {
    invocations,
    uncaughtErrors,
    invocationsFormatted,
    uncaughtErrorsFormatted,
    successRateFormatted,
    okInvocations,
    successRate,
    okInvocationsFormatted,
  } = useMetricsCount([item])

  const { getFilterValue } = useContext(FilterContext)
  const { activeOrg } = useContext(AppContext)
  const { orgName } = activeOrg
  const location = useLocation()
  const [percentValue, setPercentValue] = useState(0)
  let percent = Math.round((uncaughtErrors / invocations) * 100)

  // Set a min percent value so we surface
  // some red on the line when there is an error
  if (percent === 0 && uncaughtErrors !== 0) {
    percent = 3
  }

  // This adds some animation to the percentage
  useEffect(() => {
    const timer = setTimeout(() => {
      setPercentValue(percent)
    }, 200)

    return () => clearTimeout(timer)
  }, [percent])

  const formatter = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  })

  const formattedError = formatter.format(uncaughtErrors || 0)

  const formattedOkInvocations = formatter.format(okInvocations)
  const tooltipItems = [
    {
      title: 'Invocations',
      value: invocationsFormatted,
      isError: !okInvocations,
    },
    {
      title: 'Succeeded',
      value: okInvocationsFormatted,
    },
    {
      title: 'Uncaught errors',
      value: uncaughtErrorsFormatted,
      isError: true,
    },

    {
      title: 'Success Rate',
      value: successRateFormatted,
      isError: !successRate,
    },
  ]
  const link = stringifyUrl({
    url: `/${orgName}/explorer${location.search}`,
    query: {
      awsLambdaFunctionNames: [label],
      globalTimeFrame: getFilterValue('globalTimeFrame'),
      explorerSubScope: 'invocations',
      globalScope: 'awsLambda',
    },
  })
  const errorLink = stringifyUrl({
    url: `/${orgName}/explorer${location.search}`,
    query: {
      awsLambdaFunctionNames: [label],
      awsLambdaEvents: ['ERROR_TYPE_UNCAUGHT'],
      globalTimeFrame: getFilterValue('globalTimeFrame'),
      explorerSubScope: 'invocations',
      globalScope: 'awsLambda',
    },
  })

  return (
    <StyledTooltip title={<CustomTooltip title={label} items={tooltipItems} />} placement="top">
      <SidebarItemContainer
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { delay: index * 0.05, duration: 0.2, ease: 'easeOut' },
        }}
      >
        <SidebarItemTitle to={link}>
          <Box>
            <Typography
              variant="textPrimary"
              data-cy={`metrics-function-sidebar-item-label-${index}`}
            >
              {label}
            </Typography>
          </Box>
        </SidebarItemTitle>
        <BarContainer to={link}>
          <BorderLinearProgress variant="determinate" value={percentValue} />
        </BarContainer>
        <Box display="flex" alignItems="center" width="100" marginBottom="5px" gap="0px 10px">
          {/* Use red sparingly, hide this if there are no errors */}
          {formattedError !== '0' && (
            <SidebarItemState is-error="true" to={errorLink}>
              {formattedError}
            </SidebarItemState>
          )}
          <SidebarItemState to={link}>
            <Typography variant="textTertiary">{formattedOkInvocations}</Typography>
          </SidebarItemState>
        </Box>
      </SidebarItemContainer>
    </StyledTooltip>
  )
}

const sortKeys = ['invocations', 'uncaught_errors']

export const FunctionsList = () => {
  const theme = useTheme()
  const [currentPage, setCurrentPage] = useState(1)
  const [activeMenu, setActiveMenu] = useState()
  const [activeSortKey, setActiveSortKey] = useState(sortKeys[0])

  const size = 10

  const { data, loading, refresh, error, isValidating } = useQuery({
    query: 'aws_lambda_functions',
    size,
    sort: {
      by: activeSortKey,
      order: 'desc',
    },
    currentPage,
  })

  const total = data?.total || 0
  const functions = data?.results
  const pagesCount = useMemo(() => Math.ceil(total / size) || 1, [total, size])

  const onMenuClose = (key) => {
    setActiveMenu(null)
    if (key) {
      setActiveSortKey(key)
    }
  }
  const headingStats = [
    {
      value: (
        <Button
          aria-controls="sort-menu"
          aria-haspopup="true"
          onClick={(e) => setActiveMenu(e?.currentTarget)}
          color="secondary"
          size="small"
          variant="text"
          endIcon={<DropdownIcon isOpen={!!activeMenu} style={{ marginLeft: 0 }} />}
          sx={{
            fontSize: theme.typography.textSecondary.fontSize,
            color: theme.palette.text.secondary,
          }}
        >
          Sort by {startCase(activeSortKey)}
        </Button>
      ),
    },
  ]

  return (
    <Box
      sx={{
        position: 'relative',
        height: '90%',
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ChartHeader
        title="Functions"
        description="AWS Lambda functions within the specified time frame"
        stats={headingStats}
        isValidating={isValidating}
        loading={loading}
        error={error}
      />

      <Menu
        id="sort-menu"
        anchorEl={activeMenu}
        keepMounted
        open={!!activeMenu}
        onClose={() => onMenuClose()}
      >
        {sortKeys.map((key) => (
          <MenuItem onClick={() => onMenuClose(key)} key={`metrics-functions-list-${key}`}>
            <Typography variant="textSecondary">Sort by {startCase(key)}</Typography>
          </MenuItem>
        ))}
      </Menu>

      {loading || (!functions && isValidating) ? (
        <SkeletonLoading size={size} />
      ) : error ? (
        <ErrorState onReload={refresh} isColumnDirection fullWidth />
      ) : !functions?.length ? (
        <Box
          display="flex"
          minHeight="400px"
          minWidth="100%"
          justifyContent="center"
          alignItems="center"
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.2, delay: 0.3, ease: 'easeOut' },
          }}
        >
          <Box textAlign="center">
            <Typography variant="textSecondary">No data found within this period.</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          {functions?.map((item, index) => (
            <FunctionsListItem key={`${item.function_name}-${index}`} item={item} index={index} />
          ))}
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'auto',
          paddingBottom: '20px',
        }}
      >
        <CustomPagination
          size={size}
          total={total}
          pageCount={pagesCount}
          currentPage={currentPage}
          isValidating={isValidating}
          onChange={(page) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  )
}

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.border.dark,
    padding: '0px',
    maxWidth: 'none',
    borderRadius: 6,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.dark,
  },
}))

const BarContainer = styled(Link)(() => ({
  borderRadius: '12px',
  margin: '0 0 4px 0',
  overflow: 'hidden',
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 3,
    backgroundColor: theme.palette.text.secondary,
  },
  bar: {
    borderRight: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.error.main,
  },
}))(LinearProgress)

const SidebarItemContainer = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  margin: '0 0 15px 0',
}))

const SidebarItemTitle = styled(Link)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 'auto',
  marginBottom: 5,
  textDecoration: 'none',
}))

const SidebarItemState = styled(Link)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  fontSize: props.theme.typography.textTertiary.fontSize,
  margin: '0',
  color: props['is-error'] ? props.theme.palette.error.main : props.theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'none',
}))
