import { Box, Typography, Tooltip, Fade } from '@mui/material'
import { Chip } from './Chip'
import { isNil } from 'lodash'

export const TagBox = ({ tag, sx }) => {
  /**
   * Tag doesn't exist or if value is 'null' or 'undefined' then return nothing
   */
  if (!tag.exists || isNil(tag.value)) {
    return null
  }
  return (
    <Tooltip
      title={tag.description ? tag.description : ''}
      placement="top"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
    >
      <Box sx={sx}>
        <Typography variant="textSecondary" color="text.secondary" sx={{ marginBottom: '4px' }}>
          {tag.title}
        </Typography>
        <Chip
          label={tag.value}
          autoTruncate={false}
          size="small"
          color={tag.isError ? 'error' : undefined}
          showCopy={tag.showCopy}
        />
      </Box>
    </Tooltip>
  )
}
