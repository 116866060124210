import { styled } from '@mui/styles'
import { DropdownIcon } from './DropdownIcon'
import { Box, CircularProgress, Typography } from '@mui/material'
import { isString } from 'lodash'
import { isValidElement } from 'react'
import { Chip } from './Chip'
import { motion } from 'framer-motion'

/**
 * This component is used to wrap any dropdown filter component with custom children
 */
export const DropdownWrapper = ({
  children,
  title,
  isOpen,
  onToggle,
  selectedItems,
  dropdownIcon,
  isLoading,
  options,
  total,
  onDelete,
  icon,
  collapsable = true,
  renderChipLabel,
}) => {
  return (
    <Wrapper is-open={isOpen ? 'true' : undefined}>
      <TitleWrapper is-open={isOpen ? 'true' : undefined} onClick={onToggle}>
        {isString(title) ? <Typography variant="textPrimary">{title}</Typography> : title}
        {isOpen && isLoading ? (
          <CircularProgress
            size={11}
            thickness={5}
            sx={{ color: 'primary.main', marginRight: '10px', marginLeft: 'auto' }}
          />
        ) : isOpen && options?.length && total ? (
          <Typography variant="textTertiary" color="text.secondary" sx={{ marginLeft: 'auto' }}>
            {`${options?.length} of ${total}`}
          </Typography>
        ) : null}
        {dropdownIcon ? dropdownIcon : collapsable ? <DropdownIcon isOpen={isOpen} /> : null}
        {/* Add a flex line break */}
        <Box
          sx={{
            flexBasis: '100%',
            width: '0px',
            height: '0px',
            overflow: 'hidden',
          }}
        />
        {/*  Show selected tags only when dropdown is closed, and only show first 8 with a message stating the total number of tags selected */}
        <Box
          component={motion.div}
          initial={{ height: 0 }}
          animate={{
            height: !!selectedItems?.length ? 'auto' : 0,
            transition: { duration: 0.2, ease: 'easeOut' },
          }}
        >
          {!selectedItems ? null : isValidElement(selectedItems) ? (
            selectedItems
          ) : (
            <>
              {/* Show selected tags only when dropdown is closed, and only show first 8 with a message stating the total number of tags selected */}

              {selectedItems?.length && !isOpen ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '5px',
                    marginTop: '5px',
                  }}
                >
                  {selectedItems.slice(0, 8)?.map((selectedValue, idx) => (
                    <Chip
                      key={`selected-values-${selectedValue?.value}-${idx}`}
                      icon={icon}
                      label={renderChipLabel?.(selectedValue)}
                      onDelete={onDelete ? (e) => onDelete(selectedValue) : null}
                      size="small"
                      autoTruncate={false}
                    />
                  ))}
                </Box>
              ) : null}
              {selectedItems?.length > 8 && !isOpen ? (
                <Typography variant="textTertiary" color="text.secondary">
                  and {selectedItems?.length - 8} more...
                </Typography>
              ) : null}
              {isOpen && selectedItems?.length ? (
                <Typography variant="textTertiary" color="text.secondary">
                  {selectedItems?.length} Selected
                </Typography>
              ) : null}
            </>
          )}
        </Box>
      </TitleWrapper>
      {children && (
        <Box
          component={motion.div}
          initial={{ height: 0 }}
          animate={{
            height: isOpen ? 'auto' : 0,
            transition: { duration: 0.2, ease: 'easeInOut' },
          }}
          sx={{
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')((props) => ({
  position: 'relative',
  display: 'block',
  height: 'auto',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  border: `1px solid ${
    props['is-open'] ? props.theme.palette.border.main : props.theme.palette.secondary.main
  }`,
  margin: props.margin || '0',
  marginBottom: '5px',
  transition: 'all 0.2s ease',
  borderRadius: 4,

  '&:hover': {
    background: props['is-open'] ? 'none' : props.theme.palette.grey.dark,
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
export const TitleWrapper = styled('div')((props) => ({
  zIndex: '10',
  padding: '10px',
  width: '100%',
  boxSizing: 'border-box',
  userSelect: 'none',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: props['is-open'] ? 'none' : props.theme.palette.grey.dark,
  },
}))
