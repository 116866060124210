import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useEventCallback,
} from '@mui/material'
import { memo, useMemo, useState } from 'react'
import useVirtual from 'react-cool-virtual'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { ChevronRight } from 'lucide-react'
import { Link } from 'common/components/Link'
import { stringifyUrl } from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import { Search } from 'lucide-react'
import { X } from 'lucide-react'

import { useDebounce } from 'react-use'
import { toLower } from 'lodash'
import { ExternalLink } from 'lucide-react'
import { slugifyString } from 'filter/components/FiltersWidget'
import { FilterContext } from 'filter/context/FilterContext'
import { useContext } from 'react'
import pluralize from 'pluralize'

export const ActivityAffectedResources = ({ activity, ...props }) => {
  const location = useLocation()

  const { orgName } = useParams()
  const { getFilterValue } = useContext(FilterContext)

  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')

  const affectedResources = useMemo(
    () =>
      activity?.affected_resources_with_count
        ?.filter(({ resourceName: i }) => toLower(i).includes(debouncedSearch))
        .sort((a, b) => (parseInt(a.occurrence) > parseInt(b.occurrence) ? -1 : 1)),
    [debouncedSearch, activity]
  )
  const { outerRef, innerRef, items } = useVirtual({
    itemCount: affectedResources?.length || 0,
    itemSize: 55,
  })

  useDebounce(
    () => {
      setDebouncedSearch(toLower(search))
    },
    500,
    [search]
  )
  const getLink = useEventCallback((awsLambdaFunctionNames) => {
    if (!activity) return ''
    return stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        globalTimeFrame: getFilterValue('globalTimeFrame'),
        awsLambdaFunctionNames: [awsLambdaFunctionNames],
        explorerTracesFingerprints: activity?.fingerprint,
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })
  }, [])

  return (
    <StyledAccordion {...props}>
      <StyledAccordionSummary>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          Affected Resources ({affectedResources?.length})
        </Typography>
      </StyledAccordionSummary>
      {props.expanded && (
        <TextField
          sx={{
            padding: '0 ',
            position: 'absolute',
            right: '26px',
            top: '12px',
            zIndex: 2,
            maxWidth: 200,
            backgroundColor: 'background.default',
            '& .MuiInputBase-root': {
              height: 30,

              '& input': {
                fontSize: '0.85rem',
                height: 30,
                padding: 0,
              },
            },
          }}
          placeholder={`Search Resources`}
          value={search}
          InputProps={{
            style: {
              paddingLeft: 10,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search size={15} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <IconButton onClick={() => setSearch('')}>
                    <X size={13} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(slugifyString({ str: e.target.value }))}
        />
      )}
      <StyledAccordionDetails ref={outerRef}>
        <div ref={innerRef}>
          {items.map(({ index, measureRef }) => {
            const link = getLink(affectedResources[index]?.resourceName)
            return (
              <AffectedResourceRow
                key={`activities-affected-resources-${index}`}
                to={link}
                underline="none"
                ref={measureRef}
              >
                <IconAwsLambda size={12} />
                <Typography variant="textSecondary">
                  {affectedResources[index]?.resourceName}
                </Typography>
                <Box
                  sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginLeft: 'auto' }}
                >
                  <Typography variant="textSecondary" color="text.secondary">
                    {affectedResources[index]?.occurrence || 1}{' '}
                    {pluralize('occurrences', parseInt(affectedResources[index]?.occurrence || 1))}
                  </Typography>
                  <ExternalLink
                    size={12}
                    className="affected-resources-external-link"
                    onClick={(e) => {
                      e?.preventDefault()
                      window.open(link)
                    }}
                  />
                </Box>
              </AffectedResourceRow>
            )
          })}
        </div>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: '0',
  margin: '0',
  position: 'relative',
  '&:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRight size={14} />} {...props} />
))(({ theme }) => ({
  minHeight: '40px',

  padding: '0 0 0 20px',
  margin: '0',
  flexDirection: 'row-reverse',
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& svg': {
    color: theme.palette.text.secondary,
  },
  '& p': {
    fontSize: '13px',
    fontFamily: "'Roboto Mono', monospace",
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 6px',
  },
}))

const StyledAccordionDetails = styled('div')(({ theme }) => ({
  height: '55vh',
  width: '100%',
  padding: '20px',
  overflowX: 'hidden',
  overflowY: 'auto',
}))
const AffectedResourceRow = memo(
  styled(Link)(({ theme }) => ({
    padding: '10px 20px',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.border.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    minHeight: '45px',
    marginBottom: '10px',
    transition: 'all .3s ease-in-out',
    '& .affected-resources-external-link': {
      transition: 'all .3s ease-in-out',
      opacity: 0,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      '& .affected-resources-external-link': {
        opacity: 1,
      },
    },
  }))
)
