import { Box, ButtonGroup, Tooltip } from '@mui/material'
import Button from 'common/components/Button'
import { useTheme } from '@mui/styles'
import { HelpCircle } from 'lucide-react'

const features = [
  {
    value: 'none',
    label: 'None',
    compatible: () => true,
    description: 'Disables all Console features. No data will be ingested.',
  },
  {
    value: 'dev',
    label: 'Dev',
    compatible: ({ canUseDev }) => canUseDev,
    disabledTooltip: 'This feature is not available for this runtime.',
    description:
      'Enables all Console features, including Dev Mode which streams Logs & Traces in real-time. Traces are not sampled. Everything is ingested.',
  },
  {
    value: 'prod',
    label: 'Prod',
    disabledTooltip: 'This feature is not available for this runtime.',
    compatible: ({ canUseProd }) => canUseProd,
    description:
      'Enables all Console features, except Dev Mode. Traces are automatically sampled at 20%, unless they include Errors & Warnings.',
  },
]

const getTooltip = (description) => (
  <Tooltip title={description}>
    <Box
      sx={{
        color: 'text.secondary',
      }}
    >
      <HelpCircle size={13} />
    </Box>
  </Tooltip>
)

export const ModeSelect = ({ value, onChange, showTooltip, canUseProd, canUseDev }) => {
  const theme = useTheme()

  return (
    <ButtonGroup sx={{ width: '100%' }}>
      {features?.map((feature) => {
        const disabled = !feature.compatible({ canUseProd, canUseDev })
        let endIcon = null
        if (showTooltip) {
          endIcon = getTooltip(feature.description)
        } else if (disabled) {
          endIcon = getTooltip(feature.disabledTooltip)
        }
        return (
          <Button
            key={`resources-feature-${feature?.value}`}
            variant="contained"
            size="small"
            color={value === feature?.value && !disabled ? 'primary' : 'secondary'}
            onClick={(e) => (disabled ? null : onChange(feature.value))}
            endIcon={endIcon}
            disabled={disabled}
            sx={{
              fontSize: theme.typography.textTertiary.fontSize,
              padding: '2.5px 5px',
              height: '25px',
              minWidth: '33%',
              width: 'auto',
              flex: 1,
            }}
          >
            {feature?.label}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
