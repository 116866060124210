import React, { useContext, useState, useEffect } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  IconButton,
  Divider,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { MetricsContext } from 'metrics/context/MetricsContext'

import { Loading } from 'common/components/Loading'
import { Button } from 'common/components/Button'
import { Plus, Trash } from 'lucide-react'
import { useTheme } from '@mui/styles'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { sortBy } from 'lodash'

const Loader = () => (
  <Box height="200px" width="100%" display="flex" alignItems="center" justifyContent="center">
    <Loading size={40} spinnerStyles={{ margin: 'auto' }} />
  </Box>
)

export const MetricsViewDropdownList = ({ showCreate, showDelete, setDropdown }) => {
  const theme = useTheme()
  const { metricsView, listMetricsViews, navigateToMetricsView } = useContext(MetricsContext)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [metricsViews, setMetricsViews] = useState([])

  /**
   * Fetches Metrics Views from the API, adds static views, and icons
   * @returns
   */
  const listMetricsViewsWrapper = async () => {
    let records
    try {
      records = await listMetricsViews()
    } catch (error) {
      console.error(error)
      setErrorMessage('Sorry, something went wrong.  Please try again later.')
      setIsLoading(false)
      return
    }
    setMetricsViews(records)
    setIsLoading(false)
  }

  useEffect(async () => {
    await listMetricsViewsWrapper()
  }, [])

  // TODO: Create a proper error screen
  if (isLoading) return <Loader />

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          marginBottom: 2,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" component="h3">
            Metrics Views
          </Typography>

          <Button size={'small'} endIcon={<Plus />} onClick={showCreate}>
            Create View
          </Button>
        </Box>

        <Divider sx={{ borderBottomWidth: 2, margin: '20px 0 5px 0' }} />
      </Box>

      {!isLoading && <ErrorMessage message={errorMessage} />}

      {!metricsViews.length && (
        <Typography variant="textSecondary" color="text.secondary">
          No Metrics Views found.
        </Typography>
      )}

      <List>
        {sortBy(metricsViews, ({ createdAt }) => new Date(createdAt)).map(
          (metricsViewListItem, index) => {
            return (
              <StyledListItem
                key={index}
                button
                selected={metricsView?.id === metricsViewListItem?.id}
                onClick={(e) => {
                  e.stopPropagation()
                  if (metricsView?.id !== metricsViewListItem?.id) {
                    navigateToMetricsView(metricsViewListItem?.id)
                  }
                  setDropdown(null)
                }}
              >
                <ListItemIcon
                  style={{
                    margin: '0 -28px 5px 0',
                    padding: '0 0 0 0',
                    color: theme.palette.primary.main,
                  }}
                >
                  {metricsView?.icon && React.cloneElement(metricsView?.icon, { size: 14 }, null)}
                </ListItemIcon>
                <StyledListItemText primary={metricsViewListItem.name} />

                <ListItemSecondaryAction sx={{ display: 'flex', padding: '0' }}>
                  {!metricsViewListItem.isStatic && (
                    <IconButton
                      disabled={metricsViewListItem.isStatic}
                      aria-label="delete"
                      onClick={() => {
                        if (metricsViewListItem.isStatic) {
                          return
                        }
                        showDelete(metricsViewListItem)
                      }}
                    >
                      <Trash size={17} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </StyledListItem>
            )
          }
        )}
      </List>
    </>
  )
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: '0 0 2px 0',
  padding: '5px 0 5px 5px!important',
  borderRadius: '4px',
}))

const StyledListItemText = styled(ListItemText)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 0 0 0',
}))
