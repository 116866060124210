import { Box, Dialog, DialogContent, InputAdornment, TextField, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import CloseButton from 'common/components/CloseButton'
import { findIndex } from 'lodash'
import { createContext, useContext, useEffect, useState } from 'react'
import { AppContext } from 'app/context/AppContext'
import { Lock } from 'lucide-react'
import { coreApiClient } from 'util/coreApiClient'
import { useSnackbar } from 'notistack'
import { useSecretsDialog } from './Secrets'
import { CopyText } from 'common/components/CopyText'
import { types } from 'settings/util/secretTypes'

export const SecretContext = createContext()

const getSelectedTypeIndex = (secret) =>
  findIndex(
    types,
    (type) =>
      type.valueType === secret?.valueType &&
      type.provider?.providerType === secret?.provider?.providerType
  )
export const SecretsDialog = ({ selectedSecret, setSelectedSecret, refresh }) => {
  const { isOpen, setIsOpen } = useSecretsDialog()
  const { activeOrg } = useContext(AppContext)
  const [errorMessage, setErrorMessage] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  // const defaultView = secretOptions.parameter
  const selectedIndex = getSelectedTypeIndex(selectedSecret) || 0
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(selectedIndex)
  const [loadingSave, setLoadingSave] = useState(false)
  // const [secret, { set, setAll }] = useMap(selectedSecret ? selectedSecret : defaultView)
  const defaultSecret = {
    name: '',
    path: isOpen !== 'isNew' ? isOpen : '',
  }
  const initialSecret = selectedSecret || defaultSecret
  const [secret, setSecret] = useState(initialSecret)
  const orgId = activeOrg?.orgId

  useEffect(() => {
    setSelectedTypeIndex(selectedIndex !== -1 ? selectedIndex : 0)
    setSecret(initialSecret)
  }, [selectedSecret, isOpen])

  if (!isOpen) return null
  const closeDialog = () => {
    setIsOpen(undefined)
    setSelectedSecret(null)
    setSecret({})
  }

  const save = async (data = {}) => {
    setLoadingSave(true)
    try {
      let successMessage = `Created secret successfully`
      let obj = {}
      /**
       * If selected secret then update, otherwise create
       */
      if (selectedSecret) {
        obj = {
          url: `/secrets/${orgId}/secrets/${secret?.uid}`,
          method: 'patch',
          data: {
            ...data,
            uid: selectedSecret?.uid,
          },
        }
        successMessage = `Updated secret successfully`
      } else {
        obj = {
          method: 'post',
          data,
        }
      }
      await coreApiClient({
        url: `/secrets/${orgId}/secrets`,
        ...obj,
      })
      refresh()
      closeDialog()
      enqueueSnackbar(successMessage, {
        autoHideDuration: 2000,
      })
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err?.message || 'Something went wrong')
    }
    setLoadingSave(false)
  }

  return (
    <SecretContext.Provider
      value={{
        refresh,
        setSelectedSecret,
        selectedSecret,
        setIsOpen,
        isOpen,
        enqueueSnackbar,
        orgId,
        errorMessage,
        setErrorMessage,
        secret,
        // set,
        // setAll,
        setSecret,
        closeDialog,
        loadingSave,
        save,
        selectedTypeIndex,
        setSelectedTypeIndex,
        // isSaveDisabled,
      }}
    >
      <SecretsDialogContent />
    </SecretContext.Provider>
  )
}

const SecretsDialogContent = () => {
  const {
    isOpen,
    selectedSecret,
    setSelectedTypeIndex,
    setErrorMessage,
    secret,
    setSecret,
    selectedTypeIndex,
    closeDialog,
  } = useContext(SecretContext)

  /**
   * Reset error message on alert values change
   */
  useEffect(() => {
    setErrorMessage('')
  }, [secret])
  const slugifyString = (str = '') => {
    let newString = str
      .replace(/[^a-zA-Z0-9_/.^]+/g, '-') // Replace any run of disallowed chars with a hyphen
      .replace(/^-+/, '') // remove leading hyphens

    return newString
  }

  if (!isOpen) return null
  return (
    <Dialog
      open={!!isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <Header>
        <Box sx={{ marginRight: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '10px',
            }}
          >
            <Lock size={20} />
            <Typography variant="h2">{selectedSecret ? 'Update' : 'Create'} Secret</Typography>
          </Box>
          <SubLabel>Manage secrets, parameters, and providers</SubLabel>
        </Box>
        <CloseButton size="large" onClick={closeDialog} aria-label="close" />
      </Header>

      <StyledDialogContent>
        <Box>
          <LabelWrapper>
            <Label>Secret Type</Label>
            <SubLabel>Select secret type</SubLabel>
          </LabelWrapper>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${types?.length},1fr)`,
              gap: '10px',
            }}
          >
            {types?.map((type, idx) => {
              const { icon, label, description, disabled } = type
              return (
                <SecretTypes
                  is-selected={selectedTypeIndex === idx && 'true'}
                  key={`alerts-notifications-types-${idx}`}
                  onClick={() => setSelectedTypeIndex(idx)}
                  disabled={!!disabled || !!selectedSecret}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {icon}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="textSecondary">{label}</Typography>
                    <Typography variant="textTertiary" color="text.secondary">
                      {description}
                    </Typography>
                  </Box>
                </SecretTypes>
              )
            })}
          </Box>
        </Box>
        <Box>
          <LabelWrapper>
            <Label>Namespace</Label>
          </LabelWrapper>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Box>
              <LabelWrapper>
                <SubLabel>Path</SubLabel>
              </LabelWrapper>
              <TextField
                autoFocus
                value={secret?.path}
                onChange={(e) =>
                  setSecret((prev) => ({ ...prev, path: slugifyString(e.target.value) }))
                }
                placeholder="foo/bar"
                InputProps={{
                  /**
                   * Show copy icon if secret already exists and there is a 'path' value
                   */
                  endAdornment: secret?.path && selectedSecret && (
                    <InputAdornment position="start">
                      <CopyText value={secret?.path} iconSize={15} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* <Typography
              variant="h1"
              color="text.secondary"
              sx={{ margin: '30px auto 0 auto', fontSize: '2rem' }}
            >
              /
            </Typography> */}
            <Box>
              <LabelWrapper>
                <SubLabel>Name</SubLabel>
              </LabelWrapper>
              <TextField
                value={secret?.name}
                onChange={(e) =>
                  setSecret((prev) => ({ ...prev, name: slugifyString(e.target.value) }))
                }
                placeholder="default"
                InputProps={{
                  /**
                   * Show copy icon if secret already exists and there is a 'name' value
                   */
                  endAdornment: secret?.name && selectedSecret && (
                    <InputAdornment position="start">
                      <CopyText value={secret?.name} iconSize={15} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        {types[selectedTypeIndex]?.getComponent()}
      </StyledDialogContent>
    </Dialog>
  )
}

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingBottom: '25px',
  gap: '10px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
  padding: '20px 30px',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.secondary.main,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  height: '70vh',
}))

export const LabelWrapper = styled('div')(({ theme }) => ({
  marginBottom: '8px',
}))

export const Label = styled((props) => <Typography variant="textPrimary" {...props} />)(
  ({ theme }) => ({})
)
export const SubLabel = styled((props) => (
  <Typography variant="textSecondary" color="text.secondary" {...props} />
))(({ theme }) => ({}))

const SecretTypes = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  padding: '10px 5px',
  display: 'grid',
  gridTemplateColumns: '0.7fr 2fr',
  cursor: 'pointer',
  position: 'relative',
  transition: 'all .1s ease-in-out',
  '&:hover:not([disabled]), &[is-selected]': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '&[disabled]:not([is-selected])': {
    cursor: 'default',
    color: theme.palette.text.secondary,
    pointerEvents: 'none',
  },
}))
