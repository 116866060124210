import React from 'react'
import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { IconServerlessConsole } from '../icons/IconServerlessConsole'
import { merge } from 'lodash'

/**
 * Simple bolt + label content to show as an empty state for
 * various pages
 */
export const EmptyState = ({ color = '#d4d4d4', label, subLabel, actions, sx = {}, ...rest }) => (
  <Box
    component={motion.div}
    sx={merge(
      {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '30px',
        height: '100%',
        color: 'text.secondary',
      },
      sx
    )}
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1, transition: { duration: 0.25, ease: 'easeOut' } }}
    {...rest}
  >
    <IconServerlessConsole size={70} />
    {label && (
      <Typography variant="h4" textAlign="center" maxWidth="600px" px={2} lineHeight={1}>
        {label}
      </Typography>
    )}
    {subLabel && (
      <Typography variant="textPrimary" textAlign="center" maxWidth="600px" px={2} lineHeight={1}>
        {subLabel}
      </Typography>
    )}

    {actions}
  </Box>
)
