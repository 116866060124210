import {
  Box,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  linearProgressClasses,
} from '@mui/material'
import { useOrgUsage } from 'common/hooks/useOrgUsage'

import { useContext } from 'react'
import { AppContext } from 'app/context/AppContext'
import { styled } from '@mui/styles'
import { number } from './billingUtils'

export const UsageBars = ({ titleColumn = false }) => {
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}
  const { orgUsage, orgSubscription, loadingUsage, enrichTier } = useOrgUsage({ orgId })

  const usage = {
    traces: orgSubscription?.tier || 0,
    metrics: enrichTier(orgSubscription?.tier)?.metrics,
  }
  return (
    <Box>
      {loadingUsage ? (
        <>
          <Skeleton width="20%" height="20px" />
          <Skeleton width="100%" height="20px" sx={{ mb: 2 }} />
          <Skeleton width="20%" height="20px" />
          <Skeleton width="100%" height="20px" sx={{ mb: 2 }} />
        </>
      ) : (
        <>
          <Stack
            direction={titleColumn ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4">Traces</Typography>
            <Typography variant="h4">
              {orgUsage?.traces?.toLocaleString()} / {number(usage.traces)}
            </Typography>
          </Stack>
          <BorderLinearProgress
            variant="determinate"
            value={(orgUsage?.traces / usage.traces) * 100}
          />
          <Stack
            direction={titleColumn ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4">Metrics</Typography>
            <Typography variant="h4">
              {orgUsage?.metrics?.toLocaleString()} / {number(usage.metrics)}
            </Typography>
          </Stack>

          <BorderLinearProgress
            variant="determinate"
            value={(orgUsage?.metrics / usage.metrics) * 100}
          />
        </>
      )}
    </Box>
  )
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 4,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
  },
  marginBottom: 20,
}))
