import React, { memo, useContext, useMemo, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import numeral from 'numeral'
import CustomizedTicks from './CustomizedTicks'
import CustomTooltip from './CustomTooltip'
import { useTheme } from '@mui/styles'
import { typography } from 'theme/typography'
import { motion } from 'framer-motion'
import { isNumber, random, range } from 'lodash'
import { useQuery } from 'metrics/hooks/useQuery'
import { FilterContext } from 'filter/context/FilterContext'
import { Box, Skeleton } from '@mui/material'
import ChartHeader from './ChartHeader'
import { formatNumber, getRectanglePath } from '../helpers/graph-helpers'
import InvocationsLegend from './InvocationsLegend'
import ErrorState from 'common/components/ErrorState'
import { useMetricsCount } from 'metrics/hooks/useMetricsCount'
import { useLocation, useNavigate } from 'react-router-dom'
import { stringifyUrl } from 'query-string'
import { AppContext } from 'app/context/AppContext'
import CustomResponsiveContainer from './CustomResponsiveContainer'
import { getIntervalDate } from '../helpers/getIntervalDate'

const minPointSize = 10
const tooltipWidth = 340
const tooltipHeight = 56

const maxBarSize = 10

/**
 * Skeleton loading based on items count in page
 */
const SkeletonLoading = ({ size = 10, height } = {}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      margin: '20px 20px 20px 50px',
      height: height - 20,
    }}
  >
    {range(size)?.map((num) => (
      <Box
        key={`metrics-request-skeleton-loading-${num}`}
        component={motion.div}
        initial={{ y: 5, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
        }}
      >
        <Skeleton variant="rect" width={maxBarSize} height={height - random(10, 80)} />
      </Box>
    ))}
  </Box>
)

const enrichData = (data) => {
  return data?.results?.map((item) => {
    const ok = item.invocations - item.uncaught_errors || 0
    const events = item.caught_errors + item.warnings + item.sdk_user_errors || 0
    return {
      ...item,
      ok,
      events,
    }
  })
}

const renderShape =
  ({ valueKey, hoverColor, focusedBarIndex, errorBarColor, okBarColor, eventsBarColor, onClick }) =>
  (props) => {
    let { x, y, width, height, className, radius, index, uncaught_errors, ok, invocations } = props

    /**
     * Handle bar color
     */
    let eventsFill = eventsBarColor
    let errorsFill = errorBarColor
    let okFill = okBarColor
    const isFocusedOnOthers = isNumber(focusedBarIndex) && focusedBarIndex !== index

    if (isFocusedOnOthers) {
      eventsFill = hoverColor
      errorsFill = hoverColor
      okFill = hoverColor
    }

    if (valueKey === 'events') {
      return (
        <path
          fill={eventsFill}
          className={`recharts-rectangle-${className}`}
          d={getRectanglePath(x, y, width, height, radius)}
        />
      )
    }
    if (props[valueKey] === 0) {
      height = 0
    }
    /**
     * Different cases:
     * 1. Both values are smaller than the minimum.
     * 2. Only errors bar is smaller than the minimum.
     * 3. Only ok bar is smaller than the minimum.
     * 4. Only have errors.
     * 5. Only have invocations.
     */
    let errorsBarHeight = uncaught_errors ? height * (uncaught_errors / invocations) : 0
    let okBarHeight = ok ? height * (ok / invocations) : 0
    const separatorHeight = 2
    let offset = 0
    const errorsBar = {
      y,
      height: errorsBarHeight,
      radius: ok ? [0, 0, 10, 10] : radius,
      fill: errorsFill,
    }
    const okBar = {
      y,
      height: okBarHeight,
      radius: uncaught_errors ? [10, 10, 0, 0] : radius,
      fill: okFill,
    }

    if (errorsBarHeight && errorsBarHeight < minPointSize) {
      errorsBar.height = minPointSize
    }
    if (okBarHeight && okBarHeight < minPointSize) {
      okBar.height = minPointSize
    }
    if (
      errorsBarHeight &&
      errorsBarHeight < minPointSize &&
      okBarHeight &&
      okBarHeight < minPointSize
    ) {
      offset = errorsBar.height
    } else if (errorsBarHeight && errorsBarHeight < minPointSize) {
      offset = minPointSize - errorsBarHeight
    } else if (okBarHeight && okBarHeight < minPointSize) {
      offset = minPointSize - okBarHeight
    } else {
      offset = 0
    }
    okBar.y = okBar.y - separatorHeight - offset
    errorsBar.y = errorsBar.y + okBar.height - offset

    return (
      <>
        {!!okBar.height && (
          <>
            {/* Invisible bar to make clickable area bigger and easier to click*/}
            <path
              onClick={() => onClick({ data: props, index, isError: false })}
              style={{
                cursor: 'pointer',
              }}
              fillOpacity="0"
              className={`recharts-rectangle-${className}`}
              d={getRectanglePath(x - 10, okBar.y, width + 20, okBar.height, okBar.radius)}
            />
            {/* Successful bar */}
            <path
              onClick={() => onClick({ data: props, index, isError: false })}
              style={{
                cursor: 'pointer',
                transition: 'all 1s easeInOut',
              }}
              fill={okBar.fill}
              className={`recharts-rectangle-${className}`}
              d={getRectanglePath(x, okBar.y, width, okBar.height, okBar.radius)}
            />
          </>
        )}
        {!!errorsBar.height && (
          <>
            {/* Invisible bar to make clickable area bigger and easier to click*/}
            <path
              onClick={() => onClick({ data: props, index, isError: true })}
              style={{
                cursor: 'pointer',
              }}
              fillOpacity="0"
              className={`recharts-rectangle-${className}`}
              d={getRectanglePath(
                x - 10,
                errorsBar.y,
                width + 20,
                errorsBar.height,
                errorsBar.radius
              )}
            />
            {/* Uncaught errors bar */}
            <path
              onClick={() => onClick({ data: props, index, isError: true })}
              style={{
                cursor: 'pointer',
                transition: 'all 1s easeInOut',
              }}
              fill={errorsBar.fill}
              className={`recharts-rectangle-${className}`}
              d={getRectanglePath(x, errorsBar.y, width, errorsBar.height, errorsBar.radius)}
            />
          </>
        )}
      </>
    )
  }

function InvocationsGraph({ showRefreshButton, useCustomContainer, height = 180 }) {
  const theme = useTheme()
  const { activeOrg } = useContext(AppContext)
  const { orgName } = activeOrg
  const { currentTimeFrame, getFilterValue } = useContext(FilterContext)
  const [focusedBarIndex, setFocusedBarIndex] = useState(null)

  const ChartContainer = useCustomContainer ? CustomResponsiveContainer : ResponsiveContainer

  const { data, isValidating, refresh, error, loading } = useQuery({
    query: 'aws_lambda_invocations',
  })

  const location = useLocation()
  const navigate = useNavigate()

  const { successRateFormatted, invocations, uncaughtErrors } = useMetricsCount(data?.results)

  const invocationsData = useMemo(() => enrichData(data), [data])

  const errorBarColor = theme.palette.error.main
  const okBarColor = theme.palette.border.light
  const eventsBarColor = theme.palette.grey.main
  const eventsBarHoverColor = theme.palette.grey.dark
  const labelColor = theme.palette.text.secondary
  let interval = invocationsData?.length >= 10 ? 1 : 0

  const getOkLink = (timeframe) =>
    stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        globalTimeFrame: timeframe || getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })
  const getErrorLink = (timeframe) =>
    stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        awsLambdaEvents: ['ERROR_TYPE_UNCAUGHT'],
        globalTimeFrame: timeframe || getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        globalScope: 'awsLambda',
      },
    })
  const headingStats = [
    {
      value: formatNumber(invocations),
      description: 'Total invocations',
      link: getOkLink(),
    },
    {
      value: formatNumber(uncaughtErrors),
      description: 'Total Uncaught errors',
      isError: true,
      link: getErrorLink(),
    },
    {
      value: successRateFormatted,
      description: 'Success rate',
    },
  ]

  const onBarClick = (props) => {
    const { startDate, endDate } = getIntervalDate({
      currentDate: props.data.time,
      index: props.index,
      data: invocationsData,
    })
    const okLink = getOkLink(`${startDate?.getTime()},${endDate?.getTime()}`)
    const errorLink = getErrorLink(`${startDate?.getTime()},${endDate?.getTime()}`)

    navigate(!props.isError ? okLink : errorLink)
  }

  return (
    <Box
      sx={{
        minHeight: height + 100,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ChartHeader
        title="Invocations & Events"
        description="AWS Lambda Successful Invocations and Uncaught Errors are in the foreground. Other Events such as Caught Errors and Warnings are in the background"
        stats={headingStats}
        onRefresh={showRefreshButton && refresh}
        loading={loading}
        isValidating={isValidating}
        error={error}
      />

      <Box
        sx={{
          minHeight: height,
        }}
      >
        {loading || isValidating ? (
          <SkeletonLoading size={30} height={height} />
        ) : error ? (
          <ErrorState onReload={refresh} isColumnDirection fullWidth />
        ) : (
          <ChartContainer width="100%" height={height}>
            <BarChart
              data={invocationsData}
              margin={{ top: 10 }}
              onMouseMove={(state) => {
                if (state.activeTooltipIndex >= 0) {
                  setFocusedBarIndex(state.activeTooltipIndex)
                } else {
                  setFocusedBarIndex(null)
                }
              }}
              onMouseLeave={() => setFocusedBarIndex(null)}
            >
              <Tooltip
                content={
                  <CustomTooltipComponent
                    focusedBarIndex={focusedBarIndex}
                    data={invocationsData}
                  />
                }
                cursor={{ fill: theme.palette.secondary.main }}
                wrapperStyle={{
                  outline: 'none',
                  top: -(tooltipHeight * 2 - 22),
                }}
              />
              <XAxis
                dataKey="time"
                axisLine={false}
                tickLine={false}
                allowDataOverflow={false}
                tickMargin={5}
                height={45}
                interval={interval}
                xAxisId={0}
                tick={
                  <CustomizedTicks
                    labelColor={labelColor}
                    currentTimeFrame={currentTimeFrame}
                    dy={15}
                  />
                }
              />
              {/* We need two xAxis to make invocations and events bars overlap */}
              <XAxis dataKey="time" xAxisId={1} hide />
              <YAxis
                width={40}
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                tick={{ fontSize: typography.textTertiary.fontSize, fill: labelColor }}
                tickFormatter={(value) => numeral(value || 0).format('0a')}
                yAxisId="left"
                orientation="left"
                dataKey="invocations"
              />
              <YAxis
                width={40}
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                tick={{ fontSize: typography.textTertiary.fontSize, fill: labelColor }}
                tickFormatter={(value) => numeral(value || 0).format('0a')}
                yAxisId="right"
                orientation="right"
                dataKey="events"
              />
              <Bar
                dataKey="events"
                stackId="a"
                radius={[4, 4, 4, 4]}
                maxBarSize={maxBarSize + 20}
                yAxisId="right"
                xAxisId={1}
                shape={renderShape({
                  valueKey: 'events',
                  hoverColor: eventsBarHoverColor,
                  eventsBarColor,
                  focusedBarIndex,
                })}
              />

              <Bar
                dataKey="invocations"
                stackId="a"
                fill={okBarColor}
                radius={[10, 10, 10, 10]}
                maxBarSize={maxBarSize}
                minPointSize={minPointSize}
                yAxisId="left"
                xAxisId={0}
                shape={renderShape({
                  valueKey: 'invocations',
                  hoverColor: eventsBarColor,
                  errorBarColor,
                  okBarColor,
                  focusedBarIndex,
                  onClick: onBarClick,
                })}
              />
            </BarChart>
          </ChartContainer>
        )}
      </Box>
      <InvocationsLegend />
    </Box>
  )
}

const CustomTooltipComponent = ({ active, payload, focusedBarIndex, data }) => {
  const invocationsPayload = payload?.find((i) => i.dataKey === 'invocations')?.payload

  const {
    invocations,
    okInvocations,
    uncaughtErrors,
    caughtErrors,
    warnings,
    sdkErrors,
    sdkWarnings,
  } = useMetricsCount([invocationsPayload])

  if (!active || !payload?.length) return null

  const formatter = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  })

  const { startDate, endDate } = getIntervalDate({
    currentDate: invocationsPayload?.time,
    index: focusedBarIndex,
    formatString: 'MMMM dd h:mmaa',
    data,
  })

  const title = `${startDate} - ${endDate}`
  const items = [
    {
      title: 'Invocations',
      value: formatter.format(invocations),
      isError: !okInvocations,
    },
    {
      title: 'Successful',
      value: formatter.format(okInvocations),
    },
    {
      title: 'Uncaught Errors',
      value: formatter.format(uncaughtErrors),
      isError: true,
    },

    {
      title: 'Caught Errors',
      value: formatter.format(caughtErrors),
      isError: true,
    },
    {
      title: 'Warnings',
      value: formatter.format(warnings),
    },
    {
      title: 'SDK Errors',
      value: formatter.format(sdkErrors),
    },
    {
      title: 'SDK Warnings',
      value: formatter.format(sdkWarnings),
    },
  ]
  return <CustomTooltip width={tooltipWidth} title={title} items={items} />
}

export default memo(InvocationsGraph)
