import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  intlFormat,
  isSameDay,
  subDays,
  subHours,
  subMinutes,
} from 'date-fns'

const setInterval = ({ startTime, stopTime }) => {
  let interval = '24h'
  const differences = getDifferenceInTimes(startTime, stopTime)

  // '5m'
  if (differences.hours < 1 && differences.minutes <= 5) {
    interval = {
      number: 1,
      unit: 'minute',
    }
  }
  // '10m'
  else if (differences.hours < 1 && differences.minutes <= 10) {
    interval = {
      number: 1,
      unit: 'minute',
    }
  }
  // '15m'
  else if (differences.hours < 1 && differences.minutes <= 15) {
    interval = {
      number: 1,
      unit: 'minute',
    }
  }
  // '1h' (difference could be 59 or 60 mins)
  else if (
    (differences.hours < 1 && differences.minutes <= 60) ||
    (differences.hours >= 1 && differences.hours <= 2)
  ) {
    interval = {
      number: 5,
      unit: 'minute',
    }
  }
  // '24h'
  else if (differences.hours >= 1 && differences.hours <= 24) {
    interval = {
      number: 1,
      unit: 'hour',
    }
  }
  // '7d'
  else if (differences.days >= 1 && differences.days <= 7) {
    interval = {
      number: 1,
      unit: 'day',
    }
  }
  // '30d'
  else if (differences.days >= 1 && differences.days <= 30) {
    interval = {
      number: 1,
      unit: 'day',
    }
  }

  return interval
}
export const getTimeFrame = (timeframe = '') => {
  const splitTime = timeframe?.split(',')
  const now = new Date()

  let startTime = subDays(now, 1).toISOString()
  let stopTime = now.toISOString()
  let interval = {
    number: 1,
    unit: 'hour',
  }

  if (splitTime?.length === 2) {
    startTime = new Date(parseInt(splitTime[0])).toISOString()
    stopTime = new Date(parseInt(splitTime[1])).toISOString()
  } else if (timeframe === '1m') {
    startTime = subMinutes(now, 1).toISOString()
  } else if (timeframe === '5m') {
    startTime = subMinutes(now, 5).toISOString()
  } else if (timeframe === '15m') {
    startTime = subMinutes(now, 15).toISOString()
  } else if (timeframe === '1h') {
    startTime = subHours(now, 1).toISOString()
  } else if (timeframe === '24h') {
    startTime = subHours(now, 24).toISOString()
  } else if (timeframe === '7d') {
    startTime = subDays(now, 7).toISOString()
  } else if (timeframe === '30d') {
    startTime = subDays(now, 30).toISOString()
  }
  interval = setInterval({ startTime, stopTime })
  return {
    startTime,
    stopTime,
    interval,
  }
}

/**
 * Get day, hour and minute differences of a timeframe
 * @param {*} startTime
 * @param {*} stopTime
 */
export const getDifferenceInTimes = (startTime, stopTime) => {
  const days = differenceInDays(new Date(stopTime), new Date(startTime))
  const hours = differenceInHours(new Date(stopTime), new Date(startTime))
  const minutes = differenceInMinutes(new Date(stopTime), new Date(startTime))

  return {
    days,
    hours,
    minutes,
  }
}

export const getFormattedTimeFrameRange = (timeFrame) => {
  const { startTime, stopTime } = getTimeFrame(timeFrame)
  const startDate = new Date(startTime)
  const stopDate = new Date(stopTime)
  const options = isSameDay(startDate, stopDate)
    ? { hour: 'numeric', minute: 'numeric' }
    : { month: 'short', day: '2-digit' }

  return `${intlFormat(startDate, options)} - ${intlFormat(stopDate, options)}`
}
