import { Popper } from '@mui/material'
import { styled } from '@mui/styles'
import { useEffect, useState } from 'react'

import { debounce } from 'lodash'

export const CustomPopper = ({ tooltipAnchor }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipArrowRef, setTooltipArrowRef] = useState()

  const debouncedHandleMouseEnter = debounce(() => {
    setShowTooltip(true)
  }, 1000)
  useEffect(() => {
    if (tooltipAnchor?.anchorEl) {
      debouncedHandleMouseEnter()
    } else {
      setShowTooltip(false)
      debouncedHandleMouseEnter.cancel()
    }
  }, [tooltipAnchor?.anchorEl])

  // const openPopper = Boolean(contextMenu?.anchor)
  if (!showTooltip || !tooltipAnchor?.anchorEl) return null
  return (
    <StyledPopper
      open={showTooltip && !!tooltipAnchor?.anchorEl}
      anchorEl={showTooltip && tooltipAnchor?.anchorEl}
      onClick={(e) => e?.stopPropagation()}
      placement="top"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: tooltipArrowRef,
          },
        },
      ]}
    >
      <PopperArrow ref={setTooltipArrowRef} />

      {tooltipAnchor?.label}
    </StyledPopper>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  borderRadius: 4,
  zIndex: theme.zIndex.modal,
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  padding: '5px 10px',
  fontWeight: 'bold',
  fontSize: theme.typography.textSecondary.fontSize,
}))

export const PopperArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  fontSize: 14,
  top: '90% !important',
  zIndex: 100,
  left: 7,
  height: '4px',
  width: '2px',
  marginBottom: '32px',

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0.4em 0.4em 0.4em 0',
    transform: 'rotateZ(-90deg)',
    borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
  },
}))
