export const colors = {
  redPrimary: '#FD5750',
  redLight: '#FF746F',
  redDark: '#C4413C',
  blackDark: '#000000',
  blackMedium: '#151515',
  blackMediumLight: '#222222',
  blackLight: '#444444',
  blackUltraLight: '#666666',
  greyUltraDark: '#7C7C7C',
  greyDark: '#C7C7C7',
  greyMedium: '#DDDDDD',
  greyLight: '#F8F8F8',
  white: '#FFFFFF',
}
