import { Box, Checkbox as MuiCheckbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { HelpCircle } from 'lucide-react'
import React from 'react'

/**
 * Simply a material-ui Checkbox, but with an additional styling
 */

export const Checkbox = ({
  label,
  onChange = () => {},
  checked,
  disabled = false,
  tooltipMessage,
  labelColor = 'text.secondary',
  textVariant = 'textTertiary',
  ...rest
}) => {
  const theme = useTheme()

  return (
    <FormControlLabel
      sx={{
        margin: '0',
      }}
      control={
        <MuiCheckbox
          /**
           * Only include checked if it's provided from props
           */
          {...(typeof checked === 'boolean' ? { checked } : {})}
          disabled={disabled}
          size="small"
          onChange={onChange}
          {...rest}
        />
      }
      label={
        <Box display="flex" alignItems="center">
          <Typography variant={textVariant} color={labelColor}>
            {label}
          </Typography>
          {tooltipMessage && (
            <Tooltip title={tooltipMessage} placement="top">
              <HelpCircle
                style={{ marginLeft: 6, color: theme.palette.text.secondary }}
                size={14}
              />
            </Tooltip>
          )}
        </Box>
      }
    />
  )
}
