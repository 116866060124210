import { Paper, styled } from '@mui/material'
import { FilterProvider } from 'filter/context/FilterContext'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { DevModeProvider } from '../context/DevMode.context'
import { Header } from './Header'
import { ActivityStream } from './ActivityStream'

/**
 * Dev Mode - Real-time cloud feedback.
 *
 * A few things to note before working on this feature:
 *
 * Use "Activity" rather than "Logs".
 *
 * Historical Activity is currently not supported and is in an outdated and incomplete state.
 * Meanwhile the code for Streaming Activity is current.
 * The code reflects this fractured state of things unfortunately at the moment.
 *
 * AVOID making changes, transformations, enrichments to the Activity data in this Client/UI.
 * We want Dev Mode to be plugged into other tools/UIs.
 * So, ALL fancy data enhancements should be done on the back-end.
 * FAT server, THIN client is what we're going for.
 * Time spent improving the data in this client reduces business value.
 * Avoid it as much as you can.
 *
 * Dev Mode handles a lot of data, and performance is a real concern.
 * We should embrace raw javascript and stop using abstraction libraries with bad performance (Material UI, CSS-in-JS, Motion).
 *
 */

const DevMode = () => {
  usePageTitle('Dev Mode')
  return (
    <FilterProvider page="devMode">
      <DevModeProvider>
        <ContainedPaper elevation={0}>
          <Header />
          <ActivityStream />
        </ContainedPaper>
      </DevModeProvider>
    </FilterProvider>
  )
}

const ContainedPaper = styled(Paper)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
})

export default DevMode
