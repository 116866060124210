import { createContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { coreApiClient } from 'util/coreApiClient'

export const TeamContext = createContext()

export const TeamProvider = ({ orgId, isOwner, children }) => {
  const [removingInvite, setRemovingInvite] = useState({})
  const [updatingRoles, setUpdatingRoles] = useState({})
  const { enqueueSnackbar } = useSnackbar()
  const [pendingInviteState, setPendingInviteState] = useState({
    initialLoading: true,
    pendingInvites: [],
  })

  const [teamMemberState, setTeamMemberState] = useState({
    initialLoading: true,
    teamMembers: [],
  })

  const getPendingInvites = async () => {
    try {
      const query = `?${new URLSearchParams({
        orgId,
        ...(pendingInviteState.startKey || {}),
      })}`
      const res = await coreApiClient.get(`/identity/invitations${query}`)
      if (!res) throw new Error('Something went wrong...')
      const { pendingInvites: invites, lastKey } = res

      setPendingInviteState({
        ...pendingInviteState,
        initialLoading: false,
        pendingInvites: [...pendingInviteState.pendingInvites, ...invites],
        startKey: { startKey: JSON.stringify(lastKey) },
      })
    } catch (error) {
      enqueueSnackbar('Could not get pending invites', {
        variant: 'error',
        autoHideDuration: 5000,
      })

      setPendingInviteState({
        ...pendingInviteState,
        initialLoading: false,
      })
    }
  }

  const removePendingInvite = async ({ token }) => {
    setRemovingInvite({
      ...removingInvite,
      [token]: true,
    })
    try {
      await coreApiClient.delete(`/identity/invitations/${token}`)
      setPendingInviteState({
        ...pendingInviteState,
        pendingInvites: pendingInviteState.pendingInvites.filter(
          (invites) => invites.token !== token
        ),
      })
    } catch (error) {
      enqueueSnackbar('Failed to remove invite', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    } finally {
      setRemovingInvite({
        ...removingInvite,
        [token]: false,
      })
    }
  }

  const removeOrgMember = async ({ memberId }) => {
    try {
      await coreApiClient.delete(`/identity/orgs/${orgId}/members/${memberId}`)
      setTeamMemberState({
        ...teamMemberState,
        teamMembers: teamMemberState.teamMembers.filter(
          (teamMember) => teamMember.memberId !== memberId
        ),
      })
    } catch (error) {
      enqueueSnackbar('Failed to remove org member', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    }
  }

  const updateOrgMember = async ({ memberId, memberRole }) => {
    setUpdatingRoles({
      ...updatingRoles,
      [memberId]: true,
    })
    try {
      const orgMember = await coreApiClient.patch(
        `/identity/orgs/${orgId}/members/${memberId}/role`,
        {
          memberRole,
        }
      )
      setTeamMemberState({
        ...teamMemberState,
        teamMembers: teamMemberState.teamMembers.map((teamMember) =>
          teamMember.memberId === memberId ? { ...teamMember, ...orgMember } : teamMember
        ),
      })
    } catch (error) {
      enqueueSnackbar('Failed to update org member', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    } finally {
      setUpdatingRoles({
        ...updatingRoles,
        [memberId]: false,
      })
    }
  }

  const getTeamMembers = async () => {
    const query = teamMemberState.startKey
      ? `?${new URLSearchParams(teamMemberState.startKey)}`
      : ''
    const res = await coreApiClient.get(`/identity/orgs/${orgId}/members${query}`)
    if (!res) return
    const { members, lastKey } = res

    setTeamMemberState({
      ...teamMemberState,
      initialLoading: false,
      teamMembers: [...teamMemberState.teamMembers, ...members],
      startKey: { startKey: JSON.stringify(lastKey) },
    })
  }

  useEffect(async () => {
    if (orgId && isOwner) {
      getPendingInvites()
      getTeamMembers()
    } else {
      getTeamMembers()
    }
  }, [orgId, isOwner])

  const moreInvites = () => {
    getPendingInvites()
  }

  const moreTeamMembers = () => {
    getTeamMembers()
  }

  const addInvite = (invite) => {
    setPendingInviteState({
      ...pendingInviteState,
      pendingInvites: [invite, ...pendingInviteState.pendingInvites],
    })
  }

  return (
    <TeamContext.Provider
      value={{
        pendingInviteState,
        teamMemberState,
        updatingRoles,
        removingInvite,
        moreInvites,
        moreTeamMembers,
        addInvite,
        removePendingInvite,
        removeOrgMember,
        updateOrgMember,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}
