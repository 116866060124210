import React, { useContext, useState } from 'react'
import { useKey } from 'react-use'
import { motion } from 'framer-motion'
import { styled, Box, Tooltip } from '@mui/material'
import { MetricsContext } from 'metrics/context/MetricsContext'
import { IconRefresh } from 'common/icons/IconRefresh'
import { MetricsViewDropdown } from 'metrics/components/Nav/MetricsViewDropdown'
import { FilterContext } from 'filter/context/FilterContext'
import { timeFrameDefaults } from 'filter/util/filters'
import { TimeFrameDropdown } from 'metrics/components/Nav/TimeFrameDropdown'
import { MetricsPoll } from 'metrics/components/Nav/MetricsPoll'
import { Typography } from '@mui/material'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { NavBarItem } from '../../../common/components/NavBarItem'
import { FiltersNavItem } from './FiltersNavItem'
import { getFormattedTimeFrameRange, getTimeFrame } from 'filter/util/time'

export const MetricsNav = () => {
  const { getFilterValue, currentTimeFrame, setCurrentTimeFrame, setAllFilterValues } =
    useContext(FilterContext)
  const { metricsView, refreshMetricsData, updateMetricsViewFilters } = useContext(MetricsContext)
  const [dropdown, setDropdown] = useState(null)
  const globalTimeFrame = getFilterValue('globalTimeFrame')
  const selectedTimeFrame = timeFrameDefaults.find(({ value }) => value === globalTimeFrame)
  const timeFrameLabel = selectedTimeFrame
    ? selectedTimeFrame.label
    : getFormattedTimeFrameRange(globalTimeFrame)

  // Disable if custom time frame
  const disableRefresh = !globalTimeFrame || globalTimeFrame.includes(',')

  // Listener to close dropdowns if escape key is used
  useKey('Escape', () => setDropdown(null))

  return (
    <NavBar
      initial={{ y: -40, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.2, ease: 'easeOut' } }}
    >
      <NavBarItem
        type="view"
        className={dropdown && dropdown !== 'view' ? `notSelected` : null}
        onClick={() => setDropdown('view')}
        sx={{ padding: '0 30px' }}
      >
        <Tooltip title={`Select Metrics View`} placement="bottom">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            {metricsView && React.cloneElement(metricsView?.icon, { size: 17 }, null)}

            <Typography variant="h2" marginLeft={2}>
              {metricsView?.name}
            </Typography>
            <DropdownIcon isOpen={dropdown === 'view'} />
          </Box>
        </Tooltip>

        {dropdown === 'view' && <MetricsViewDropdown setDropdown={setDropdown} />}
      </NavBarItem>

      <NavBarItem
        type="timeframe"
        data-cy="metrics-time-frame-label"
        className={dropdown && dropdown !== 'timeframe' ? `notSelected` : null}
        onClick={() => setDropdown('timeframe')}
      >
        <Tooltip title={`Adjust Timeframe`} placement="bottom">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <Typography variant="h3">{timeFrameLabel}</Typography>
            <DropdownIcon isOpen={dropdown === 'timeframe'} />
          </Box>
        </Tooltip>

        {dropdown === 'timeframe' && <TimeFrameDropdown setDropdown={setDropdown} />}
      </NavBarItem>

      <FiltersNavItem
        dropdown={dropdown}
        open={dropdown === 'filters'}
        setOpen={() => setDropdown('filters')}
        onCancel={() => {
          setDropdown(null)
        }}
        onSave={(newFilters) => {
          updateMetricsViewFilters(newFilters)
          setAllFilterValues(newFilters)
          setDropdown(null)
        }}
      />

      <Tooltip title={`Refresh Data`} placement="bottom">
        <NavBarItem
          disabled={disableRefresh}
          onClick={(e) => {
            if (!disableRefresh) {
              refreshMetricsData({ globalTimeFrame: getFilterValue('globalTimeFrame') })
              setCurrentTimeFrame(getTimeFrame(getFilterValue('globalTimeFrame')))
              setDropdown(null)
            }
          }}
          className={dropdown ? `notSelected` : null}
        >
          <Box
            component={motion.div}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            key={currentTimeFrame?.startTime}
            animate={{
              rotate: 360,
              transition: {
                ease: 'easeInOut',
                duration: 0.6,
              },
            }}
          >
            <IconRefresh size={22} />
          </Box>
        </NavBarItem>
      </Tooltip>

      <MetricsPoll dropdown={dropdown} setDropdown={setDropdown} disabled={disableRefresh} />
    </NavBar>
  )
}

const NavBar = styled(motion.div)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 4,
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'stretch',
  height: '72px',
  width: '100%',
  background: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.border.main}`,
}))
