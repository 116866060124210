import { SvgIcon } from '@mui/material'

const IconPause = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <rect x="7.24023" y="4.5" width="4" height="15" rx="1" />
      <rect x="14.2402" y="4.5" width="4" height="15" rx="1" />
    </SvgIcon>
  )
}

export { IconPause }
