import { useEffect, useState } from 'react'
import {
  styled,
  Box,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { Button } from 'common/components/Button'
import CheckIcon from '@mui/icons-material/Check'

import Calendar from 'react-calendar'
import { getHours, getMinutes, set, setHours, setMinutes, subDays } from 'date-fns'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { motion } from 'framer-motion'
import { formatLongDate, usesAmPmTime } from 'util/date'
import { range } from 'lodash'
import { useTheme } from '@mui/styles'
import { getTimeFrame } from '../util/time'

const calculateTimeRange = (date, prefix) => {
  const rawHours = getHours(date)
  const amPmInit = rawHours >= 12 ? 'PM' : 'AM'
  const minutesInit = getMinutes(date)
  const hourInit = rawHours % 12 === 0 ? 12 : rawHours % 12

  return {
    [`${prefix}24Hour`]: rawHours >= 10 ? rawHours : `0${rawHours}`,
    [`${prefix}Hour`]: hourInit >= 10 ? hourInit : `0${hourInit}`,
    [`${prefix}Minutes`]: minutesInit >= 10 ? minutesInit : `0${minutesInit}`,
    [`${prefix}AmPm`]: amPmInit,
  }
}

export const TimeFrame = ({
  includeCustomTimeFrame = true,
  timeFrameDefaults,
  darkMode = false,
  globalTimeFrame,
  timeFrameKey,
  setTimeFrame = () => {},
  closeDropdown = () => {},
}) => {
  const theme = useTheme()
  const { startTime, stopTime } = getTimeFrame(globalTimeFrame)
  const [startDate, setStartDate] = useState(new Date(startTime))
  const [endDate, setEndDate] = useState(new Date(stopTime))
  const [showCustomTimeFrame, setShowCustomTimeFrame] = useState(false)

  const [timeRange, setTimeRange] = useState({
    ...calculateTimeRange(new Date(startTime), 'start'),
    ...calculateTimeRange(new Date(stopTime), 'end'),
  })
  const isCustomTimeFrame = globalTimeFrame?.split(',').length > 1 || showCustomTimeFrame

  const isAmPm = usesAmPmTime()

  useEffect(() => {
    const { startTime, stopTime } = getTimeFrame(globalTimeFrame)
    if (startDate.getTime() !== new Date(startTime).getTime()) {
      setStartDate(new Date(startTime))
    }
    if (endDate.getTime() !== new Date(stopTime).getTime()) {
      setEndDate(new Date(stopTime))
    }
  }, [globalTimeFrame])

  useEffect(() => {
    const newRange = {
      ...calculateTimeRange(new Date(startDate), 'start'),
      ...calculateTimeRange(new Date(endDate), 'end'),
    }
    if (JSON.stringify(timeRange) !== JSON.stringify(newRange)) {
      setTimeRange(newRange)
    }
  }, [startDate, endDate])

  const selectDates = (dates) => {
    const [start, end] = dates
    setStartDate(
      set(start, {
        hours: parseInt(timeRange.start24Hour),
        minutes: parseInt(timeRange.startMinutes),
      })
    )
    setEndDate(
      set(end, {
        hours: parseInt(timeRange.end24Hour),
        minutes: parseInt(timeRange.endMinutes),
      })
    )
  }

  const updateTimeRange = ({ val, timeRangeKey }) => {
    setTimeRange({
      ...timeRange,
      [timeRangeKey]: val,
    })
    // For 12h format hour set
    if (timeRangeKey === 'startHour' || timeRangeKey === 'endHour') {
      const intVal = parseInt(val)
      // Check if it is AM or PM, according to start or end
      const amPm = timeRangeKey === 'startHour' ? timeRange.startAmPm : timeRange.endAmPm
      // For PM, add 12 hours
      let finalVal = amPm === 'PM' ? intVal + 12 : intVal
      // For AM, if it is midnight, set it to 0
      finalVal = amPm === 'AM' && finalVal === 12 ? 0 : finalVal
      if (timeRangeKey === 'startHour') {
        setStartDate(setHours(startDate, finalVal))
      } else {
        setEndDate(setHours(endDate, finalVal))
      }
    } else if (timeRangeKey === 'start24Hour' || timeRangeKey === 'end24Hour') {
      // For 24h hour set, we just need to send raw values
      const intVal = parseInt(val)
      if (timeRangeKey === 'start24Hour') {
        setStartDate(setHours(startDate, intVal))
      } else {
        setEndDate(setHours(endDate, intVal))
      }
    } else if (timeRangeKey === 'startMinutes' || timeRangeKey === 'endMinutes') {
      // Send raw minutes values
      const intVal = parseInt(val)
      if (timeRangeKey === 'startMinutes') {
        setStartDate(setMinutes(startDate, intVal))
      } else {
        setEndDate(setMinutes(endDate, intVal))
      }
    } else if (timeRangeKey === 'startAmPm' || timeRangeKey === 'endAmPm') {
      // Check for AM/PM update, and set the hour accordingly
      const isPm = val === 'PM'
      if (timeRangeKey === 'startAmPm') {
        const hour = getHours(startDate)
        if (hour < 12 && isPm) {
          setStartDate(setHours(startDate, hour + 12))
        } else if (hour >= 12 && !isPm) {
          setStartDate(setHours(startDate, hour - 12))
        }
      } else {
        const hour = getHours(endDate)
        if (hour < 12 && isPm) {
          setEndDate(setHours(endDate, hour + 12))
        } else if (hour >= 12 && !isPm) {
          setEndDate(setHours(endDate, hour - 12))
        }
      }
    }
  }

  const updateDates = (e) => {
    e.stopPropagation()
    if (endDate.getTime() < startDate.getTime()) {
      setTimeFrame({
        key: timeFrameKey,
        timeFrameCSV: `${startDate.getTime()},${startDate.getTime()}`,
        startDate: startDate.getTime(),
        endDate: startDate.getTime(),
      })
    } else {
      setTimeFrame({
        key: timeFrameKey,
        timeFrameCSV: `${startDate.getTime()},${endDate.getTime()}`,
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
      })
    }
    closeDropdown()
  }

  const hourOptions = isAmPm
    ? Array(12)
        .fill('')
        .map((_, index) => (index + 1 > 9 ? `${index + 1}` : `0${index + 1}`))
    : Array(24)
        .fill('')
        .map((_, index) => (index > 9 ? `${index}` : `0${index}`))

  return (
    <>
      <List component="nav" aria-label="preset time frames">
        {[
          ...timeFrameDefaults,
          // Add custom option
          ...(includeCustomTimeFrame
            ? [
                {
                  label: 'Custom',
                  value: 'custom',
                  cy: 'metrics-timeframe-select-custom',
                },
              ]
            : []),
        ].map(({ label, value, cy, isClearTimeFrame }) => (
          <span key={value}>
            <StyledListItem
              disableGutters={true}
              button
              // divider={value !== 'custom'}
              data-cy={cy}
              onClick={(e) => {
                e.stopPropagation()
                if (value === 'custom') {
                  setShowCustomTimeFrame(true)
                } else {
                  const split = value.split(',')
                  setTimeFrame({
                    key: timeFrameKey,
                    timeFrameCSV: value,
                    startDate: parseInt(split[0]),
                    endDate: parseInt(split[1]),
                  })
                  setShowCustomTimeFrame(false)
                  closeDropdown()
                }
              }}
            >
              <ListItemText primary={label} />
              {(value === 'custom' && isCustomTimeFrame) ||
              (globalTimeFrame === value && !isCustomTimeFrame && !isClearTimeFrame) ? (
                <ListItemSecondaryAction>
                  <CheckIcon style={{ marginRight: 10, marginTop: 5 }} />
                </ListItemSecondaryAction>
              ) : null}
            </StyledListItem>
          </span>
        ))}
      </List>

      <Box
        component={motion.div}
        variants={{
          open: {
            opacity: 1,
            display: 'block',
            height: 'auto',
            y: 0,
            marginTop: 5,
          },
          collapsed: { opacity: 0, height: 0, y: -30, display: 'none' },
        }}
        initial={isCustomTimeFrame ? 'open' : 'collapsed'}
        animate={isCustomTimeFrame ? 'open' : 'collapsed'}
        inherit={false}
        transition={{
          duration: 0.35,
          ease: 'easeOut',
        }}
      >
        <Box height="auto" padding="10px">
          <Box className={`calendar-wrapper-${theme.palette.mode}`}>
            <Calendar
              minDate={subDays(new Date(), 30)}
              maxDate={new Date()}
              onChange={selectDates}
              value={[startDate, endDate]}
              selectRange={true}
              nextLabel={<ArrowForwardIcon />}
              prevLabel={<ArrowBackIcon />}
            />
          </Box>

          <Box marginTop="12px" marginBottom="12px">
            <Typography variant="textPrimary" gutterBottom>
              Date
            </Typography>
            <Typography variant="textPrimary" color="text.secondary">
              {formatLongDate(startDate)} - {formatLongDate(endDate)}
            </Typography>
          </Box>

          <Box marginBottom="16px">
            <Typography variant="textPrimary" marginBottom="5px">
              Time
            </Typography>
            <Box display="flex" alignItems="center">
              <Grid container spacing={0}>
                {range(3).map((num) => {
                  const key = num === 0 ? 'start' : 'end'
                  /**
                   * Seperator
                   */
                  if (num === 1) {
                    return (
                      <Grid
                        key={num}
                        item
                        xs={1}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Typography variant="body1" component="p">
                          -
                        </Typography>
                      </Grid>
                    )
                  }
                  /**
                   * Render time dropdowns
                   */
                  return (
                    <Grid item xs={5} key={num}>
                      <Box
                        darkmode={darkMode ? 'true' : 'false'}
                        display="flex"
                        alignItems="center"
                        border={`1px solid ${theme.palette.grey.light}`}
                        width="100%"
                        borderRadius="4px"
                      >
                        <TimeValue
                          value={isAmPm ? timeRange[`${key}Hour`] : timeRange[`${key}24Hour`]}
                          timeRangeKey={isAmPm ? `${key}Hour` : `${key}24Hour`}
                          updateTimeRange={updateTimeRange}
                          values={hourOptions}
                        />
                        <Typography variant="body1" component="p">
                          :
                        </Typography>
                        <TimeValue
                          value={timeRange[`${key}Minutes`]}
                          timeRangeKey={`${key}Minutes`}
                          updateTimeRange={updateTimeRange}
                          values={Array(60)
                            .fill('')
                            .map((_, index) => (index > 9 ? `${index}` : `0${index}`))}
                        />
                        {isAmPm && (
                          <TimeValue
                            variant="text"
                            updateTimeRange={updateTimeRange}
                            timeRangeKey={`${key}AmPm`}
                            value={timeRange[`${key}AmPm`]}
                            values={['AM', 'PM']}
                          />
                        )}
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
            <Button onClick={updateDates} color="primary" size="small">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const TimeValue = ({ value = '01', values, timeRangeKey, updateTimeRange }) => {
  const [picker, setPicker] = useState(null)
  const handleClick = (event) => {
    setPicker(event.currentTarget)
  }

  const handleClose = (val) => {
    if (val) {
      updateTimeRange({
        val,
        timeRangeKey,
      })
    }
    setPicker(null)
  }

  return (
    <>
      <Button
        style={{ minWidth: '30px', padding: '7px 0 7px 0', width: '32%' }}
        aria-controls="start hour picker"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        variant="text"
      >
        {value}
      </Button>
      <Menu
        id="hours"
        anchorEl={picker}
        keepMounted
        open={Boolean(picker)}
        onClose={() => handleClose()}
      >
        {values.map((val) => (
          <MenuItem key={`${val}-${timeRangeKey}`} onClick={(event) => handleClose(val)}>
            {val}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const StyledListItem = styled(ListItem)(() => ({
  paddingTop: '5px',
  paddingBottom: '5px',
  padding: '5px 15px',
}))
