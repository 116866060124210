import { useContext, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Navigate, useLocation } from 'react-router-dom'
import { styled, Typography, Box, Tab, Tabs } from '@mui/material'
import Login from './Login'
import { Button } from 'common/components/Button'
import { IconServerlessConsole } from 'common/icons/IconServerlessConsole'
import { loginCredentials, loginOrRegisterSocial, registerCredentials } from 'app/util/auth'
import { helperExtractInitialParams } from 'login/util/helper'
import { Link } from 'common/components/Link'
import { AppContext } from 'app/context/AppContext'
import { defaultMetricsFilterString } from 'filter/util/filters'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`login-tabpanel-${index}`}
      aria-labelledby={`login-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, maxWidth: '500px', margin: '0 auto', marginTop: '10px' }}>{children}</Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `login-tab-${index}`,
    'aria-controls': `login-tabpanel-${index}`,
  }
}

const setError = (error) => {
  if (error.code === 'invalid_password') {
    return 'Password must be at least 8 characters, and contain a lowercase letter, uppercase letter, number and special character'
  } else if (error.code === 'user_exists') {
    return 'An account with this email already exists. Please sign in.'
  } else if (typeof error.description === 'string') {
    return error.description
  } else {
    return 'There was an issue with logging in. Please try again.'
  }
}

export default function LoginContainer() {
  const { loggedIn, activeOrg } = useContext(AppContext)
  const { state } = useLocation()
  const [value, setValue] = useQueryParam('view', StringParam)
  const [currentView, setCurrentView] = useState(value || 'login')
  const [errorMessage, setErrorMessage] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setLoading] = useState({
    google: false,
    github: false,
    email: false,
  })

  const currentIndex = currentView === 'login' ? 1 : 0

  const handleChange = (_, newValue) => {
    setErrorMessage(undefined)
    if (newValue === 0) {
      setValue('register')
      setCurrentView('register')
    } else {
      setValue('login')
      setCurrentView('login')
    }
  }

  const onSubmit = async (params) => {
    setErrorMessage(undefined)
    const loadingKey = !params?.isSocial
      ? 'email'
      : params.socialProvider === 'github'
      ? 'github'
      : 'google'

    const queryParameters = helperExtractInitialParams()
    if (params.isSocial && params.socialProvider) {
      setLoading((prev) => ({ ...prev, [loadingKey]: true }))
      try {
        await loginOrRegisterSocial({
          provider: params.socialProvider,
          queryParameters: {
            ...helperExtractInitialParams(state?.from?.search),
            ...queryParameters,
          },
          from: state?.from,
        })
      } catch (error) {
        setLoading((prev) => ({ ...prev, [loadingKey]: false }))
        setErrorMessage(setError(error))
      }
    } else if (currentIndex === 0 && params.email && params.password) {
      setLoading((prev) => ({ ...prev, [loadingKey]: true }))
      try {
        await registerCredentials({
          email: params.email,
          password: params.password,
        })
        await loginCredentials({
          email: params.email,
          password: params.password,
          queryParameters: {
            ...helperExtractInitialParams(state?.from?.search),
            ...queryParameters,
          },
          from: state?.from,
        })
      } catch (error) {
        setLoading((prev) => ({ ...prev, [loadingKey]: false }))
        setErrorMessage(setError(error))
      }
    } else if (currentIndex === 1 && params.email && params.password) {
      setLoading((prev) => ({ ...prev, [loadingKey]: true }))
      try {
        await loginCredentials({
          email: params.email,
          password: params.password,
          queryParameters: {
            ...helperExtractInitialParams(state?.from?.search),
            ...queryParameters,
          },
          from: state?.from,
        })
      } catch (error) {
        setLoading((prev) => ({ ...prev, [loadingKey]: false }))
        setErrorMessage(setError(error))
      }
    }
  }
  const loginProps = {
    email,
    setEmail,
    password,
    setPassword,
    onSubmit,
    isLoading,
  }

  if (loggedIn && activeOrg) {
    return (
      <Navigate
        to={`/${activeOrg?.orgName}/metrics/awsLambda?${defaultMetricsFilterString}`}
        replace
      />
    )
  }

  return (
    <Container>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <IconServerlessConsole size={46} />
        <Box sx={{ width: '100%', margin: '20px auto 0 auto' }}>
          <Tabs centered value={currentIndex} onChange={handleChange} aria-label="login tabs">
            <StyledTab label="Register" {...a11yProps(0)} />
            <StyledTab label="Login" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={currentIndex} index={0}>
          <Login
            {...loginProps}
            submitText="Register"
            footer={
              <StyledBox mt="20px" textAlign="center">
                Registering signifies you have read and agree to our <br />
                <Link to="https://www.serverless.com/legal/terms" target="_blank">
                  Terms of Service
                </Link>{' '}
                &amp;{' '}
                <Link to="https://www.serverless.com/legal/privacy" target="_blank">
                  Privacy Policy
                </Link>
                .
              </StyledBox>
            }
          />
        </TabPanel>
        <TabPanel value={currentIndex} index={1}>
          <Login
            {...loginProps}
            footer={
              <Box
                mt="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Button variant="text" fullWidth href="/password/reset">
                  Forgot Password?
                </Button>
              </Box>
            }
          />
        </TabPanel>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '0 20px',
            maxWidth: '500px',
            textAlign: 'center',
          }}
        >
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      </Box>
    </Container>
  )
}

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,

  '& a': {
    color: theme.palette.text.secondary,
  },
}))

const StyledTab = styled(Tab)({
  padding: '12px 45px',
  fontSize: '16px',
})

const ErrorMessage = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.error.main,
}))

const Container = styled(Box)(({ theme }) => ({
  height: 'var(--app-height)',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingTop: '15vh',
  background: theme.palette.background.paper,
}))
