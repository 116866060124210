import { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  Backdrop,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
  IconButton,
} from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEnableBulkInstrumentation } from '../hooks/useEnableBulkInstrumentation'
import { IconAwsLambda } from '../icons/IconAwsLambda'
import { BulkInstrumentationSelect } from './BulkInstrumentationSelect'
import { useLocalStorage } from 'react-use'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { useProdAnnouncement } from '../hooks/useProdAnnouncement'
import { AlertTriangle, ChevronDown, ChevronUp } from 'lucide-react'
import { InstrumentationFailureList } from './InstrumentationFailureList'

const columns = [
  {
    field: 'functionName',
    headerName: 'Name',
    flex: 1,
    renderCell: (params) => (
      <StyledRow>
        <IconAwsLambda viewBox="-2 -2 14 14" />
        {params.row.functionName}
      </StyledRow>
    ),
  },
]

const textMap = {
  noCompatibleFunctions: {
    showSelect: false,
    title: () => 'You do not have any compatible resources',
    description: () =>
      `You've connected an AWS account, however, you do not have any resources compatible with serverless console.<br/><br/>Please try adding compatible resources to your AWS account or adding a new integration.<br/><br/>Please see <a style="color: inherit;" href="https://www.serverless.com/console/docs" target="_blank">our documentation</a> for more information.`,
    buttonText: () => 'Manage Inventory',
  },
  nothingInstrumented: {
    showSelect: true,
    title: () => 'Get Started By Instrumenting Your Functions',
    description: () =>
      `You've connected an AWS account. Now, we'll automatically instrument your AWS Lambda functions to enable Console's Metrics, Traces & Logs within seconds.`,
    buttonText: (functionCount) => `Instrument ${functionCount || 0} Resources`,
  },
  someInstrumented: {
    showSelect: true,
    title: (announcement, announcementBypass) =>
      !announcementBypass ? announcement.title : 'Instrument Resources',
    description: (announcement, announcementBypass) =>
      !announcementBypass
        ? announcement.description
        : `You've connected an AWS account and enabled instrumentation on some resources. Would you like to instrument more resources?`,
    buttonText: (functionCount) =>
      functionCount ? `Instrument ${functionCount || 0} Resources` : 'Awesome!',
  },
  done: {
    showSelect: false,
    title: (_announcement, _announcementBypass, withErrors) =>
      `Instrumentation complete ${withErrors && 'with some errors'}`,
    description: (_announcement, _announcementBypass, functionCount) =>
      `You've successfully instrumented ${
        functionCount || 0
      } Resources. Metrics, Logs, & Traces will appear once your resources receive requests.`,
    buttonText: () => `Ok`,
  },
}

export const EnableBulkInstrumentation = ({
  orgId,
  loading,
  instrumentationType,
  hasExistingData,
  bypassKey,
  children,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [selectText, setSelectText] = useState(textMap.nothingInstrumented)
  const [loadingChecks, setLoadingChecks] = useState(true)
  const [shouldHideCancel, setShouldHideCancel] = useState(!hasExistingData)
  const [bypass, setBypass] = useLocalStorage(bypassKey, {})
  const [expandFailedResources, setExpandFailedResources] = useState(false)
  const [failedResources, setFailedResources] = useState([])
  const [successResources, setSuccessResources] = useState([])
  const [titleOpen, setTitleOpen] = useState(true)
  const {
    announcement,
    shouldBypass: announcementBypass,
    setShouldBypass: setAnnouncementBypass,
  } = useProdAnnouncement({
    orgId,
  })
  const [open, setOpen] = useState(!bypass[orgId] || !announcementBypass)
  const [canSkipSelect, setCanSkipSelect] = useState(false)
  const {
    hasNoCompatibleResources,
    currentlyInstrumenting,
    instrumentationProgress,
    compatibleFunctionCount,
    waitingForInstrumentation,
    loadingFunctionCount,
    allResources,
    setResources,
    selectedFunctionCount,
    enableTargetFunctions,
    beginCheckForFunctions,
    hasTracesIncludingDevMode,
  } = useEnableBulkInstrumentation({
    orgId,
    enableInstrumentationType: instrumentationType,
    showProgressSnackbar: false,
  })

  // Only allow collapse for announcement
  // text since it can be large
  const collapsable = !announcementBypass

  const handleTitleChange = (_event, isExpanded) => {
    if (collapsable) setTitleOpen(isExpanded)
  }

  const close = () => {
    if (!shouldHideCancel) {
      setOpen(false)
      setAnnouncementBypass(true)
      setBypass((current) => ({ ...current, [orgId]: true }))
    }
  }

  /**
   * Initiate check for functions
   */
  useEffect(() => {
    const init = async () => {
      if (orgId) {
        const {
          resources: newResources,
          doesNotHaveInstrumentation,
          compatibleFunctionCount: compatibleFunctions,
          hasNoCompatibleResources: noCompatibleFunctions,
        } = await beginCheckForFunctions(!announcementBypass)
        const hasProdModeEnabled = !doesNotHaveInstrumentation
        const hasCompatibleFunctions = compatibleFunctions > 0
        const canHideCancel = await hasTracesIncludingDevMode()
        const hasDevModeFunctions = !canHideCancel
        setShouldHideCancel(canHideCancel)

        let hasBypass = bypass[orgId]
        if (!hasDevModeFunctions && !hasProdModeEnabled) {
          hasBypass = false
          setBypass((current) => ({ ...current, [orgId]: false }))
        }

        if ((hasProdModeEnabled || hasBypass || currentlyInstrumenting) && announcementBypass) {
          setOpen(false)
        } else if (hasDevModeFunctions || hasCompatibleFunctions || !announcementBypass) {
          setCanSkipSelect(newResources.length === 0)
          setOpen(compatibleFunctions !== 0 || !announcementBypass)
          if (noCompatibleFunctions) {
            setSelectText(textMap.noCompatibleFunctions)
          } else {
            setSelectText(
              hasDevModeFunctions ? textMap.someInstrumented : textMap.nothingInstrumented
            )
          }
        }
        setLoadingChecks(false)
      }
    }
    init()
  }, [orgId])

  /**
   * Render children if we are not going to show the function select
   */

  const isLoading = loadingFunctionCount || loading || loadingChecks

  return (
    <>
      {children}
      {!isLoading && (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            paper: { background: '#262626' },
            left: '80px',
            '& .MuiDialog-container': { paddingBottom: '50px' },
          }}
          BackdropComponent={styled(Backdrop, {
            name: 'MuiModal',
            slot: 'Backdrop',
            overridesResolver: (_props, styles) => {
              return styles.backdrop
            },
          })({ zIndex: -1, left: '80px' })}
        >
          <DialogContent>
            <Accordion expanded={!collapsable ? true : titleOpen} onChange={handleTitleChange}>
              <AccordionSummary
                sx={{
                  padding: 0,
                  paddingBottom: '10px',
                  minHeight: 'unset!important',
                  cursor: collapsable ? 'pointer' : 'default !important',
                  '& .MuiAccordionSummary-content': {
                    margin: '0 !important',
                  },
                }}
                expandIcon={!collapsable ? <></> : <ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {failedResources.length > 0 && (
                    <AlertTriangle
                      style={{ minWidth: 'fit-content' }}
                      size="18"
                      color={theme?.palette?.error?.main}
                    />
                  )}
                  <Typography variant="h2">
                    {selectText.title(announcement, announcementBypass, failedResources.length > 0)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: '8px 0px 0px',
                  '& p': { margin: 0, marginBottom: '10px' },
                  '& li': { marginBottom: '10px' },
                  '& a': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
              >
                <Typography
                  color="text.primary"
                  sx={{ whiteSpace: 'break-spaces' }}
                  dangerouslySetInnerHTML={{
                    __html: selectText.description(
                      announcement,
                      announcementBypass,
                      waitingForInstrumentation
                        ? instrumentationProgress.total
                        : successResources.length
                    ),
                  }}
                />
                {failedResources.length > 0 && (
                  <>
                    <Box
                      onClick={() => setExpandFailedResources((c) => !c)}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <Typography sx={{ m: 0 }} color="text.primary">
                        Show Failed Resources
                      </Typography>
                      <IconButton>
                        {expandFailedResources ? <ChevronUp /> : <ChevronDown />}
                      </IconButton>
                    </Box>
                    <InstrumentationFailureList
                      expanded={expandFailedResources}
                      incompleteInventories={failedResources}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            {selectText.showSelect && !canSkipSelect ? (
              <>
                <Divider sx={{ marginTop: '10px', marginBottom: '20px' }} />
                <BulkInstrumentationSelect
                  selectedFunctionCount={selectedFunctionCount}
                  compatibleFunctionCount={compatibleFunctionCount}
                  waitingForInstrumentation={waitingForInstrumentation}
                  instrumentationProgress={instrumentationProgress}
                  setBypass={() => {
                    setOpen(false)
                    setAnnouncementBypass(true)
                    setBypass((current) => ({ ...current, [orgId]: true }))
                  }}
                  enableTargetFunctions={async () => {
                    if (compatibleFunctionCount === 0) {
                      navigate(
                        `/${window?.location?.pathname?.split?.('/')?.[1]}/settings/integrations`
                      )
                    }
                    const { completeFunctions, incompleteFunctions } = await enableTargetFunctions()
                    setFailedResources(incompleteFunctions)
                    setSuccessResources(completeFunctions)
                    setSelectText(textMap.done)
                    setTitleOpen(true)
                    setAnnouncementBypass(true)
                    setBypass((current) => ({ ...current, [orgId]: true }))
                  }}
                  columns={columns}
                  allResources={allResources}
                  setResources={setResources}
                  hideCancel={shouldHideCancel}
                  parentExpanded={titleOpen}
                  expandChange={(expanded) => {
                    if (collapsable && expanded) {
                      setTitleOpen(false)
                    } else if (!collapsable) {
                      setTitleOpen(!expanded)
                    }
                  }}
                />
              </>
            ) : (
              <Button
                fullWidth
                sx={{ marginTop: '20px' }}
                onClick={() => {
                  if (hasNoCompatibleResources) {
                    return navigate(
                      `/${window?.location?.pathname?.split?.('/')?.[1]}/settings/integrations`
                    )
                  }
                  setOpen(false)
                  setAnnouncementBypass(true)
                }}
              >
                {selectText.buttonText()}
              </Button>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  paddingLeft: '10px',
  height: '50%',
  borderLeft: `1px solid ${theme.palette.grey.light}`,
}))
