import { SvgIcon } from '@mui/material'

const IconDangerTriangle = ({ size = 24, viewBox, ...props }) => {
  return (
    <SvgIcon
      style={{ fontSize: `${size}px` }}
      viewBox={viewBox ? viewBox : '-2 -3 24 24'}
      {...props}
    >
      <path d="m12.8 1.613 6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.213 3.213 0 0 1-1.743.516H3.298C1.477 18 0 16.47 0 14.581c0-.639.173-1.264.498-1.807L7.2 1.613C8.162.01 10.196-.481 11.743.517c.428.276.79.651 1.057 1.096zM10 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0-9a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1z" />
    </SvgIcon>
  )
}

export { IconDangerTriangle }
