import { useContext, useMemo } from 'react'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { AppContext } from 'app/context/AppContext'
import { getVolumeDiscountRate, n100K, n1MM } from 'settings/components/billing/billingUtils'
import { isBefore, isValid } from 'date-fns'
import { last } from 'lodash'

/**
 * Default monthly limits in case not returned from API
 */
const MONTHLY_TRACES_LIMIT = n100K

/**
 * A custom hook to return org usage and org current plan.
 */

export const useOrgUsage = ({ orgId }) => {
  const { token } = useContext(AppContext)
  /**
   * Get org current subscription
   */
  const {
    data: orgSubscription,
    error: orgSubscriptionError,
    mutate: mutateBilling,
  } = useSWR(token && orgId && `/billing/orgs/${orgId}`, coreApiClient, {
    shouldRetryOnError: false,
  })
  /**
   * tier: is the current subscription tiers limit.
   * status: status of the current subscription.
   * discounts: custom discounts array coming in from the admin dashboard
   * traces: pricing details for the current subscription.
   */
  let { tier, status, discounts: customDiscounts } = orgSubscription || {}

  /**
   * Get org usage
   */
  const {
    data: orgUsage,
    error: orgUsageError,
    mutate: mutateUsage,
  } = useSWR(token && orgId && `/usage/mtd/${orgId}`, coreApiClient)

  /**
   * Loading
   */
  const loadingUsage = !orgUsage && !orgUsageError
  const loadingSubscription = !orgSubscription && !orgSubscriptionError

  /**
   * If status is 'canceled' it means org had previous subscription and card is stored in Stripe
   */
  const hadSubscriptionBefore = status !== 'inactive'

  /**
   * Limits
   */
  const { tier: tracesUsage } = orgUsage || {}

  const tracesLimit = tier || MONTHLY_TRACES_LIMIT
  const isFreePlan = status === 'canceled' || tracesLimit === MONTHLY_TRACES_LIMIT
  /**
   * Check if org is over limit for traces, or dev-mode
   */
  const isTracesOverLimit = false

  const isTracesApproachingLimit = false

  const isOverDevModeLimit = false

  /**
   * Usage percentage for traces used for progress bars
   */
  const tracesUsagePercent = isTracesOverLimit
    ? 100
    : ((tracesUsage * 100) / tracesLimit).toFixed(2) || 0

  /**
   * Get pricing based on passed traces count
   */
  const getPrice = (tracesCount) => {
    let subtotal = (tracesCount / n1MM) * 60
    if (tracesCount === n100K) {
      subtotal = 0
    }
    return subtotal
  }
  /**
   * Take traces count and return enriched details such as pricing, discounts and available limits for traces, invocations and metrics
   */
  const enrichTier = (tracesCount = 0) => {
    const subtotal = getPrice(tracesCount)

    let volumePercentDiscount = getVolumeDiscountRate(tracesCount)
    let volumeDiscountValue = (subtotal * volumePercentDiscount) / 100
    let discounts = [
      {
        title: 'Usage volume discount',
        percentDiscount: volumePercentDiscount,
        discountValue: volumeDiscountValue ? volumeDiscountValue * -1 : 0,
        totalAfterDiscount: subtotal - (subtotal * volumePercentDiscount) / 100,
      },
    ]
    /**
     * If there are additional discounts we should add them to the `totalAfterDiscount`
     */
    if (customDiscounts?.length) {
      for (const discount of customDiscounts) {
        const discountExpiration = new Date(discount?.expiration)

        if (
          isValid(discountExpiration) &&
          isBefore(new Date(), discountExpiration) &&
          discount?.applicability !== 'members'
        ) {
          const discountValue =
            (last(discounts)?.totalAfterDiscount * discount?.percentDiscount) / 100
          discounts.push({
            ...discount,
            discountValue: discountValue ? discountValue * -1 : 0,
            totalAfterDiscount: last(discounts)?.totalAfterDiscount - discountValue,
          })
        }
      }
    }
    const volumeTotal = subtotal - (subtotal * volumePercentDiscount) / 100
    const total = discounts?.reduce(
      (acc, discount) => acc - (acc * discount?.percentDiscount) / 100,
      subtotal
    )

    let metrics
    let invocations
    if (tracesCount === n100K || tracesCount === n1MM) {
      metrics = tracesCount * 4
      invocations = tracesCount
    } else {
      metrics = tracesCount * 20
      invocations = tracesCount * 5
    }
    return {
      traces: tracesCount,
      invocations,
      metrics,
      subtotal, // price before discount
      volumeTotal, // price after volume discount
      volumePercentDiscount, // volume discount percent
      total, // price after discount
      discounts, // discounts array
    }
  }
  const currentTier = useMemo(() => enrichTier(isFreePlan ? n100K : tier), [orgSubscription])
  const refresh = () => {
    mutateUsage()
    mutateBilling()
  }
  return {
    currentTier,
    isFreePlan,
    isTracesApproachingLimit,
    tracesLimit,
    tracesUsage,
    enrichTier,
    refresh,
    orgUsage,
    orgSubscription,
    loadingUsage,
    loadingSubscription,
    isTracesOverLimit,
    isOverDevModeLimit,
    tracesUsagePercent,
    hadSubscriptionBefore,
  }
}
