import { hotjar } from 'react-hotjar'
import config from 'config'

/**
 * Initialize hotjar if:
 * User is logged in
 * User is not internal serverless user in prod
 */
export const initializeHotjar = () => {
  try {
    const localStorageData = localStorage.getItem(config.localStorageKey)
    const { activeOrg, user } = (localStorageData && JSON.parse(localStorageData)) || {}
    const { userEmail, userId } = user || {}
    const { orgId, orgName } = activeOrg || {}
    const isProd = config.platform.stage === 'prod'
    const isDev = config.platform.stage === 'dev'
    const isSlsUser = userEmail.includes('@serverless.com')
    if ((isProd && !isSlsUser) || isDev) {
      hotjar.initialize(config.hotjar.hjid, config.hotjar.hjsv)
      // Check if Hotjar has been initialized then identify the current logged in user
      if (hotjar.initialized()) {
        hotjar.identify(userId, { userEmail, orgId, orgName })
      }
    }
  } catch (err) {}
}
