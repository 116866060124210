import { Box, Globe2, Terminal } from 'lucide-react'
import { Zap } from 'lucide-react'
import { IconAws } from 'common/icons/IconAws'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'

export const groupByMap = {
  tag_namespace: {
    title: 'Namespace',
    field: 'namespace',
    icon: (size) => <Box size={size} />,
    paramName: 'globalNamespace',
  },
  tag_environment: {
    title: 'Environment',
    field: 'environment',
    icon: (size) => <Zap size={size} />,
    paramName: 'globalEnvironments',
  },
  tag_region: {
    title: 'Region',
    field: 'region',
    icon: (size) => <Globe2 size={size} />,
    paramName: 'globalRegions',
  },
  tag_account_id: {
    title: 'Account',
    field: 'account_id',
    icon: (size) => <IconAws size={size + 4} />,
    paramName: 'globalCloudAccountId',
  },
  aws_lambda_runtime: {
    title: 'Runtime',
    field: 'runtime',
    icon: (size) => <Terminal size={size} />,
    paramName: 'awsLambdaRuntime',
  },
  // instrument_mode: {
  //   title: 'Mode',
  //   field: 'mode',
  //   icon: (size) => <ToggleRight size={size} />,
  //   paramName: 'instrumentationMode',
  // },
  resource_aws_lambda: {
    title: 'Resource',
    field: 'aws_lambda_name',
    icon: (size) => <IconAwsLambda size={size - 4} />,
    paramName: 'awsLambdaFunctionNames',
  },
}
