import { forwardRef, memo, useContext, useMemo, useState } from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { formatShortDateTime } from 'util/date'
import { useQuery } from 'metrics/hooks/useQuery'
import { getFormattedDuration } from 'trace/util/span-chart'
import { useLocation } from 'react-use'
import { AppContext } from '../../../app/context/AppContext'
import { DataGridPro, GridColumnHeaders, GridRow } from '@mui/x-data-grid-pro'
import { GripVertical } from 'lucide-react'
import classes from './ExplorerTable.module.css'
import clsx from 'clsx'
import { SimplePagination } from './SimplePagination'
import { NoRowsOverlay } from './NoRowsOverlay'
import { CustomRow } from './CustomRow'
import { GridCell } from './GridCell'
import { EventsCell } from './EventsCell'
import { FilterContext } from 'filter/context/FilterContext'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { getEventInfo } from 'common/utils/getEventInfo'
import CloseButton from 'common/components/CloseButton'

const debounceDelay = 800
const size = 50

const renderHeader = ({ colDef }) => {
  return (
    <>
      <Typography variant="textSecondary" color="text.secondary">
        {colDef.headerName}
      </Typography>
      <GripVertical size={12} className={clsx(classes.explorerTableHeaderGripIcon)} />
    </>
  )
}
const FooterMessage = ({ eventInfo, onClick }) => {
  const { title, color } = getEventInfo(eventInfo || {})
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
        maxWidth: '40vw',
      }}
    >
      <Typography variant="textSecondary" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
        Showing Traces that includes:{' '}
      </Typography>
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color,
          lineHeight: 1,
        }}
        variant="textSecondary"
        color={color}
      >
        {title}
      </Typography>

      <CloseButton onClick={onClick} size="small" />
    </Box>
  )
}
const renderCustomCell = (params) => {
  return <GridCell {...params} />
}
const renderEventsCell = (params) => {
  return <EventsCell {...params} />
}
const RenderMemorizedRow = forwardRef((props, ref) => {
  return <CustomRow ref={ref} Component={GridRow} {...props} />
})
const MemoizedRow = memo(RenderMemorizedRow)
const MemoizedColumnHeaders = memo(GridColumnHeaders)

export const ExplorerTable = memo(({ currentPage, setCurrentPage }) => {
  const theme = useTheme()
  const { search } = useLocation()
  const { activeOrg } = useContext(AppContext)
  const { getFilterValue, setFilterValue } = useContext(FilterContext)
  const { orgName, orgId } = activeOrg || {}
  /**
   * If there is fingerprint filter then we need to show a message at the bottom of the table
   */
  const fingerprint = getFilterValue('explorerTracesFingerprints')?.[0]
  const url = `/activity/${orgId}/events/detail/fingerprint`
  const { data: eventsData } = useSWR(
    fingerprint && url,
    (url) =>
      coreApiClient({
        url,
        method: 'post',
        data: {
          fingerprint,
        },
      }),
    { shouldRetryOnError: false }
  )
  const eventInfo = eventsData?.data?.[0]

  const onFooterMessageClick = () => {
    setFilterValue('explorerTracesFingerprints', undefined)
  }
  const [sortModel, setSortModel] = useState([
    {
      field: 'start_time',
      sort: 'desc',
    },
  ])

  const { data, loading, isValidating, error, refresh } = useQuery({
    query: 'aws_lambda_traces',
    size,
    currentPage,
    sort: {
      by: sortModel?.[0]?.field,
      order: sortModel?.[0]?.sort,
    },
  })

  const rows = useMemo(() => data?.results || [], [data?.results])

  const currentSize = useMemo(() => data?.results?.length || 0, [data?.results])

  const columns = useMemo(
    () => [
      {
        headerName: 'Time',
        field: 'start_time',
        sortModel,
        width: 130,
        minWidth: 120,
        renderHeader,
        valueGetter: ({ value }) => formatShortDateTime(value),
        headerClassName: classes.explorerTableHeader,
      },
      {
        headerName: 'Resource Name',
        field: 'resource_name',
        sortModel,
        flex: 2,
        renderHeader,
        renderCell: renderCustomCell,
        headerClassName: classes.explorerTableHeader,
      },
      {
        headerName: 'Path',
        field: 'http_path',
        flex: 2,
        sortModel,
        renderHeader,
        renderCell: renderCustomCell,
        headerClassName: classes.explorerTableHeader,
      },
      {
        headerName: 'Method',
        field: 'http_method',
        sortModel,
        width: 75,
        renderHeader,
        valueGetter: ({ row }) => row.http_method || '-',
        headerClassName: classes.explorerTableHeader,
        cellClassName: (params) => clsx(params.row.http_method && classes.explorerTableCellChip),
      },
      {
        headerName: 'Status',
        field: 'http_status_code',
        sortModel,
        width: 70,
        renderHeader,
        valueGetter: ({ row }) => row.http_status_code || '-',
        headerClassName: classes.explorerTableHeader,
        cellClassName: (params) =>
          clsx(params.row.http_status_code && classes.explorerTableCellChip),
      },
      {
        headerName: 'Cold Start',
        field: 'is_cold_start',
        sortModel,
        width: 90,
        renderHeader,
        valueGetter: ({ row }) => (row.is_cold_start ? 'true' : '-'),
        cellClassName: (params) => clsx(params.value === 'true' && classes.explorerTableCellChip),
        headerClassName: classes.explorerTableHeader,
      },

      {
        headerName: 'Duration',
        field: 'duration',
        sortModel,
        width: 80,
        renderHeader,
        valueGetter: ({ row }) => getFormattedDuration(row)?.formatted,
        headerClassName: classes.explorerTableHeader,
      },

      {
        headerName: 'Runtime',
        field: 'runtime',
        sortModel,
        renderHeader,
        width: 80,
        headerClassName: classes.explorerTableHeader,
      },
      {
        headerName: 'Events',
        field: 'events',
        sortModel,
        width: 120,
        valueGetter: ({
          row: { uncaught_errors, caught_errors, warnings, sdk_user_errors, sdk_user_warnings },
        }) => {
          return {
            uncaught_errors,
            caught_errors,
            warnings,
            sdk_user_errors,
            sdk_user_warnings,
          }
        },
        renderHeader,
        renderCell: renderEventsCell,
        headerClassName: classes.explorerTableHeader,
      },
    ],
    [rows, sortModel]
  )

  return (
    <Box
      component={motion.div}
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        // maxWidth: '800px',
      }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.3, ease: 'easeOut' } }}
    >
      <DataGridPro
        rows={rows}
        getRowId={(row) => row.trace_id}
        columns={columns}
        pagination
        slots={{
          loadingOverlay: TableLoadingProgressBar,
          noRowsOverlay: NoRowsOverlay,
          pagination: SimplePagination,
          row: MemoizedRow,
          columnHeaders: MemoizedColumnHeaders,
        }}
        slotProps={{
          noRowsOverlay: { error },
          row: {
            orgName,
            search,
          },
          pagination: {
            debounceDelay,
            currentSize,
            refresh,
            currentPage,
            setCurrentPage,
            isValidating,
            loading,
            size,
            footerMessage: eventInfo && (
              <FooterMessage eventInfo={eventInfo} onClick={onFooterMessageClick} />
            ),
          },
        }}
        error={error}
        sortingMode="server"
        loading={loading || isValidating}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        disableRowSelectionOnClick
        rowSelection={false}
        onSortModelChange={(newSortModel, stuff) => {
          setSortModel(newSortModel)
        }}
        rowSpacingType="margin"
        sx={{
          border: 'none',
          borderTop: `1px solid ${theme.palette.border.main}`,
          borderRadius: 0,
          '& .MuiDataGrid-cell': {
            borderColor: 'grey.dark',
            color: 'text.secondary',
          },
          '& .traces-list-row-has-http': {
            backgroundColor: 'grey.dark',
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  )
})

const TableLoadingProgressBar = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 0,
  height: 2,
}))
