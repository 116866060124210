import { Typography } from '@mui/material'
import { Button } from 'common/components/Button'
import { Box } from '@mui/system'
import { useMotionValue, useTransform, motion } from 'framer-motion'
import { isString } from 'lodash'

export const SuccessfulMessage = ({ closeDialog, isDone, message }) => {
  const pathLength = useMotionValue(0)
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1])

  return (
    <>
      <Box textAlign="center" p={4} minWidth="450px">
        <motion.div
          style={{
            margin: 'auto auto 20px auto',
            width: 150,
            height: 150,
            borderRadius: 30,
            backgroundColor: '#fff',
          }}
          animate={{
            scale: isDone ? 1 : 0.5,
            backgroundColor: isDone ? '#EEEEEE' : '#fff',
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
            <motion.path
              d="M38 74.707l24.647 24.646L116.5 45.5"
              fill="transparent"
              strokeWidth="15"
              stroke="#000000"
              strokeLinecap="round"
              animate={{ pathLength: isDone ? 0.9 : 0 }}
              style={{ pathLength: pathLength, opacity: opacity }}
            />
          </svg>
        </motion.div>
        {isString(message) ? (
          <Typography color="text.secondary" fontWeight="bold" variant="h2">
            {message}
          </Typography>
        ) : (
          message
        )}
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Button onClick={closeDialog} variant="contained">
          Done
        </Button>
      </Box>
    </>
  )
}
