import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'

export const useUpgradeDialog = () => {
  const [query, setQuery] = useQueryParams({
    upgradePlan: BooleanParam,
    isUpdatePayment: BooleanParam,
    discountCode: StringParam,
    traces: NumberParam,
    status: StringParam,
  })
  const openDialog = (traces) =>
    setQuery({
      upgradePlan: true,
      traces,
    })
  return {
    openDialog,
    query,
    setQuery,
  }
}
