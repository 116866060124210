import { useContext, useState } from 'react'
import { Box, Typography, Drawer } from '@mui/material'
import { Button } from 'common/components/Button'
import AddIcon from '@mui/icons-material/Add'
import { SettingLayout } from 'settings/components/SettingLayout'
import { AppContext } from 'app/context/AppContext'
import { AddMember } from 'settings/components/team/AddMember'
import { TeamList, PendingList } from 'settings/components/team/TeamList'
import { TeamProvider } from '../../context/Team.context'

export const Team = () => {
  const {
    activeOrg: { orgId, isOwner },
  } = useContext(AppContext)
  const [showAddMember, setShowAddMember] = useState(false)

  return (
    <TeamProvider orgId={orgId} isOwner={isOwner}>
      <SettingLayout style={{ flexDirection: 'column', height: '90%', overflow: 'scroll' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">Team</Typography>
          <Button
            variant="contained"
            disabled={!isOwner}
            onClick={() => setShowAddMember(true)}
            size="small"
            color="primary"
            sx={{ float: 'right' }}
            startIcon={<AddIcon />}
          >
            Add Member
          </Button>
        </Box>
        <Box width="100%">
          {isOwner && <PendingList />}
          <TeamList />
          <Drawer anchor="right" open={showAddMember} onClose={() => setShowAddMember(false)}>
            <AddMember close={() => setShowAddMember(false)} />
          </Drawer>
        </Box>
      </SettingLayout>
    </TeamProvider>
  )
}
