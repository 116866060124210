import { Key } from 'lucide-react'
import { RenderFields } from '../components/secrets/RenderFields'
import { IconAws } from 'common/icons/IconAws'
import { SectionArn } from '../components/secrets/SectionArn'

export const types = [
  {
    valueType: 'PARAMETER',
    label: 'Simple Parameter',
    description: 'Add a simple key/value parameter',
    icon: <Key size={26} />,
    getComponent: () => (
      <RenderFields
        fields={[
          {
            type: 'string',
            key: 'value',
            label: 'Value',
            subLabel: '',
            isRequired: true,
            isSensitive: true,
            placeholder: 'Value',
          },
        ]}
      />
    ),
  },
  {
    valueType: 'PROVIDER',
    label: 'Access/Secret Key',
    description: 'Add AWS access/secret keys',
    icon: <IconAws size={30} />,
    provider: {
      providerType: 'SECRET',
      // cloudProvider: 'AWS',
      // accessKeyId: '',
      // secretAccessKey: '',
    },
    getComponent: () => (
      <RenderFields
        fields={[
          {
            type: 'string',
            key: 'accessKeyId',
            label: 'AWS Access Key',
            subLabel: '',
            placeholder: 'AWS Access Key',
            isRequired: true,
          },
          {
            type: 'string',
            key: 'secretAccessKey',
            label: 'AWS Secret Key',
            subLabel: '',
            isRequired: true,
            isSensitive: true,
            placeholder: 'AWS Secret Key',
          },
        ]}
      />
    ),
  },
  {
    valueType: 'PROVIDER',
    label: 'Access Role ARN',
    description: 'Add AWS account or access role ARN',
    icon: <IconAws size={30} />,
    provider: {
      providerType: 'ARN',
    },
    getComponent: () => <SectionArn />,
  },
]
