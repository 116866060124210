import { defaultDurations, durationIntervals } from '../util/duration'
import React, { useEffect, useMemo, useState } from 'react'
import { Slider, Box } from '@mui/material'
import { useWindowSize } from 'react-use'
import { useTheme } from '@mui/styles'
import { DropdownWrapper } from 'common/components/DropdownWrapper'

const marks = durationIntervals
  .map((di, index) => ({ value: index, label: di.label }))
  .filter((di) => di.value % 4 === 0 || di.value === 0 || di.value === durationIntervals.length - 1)
const smallMarks = [marks[0], marks[marks.length - 1]]

const getInitialValue = (filter) => {
  const { min, max } = filter?.value || defaultDurations
  const minIndex = durationIntervals.findIndex((di) => di.value === min) || 0
  const maxIndex = durationIntervals.findIndex((di) => di.value === max) || 20
  return [minIndex, maxIndex]
}

const valueText = (index) => {
  return durationIntervals[index]?.label
}

/**
 * This component presents a widget for adding Filter Values to a Duration field
 */
export default ({ filter, onChange, expandedFilter, toggleExpandedFilter }) => {
  const theme = useTheme()
  const isOpen = expandedFilter === filter.filter
  const [curValue, setCurValue] = useState(getInitialValue(filter))
  const { width } = useWindowSize()

  useEffect(() => {
    setCurValue(getInitialValue(filter))
  }, [filter.value])
  const selectedItems = useMemo(() => {
    const items = []
    if (curValue[0] !== 0) {
      items.push({ key: 'min', value: valueText(curValue[0]) })
    }
    if (curValue[1] !== 20) {
      items.push({ key: 'max', value: valueText(curValue[1]) })
    }
    return items
  }, [curValue])

  const currentMarks = width >= 1330 ? marks : smallMarks

  const handleChange = (_, newValue) => {
    setCurValue(newValue)
    onChange(filter, {
      min: durationIntervals[newValue[0]]?.value || newValue[0],
      max: durationIntervals[newValue[1]]?.value || newValue[1],
    })
  }

  return (
    <DropdownWrapper
      title={filter.label}
      onToggle={() => toggleExpandedFilter(filter.filter)}
      isOpen={isOpen}
      renderChipLabel={(selectedValue) => `${selectedValue.key}:${selectedValue?.value}`}
      selectedItems={selectedItems.length === 0 ? null : selectedItems}
    >
      <Box
        sx={{
          overflow: 'hidden',
          padding: '0 15px 0 15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Slider
          value={curValue}
          onChangeCommitted={handleChange}
          onChange={(_, newValue, activeThumb) => {
            if (!Array.isArray(newValue)) {
              return
            }

            if (activeThumb === 0) {
              const minValue = newValue[0] >= newValue[1] ? newValue[1] - 1 : newValue[0]
              setCurValue((c) => [minValue, c[1]])
            } else {
              const maxValue = newValue[1] <= newValue[0] ? newValue[0] + 1 : newValue[1]
              setCurValue((c) => [c[0], maxValue])
            }
          }}
          valueLabelDisplay="on"
          getAriaValueText={valueText}
          valueLabelFormat={valueText}
          aria-labelledby="range-slider"
          min={0}
          max={20}
          marks={currentMarks}
          sx={{
            width: '78%',
            transform: 'translateX(-5px)',
            margin: '40px 0',
            '& .MuiSlider-markLabel': {
              color: theme.palette.text.secondary,
            },
            '& .MuiSlider-markLabel.MuiSlider-markLabelActive': {
              color: theme.palette.text.primary,
            },
          }}
        />
      </Box>
    </DropdownWrapper>
  )
}
