import {
  Dialog,
  DialogContent,
  Box,
  styled,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Backdrop,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BulkInstrumentationSelect } from 'common/components/BulkInstrumentationSelect'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'common/components/Button'

const columns = [
  {
    field: 'functionName',
    headerName: 'Name',
    flex: 1,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <IconAwsLambda />
        {params.row.functionName}
      </Box>
    ),
  },
]

export const BulkEnableDevMode = ({
  compatibleFunctionCount,
  selectedFunctionCount,
  waitingForInstrumentation,
  instrumentationProgress,
  allResources,
  setResources = () => {},
  open,
  close,
}) => {
  const navigate = useNavigate()
  const [titleOpen, setTitleOpen] = useState(true)

  const collapsable = compatibleFunctionCount > 0

  const handleTitleChange = (_event, isExpanded) => {
    if (!collapsable) setTitleOpen(isExpanded)
  }
  const goToInventory = () => {
    const orgName = window.location.pathname.split('/')?.[1]
    navigate(`/${orgName}/settings/integrations`)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ paper: { background: '#262626' }, left: '80px' }}
      BackdropComponent={styled(Backdrop, {
        name: 'MuiModal',
        slot: 'Backdrop',
        overridesResolver: (props, styles) => {
          return styles.backdrop
        },
      })({ zIndex: -1, left: '80px' })}
    >
      <DialogContent>
        <Accordion expanded={!collapsable ? true : titleOpen} onChange={handleTitleChange}>
          <AccordionSummary
            sx={{
              padding: 0,
              paddingBottom: '10px',
              minHeight: 'unset!important',
              cursor: collapsable ? 'pointer' : 'default !important',
              '& .MuiAccordionSummary-content': {
                margin: '0 !important',
              },
            }}
            expandIcon={!collapsable ? <></> : <ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h2">Enable Dev Mode?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '8px 0px 0px' }}>
            <Typography color="text.primary">
              Dev Mode allows you to stream Logs, Traces & more from live AWS Lambda functions in
              less than 1 second, so you can get fast feedback while you develop.
              <br />
              <br />
              This feature requires your functions have instrumentation set to “Dev Mode”. We
              recommend only using this feature with functions that are in a development
              environment.
              {compatibleFunctionCount === 0 && (
                <>
                  <br />
                  <br />
                  You do not have any function with the "dev" environment tag. Please manage your
                  inventory to enable dev mode.
                </>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Divider sx={{ marginTop: '10px', marginBottom: '20px' }} />
        {compatibleFunctionCount > 0 ? (
          <BulkInstrumentationSelect
            selectHeight="400px"
            compatibleFunctionCount={compatibleFunctionCount}
            selectedFunctionCount={selectedFunctionCount}
            waitingForInstrumentation={waitingForInstrumentation}
            instrumentationProgress={instrumentationProgress}
            enableTargetFunctions={() => close({ enableDevMode: true })}
            columns={columns}
            allResources={allResources}
            setResources={setResources}
            cancelText="Configure integrations manually"
            setBypass={goToInventory}
            parentExpanded={titleOpen}
            expandChange={(expanded) => {
              if (collapsable && expanded) {
                setTitleOpen(false)
              } else if (!collapsable) {
                setTitleOpen(!expanded)
              }
            }}
          />
        ) : (
          <Button fullWidth color="primary" onClick={goToInventory}>
            Manage Inventory
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}
