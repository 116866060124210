import { IconAWSLambda } from './../../icons/IconAWSLambda'
import { formatLogDate } from '../../utils/helper'
import classes from './MessageHeader.module.css'
import { memo } from 'react'

export const Header = memo(function Header({ activity }) {
  const environment = activity?.tags?.environment
  const namespace = activity?.tags?.namespace
  const resourceName = activity?.tags?.aws?.resourceName
  const httpPath = activity?.tags?.aws?.lambda?.httpRouter?.path
  const httpMethod = activity?.tags?.aws?.lambda?.http?.method

  return (
    <div className={classes.header}>
      <div className={classes.headerText}>{formatLogDate(activity.timestamp || 0)}</div>
      <div className={classes.headerText}>
        <IconAWSLambda style={{ minWidth: '20px', fill: 'var(--activityBlackUltraLight)' }} />
        AWS LAMBDA
      </div>
      {environment && <div className={classes.headerText}>{environment}</div>}
      {namespace && <div className={classes.headerText}>{namespace}</div>}
      {resourceName && <div className={classes.headerText}>{resourceName}</div>}
      {httpMethod && <div className={classes.headerText}>{httpMethod}</div>}
      {httpPath && <div className={classes.headerText}>{httpPath}</div>}
    </div>
  )
})
