import { useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import { MetricsViewDropdownList } from 'metrics/components/Nav/MetricsViewDropdownList'
import { MetricsViewDropdownCreateOrClone } from 'metrics/components/Nav/MetricsViewDropdownCreateOrClone'
import { MetricsViewDropdownDelete } from 'metrics/components/Nav/MetricsViewDropdownDelete'
import { NavBarItemDropdown, NavBarItemDropdownInner } from '../../../common/components/NavBarItem'

export const MetricsViewDropdown = ({ setDropdown }) => {
  const [metricsViewToClone, setMetricsViewToClone] = useState(null)
  const [metricsViewToDelete, setMetricsViewToDelete] = useState(null)

  // A little finite state machine to help manage things neatly;
  // this is done to prevent the need to pollute the global state
  const variants = {
    LISTING: 'listing',
    CREATING: 'creating',
    CLONING: 'cloning',
    DELETING: 'deleting',
  }

  const [variant, setVariant] = useState(variants.LISTING)

  const showListing = () => setVariant('listing')
  const showCreate = () => setVariant('creating')
  const showClone = (selectedMetricsView) => {
    setMetricsViewToClone(selectedMetricsView)
    setVariant('cloning')
  }
  const showDelete = (selectedMetricsView) => {
    setMetricsViewToDelete(selectedMetricsView)
    setVariant('deleting')
  }

  const assembleContent = (v) => {
    switch (v) {
      case variants.CREATING:
        return <MetricsViewDropdownCreateOrClone onClose={showListing} setDropdown={setDropdown} />
      case variants.CLONING:
        return (
          <MetricsViewDropdownCreateOrClone
            metricsViewToClone={metricsViewToClone}
            onClose={showListing}
            setDropdown={setDropdown}
          />
        )
      case variants.DELETING:
        return (
          <MetricsViewDropdownDelete
            metricsViewToDelete={metricsViewToDelete}
            onClose={showListing}
            onDelete={showListing}
          />
        )
      case variants.LISTING:
      default:
        return (
          <MetricsViewDropdownList
            showClone={showClone}
            showCreate={showCreate}
            showDelete={showDelete}
            setDropdown={setDropdown}
          />
        )
    }
  }

  const content = assembleContent(variant)

  // Container rendering logic
  return (
    <ClickAwayListener onClickAway={() => setDropdown(null)}>
      <NavBarItemDropdown
        left="230px"
        initial={{ height: '0' }}
        animate={{ height: 'auto', transition: { duration: 0.2, ease: 'easeOut' } }}
      >
        <NavBarItemDropdownInner sx={{ padding: '30px' }}>{content}</NavBarItemDropdownInner>
      </NavBarItemDropdown>
    </ClickAwayListener>
  )
}
