import { useContext, useState, useEffect } from 'react'
import { LinearProgress, Tooltip } from '@mui/material'
import { NavBarItem } from 'common/components/NavBarItem'
import { IconDevMode } from 'common/icons/IconDevMode'
import { IconPause } from 'common/icons/IconPause'
import { MetricsContext } from 'metrics/context/MetricsContext'
import { FilterContext } from 'filter/context/FilterContext'

export const MetricsPoll = ({ dropdown, setDropdown, disabled }) => {
  const { getFilterValue } = useContext(FilterContext)
  const {
    metricsAutoRefreshEnabled,
    toggleMetricsAutoRefresh,
    setMetricsAutoRefreshEnabled,
    refreshMetricsData,
  } = useContext(MetricsContext)
  const [progressValue, setProgressValue] = useState(0)
  const queryTimeFrame = getFilterValue('globalTimeFrame')

  /**
   * Auto-refresh metrics data
   * MetricsContext stores the state and trigger of the auto-refresh because
   * other Components use that state.  However, the actual interval is configured
   * here because this is where the UI is updated.  Having multiple intervals
   * causes the UI to go out of sync with the data requests, so they are joined
   * here for that purpose.
   */
  useEffect(() => {
    let interval
    if (metricsAutoRefreshEnabled) {
      let intervalValue = progressValue
      interval = setInterval(() => {
        intervalValue = (intervalValue + 1) % 100
        setProgressValue(intervalValue)
        if (intervalValue === 99) {
          setProgressValue(0)
          refreshMetricsData({ refreshType: 'auto', globalTimeFrame: queryTimeFrame })
        }
      }, 30000 / 100)
    } else {
      setProgressValue(0)
      clearInterval(interval)
      interval = null
    }

    return () => clearInterval(interval)
  }, [metricsAutoRefreshEnabled, queryTimeFrame])

  /**
   * Each time the filters change, check if there is a relative timeframe filter applied.
   * If so, enable auto-refresh by default.
   */
  useEffect(() => {
    // Lastly, check if there is a relative timeframe filter applied.
    // If so, enable auto-refresh by default.
    if (disabled) {
      setMetricsAutoRefreshEnabled(false)
    } else {
      setMetricsAutoRefreshEnabled(true)
    }
  }, [disabled])

  let tooltipTitle
  if (disabled) {
    tooltipTitle = 'Select a 24 hour, 1 hour, or 15 minute time frame to enable auto refresh'
  } else if (metricsAutoRefreshEnabled) {
    tooltipTitle = 'Disable Auto Refresh'
  } else {
    tooltipTitle = 'Enable Auto Refresh'
  }

  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <NavBarItem
        data-cy="metrics-poll-toggle"
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            toggleMetricsAutoRefresh()
            setDropdown(null)
          }
        }}
        className={dropdown ? `notSelected` : null}
        style={metricsAutoRefreshEnabled ? { paddingBottom: '6px' } : {}}
      >
        {!metricsAutoRefreshEnabled ? <IconDevMode size={22} /> : <IconPause size={22} />}
        {metricsAutoRefreshEnabled && (
          <LinearProgress
            style={{
              position: 'absolute',
              zIndex: 2,
              bottom: '16px',
              left: 'auto',
              right: 'auto',
              height: '3px',
              width: '20%',
              opacity: 1,
            }}
            variant="determinate"
            value={progressValue}
          />
        )}
      </NavBarItem>
    </Tooltip>
  )
}
