import { Box, Typography } from '@mui/material'

export const n100K = 100000
export const n1MM = 1000000
export const n10MM = 10000000
export const n100MM = 100000000

export const Title = ({ title, sx }) => (
  <Typography sx={sx} variant="h1" gutterBottom>
    {String(title)?.includes('$') ? (
      <Box component="span" display="flex">
        <Box
          component="span"
          sx={{
            fontSize: 14,
            margin: '-2px 2px auto 0',
          }}
        >
          $
        </Box>
        {title.replace('$', '')}
      </Box>
    ) : (
      title
    )}
  </Typography>
)

export const animateProps = ({ delay = 0 } = {}) => ({
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { delay, duration: 0.6, ease: 'easeOut' },
  },
})
export const usd = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
  })
  return formatter.format(amount)
}

/** Get volume discount based on passed tracesCount
 * Formula is based on drawing a line through two points (x1,y1) and (x2,y2).
 * In this case x1=discountStartTraceCount, x2 = discountEndTraceCount,
 * y1 = discountMin, and y2= discountMax. This means that between the
 * discountStartTraceCount and discountEndTraceCount the discount rate will
 * be calculated to be between the discountMin and discountMax linearly.
 **/
export const getVolumeDiscountRate = (tracesCount = 0) => {
  const discountStartTraceCount = n10MM
  const discountEndTraceCount = 2 * n100MM
  const discountMin = 10
  const discountMax = 60
  let discountRate = 0
  if (tracesCount < discountStartTraceCount) {
    discountRate = 0
  } else if (tracesCount >= discountStartTraceCount && tracesCount < discountEndTraceCount) {
    discountRate = Math.round(
      ((discountMax - discountMin) / (discountEndTraceCount - discountStartTraceCount)) *
        (tracesCount - discountStartTraceCount) +
        discountMin
    )
  } else {
    discountRate = discountMax
  }
  return discountRate || 0
}
export const SectionHeader = ({ title, subTitle, mb = 2 }) => (
  <Box mb={mb}>
    <Typography variant="h2" sx={{ lineHeight: 1.1, mb: 2 }}>
      {title}
    </Typography>
    {subTitle && (
      <Typography
        variant="textSecondary"
        color="text.secondary"
        sx={{
          lineHeight: 1,
        }}
      >
        {subTitle}
      </Typography>
    )}
  </Box>
)

export const number = (amount) => {
  const formatter = new Intl.NumberFormat('en-US')

  if (amount < 1000) {
    return formatter.format(amount)
  }
  if (amount >= 1000000000) {
    return `${amount / 1000000000}B`
  }
  if (amount >= 1000000) {
    return `${amount / 1000000}M`
  }
  if (amount >= 1000 && amount % 1000 === 0) {
    return `${amount / 1000}K`
  }

  return formatter.format(amount)
}

export const initialTiers = [
  {
    value: n100K,
    label: number(n100K),
  },
  {
    value: n1MM * 1,
    label: number(n1MM * 1),
  },
  {
    value: n1MM * 5,
    label: number(n1MM * 5),
  },
]
export const supportedTiers = [
  {
    value: n10MM * 1,
    label: number(n10MM * 1),
  },
  {
    value: n10MM * 1.5,
    label: number(n10MM * 1.5),
  },
  {
    value: n10MM * 2,
    label: number(n10MM * 2),
  },
  {
    value: n10MM * 2.5,
    label: number(n10MM * 2.5),
  },
  {
    value: n10MM * 3,
    label: number(n10MM * 3),
  },
  {
    value: n10MM * 3.5,
    label: number(n10MM * 3.5),
  },
  {
    value: n10MM * 4,
    label: number(n10MM * 4),
  },
  {
    value: n10MM * 4.5,
    label: number(n10MM * 4.5),
  },
  {
    value: n10MM * 5,
    label: number(n10MM * 5),
  },
  {
    value: n10MM * 5.5,
    label: number(n10MM * 5.5),
  },
  {
    value: n10MM * 6,
    label: number(n10MM * 6),
  },
  {
    value: n10MM * 6.5,
    label: number(n10MM * 6.5),
  },
  {
    value: n10MM * 7,
    label: number(n10MM * 7),
  },
  {
    value: n10MM * 7.5,
    label: number(n10MM * 7.5),
  },
  {
    value: n10MM * 8,
    label: number(n10MM * 8),
  },
  {
    value: n10MM * 8.5,
    label: number(n10MM * 8.5),
  },
  {
    value: n10MM * 9,
    label: number(n10MM * 9),
  },
  {
    value: n10MM * 9.5,
    label: number(n10MM * 9.5),
  },
  {
    value: n100MM * 1,
    label: number(n100MM * 1),
  },
  {
    value: Math.round(n100MM * 1.1),
    label: number(Math.round(n100MM * 1.1)),
  },
  {
    value: n100MM * 1.2,
    label: number(n100MM * 1.2),
  },
  {
    value: n100MM * 1.3,
    label: number(n100MM * 1.3),
  },
  {
    value: n100MM * 1.4,
    label: number(n100MM * 1.4),
  },
  {
    value: n100MM * 1.5,
    label: number(n100MM * 1.5),
  },
  {
    value: n100MM * 1.6,
    label: number(n100MM * 1.6),
  },
  {
    value: n100MM * 1.7,
    label: number(n100MM * 1.7),
  },
  {
    value: n100MM * 1.8,
    label: number(n100MM * 1.8),
  },
  {
    value: n100MM * 1.9,
    label: number(n100MM * 1.9),
  },
  {
    value: n100MM * 2,
    label: number(n100MM * 2),
  },
  {
    value: n100MM * 2.5,
    label: number(n100MM * 2.5),
  },
  {
    value: n100MM * 3,
    label: number(n100MM * 3),
  },
  {
    value: n100MM * 3.5,
    label: number(n100MM * 3.5),
  },
  {
    value: n100MM * 4,
    label: number(n100MM * 4),
  },
  {
    value: n100MM * 4.5,
    label: number(n100MM * 4.5),
  },
  {
    value: n100MM * 5,
    label: number(n100MM * 5),
  },
  {
    value: n100MM * 5.5,
    label: number(n100MM * 5.5),
  },
  {
    value: n100MM * 6,
    label: number(n100MM * 6),
  },
  {
    value: n100MM * 6.5,
    label: number(n100MM * 6.5),
  },
  {
    value: n100MM * 7,
    label: number(n100MM * 7),
  },
  {
    value: n100MM * 7.5,
    label: number(n100MM * 7.5),
  },
  {
    value: n100MM * 8,
    label: number(n100MM * 8),
  },
]
