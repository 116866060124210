import { useContext } from 'react'
import { Fade, Tooltip, tooltipClasses } from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import { Box } from '@mui/system'
import { Button } from 'common/components/Button'
import { AppContext } from '../context/AppContext'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { usePrevious } from 'react-use'
import { motion } from 'framer-motion'
import { colors } from '../../theme/colors'
import { Link } from 'common/components/Link'
import { UsageBars } from 'settings/components/billing/UsageBars'

const METER_HEIGHT = 70

export const UsageMeter = (props) => {
  const theme = useTheme()
  const { activeOrg, isConfigMissing } = useContext(AppContext)
  const { orgName, orgId } = activeOrg || {}

  const {
    loadingUsage,
    loadingSubscription,
    isFreePlan,
    tracesUsagePercent,
    isTracesOverLimit,
    orgUsage,
    isTracesApproachingLimit,
  } = useOrgUsage({ orgId })

  const previousTracesUsagePercent = usePrevious(tracesUsagePercent)

  if (
    loadingUsage ||
    loadingSubscription ||
    (isConfigMissing && orgUsage?.traces === 0) ||
    !isFreePlan
  ) {
    return null
  }

  return (
    <StyledTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      placement="right"
      title={
        <Box px={1} py={2} textAlign="center">
          <UsageBars titleColumn />
          <Box mt={1}>
            <Link to={`/${orgName}/settings/billing`}>
              <Button color="primary" variant="outlined" size="small" fullWidth>
                Upgrade
              </Button>
            </Link>
          </Box>
        </Box>
      }
    >
      <Box width="100%" {...props}>
        <Box component={Link} to={`/${orgName}/settings/billing/plans`}>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={motion.div}
            animate={{
              scale: previousTracesUsagePercent !== tracesUsagePercent ? [1, 1.1, 1] : undefined,
              transition: { duration: 0.25, ease: 'easeOut' },
            }}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.25, ease: 'easeOut' },
            }}
          >
            <Box
              bgcolor={theme.palette.grey.light}
              width={8}
              height={METER_HEIGHT}
              transition="all 0.2s ease"
              borderRadius="6px"
              display="flex"
              justifyContent="flex-end"
              overflow="hidden"
            >
              <Box
                bgcolor={
                  isTracesOverLimit || isTracesApproachingLimit
                    ? colors.redPrimary
                    : theme.palette.primary.main
                }
                width="100%"
                height={`${(METER_HEIGHT / 100) * tracesUsagePercent}px`}
                borderRadius="6px"
                marginTop="auto"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledTooltip>
  )
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    minWidth: 220,

    border: `1px solid ${theme.palette.border.main}`,
  },
}))
