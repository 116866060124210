import { styled } from '@mui/styles'
import { motion } from 'framer-motion'

export const EmptyFilterArray = ({ loading = false, loadValuesError, options = [], ...rest }) => {
  if (!loading && !loadValuesError && options.length === 0) {
    return (
      <AddArrayValuesNoMore
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.25, ease: 'easeOut' } }}
        {...rest}
      >
        No more filter values found.
      </AddArrayValuesNoMore>
    )
  }
  return <></>
}

const AddArrayValuesNoMore = styled(motion.div)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  height: '100%',
  minHeight: '100px',
  width: '100%',
  padding: '0 0 0 0',
  boxSizing: 'border-box',
}))
