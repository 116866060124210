import React, { useState, useRef, useEffect, useContext, useMemo } from 'react'
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  styled,
  TextField,
  Tooltip,
} from '@mui/material'
import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import { Box, ClickAwayListener, Popper } from '@mui/material'
import { HelpCircle, Megaphone, MessageCircle, Book, Slack, X } from 'lucide-react'
import { useKey } from 'react-use'
import { motion } from 'framer-motion'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'

import { colors } from 'theme/colors'
import { sidebarLinkStyles } from './LayoutSidebar'
import { AppContext } from '../context/AppContext'
import { useTheme } from '@emotion/react'
import Button from 'common/components/Button'
import { useMemoCompare } from 'common/hooks/useMemoCompare'
import config from 'config'
import { cloneDeep, snakeCase } from 'lodash'

const { WebPageFeedbackV1Event } = WebEvents

let interval = null

export const SidebarHelp = () => {
  const theme = useTheme()
  const { activeOrg, user, toggleSupport, supportVisible, supportUnreadMessages } =
    useContext(AppContext)
  const { pathname } = useLocation()
  const params = useParams()
  const finalParams = useMemoCompare(
    params,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
  )
  const queryParams = useMemo(
    () => queryString.parse(window.location.search),
    [window.location.search]
  )
  const [feedbackInput, setFeedbackInput] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [open, setOpen] = useState(false)
  const [openFeedback, setOpenFeedback] = useState(false)
  const anchorRef = useRef(null)
  const [arrowRef, setArrowRef] = useState()
  const location = useLocation()
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const tooManyCharacters = feedbackInput.length > 255
  const disabled = feedbackInput.length === 0 || tooManyCharacters

  const closeFeedback = () => {
    clearTimeout(interval)
    setSubmitSuccess(false)
    setOpenFeedback(false)
  }

  useEffect(() => {
    return () => {
      clearTimeout(interval)
      setSubmitSuccess(false)
    }
  }, [])
  const submitFeedback = async () => {
    try {
      setSubmitting(true)
      const path = Object.keys(finalParams).reduce((str, key) => {
        const value = finalParams[key]
        return str.replace(value, `:${key}`)
      }, pathname)

      const parsedQs = cloneDeep(queryParams)
      delete parsedQs.code
      delete parsedQs.transactionId

      const session = JSON.parse(window.localStorage.getItem(config.localStorageKey) || '{}')
      const slsCoreEventsClient = new SlsCoreEventsClient(
        config.platform.eventsUrl,
        session.idToken
      )
      // Send event
      const pageViewEvent = new WebPageFeedbackV1Event({
        orgUid: activeOrg?.orgId,
        path: path,
        url: pathname,
        userId: user?.userId,
        params: Object.keys(finalParams || {}).reduce(
          (obj, key) => ({
            ...obj,
            [snakeCase(key)]: finalParams[key],
          }),
          {}
        ),
        queryParameters: Object.keys(parsedQs || {}).reduce(
          (obj, key) => ({
            ...obj,
            [snakeCase(key)]: parsedQs[key],
          }),
          {}
        ),
        feedback: feedbackInput,
      })
      await slsCoreEventsClient.publish(pageViewEvent)
    } catch (error) {
      console.error('Could not publish feedback', error)
    } finally {
      setSubmitting(false)
      setSubmitSuccess(true)
      interval = setTimeout(() => {
        closeFeedback()
        setSubmitSuccess(false)
      }, 4000)
    }
  }

  useEffect(() => handleClose(), [location.pathname])
  useKey('Escape', handleClose)
  return (
    <>
      <Box display="flex" flexDirection="column" mt="auto" alignItems="center">
        <Tooltip title="Need help?" placement={'right'} TransitionComponent={Fade}>
          <SupportLink
            className={supportVisible || open ? 'active' : ''}
            onClick={supportVisible ? toggleSupport : handleOpen}
          >
            <SidebarLinkInner ref={anchorRef}>
              {supportVisible ? (
                <SupportAnimationWrapper
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{
                    scale: [0.5, 1.25, 1],
                    opacity: [0, 1, 1],
                  }}
                >
                  <X size={24} style={{ marginBottom: '-5px' }} />
                </SupportAnimationWrapper>
              ) : (
                <SupportLinkContainer>
                  {supportUnreadMessages ? (
                    <SupportLinkMessageCount
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{
                        scale: [0.5, 1.25, 1],
                        opacity: [0, 1, 1],
                      }}
                    >
                      {supportUnreadMessages}
                    </SupportLinkMessageCount>
                  ) : null}
                  <HelpCircle size="24" style={{ path: theme.palette.colors.greyMedium }} />
                </SupportLinkContainer>
              )}
            </SidebarLinkInner>
          </SupportLink>
        </Tooltip>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="right-end"
          disablePortal={false}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
          style={{ zIndex: 10000 }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <ContainerPopper
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { duration: 0.25, ease: 'easeOut' } }}
            >
              <PopperArrow ref={setArrowRef} />

              <PopperElement>
                <HelpTitle>
                  <PopperTitleLabel>Help Resources</PopperTitleLabel>
                </HelpTitle>

                <CustomDivider />

                <PopperList>
                  <PopperListItem
                    onClick={() => {
                      handleClose()
                      toggleSupport()
                    }}
                  >
                    <PopperListItemIcon>
                      <MessageCircle size="20" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Chat with us</PopperListItemTitle>
                  </PopperListItem>

                  <CustomDivider />

                  <PopperListItem
                    onClick={() => window.open('https://www.serverless.com/console/docs', '_blank')}
                  >
                    <PopperListItemIcon>
                      <Book size="20" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Documentation</PopperListItemTitle>
                  </PopperListItem>

                  <CustomDivider />
                  <PopperListItem
                    onClick={() =>
                      window.open(
                        'https://serverless-contrib.slack.com/archives/C037D989FB5',
                        '_blank'
                      )
                    }
                  >
                    <PopperListItemIcon>
                      <Slack size="20" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Community Slack</PopperListItemTitle>
                  </PopperListItem>

                  <CustomDivider />
                  <PopperListItem
                    onClick={() => {
                      handleClose()
                      setOpenFeedback(true)
                    }}
                  >
                    <PopperListItemIcon>
                      <Megaphone size="20" style={{ color: '#000000' }} />
                    </PopperListItemIcon>
                    <PopperListItemTitle>Feedback</PopperListItemTitle>
                  </PopperListItem>
                </PopperList>
              </PopperElement>
            </ContainerPopper>
          </ClickAwayListener>
        </Popper>
      </Box>
      <Dialog
        open={openFeedback}
        onClose={closeFeedback}
        aria-labelledby="feedback-dialog"
        aria-describedby="feedback-dialog-"
        maxWidth="sm"
        fullWidth
        sx={{
          paper: { background: '#262626' },
          left: '80px',
        }}
        BackdropComponent={styled(Backdrop, {
          name: 'MuiModal',
          slot: 'Backdrop',
          overridesResolver: (props, styles) => {
            return styles.backdrop
          },
        })({ zIndex: -1, left: '80px' })}
      >
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          {submitSuccess ? (
            <DialogContentText>
              Thank you for taking the time to submit feedback! Your feedback is very valuable to
              us.
            </DialogContentText>
          ) : (
            <TextField
              variant="outlined"
              autoFocus
              margin="dense"
              id="feedback-input"
              sx={{
                '& .MuiInputBase-root': { padding: '5px 0 3px 0' },
                ...(tooManyCharacters
                  ? { '& .MuiFormHelperText-root': { color: theme.palette.error.main } }
                  : {}),
              }}
              multiline
              fullWidth
              onChange={(e) => {
                setFeedbackInput(e.target.value)
              }}
              placeholder="Let us know what you think about Console."
              helperText={`${feedbackInput.length}/255`}
            />
          )}
        </DialogContent>
        <DialogActions>
          {submitSuccess ? (
            <Button fullWidth color="primary" onClick={closeFeedback}>
              Close
            </Button>
          ) : (
            <Button
              loading={submitting}
              disabled={disabled}
              fullWidth
              color="primary"
              onClick={submitFeedback}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

/**
 * Styles
 */

const SidebarLinkInner = styled(motion.div)(({ theme }) => ({
  display: 'flex',
}))

const SupportLink = styled(motion.div)(({ theme }) => ({
  ...sidebarLinkStyles(theme),
  margin: '0 0 10px 0',
}))

const SupportLinkContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}))

const SupportLinkMessageCount = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  justifySelf: 'center',
  color: theme.palette.secondary.main,
  background: theme.palette.primary.main,
  borderRadius: '20px',
  fontSize: '12px',
  lineHeight: '19px',
  height: '20px',
  width: '20px',
  padding: '0px',
  margin: '0 0 5px 0',
}))

const SupportAnimationWrapper = styled(motion.div)(({ theme }) => ({
  display: 'block',
}))

const ContainerPopper = styled(motion.div)(() => ({
  position: 'relative',
  top: '8px',
  left: 0,
  zIndex: 103,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
  height: 'fit-content',
  width: '320px',
  padding: '0 0 0 16px',
}))

const PopperElement = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: 'fit-content',
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '4px',
  boxShadow:
    '0px 3px 20px -2px rgba(0,0,0,0.2), 0px 3px 20px 0px rgba(0,0,0,0.14), 0px 1px 20px 0px rgba(0,0,0,0.12)',
}))

const PopperList = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '100%',
  width: '100%',
}))

const PopperListItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 0 0 30px',
  overflow: 'hidden',
  minHeight: '50px',
  width: '100%',
  transition: 'all 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    background: colors.greyLight,
  },
}))

const PopperListItemIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  width: '13%',
}))

const PopperListItemTitle = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#000000',
  height: '100%',
  width: '87%',
}))

export const PopperArrow = styled('div')(() => ({
  position: 'absolute',
  fontSize: 14,
  top: '-15px !important',
  zIndex: 100,
  left: 7,
  height: '8px',
  width: '3px',
  marginBottom: '42px',

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '1em 1em 1em 0',
    borderColor: `transparent #FFFFFF transparent transparent`,
  },
}))

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.colors.greyMedium,
}))

const HelpTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: 'auto',
  width: '100%',
  padding: '18px 0 14px 30px',
  userSelect: 'none',
}))

const PopperTitleLabel = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#000000',
  height: 'auto',
  width: '100%',
  fontSize: '18px',
}))
