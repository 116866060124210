import { useContext, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Search, X } from 'lucide-react'
import { EmptyFilterArray } from 'filter/components/EmptyFilterArray'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { LoadingSpinner } from 'common/components/LoadingSpinner'
import { sortBy, uniq, keys, union } from 'lodash'
import { AppContext } from 'app/context/AppContext'
import { useDebounce } from 'react-use'

import { Checkbox } from './Checkbox'
import { animateProps } from 'filter/util/filterAnimateProps'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { stringifyUrl } from 'query-string'
import { DropdownWrapper } from './DropdownWrapper'

const defaultKeyOptions = [
  {
    label: 'Path',
    key: 'path',
    queryField: 'tags.aws.lambda.httpRouter.path',
    isSelected: true,
    options: [],
  },
  {
    label: 'Method',
    key: 'method',
    isSelected: false,
    options: ['GET', 'POST', 'PUT', 'OPTIONS', 'PATCH', 'DELETE'],
  },
  {
    label: 'Status Code',
    key: 'status',
    isSelected: false,
    options: [
      { label: '2xx', children: [200, 201, 202, 203, 204, 205, 206, 207, 208, 226] },
      {
        label: '3xx',
        children: [300, 301, 302, 303, 304, 305, 306, 307, 308],
      },
      {
        label: '4xx',
        children: [
          400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417,
          418, 421, 422, 423, 424, 425, 426, 428, 429, 431, 451,
        ],
      },
      {
        label: '5xx',
        children: [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511],
      },
    ],
  },
]
const SIZE = 250
export const FilterTagSearchDropdown = ({
  filter,
  onChange = {},
  expandedFilter,
  toggleExpandedFilter,
}) => {
  const height = 200

  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}

  const isOpen = expandedFilter === filter.filter
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState(filterText)
  const [keyOptions, setKeyOptions] = useState(defaultKeyOptions)

  /**
   * Currently selected key, can be path, pathParams, method, or status.
   * Selected Key will have 'isSelected: true' property
   */

  const selectedKey = useMemo(() => keyOptions.find((key) => key.isSelected), [keyOptions])

  const { options } = selectedKey || {}

  /**
   * Converts current filter value object to an array of key/value.
   * [{key: 'method', value: 'POST'}, {key: 'method', value: 'GET'}, {key: 'status', value: '200'}]
   */
  const filterSelectedValues = useMemo(
    () =>
      keys(filter.value).reduce((acc, key) => {
        return [...acc, ...filter.value?.[key]?.map((value) => ({ key, value }))]
      }, []),

    [filter]
  )
  /**
   * Add a debounce to update search input text changes
   */
  useDebounce(
    () => {
      setDebouncedFilterText(filterText)
    },
    500,
    [filterText]
  )

  /**
   * Only fetch when dropdown is open and selected key is path or pathParams
   */
  const ready = (isOpen && selectedKey?.key.includes('path')) || false

  const url = stringifyUrl({
    url: `/search/orgs/${orgId}/spans/aggregations`,
    query: {
      fields: [selectedKey?.queryField],
      query: debouncedFilterText,
    },
  })

  const swrKey = ready && JSON.stringify({ url, ready })

  const { data, error } = useSWR(swrKey, () => coreApiClient({ url }))

  /**
   * Loading while fetching data
   */
  const loading = ready && !data && !error

  useEffect(() => {
    if (data) {
      setKeyOptions((prev) =>
        prev.map((opt) => {
          /**
           * Set fetched options to the currently selected key (path, pathParams,)
           */
          if (opt.queryField !== selectedKey?.queryField) {
            return opt
          }
          /**
           * Make sure options are sorted (selected should be shown first) and make sure values are unique
           */
          const options = sortBy(uniq(data.slice(0, SIZE)), [
            (option) => !filterSelectedValues?.some((n) => n.value === option),
            (option) => option,
          ])

          return { ...opt, options }
        })
      )
    }
  }, [data])

  /**
   * Toggle collapsed state
   */
  const toggle = () => {
    toggleExpandedFilter(filter.filter)
    setFilterText('')
  }
  /**
   * Check to see if current option is selected or not
   * @param option - Can be a string (i.e 'GET' ) or an object containing children (i.e used for nested status codes)
   * @returns
   */
  const isCurrentOptionChecked = (option) => {
    /**
     * Make sure all children are checked in order to check parent
     */
    if (option?.children) {
      return option.children?.every((value) => filter.value?.[selectedKey?.key]?.includes(value))
    } else {
      return filter.value?.[selectedKey?.key]?.includes(option)
    }
  }
  /**
   * On option item select
   * @param option - Can be a string (i.e 'GET' ) or an object containing children (i.e used for nested status codes)
   * @returns
   */
  const onOptionSelect = (option) => {
    let value = filter.value?.[selectedKey?.key] || []
    /**
     * If clicked on parent make sure ALL children are selected
     */
    if (selectedKey?.key === 'status' && option?.children) {
      const exits = option.children?.every((val) => value?.includes(val))
      /**
       * Toggle selected status
       */
      if (exits) {
        value = value.filter((i) => !option.children.includes(i))
      } else {
        value = union(value, option?.children)
      }
    } else {
      const exits = value?.includes(option)
      if (exits) {
        value = value.filter((i) => i !== option)
      } else {
        value.push(option)
      }
    }
    /**
     * Make sure to include other keys values if found (i.e method, status, path...etc)
     */
    const prevValues = keys(filter.value)?.map((key) => ({ key, value: filter.value?.[key] })) || []
    const tags = [
      ...prevValues,
      {
        key: selectedKey?.key,
        value,
      },
    ]

    onChange(filter, tags)
  }
  /**
   * On tag remove make sure to update filter values accordingly
   */

  const onRemove = (item) => {
    const tags =
      keys(filter.value)?.reduce((acc, key) => {
        if (item.key === key && filter.value?.[key]?.length) {
          return [
            ...acc,
            {
              key,
              value: filter.value?.[key]?.filter((i) => i !== item.value),
            },
          ]
        }
        return [...acc, { key, value: filter.value?.[key] }]
      }, []) || []
    onChange(filter, tags)
  }

  return (
    <DropdownWrapper
      isOpen={isOpen}
      title={filter.label}
      isLoading={loading}
      onToggle={toggle}
      onDelete={(selectedValue) => onRemove(selectedValue)}
      renderChipLabel={(selectedValue) => `${selectedValue?.key}:${selectedValue?.value}`}
      selectedItems={filterSelectedValues}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 10px 10px 10px',
        }}
      >
        <Select
          labelId="http-tag-key"
          id="http-tag-select"
          variant="outlined"
          sx={{ flex: 1, '& .MuiSelect-select': { padding: '8px 9px' } }}
          value={selectedKey?.key}
          onChange={(e) => {
            setKeyOptions((prev) =>
              prev.map((keyOpt) => ({ ...keyOpt, isSelected: e.target.value === keyOpt.key }))
            )
            setFilterText('')
          }}
        >
          {keyOptions.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selectedKey?.key.includes('path') && (
        <TextField
          placeholder={`Search`}
          value={filterText}
          sx={{
            padding: '0 10px 10px 10px',
          }}
          InputProps={{
            style: {
              paddingLeft: 10,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search size={18} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {filterText && (
                  <IconButton onClick={() => setFilterText('')}>
                    <X size={16} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setFilterText(e.target.value)
          }}
        />
      )}
      <Divider />

      <Box
        sx={{
          overflow: 'auto',

          height,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <InfiniteScroll
          dataLength={options.length}
          loader={<LoadingSpinner minHeight="100%" />}
          height={height}
          style={{ overflowX: 'hidden' }}
        >
          {!options?.length && !loading && filterText === debouncedFilterText ? (
            <EmptyFilterArray options={options} style={{ height: '40%' }} />
          ) : null}

          {uniq(options).map((option, i) => (
            <DropdownOption
              key={`filters-${filter?.name}-options-${i}`}
              option={option}
              onClick={(e, val) => onOptionSelect(val)}
              isCurrentOptionChecked={isCurrentOptionChecked}
            />
          ))}
        </InfiniteScroll>
      </Box>
    </DropdownWrapper>
  )
}

const DropdownOption = ({ option, onClick, isCurrentOptionChecked, icon }) => {
  const isSelected = isCurrentOptionChecked(option) || false
  const [isOpen, setIsOpen] = useState(option.children?.some((opt) => isCurrentOptionChecked(opt)))

  return (
    <>
      <ValueItem
        onClick={(e) => onClick(e, option)}
        sx={{ color: isSelected ? 'text.primary' : 'text.secondary' }}
      >
        <Checkbox checked={isSelected} />
        {icon}
        <Typography variant="textPrimary">{option?.label || option}</Typography>
        {option.children && (
          <DropdownIcon
            isOpen={isOpen}
            style={{ marginLeft: 'auto' }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsOpen((prev) => !prev)
            }}
          />
        )}
      </ValueItem>
      {isOpen && (
        <Box sx={{ marginLeft: '20px', overflow: 'hidden' }} {...animateProps}>
          {option?.children?.map((childOption, idx) => (
            <DropdownOption
              key={`filters-${childOption}-options-${idx}`}
              option={childOption}
              onClick={(e, val) => onClick(e, val)}
              isCurrentOptionChecked={isCurrentOptionChecked}
              icon={icon}
            />
          ))}
        </Box>
      )}
    </>
  )
}

const ValueItem = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  width: '100%',
  padding: '5px 10px',
  transition: 'all 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    color: props.theme.palette.text.primary,
    backgroundColor: props.theme.palette.grey.dark,
  },
}))
