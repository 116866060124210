export const IconAWSKinesis = ({ style = {} }) => {
  return (
    <svg style={style} focusable="false" aria-hidden viewBox="-1 -1 22 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.576 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm-2.629-6.583h-.709c0-1.537 1.062-2.96 8.762-2.96v.735c-8.053 0-8.053 1.56-8.053 2.225ZM8.88 15h-.71c0-1.62.932-3.276 7.831-3.276v.734c-7.121 0-7.121 1.782-7.121 2.542ZM6 7.219h.709c0 .89 1.056 1.913 9.291 1.913v.735c-8.235 0-9.291 1.023-9.291 1.913H6c0-1.054.71-1.848 3.44-2.28C6.71 9.068 6 8.274 6 7.219Zm1.238-1.636h.71c0 .665 0 2.225 8.052 2.225v.735c-7.7 0-8.762-1.423-8.762-2.96ZM16 7.276v-.734C8.879 6.542 8.879 4.76 8.879 4h-.71c0 1.62.932 3.276 7.831 3.276Z"
      />
    </svg>
  )
}
