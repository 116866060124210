import { Typography, styled } from '@mui/material'
import ErrorState from 'common/components/ErrorState'

export const NoRowsOverlay = ({ error }) => (
  <NoData>
    {error ? (
      <ErrorState reloadButtonLabel="" />
    ) : (
      <>
        <Typography variant="textPrimary">No trace data available for this time frame.</Typography>
        <Typography variant="textTertiary" color="text.secondary">
          If your functions have prod instrumentation mode on, Traces may not appear as they are
          being sampled.
        </Typography>
      </>
    )}
  </NoData>
)
const NoData = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: 'auto',
  width: '100%',
  overflow: 'hidden',
}))
