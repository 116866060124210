import { useContext, useEffect, useState } from 'react'
import { Label, SecretContext, SubLabel } from './SecretsDialog'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { validateSecret } from 'settings/util/validateSecret'
import { Box, Divider, InputAdornment, TextField, Typography } from '@mui/material'
import { Checkbox } from 'common/components/Checkbox'
import Button from 'common/components/Button'
import { LoadingSoundwave } from 'common/components/LoadingSoundwave'
import { CopyText } from 'common/components/CopyText'
import { ErrorMessage } from 'common/components/ErrorMessage'

export const SectionArn = () => {
  const {
    orgId,
    secret,
    setErrorMessage,
    enqueueSnackbar,
    closeDialog,
    refresh,
    loadingSave,
    errorMessage,
    selectedSecret,
    save,
  } = useContext(SecretContext)
  const [arn, setArn] = useState(selectedSecret?.provider?.arn || '')
  const [loadingUrl, setLoadingUrl] = useState(false)
  const [stack, setStack] = useState(null)
  const [isAccountSelected, setIsAccountSelected] = useState(!selectedSecret)
  /**
   * Check stack status and handle IN_PROGRESS and COMPLETED status
   */
  useSWR(stack?.id && `/secrets/${orgId}/stacks/simple/${stack?.id}`, coreApiClient, {
    refreshInterval: stack?.status === 'IN_PROGRESS' ? 1000 : 0,
    onSuccess: (data, key, config) => {
      if (data?.simpleStack?.status === 'IN_PROGRESS') {
        setStack((prev) => ({ ...prev, ...(data?.simpleStack || {}) }))
      } else if (data?.simpleStack?.status === 'COMPLETED') {
        enqueueSnackbar('AWS Account added successfully', {
          autoHideDuration: 2000,
        })
        closeDialog()
        refresh()
      } else {
        setErrorMessage('Something went wrong')
      }
    },
  })

  const dataToSave = {
    name: secret?.name || '',
    path: secret?.path || '',
    valueType: 'PROVIDER',
    provider: {
      providerType: 'ARN',
      arn,
    },
  }

  const inProgress = stack?.status === 'IN_PROGRESS'
  const connectAWS = async () => {
    setLoadingUrl(true)
    try {
      /**
       * Validate only path and name since we don't have an arn
       */
      validateSecret(secret)
      const res = await coreApiClient({
        url: `/secrets/${orgId}/stacks/simple?name=${dataToSave?.name}&path=${dataToSave?.path}`,
      })
      setStack(res)
      if (res?.url) {
        window.open(res.url)
      }
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err?.message || 'Something went wrong')
    }
    setLoadingUrl(false)
  }

  const titleStyles = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    mb: 1.3,
  }

  useEffect(() => {
    setErrorMessage('')
  }, [arn, isAccountSelected])

  /**
   * Validate inputs before saving
   */
  const handleArnSave = () => {
    try {
      validateSecret(dataToSave)
      save(dataToSave)
    } catch (err) {
      setErrorMessage(err?.message)
    }
  }
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          gap: '10px',
          minHeight: 100,
          mt: 4,
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        }}
      >
        <Box
          onClick={() => {
            /**
             * Disable selection when edit an existing secret
             */
            if (!selectedSecret) {
              setIsAccountSelected(true)
            }
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              ...titleStyles,
              opacity: selectedSecret ? 0.5 : 1,
            }}
          >
            <Checkbox checked={isAccountSelected} disabled={!!selectedSecret} />
            <Label>Connect AWS Account</Label>
          </Box>
          <SubLabel>Easily connect your AWS account by adding a CloudFormation Stack</SubLabel>
          <Button
            variant={selectedSecret ? 'outlined' : 'contained'}
            color={isAccountSelected ? 'primary' : 'secondary'}
            sx={{ width: '100%', height: '35px' }}
            onClick={connectAWS}
            loading={loadingUrl || inProgress}
            disabled={!!selectedSecret} // disable if editing selected secret
          >
            Connect to AWS Account
          </Button>
          {inProgress && (
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Typography variant="textSecondary" color="text.secondary">
                Waiting CloudFormation Stack to be added
              </Typography>
              <Box width="50px">
                <LoadingSoundwave />
              </Box>
            </Box>
          )}
        </Box>

        <Divider
          orientation="vertical"
          sx={{ color: (theme) => theme.palette.text.secondary, padding: 0 }}
        >
          or
        </Divider>
        <Box onClick={() => setIsAccountSelected(false)}>
          <Box
            sx={{
              ...titleStyles,
            }}
          >
            <Checkbox checked={!isAccountSelected} />
            <Label>AWS Access role ARN</Label>
          </Box>

          <TextField
            value={arn}
            onChange={(e) => {
              setArn(e.target.value)
            }}
            placeholder="ARN"
            onKeyUp={(event) => {
              /**
               * If last input and 'Enter' key was pressed
               */
              if (event.key === 'Enter') {
                handleArnSave()
              }
            }}
            inputProps={{}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {arn && <CopyText value={arn} iconSize={15} />}
                </InputAdornment>
              ),
            }}
          />

          <Button
            // size="small"
            color="primary"
            loading={loadingSave}
            disabled={!!isAccountSelected || !arn}
            onClick={handleArnSave}
            sx={{ minWidth: '100%', mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box sx={{ minWidth: '100%', marginTop: 'auto' }}>
        <ErrorMessage message={errorMessage} alertProps={{ sx: { marginTop: '0 !important' } }} />
      </Box>
    </>
  )
}
