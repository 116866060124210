import {
  styled,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Fade,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import { cloneDeep } from 'lodash'
import { ChevronRight } from 'lucide-react'
import { TagBox } from 'common/components/TagBox'
import { defaultSDKTags, getSelectedSpanTags } from 'common/packages/ActivityStream/utils/helper'

/**
 * Render Details Compute
 */
const renderDetailsCompute = (tags, theme) => {
  if (!tags.aws?.resourceName) {
    return null
  }

  const uiData = {}
  uiData.tags = []

  uiData.tags.push({
    exists: true,
    title: 'Function',
    value: tags.aws.resourceName,
    showCopy: true,
    description: 'The name of the AWS Lambda function that performed this request.',
  })
  if (tags.environment) {
    uiData.tags.push({
      exists: true,
      title: 'Environment',
      showCopy: true,
      value: tags.environment,
      description: 'The "ennvironment" tag set on this resource.',
    })
  }
  if (tags.namespace) {
    uiData.tags.push({
      exists: true,
      title: 'Namespace',
      showCopy: true,
      value: tags.namespace,
      description: 'The "namespace" tag set on this resource.',
    })
  }
  if (tags.aws?.region) {
    uiData.tags.push({
      exists: true,
      title: 'Region',
      showCopy: true,
      value: tags.aws.region,
      description: 'The region of the AWS Lambda function that performed this request.',
    })
  }
  if (tags.aws?.lambda) {
    uiData.tags.push({
      exists: true,
      title: 'Cold Start',
      value: tags.aws.lambda?.isColdstart === true ? 'true' : 'false',
      description:
        'If this AWS Lambda function invocation was a cold start, which requires additional time to initialize its underlying container, resulting in additional latency.',
    })
  }
  if (tags?.aws?.lambda?.maxMemory) {
    uiData.tags.push({
      exists: true,
      title: 'Memory Used',
      value: tags?.aws?.lambda?.maxMemory,
      description: 'The maximum memory used within your AWS Lambda function.',
    })
  }
  if (tags?.aws?.lambda?.requestId) {
    uiData.tags.push({
      exists: true,
      title: 'Request ID',
      showCopy: true,
      value: tags?.aws?.lambda?.requestId,
      description: 'The AWS Lambda Request ID. This is a property from AWS.',
    })
  }
  if (tags?.aws?.lambda?.arch) {
    uiData.tags.push({
      exists: true,
      title: 'Arch',
      value: tags?.aws?.lambda?.arch,
      description: 'The type of computer processor that is set for this AWS Lambda function.',
    })
  }
  if (tags?.aws?.lambda?.runtime) {
    uiData.tags.push({
      exists: true,
      title: 'Runtime',
      value: tags?.aws?.lambda?.runtime?.identifier,
      description: 'AWS Lambda configured runtime.',
    })
  }

  return (
    <SectionTagsContainer
      sx={{
        paddingTop: '5px',
        paddingBottom: '5px',
      }}
    >
      {uiData.tags.map((t, i) => (
        <TagBox tag={t} key={`compute-summary-tags-${i}`} />
      ))}
    </SectionTagsContainer>
  )
}

/**
 * Render Durations
 */
const renderDurations = (durations = [], theme) => {
  // Validate format
  const uiDurations = durations.map((d) => {
    if (d && !d?.value) {
      d = { value: d }
    }
    if (d && !d?.title) {
      d.title = 'Duration'
      d.description = 'The total duration of this operation.'
    }
    return d
  })

  return (
    <SectionTagsContainer
      sx={{
        paddingTop: '5px',
        marginBottom: 0,
      }}
    >
      {uiDurations.map((duration, i) => (
        <Tooltip
          title={duration.description || null}
          placement="top"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          key={`duration-tooltip-${i}`}
        >
          <Duration>
            <Typography
              variant="textSecondary"
              color="text.secondary"
              sx={{ fontFamily: "'Roboto Mono', monospace" }}
            >
              {duration.title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
              <Typography
                variant={'text.primary'}
                color="text.primary"
                sx={{
                  fontFamily: "'Roboto Mono', monospace",
                  margin: '7px 0 0 0',
                }}
              >
                {duration.value}
              </Typography>
            </Box>
          </Duration>
        </Tooltip>
      ))}
    </SectionTagsContainer>
  )
}

/**
 * Render Details for Span "aws.lambda"
 * - Unique presentation for this Span.
 */
const renderInputEventTags = (tags, props, theme) => {
  // Clone tags to edit for presentation
  const clonedTags = cloneDeep(tags)

  const uiData = {}

  /**
   * AWS Lambda: Event Tags
   * Curate General Tags if these are from the "aws.lambda" Span
   */

  /**
   * AWS Lambda: Event: Enriched: AWS API Gateway
   */
  if (clonedTags.aws.lambda.eventSource === 'aws.apigateway' && clonedTags.aws.lambda.apiGateway) {
    uiData.event = {}
    uiData.event.tags = []
    uiData.event.enrichedName = {}

    if (clonedTags.aws.lambda.eventType === 'aws.apigatewayv2.http.v2') {
      uiData.event.enrichedName.value = 'Event • AWS API Gateway HTTP API'
      uiData.event.enrichedName.description =
        'This AWS Lambda function was triggered by AWS API Gateway HTTP API (aka API Gateway V2).'
    } else {
      uiData.event.enrichedName.value = 'AWS API Gateway'
      uiData.event.enrichedName.description =
        'This AWS Lambda function was triggered by AWS API Gateway (aka API Gateway V1).'
    }

    // Add Tags

    if (clonedTags.aws.lambda.httpRouter?.path) {
      uiData.event.tags.push({
        exists: true,
        title: 'Path',
        showCopy: true,
        value: clonedTags.aws.lambda.httpRouter.path,
        description:
          'This path of your HTTP request. This may have been auto-enriched by any app frameworks in use (e.g. Express.js).',
      })
    } else if (clonedTags.aws.lambda.http?.path) {
      uiData.event.tags.push({
        exists: true,
        title: 'Path',
        showCopy: true,
        value: clonedTags.aws.lambda.http.path,
        description:
          'This path of your HTTP request. This may have been auto-enriched by any app frameworks in use (e.g. Express.js).',
      })
    }
    if (clonedTags.aws.lambda.http?.method) {
      uiData.event.tags.push({
        exists: true,
        title: 'Method',
        showCopy: true,
        value: clonedTags.aws.lambda.http.method,
        description: 'This method of your HTTP request.',
      })
    }
    if (clonedTags.aws.lambda.http?.statusCode) {
      uiData.event.tags.push({
        exists: true,
        title: 'Status',
        showCopy: true,
        value: clonedTags.aws.lambda.http.statusCode,
        isError: clonedTags.aws.lambda.http.statusCode >= 500,
        description:
          'The status code returned by the code in this invocation which was triggered by an HTTP request.',
      })
    }
    if (clonedTags.aws.lambda.http?.host) {
      uiData.event.tags.push({
        exists: true,
        title: 'Host',
        showCopy: true,
        value: clonedTags.aws.lambda.http.host,
        description: 'This host (URL) of your HTTP request.',
      })
    }
    if (clonedTags.aws.lambda.http?.protocol) {
      uiData.event.tags.push({
        exists: true,
        title: 'Protocol',
        showCopy: true,
        value: clonedTags.aws.lambda.http.protocol,
        description: 'This protocol of your HTTP request.',
      })
    }
    if (clonedTags.aws.lambda.apiGateway?.request?.id) {
      uiData.event.tags.push({
        exists: true,
        title: 'API Request ID',
        showCopy: true,
        value: clonedTags.aws.lambda.apiGateway?.request?.id,
        description:
          'The AWS API Gateway Request ID for the HTTP request that triggered this AWS Lambda invocation.',
      })
    }
    if (clonedTags.aws.lambda.apiGateway?.request?.timeEpoch) {
      uiData.event.tags.push({
        exists: true,
        title: 'API Request Time',
        value: clonedTags.aws.lambda.apiGateway.request.timeEpoch,
        description:
          'The time the HTTP request was received by AWS API Gateway, which triggered this AWS Lambda invocation.',
      })
    }
    if (clonedTags.aws.lambda.apiGateway?.apiId) {
      uiData.event.tags.push({
        exists: true,
        title: 'API ID',
        showCopy: true,
        value: clonedTags.aws.lambda.apiGateway.apiId,
        description:
          'The ID of the API from AWS API Gateway that triggered this AWS Lambda invocation.',
      })
    }
    if (clonedTags.aws.lambda.apiGateway?.apiStage) {
      uiData.event.tags.push({
        exists: true,
        title: 'API Stage',
        value: clonedTags.aws.lambda.apiGateway.apiStage,
        description:
          'The Stage of the API from AWS API Gateway that triggered this AWS Lambda invocation.',
      })
    }
  } else if (clonedTags.aws.lambda.eventSource === 'aws.sqs') {
    /**
     * AWS Lambda: Event: Enriched: AWS SQS
     */
    uiData.event = {}
    uiData.event.tags = []
    uiData.event.enrichedName = {}
    uiData.event.enrichedName.value = 'AWS SQS'
    uiData.event.enrichedName.description = 'This AWS Lambda function was triggered by AWS SQS.'

    // Add Tags
    if (clonedTags.aws.lambda.sqs && Object.keys(clonedTags.aws.lambda.sqs).length) {
      for (const prop in clonedTags.aws.lambda.sqs) {
        uiData.event.tags.push({
          exists: true,
          title: prop,
          value: clonedTags.aws.lambda.sqs[prop],
          description: null,
        })
      }
    }
  } else if (clonedTags.aws.lambda.eventSource === 'aws.sns') {
    /**
     * AWS Lambda: Event: Enriched: AWS SNS
     */
    uiData.event = {}
    uiData.event.tags = []
    uiData.event.enrichedName = {}
    uiData.event.enrichedName.value = 'AWS SNS'
    uiData.event.enrichedName.description = 'This AWS Lambda function was triggered by AWS SNS.'

    // Add Tags
    if (clonedTags.aws.lambda.sns && Object.keys(clonedTags.aws.lambda.sns).length) {
      for (const prop in clonedTags.aws.lambda.sns) {
        uiData.event.tags.push({
          exists: true,
          title: prop,
          value: clonedTags.aws.lambda.sns[prop],
          description: null,
        })
      }
    }
  } else if (clonedTags.aws.lambda.eventSource) {
    /**
     * AWS Lambda: Default Event
     */
    uiData.event = {}
    uiData.event.tags = []
    uiData.event.name = {}
    uiData.event.name.exists = true
    uiData.event.name.title = 'Event'
    uiData.event.name.value = clonedTags.aws.lambda.eventSource
    uiData.event.name.description = 'The event that triggered this AWS Lambda function invocation.'
  }

  return (
    <>
      {/* Event */}

      {uiData.event && (
        <>
          <SectionContainer
            sx={{
              paddingTop: '15px',
              paddingBottom: '5px',
            }}
          >
            <AwsLambdaEventName>
              {uiData.event.enrichedName ? (
                <>
                  <Tooltip
                    title={uiData.event.enrichedName.description}
                    placement="top"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 200 }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        fontFamily: "'Roboto Mono', monospace",
                      }}
                    >
                      {uiData.event.enrichedName.value}
                    </Box>
                  </Tooltip>
                </>
              ) : (
                <TagBox tag={uiData.event.name} />
              )}
            </AwsLambdaEventName>
          </SectionContainer>
          <SectionTagsContainer
            sx={{
              paddingTop: '0',
              paddingBottom: '5px',
            }}
          >
            {uiData.event.tags.map((t, i) => (
              <TagBox tag={t} key={`eventTags-${i}`} />
            ))}
          </SectionTagsContainer>
        </>
      )}
    </>
  )
}

/**
 * Render Details Serverless Sdk
 */
const renderDetailsSdk = (sdkTags) => {
  if (!sdkTags) {
    return null
  }

  const { tags } = defaultSDKTags({
    activity: {
      tags: {
        sdk: sdkTags,
      },
    },
  })

  return (
    <>
      <SectionContainer sx={{ padding: '5px 0 0 0' }}>
        <AwsLambdaEventName>
          <Tooltip
            title={''}
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 200 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                fontFamily: "'Roboto Mono', monospace",
              }}
            >
              Serverless • SDK Details
            </Box>
          </Tooltip>
        </AwsLambdaEventName>
      </SectionContainer>
      <SectionTagsContainer
        sx={{
          paddingTop: '0',
          paddingBottom: '5px',
        }}
      >
        {tags.map((t, i) => (
          <TagBox tag={t} key={`serverless-sdk-tags-${i}`} />
        ))}
      </SectionTagsContainer>
    </>
  )
}

/**
 * Section Details
 */
export const SectionDetails = ({
  timestamp,
  startTime,
  endTime,
  tags,
  durations,
  selectedSpanName,
  customTags,
  ...props
}) => {
  const theme = useTheme()

  const {
    icon,
    name,
    tags: selectedSpanTags,
    hasError,
    errorMessage,
  } = getSelectedSpanTags({
    activity: { name: selectedSpanName, tags, startTime, endTime, durations },
    includeDefaultTags: true,
    includeDuration: false,
    iconParams: { style: { fill: theme.palette.primary.main, width: '24px' } },
    errorColor: theme.palette.colors.redPrimary,
  })

  return (
    <StyledAccordion {...props}>
      <StyledAccordionSummary>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>Details</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {selectedSpanTags.length > 0 && (
          <>
            <SectionContainer
              sx={{
                paddingTop: '15px',
                paddingBottom: '5px',
              }}
            >
              <AwsLambdaEventName>
                <Tooltip
                  title={''}
                  placement="top"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                      justifyContent: 'flex-start',
                      fontFamily: "'Roboto Mono', monospace",
                      ...(hasError ? { color: theme.palette.colors.redPrimary } : {}),
                    }}
                  >
                    {icon}
                    {name}
                  </Box>
                </Tooltip>
              </AwsLambdaEventName>
            </SectionContainer>
            <SectionTagsContainer
              sx={{
                paddingTop: '0',
                paddingBottom: '5px',
                borderBottom: `1px dashed ${theme.palette.border.dark}`,
              }}
            >
              {hasError && (
                <SectionTagsContainer
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    '& pre': {
                      margin: 0,
                      whiteSpace: 'pre-wrap',
                    },
                  }}
                >
                  <pre style={{ color: theme.palette.colors.redPrimary }}>{errorMessage}</pre>
                </SectionTagsContainer>
              )}
              {selectedSpanTags.map((t, i) => (
                <TagBox tag={t} key={`eventTags-${i}`} />
              ))}

              {renderDurations(durations, theme)}
            </SectionTagsContainer>
          </>
        )}

        {tags?.aws && (
          <>
            <SectionContainer
              sx={{
                paddingTop: '15px',
                paddingBottom: '5px',
              }}
            >
              <AwsLambdaEventName>
                <Tooltip
                  title={''}
                  placement="top"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      fontFamily: "'Roboto Mono', monospace",
                    }}
                  >
                    Compute • AWS Lambda
                  </Box>
                </Tooltip>
              </AwsLambdaEventName>
            </SectionContainer>
            <SectionTagsContainer
              sx={{
                paddingTop: '0',
                paddingBottom: '5px',
                borderBottom: `1px dashed ${theme.palette.border.dark}`,
              }}
            >
              {renderDetailsCompute(tags, theme)}
              {selectedSpanName === 'aws.lambda' && renderDurations(durations, theme)}
            </SectionTagsContainer>
          </>
        )}

        {tags?.aws?.lambda?.eventSource && (
          <SectionTagsContainer
            sx={{
              paddingTop: '0',
              paddingBottom: '5px',
              borderBottom: `1px dashed ${theme.palette.border.dark}`,
            }}
          >
            {renderInputEventTags(tags, props, theme)}
          </SectionTagsContainer>
        )}

        {tags.sdk && renderDetailsSdk(tags.sdk, theme, !!customTags)}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: '0',
  margin: '0',
  '&:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRight size={14} />} {...props} />
))(({ theme }) => ({
  minHeight: '40px',
  padding: '0 0 0 20px',
  margin: '0',
  flexDirection: 'row-reverse',
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& svg': {
    color: theme.palette.text.secondary,
  },
  '& p': {
    fontSize: '13px',
    fontFamily: "'Roboto Mono', monospace",
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 6px',
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '60vh',
  width: '100%',
  padding: '0 40px 30px 23px',
  overflowX: 'hidden',
  overflowY: 'scroll',
}))

const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '10px 0 5px 0',
  margin: '0 0 10px 18px',
}))

const SectionTagsContainer = styled(SectionContainer)(({ theme }) => ({
  display: 'inline-block',
  padding: '15px 0 0 0',
  '& > div': {
    float: 'left',
    margin: '0 30px 15px 0',
  },
}))

const Duration = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '50px',
  margin: '0 30px 0 0',
}))

const AwsLambdaEventName = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: theme.palette.text.primary,
  fontSize: theme.typography.textPrimary.fontSize,
}))
