import { createContext, useEffect, useState } from 'react'
import decode from 'jwt-decode'
import { useSnackbar } from 'notistack'
import { useTheme } from '@mui/styles'
import { coreApiClient, updateSessionData } from 'util/coreApiClient'
import { useNavigate, useLocation } from 'react-router-dom'
import config from 'config'
import useSWR from 'swr'
import LoadingPage from '../components/LoadingPage'
import { hasConfiguredIntegration } from '../util/hasConfiguredIntegration'
import { defaultMetricsFilterString } from 'filter/util/filters'
import { initializeHotjar } from '../util/hotjar'
import { UpgradePlanDialog } from 'settings/components/billing/UpgradePlanDialog'
import { IntegrationDialog } from 'settings/components/integrations/IntegrationDialog'
import { AlertDialog } from 'settings/components/alerts/AlertDialog'
import { GlobalSearchDialog } from 'common/components/GlobalSearchDialog/GlobalSearchDialog'

// Initialize hotjar
initializeHotjar()

const defaultSessionData = {
  loggedIn: false,
  user: null,
  activeOrg: null,
  userOrgs: [],
}

const mappedConnectionTypes = {
  'google-oauth2': 'google',
  'Username-Password-Authentication': 'email',
  github: 'github',
}

const matchingRootPaths = ({ basePathValue, inviteOnly = false }) => {
  if (inviteOnly) {
    return ['accept-invitation'].includes(basePathValue)
  }
  return ['accept-invitation', 'callback', 'password'].includes(basePathValue)
}

const getSessionData = () => {
  const parsedSession = JSON.parse(
    localStorage.getItem(config.localStorageKey) || JSON.stringify(defaultSessionData)
  )
  return parsedSession
}

export const AppContext = createContext({
  mode: 'dark',
  isConfigMissing: true,
  login: () => {},
  changeOrg: () => {},
  useSignOut: () => {},
  refreshTokenNow: () => {},
})

export const AppProvider = ({ children }) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [sessionData, setSessionData] = useState(getSessionData())
  const [loadingOrgConfiguration, setLoadingOrgConfiguration] = useState(
    location?.pathname?.split?.('/')?.[1] !== '' &&
      !matchingRootPaths({
        basePathValue: location.pathname.split('/')[1],
        inviteOnly: true,
      })
  )
  const [loadingSessionData, setLoadingSessionData] = useState(!sessionData?.loggedIn)
  const [supportVisible, showSupport] = useState(false)
  const [supportUnreadMessages, setUnreadSupportMessages] = useState(0)
  const [isConfigMissing, setIsConfigMissing] = useState(true)

  const params = new URLSearchParams(window.location.search)
  const cliTransactionId = params.get('transactionId')
  const client = params.get('client')

  /**
   * Updates our local storage item with most recent session data
   * @param {object} data
   */
  const updateSession = (data) => {
    updateSessionData({
      ...data,
      disableDispatch: true,
    })
    setSessionData((currentData) => ({
      ...currentData,
      ...data,
    }))
  }

  /**
   * Watch for CLI login params in the case the the customer is already logged in
   * The CLI will send the customer to https://BASE_URL.com?transactionId=TRANSACTION_ID&client=CLIENT
   *
   * This does not interfere with the login event because the transaction and clientId will not show up
   * in the url, they will be sent back via the state object sent back from the auth0 login callback.
   */
  useSWR(
    sessionData?.loggedIn &&
      client &&
      cliTransactionId &&
      `/identity/auth/login-sessions/${cliTransactionId}/refresh-token`,
    async () => cliLogin(cliTransactionId)
  )

  /**
   * If the customer navigates directly to a page with an org name filled in
   * then we want to first validate that they have access to the organization they
   * are attempting to view :)
   */
  useSWR(location.pathname, async () => {
    const orgName = location.pathname.split('/')[1]
    if (
      orgName?.trim() !== '' &&
      !matchingRootPaths({ basePathValue: orgName?.trim() }) &&
      orgName !== sessionData?.activeOrg?.orgName
    ) {
      const foundOrg = sessionData?.userOrgs?.find((org) => org.orgName === orgName)
      if (!foundOrg && sessionData?.user?.superAdmin) {
        try {
          const org = await coreApiClient.get(`/identity/orgs/name/${orgName}`)
          await changeOrg(org, sessionData, false)
        } catch (error) {
          await changeOrg(sessionData?.userOrgs?.[0], sessionData, true)
        }
      } else if (foundOrg) {
        await changeOrg(foundOrg, sessionData, false)
      } else {
        await changeOrg(sessionData?.userOrgs?.[0], sessionData, false)
      }
    }
  })

  /**
   * When we navigate to a new org page we always want to check that the organization
   * has at least 1 integration set up. If they do not have an integration set up we
   * need to display the connect page.
   */
  useSWR(
    sessionData?.loggedIn &&
      location.pathname &&
      sessionData?.activeOrg?.orgId &&
      `/integrations/?orgId=${sessionData?.activeOrg?.orgId}`,
    async () => {
      const orgName = location.pathname.split('/')[1]
      // orgName === sessionData?.activeOrg?.orgName ensures that we are on the correct org when attempting to validate if the org is configured
      // (orgName === '' && sessionData?.activeOrg) moves forward with the check if we land on the / route
      if (
        (!matchingRootPaths({ basePathValue: orgName.trim() }) &&
          orgName === sessionData?.activeOrg?.orgName) ||
        (orgName === '' && sessionData?.activeOrg)
      ) {
        const isIntegrationReady = await checkIfOrgIsConfigured(sessionData?.activeOrg)
        if (!isIntegrationReady) {
          navigate(`/${sessionData?.activeOrg?.orgName}/connect?client=web`, { replace: true })
        }
      }
    }
  )

  /**
   * This function will watch for external events
   */
  useEffect(() => {
    // Listen for login events form coreApiClient.
    // This will be triggered if our session data is updated
    // or we are logged out unexpectedly due to a refresh token
    // not being refreshed properly.
    const listener = (event) => {
      const { errorClearSession, detail: session } = event
      if (errorClearSession) {
        enqueueSnackbar('Your session has expired. Please log in again.', {
          variant: 'error',
          autoHideDuration: 2000,
        })
        signOut()
      } else {
        setSessionData({
          ...sessionData,
          ...session,
        })
      }
    }
    document.addEventListener(config.sessionUpdateEvent, listener)

    // Init hubspot chat
    if (window.HubSpotConversations) {
      window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {
        setUnreadSupportMessages(payload.unreadCount)
      })
      window.HubSpotConversations.widget.load()
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {
            setUnreadSupportMessages(payload.unreadCount)
          })
          window.HubSpotConversations.widget.load({ widgetOpen: false })
        },
      ]
    }

    return () => {
      document.removeEventListener(config.sessionUpdateEvent, listener)
    }
  }, [sessionData])

  /**
   * Build the sessionData object from the idToken and refreshToken.
   *
   * @param {idToken, refreshToken, defaultOrgName} param idToken and refreshToken are required. defaultOrgName is optional. defaultOrgName will set the activeOrg to the org with the matching orgName. If no defaultOrgName is provided, the first org in the user's org list will be set as the activeOrg.
   * @returns
   */
  const buildSessionDataViaToken = ({ idToken, refreshToken, defaultOrgName }) => {
    const decodedToken = decode(idToken)
    const currentUser = {
      userId: decodedToken.sub,
      userEmail: decodedToken.email,
      profilePictureUrl: decodedToken.profilePictureUrl,
      orgs: decodedToken.orgs,
      superAdmin: !!decodedToken.superAdmin,
    }
    const userOrgs = Object.keys(decodedToken.orgs).reduce(
      (arr, key) => [
        ...arr,
        {
          orgId: key,
          ...decodedToken.orgs[key],
        },
      ],
      []
    )
    const foundDefaultOrg = userOrgs.find((org) => org.orgName === defaultOrgName)
    const activeOrg = foundDefaultOrg || sessionData?.activeOrg || userOrgs?.[0]
    const newSessionData = {
      idToken,
      refreshToken,
      user: currentUser,
      activeOrg: {
        ...activeOrg,
        memberRole: activeOrg?.memberRole || 'contributor',
        isOwner: activeOrg?.memberRole === 'owner',
        isOrgMember: userOrgs?.some((org) => org.orgName === activeOrg?.orgName),
      },
      userOrgs,
      loggedIn: true,
    }

    updateSession(newSessionData)
    return newSessionData
  }

  /**
   * When we perform a CLI login we need to send the transactionId to identity that way the CLI
   * knows that the customer has logged in successfully.
   * @param {string} transactionId
   */
  const cliLogin = async (transactionId) => {
    try {
      await coreApiClient.post(`/identity/auth/login-sessions/${transactionId}/refresh-token`)
      enqueueSnackbar('CLI login success 🎉', {
        autoHideDuration: 2000,
      })
    } catch (error) {
      enqueueSnackbar('Could not login to CLI. Please try again', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    }
  }

  /**
   * Login entry point. This function is called from our `Callback` component.
   *
   * @param {code, state} param code is the auth0 code and state is the state from the router prior to login
   * @returns sessionData
   */
  const login = async ({ code, state }) => {
    try {
      const stateData = JSON.parse(state)
      const method = mappedConnectionTypes[stateData?.connection] || 'email'
      const res = await coreApiClient.post(`/identity/auth/tokens/auth0`, {
        code,
        redirectUri: window.location.origin,
        queryParameters: {
          ...stateData.queryParameters,
          client: stateData?.queryParameters?.client
            ? [stateData?.queryParameters?.client]
            : ['web'],
          method: [method],
          ref: stateData?.queryParameters?.ref,
        },
      })
      /**
       * Set sessionData based on token and state information
       */
      let defaultOrgName
      if (stateData?.from?.pathname) {
        const splitPath = stateData.from.pathname.split('/')
        const possibleOrgName = splitPath[0]
        if (/accept-invitation/.test(possibleOrgName)) {
          defaultOrgName = possibleOrgName
        }
      }

      const newSessionData = buildSessionDataViaToken({
        idToken: res.idToken,
        refreshToken: res.refreshToken,
        defaultOrgName,
      })

      /**
       * Login to the CLI if applicable
       */
      if (
        stateData?.queryParameters?.transactionID &&
        stateData?.queryParameters?.transactionID?.[0] &&
        stateData?.queryParameters?.client &&
        stateData?.queryParameters?.client[0] &&
        stateData?.queryParameters?.client[0].includes('cli')
      ) {
        await cliLogin(stateData?.queryParameters?.transactionID?.[0])
      }

      const hasAccess = await checkOrgAccess(newSessionData?.activeOrg?.orgId, newSessionData, true)
      if (!hasAccess) {
        newSessionData.activeOrg = newSessionData.userOrgs?.[0]
        updateSession({
          idToken: res.idToken,
          refreshToken: res.refreshToken,
          user: newSessionData.user,
          activeOrg: newSessionData.activeOrg,
          userOrgs: newSessionData.userOrgs,
          loggedIn: true,
        })
      }
      const isConfigured = await checkIfOrgIsConfigured(newSessionData?.activeOrg)
      if (!isConfigured && !(stateData?.from?.pathname === '/accept-invitation')) {
        navigate(`/${newSessionData?.activeOrg?.orgName}/connect?client=web`, { replace: true })
      } else {
        /**
         * Redirect based on state information
         */
        const location = stateData?.from || {
          pathname: `/${newSessionData?.activeOrg?.orgName}/metrics/awsLambda`,
          search: `?${defaultMetricsFilterString}`,
        }
        navigate(`${location.pathname}${location.search}`, { replace: true })
      }
    } catch (error) {
      enqueueSnackbar('Unable to login. Please try agin.', {
        variant: 'error',
        autoHideDuration: 5000,
      })
      navigate('/', { replace: true, state })
    } finally {
      setLoadingSessionData(false)
      setLoadingOrgConfiguration(false)
    }
  }

  /**
   * This function allows you to refresh the current token. This can be helpful in the event that you added a new org, updated permissions, etc.
   */
  const refreshTokenNow = async ({ defaultOrgName } = {}) => {
    try {
      const res = await coreApiClient.post('/identity/auth/tokens/refresh', {
        refreshToken: getSessionData()?.refreshToken,
      })
      return buildSessionDataViaToken({
        idToken: res.idToken,
        refreshToken: res.refreshToken,
        defaultOrgName,
      })
    } catch (error) {
      enqueueSnackbar('Unable to refresh your session.', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    }
  }

  /**
   * Validate that the customer has access to the org they are trying to access.
   *
   * @param {string} id Organization UUID
   * @param {sessionData} data We want to pass in sessionData here so that if the calling function has recently updated the sessionData we don't have to wait for the state data to reflect the changes.
   * @returns
   */
  const checkOrgAccess = async (id, data, ignoreSuperAdmin = false) => {
    if (data?.user?.superAdmin && !ignoreSuperAdmin) return true
    return data?.userOrgs?.some(({ orgId }) => orgId === id)
  }

  /**
   * Call this function when you need to switch orgs
   *
   * @param {object} org The org object that
   * @param {object} updatedSessionData We want to pass in sessionData here so that if the calling function has recently updated the sessionData we don't have to wait for the state data to reflect the changes.
   * @returns
   */
  const changeOrg = async (org, updatedSessionData, shouldNavigate = true) => {
    setLoadingOrgConfiguration(true)
    let activeOrg = org
    let selectedOrgName = org?.orgName
    const data = updatedSessionData || sessionData
    if (!data?.user?.superAdmin) {
      const foundOrg = (data?.userOrgs || []).find(({ orgName }) => orgName === selectedOrgName)
      if (!foundOrg) {
        activeOrg = data?.userOrgs[0]
        selectedOrgName = activeOrg.orgName
      }
    }

    const newSessionData = {
      ...data,
      activeOrg: {
        ...activeOrg,
        memberRole: activeOrg?.memberRole || 'contributor',
        isOwner: activeOrg?.memberRole === 'owner' || data?.user?.superAdmin,
      },
    }

    const hasAccess = await checkOrgAccess(newSessionData?.activeOrg?.orgId, newSessionData)
    if (!hasAccess) {
      newSessionData.activeOrg = {
        ...newSessionData.userOrgs?.[0],
        isOwner:
          newSessionData.userOrgs?.[0]?.memberRole === 'owner' || newSessionData?.user?.superAdmin,
      }
    }

    updateSession({
      activeOrg: newSessionData.activeOrg,
    })
    const isConfigured = await checkIfOrgIsConfigured(activeOrg)
    if (!isConfigured) {
      navigate(`/${selectedOrgName}/connect?client=web`, { replace: true })
    } else if (shouldNavigate) {
      navigate(`/${selectedOrgName}/metrics/awsLambda?${defaultMetricsFilterString}`)
    }
    setLoadingOrgConfiguration(false)
    return newSessionData
  }

  /**
   * This function is called during login or when switching organizations so the we can check if the org has been configured with at least one integration.
   *
   * This function is used over the useSWR hook so that we can catch potential un-configured organizations before navigating to the org.
   * @param {org} org
   * @returns
   */
  const checkIfOrgIsConfigured = async (org) => {
    try {
      const { integrations } = await coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/?orgId=${org.orgId}`,
      })
      const isIntegrationReady = hasConfiguredIntegration(integrations)
      setIsConfigMissing(!isIntegrationReady)
      setLoadingOrgConfiguration(false)
      return isIntegrationReady
    } catch (error) {
      setLoadingOrgConfiguration(false)
      setIsConfigMissing(true)
      return false
    }
  }

  /**
   * Use this function to logout of console
   */
  const signOut = () => {
    const newSessionData = {
      idToken: null,
      refreshToken: null,
      user: null,
      activeOrg: sessionData?.activeOrg,
      userOrgs: [],
      loggedIn: false,
    }
    updateSession(newSessionData)
    navigate('/', { replace: true })
  }

  /**
   * Toggle support widget in the sidebar
   */
  const toggleSupport = () => {
    const el = document.getElementById('support')
    if (supportVisible) {
      showSupport(false)
      el.setAttribute('style', 'display:none')
    } else {
      window?.HubSpotConversations?.widget.open()
      showSupport(true)
      el.setAttribute('style', 'display:block')
    }
  }

  const appLoading = sessionData?.loggedIn && (loadingSessionData || loadingOrgConfiguration)

  return (
    <AppContext.Provider
      value={{
        mode,
        idToken: sessionData.idToken,
        token: sessionData.idToken,
        user: sessionData.user,
        activeOrg: sessionData.activeOrg,
        userOrgs: sessionData.userOrgs,
        loggedIn: sessionData.loggedIn,
        isConfigMissing,
        supportVisible,
        supportUnreadMessages,
        login,
        useSignOut: signOut,
        signOut,
        refreshTokenNow,
        changeOrg,
        toggleSupport,
        setIsConfigMissing,
      }}
    >
      {!appLoading ? children : <LoadingPage type="spin3D" />}
      {/* Only allow Global dialogs if user is logged in */}
      {!appLoading && (
        <>
          <UpgradePlanDialog />
          <IntegrationDialog />
          <AlertDialog />
          {!isConfigMissing && <GlobalSearchDialog />}
        </>
      )}
    </AppContext.Provider>
  )
}
