import React from 'react'
import { CircularProgress } from '@mui/material'
import { useTheme } from '@mui/styles'

export const Loading = ({ thickness = 4, size = 32, spinnerStyles, color, ...props }) => {
  const { palette } = useTheme()
  return (
    <CircularProgress
      {...props}
      thickness={thickness}
      size={size}
      style={{ color: color || palette.grey.light, ...spinnerStyles }}
    />
  )
}
