import { format, intlFormat } from 'date-fns'

export function formatTime(date) {
  const d = date instanceof Date ? date : new Date(date)
  return format(d, 'h:mm:ss:SSS aa')
}

export function formatDay(date) {
  const d = date instanceof Date ? date : new Date(date)
  return intlFormat(d, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })
}

export function formatLongDateTime(date) {
  const d = date instanceof Date ? date : new Date(date)
  return intlFormat(d, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  })
}

export function formatShortDateTime(date) {
  const d = date instanceof Date ? date : new Date(date)
  return intlFormat(d, {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  })
}

export function formatLongDate(date) {
  const d = date instanceof Date ? date : new Date(date)
  return intlFormat(d, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })
}

export function usesAmPmTime() {
  return Boolean(
    new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
    })
      .formatToParts(new Date(2020, 0, 1, 13))
      .find((part) => part.type === 'dayPeriod')
  )
}

export function formatTimestamp(date) {
  const d = date instanceof Date ? date : new Date(date)
  const t = usesAmPmTime() ? 'h:mm:ss.SSS aa' : 'HH:mm:ss.SSS'
  return `${intlFormat(d, { year: '2-digit', day: '2-digit', month: '2-digit' })} - ${format(d, t)}`
}

export function formatLogDate(date) {
  const d = date instanceof Date ? date : new Date(date)
  const res = format(d, 'h:mm:ss:SSS aa - MMM dd y')
  return res
}
