import { memo, useMemo } from 'react'
import { Typography, styled, Box } from '@mui/material'
import { useTheme } from '@mui/styles'
import { formatLogDate } from 'util/date'
import { ellipsisKeyframes } from 'common/components/LoadingEllipsis'
import { LogItem } from 'common/components/LogItem'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { EventMessage } from 'common/packages/ActivityStream/components/EventMessage/EventMessage'
import { getSelectedSpanTags } from 'common/packages/ActivityStream/utils/helper'

/**
 * Render Activity Header
 */
const RenderActivityHeader = ({ activity = {} }) => {
  const timestamp = activity?.timestamp
  const environment = activity?.tags?.environment
  const namespace = activity?.tags?.namespace
  const resourceName = activity?.tags?.aws?.resourceName
  const httpPath = activity?.tags?.httpPath
  const httpMethod = activity?.tags?.httpMethod

  return (
    <div
      className="log-stream-header"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        paddingBottom: '20px',
      }}
    >
      {timestamp && <Header>{formatLogDate(new Date(timestamp))}</Header>}
      {activity.type !== 'local' && (
        <Header>
          <IconAwsLambda size={12} sx={{ margin: '0 5px 0 0' }} /> AWS LAMBDA
        </Header>
      )}
      {environment && <Header>{environment}</Header>}
      {namespace && <Header>{namespace}</Header>}
      {resourceName && <Header>{resourceName}</Header>}
      {httpMethod && <Header>{httpMethod}</Header>}
      {httpPath && <Header>{httpPath}</Header>}
    </div>
  )
}

/**
 * Render Activity Span
 */
const RenderActivitySpan = ({ activity, onClick, zoom, isClickable, stylePadding }) => {
  const theme = useTheme()

  const uiData = getSelectedSpanTags({
    activity,
    includeDefaultTags: false,
    includeDuration: true,
    iconParams: { style: { fill: theme.palette.primary.main, width: '24px' } },
    errorColor: theme.palette.colors.redPrimary,
  })

  return (
    <div
      className={`log-stream-box ${!isClickable ? `compactMode` : ''}`}
      style={{
        zoom,
        fontFamily: "'Roboto Mono', monospace",
        padding: stylePadding,
      }}
      onClick={(event) => {
        if (!isClickable) {
          event.preventDefault()
        } else {
          setTimeout(() => onClick(activity), 50)
        }
      }}
    >
      <RenderActivityHeader activity={activity} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            padding: '0 0 0 15px',
            fontFamily: "'Roboto Mono', monospace",
          }}
        >
          {uiData.icon && (
            <Box
              sx={{
                display: 'flex',
                margin: '0 12px 0 0',
                fontFamily: "'Roboto Mono', monospace",
              }}
            >
              {uiData.icon}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              margin: '0 10px 0 0',
              fontFamily: "'Roboto Mono', monospace",
              ...(uiData.hasError ? { color: theme.palette.colors.redPrimary } : {}),
            }}
          >
            {uiData.name}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            width: '100%',
            margin: '15px 0 0 0',
            padding: '0 0 0 18px',
          }}
        >
          {uiData.tags.map((t, i) => (
            <Box sx={{ width: 'auto', margin: '0 45px 20px 0' }} key={`span-details-${i}`}>
              <Box
                sx={{
                  display: 'flex',
                  margin: '0 0 5px 0',
                  color: uiData?.hasError
                    ? theme.palette.colors.redPrimary
                    : theme.palette.text.secondary,
                  fontSize: theme.typography.textSecondary.fontSize,
                  fontFamily: "'Roboto Mono', monospace",
                }}
              >
                {t.label}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  color: uiData?.hasError
                    ? theme.palette.colors.redPrimary
                    : theme.palette.text.secondary,
                  fontSize: theme.typography.textSecondary.fontSize,
                  fontFamily: "'Roboto Mono', monospace",
                }}
              >
                {t.value}
              </Box>
            </Box>
          ))}
          {uiData?.hasError && (
            <pre style={{ marginBottom: 0, color: theme.palette.colors.redPrimary }}>
              {uiData?.errorMessage}
            </pre>
          )}
        </Box>
      </Box>
    </div>
  )
}

/**
 * Render Activity Lambda ReqRes
 */
const RenderActivityLambdaReqRes = ({ activity, onClick, zoom, isClickable, stylePadding }) => {
  const theme = useTheme()
  const uiData = {}
  const hasError = !!activity?.tags?.error && Object.keys(activity?.tags?.error || {}).length > 0
  uiData.icon = (
    <IconAwsLambda style={hasError ? { fill: theme.palette.error.main } : {}} size={24} />
  )
  uiData.name =
    activity.type === 'aws-lambda-request'
      ? 'AWS Lambda - Invocation Started'
      : 'AWS Lambda - Invocation Ended'
  uiData.name = uiData.name.toUpperCase()
  uiData.duration = activity.durationFormatted || null
  uiData.tags = []

  if (activity?.tags?.aws?.resourceName) {
    uiData.tags.push({
      label: 'Function',
      value: activity.tags.aws.resourceName,
    })
  }
  if (activity?.tags?.environment) {
    uiData.tags.push({
      label: 'Environment',
      value: activity.tags.environment,
    })
  }
  if (activity?.tags?.namespace) {
    uiData.tags.push({
      label: 'Namespace',
      value: activity.tags.namespace,
    })
  }
  if (activity?.tags?.aws?.region) {
    uiData.tags.push({
      label: 'Region',
      value: activity.tags.aws.region,
    })
  }

  return (
    <div
      className={`log-stream-box ${!isClickable ? `compactMode` : ''}`}
      style={{
        zoom,
        fontFamily: "'Roboto Mono', monospace",
        padding: stylePadding,
      }}
      onClick={(event) => {
        if (!isClickable) {
          event.preventDefault()
        } else {
          setTimeout(() => onClick(activity), 50)
        }
      }}
    >
      <RenderActivityHeader activity={activity} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            padding: '0 0 0 15px',
            fontFamily: "'Roboto Mono', monospace",
          }}
        >
          {uiData.icon && (
            <Box
              sx={{ display: 'flex', margin: '0 12px 0 0', fontFamily: "'Roboto Mono', monospace" }}
            >
              {uiData.icon}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              margin: '0 10px 0 0',
              fontFamily: "'Roboto Mono', monospace",
              color: hasError ? theme.palette.colors.redPrimary : 'inherit',
            }}
          >
            {uiData.name}
          </Box>
          {uiData.duration && activity.type === 'aws-lambda-response' && (
            <>
              -
              <Box
                sx={{
                  display: 'flex',
                  margin: '0 0 0 8px',
                  fontFamily: "'Roboto Mono', monospace",
                }}
              >
                {uiData.duration}
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            margin: '15px 0 0 0',
            padding: '0 0 0 18px',
          }}
        >
          {uiData.tags.map((t, i) => (
            <Box sx={{ margin: '0 45px 20px 0' }} key={`span-details-${i}`}>
              <Box
                sx={{
                  display: 'flex',
                  margin: '0 0 5px 0',
                  color: hasError ? theme.palette.error.main : theme.palette.text.secondary,
                  fontSize: theme.typography.textSecondary.fontSize,
                  fontFamily: "'Roboto Mono', monospace",
                }}
              >
                {t.label}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  color: hasError ? theme.palette.error.main : theme.palette.text.primary,
                  fontSize: theme.typography.textSecondary.fontSize,
                  fontFamily: "'Roboto Mono', monospace",
                }}
              >
                {t.value}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  )
}

/**
 * Activity
 */
export const Activity = memo(function Activity({
  activity,
  onClick,
  zoom,
  isClickable,
  index,
  hideArrowIcon,
  stylePadding,
}) {
  const theme = useTheme()
  const component = useMemo(() => {
    /**
     * "local"
     */
    if (activity?.type === 'local') {
      return (
        <div
          className={`log-stream-header internal-message ${activity.error ? 'error-message' : ''}`}
        >
          <Typography
            unselectable="on"
            variant="textSecondary"
            color="text.secondary"
            sx={{ fontFamily: "'Roboto Mono', monospace", marginRight: '35px' }}
          >
            {formatLogDate(new Date(activity?.timestamp))}
          </Typography>
          <StyledMessage
            includedots={activity.includeDots ? 'true' : 'false'}
            unselectable="on"
            variant="textPrimary"
            color="text.secondary"
            sx={{ fontFamily: "'Roboto Mono', monospace" }}
            dangerouslySetInnerHTML={{ __html: activity?.message }}
          />
        </div>
      )
    }

    /**
     * "aws-lambda-request", "aws-lambda-response"
     */
    if (activity?.type === 'aws-lambda-request' || activity?.type === 'aws-lambda-response') {
      return (
        <RenderActivityLambdaReqRes
          activity={activity}
          onClick={onClick}
          zoom={zoom}
          isClickable={isClickable}
          index={index}
          hideArrowIcon={hideArrowIcon}
          stylePadding={stylePadding}
        />
      )
    }

    /**
     * "span"
     */
    if (activity?.type === 'span') {
      return (
        <RenderActivitySpan
          activity={activity}
          onClick={onClick}
          zoom={zoom}
          isClickable={isClickable}
          index={index}
          hideArrowIcon={hideArrowIcon}
          stylePadding={stylePadding}
        />
      )
    }

    /**
     * "log"
     */
    if (activity?.type === 'log') {
      return (
        <div
          className={`log-stream-box ${!isClickable ? `compactMode` : ''}`}
          style={{
            zoom,
            padding: stylePadding,
          }}
          onClick={(event) => {
            if (!isClickable) {
              event.preventDefault()
            } else {
              setTimeout(() => onClick(activity), 50)
            }
          }}
        >
          <RenderActivityHeader activity={activity} />
          <div className="log-stream-body">
            <LogItem
              logBody={activity.body}
              logSeverityText={activity.severityText}
              isClickable={isClickable}
            />
          </div>
        </div>
      )
    }

    if (activity?.type === 'event') {
      return (
        <div
          index={index}
          className={`log-stream-box ${!isClickable ? `compactMode` : ''}`}
          style={{
            zoom,
            padding: stylePadding,
          }}
          onClick={(event) => {
            if (!isClickable) {
              event.preventDefault()
            } else {
              setTimeout(() => onClick(activity), 50)
            }
          }}
        >
          <RenderActivityHeader activity={activity} />
          <EventMessage activity={activity} theme={theme} />
        </div>
      )
    }

    return null
  }, [activity, zoom, stylePadding, isClickable, theme])

  return component
})

const StyledMessage = styled(Typography)(({ includedots }) => ({
  ...(includedots === 'true'
    ? {
        '&:after': {
          display: 'inline-block',
          animation: `${ellipsisKeyframes} steps(1,end) 2s infinite`,
          content: '""',
        },
      }
    : {}),
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontFamily: "'Roboto Mono', monospace",
  color: theme.palette.text.secondary,
  fontSize: theme.typography.textSecondary.fontSize,
  lineHeight: '26px',
  margin: '0 30px 0 0',
}))
