import { useContext, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Search, X, Plus } from 'lucide-react'
import { EmptyFilterArray } from 'filter/components/EmptyFilterArray'
import { useSearch } from 'common/hooks/useSearch'
import { LoadingSpinner } from 'common/components/LoadingSpinner'
import { isArray, uniqBy, some, sortBy, unionBy, set } from 'lodash'
import { slugifyString } from 'filter/components/FiltersWidget'
import { AppContext } from 'app/context/AppContext'
import { useDebounce } from 'react-use'
import { Checkbox } from './Checkbox'
import { DropdownWrapper } from './DropdownWrapper'

/**
 * This component handles most filters dropdowns based on filter type. If no `filter.options` is provided
 * it will attempt to to fetch options via `opensearch`. Selected values are provided by `filter.value`.
 * For the `accountId` filter, account aliases need to be fetched to show account name instead of the id.
 * Provided props are:
 * `filter` - object - The defined filter properties.
 * `onChange` - function - Event to handle selecting and deselecting options.
 * `collapsable` - boolean - Determines if dropdown is collapsable or always open.
 * `allowAddNew` - boolean - Allows adding new options.
 * `expandedFilter` - string - Shows the currently expanded filter name (`filter.filter`).
 * `toggleExpandedFilter` - function - Toggles the currently expanded filter.
 */
export const FilterSearchDropdown = ({
  filter,
  onChange = {},
  collapsable = true,
  allowAddNew = false,
  allowMulti = true,
  expandedFilter,
  toggleExpandedFilter,
}) => {
  const selectedValues = filter?.value
  const size = 50
  const height = 200

  const { name: type, searchable, icon, queryKey } = filter || {}
  /**
   * Temporary get org id to fetch aliases for aws accounts
   */
  const { activeOrg } = useContext(AppContext)

  const [filterText, setFilterText] = useState('')

  const initialOptions = filter?.options || []
  const [dropdownAliasTotal, setDropdownAliasTotal] = useState()
  const [options, setOptions] = useState(initialOptions)
  const [loadingAlias, setLoadingAlias] = useState(false)
  const [page, setPage] = useState(1)
  const [debouncedQuery, setDebouncedQuery] = useState({ page, filterText })
  const selected = useMemo(
    () =>
      selectedValues?.map((value) => {
        const selectedItem = options?.find((op) => op.value === value)
        return {
          label: selectedItem?.label || value,
          value: selectedItem?.value || value,
        }
      }) || [],
    [options, selectedValues]
  )

  useDebounce(
    () => {
      setDebouncedQuery({ page, filterText })
    },
    500,
    [filterText, page]
  )
  const isOpen = !collapsable ? true : collapsable && expandedFilter === filter.filter

  const query = {
    bool: {
      must: [
        {
          match: {
            type,
          },
        },
      ],
    },
  }
  /**
   * Add search input value to query if exists
   */
  if (debouncedQuery?.filterText) {
    set(query, 'bool.must[1]', {
      multi_match: {
        query: debouncedQuery?.filterText,
        fields: [queryKey || '*'],
        type: 'phrase_prefix',
      },
    })
  }
  const sort = [
    {
      [`${queryKey}.keyword`]: {
        order: 'asc',
      },
    },
  ]
  const currentPage = debouncedQuery?.page
  /**
   *  Only fetch when:
   * - Dropdown is open.
   * - Filter type is not boolean.
   * - Filter is account ID (needs to fetch aliases).
   */

  const ready = (isOpen && !filter?.options) || (filter.fetchAlias && selected?.length) || false // Only fetch when dropdown is open, no passed options and filter is not boolean
  const { data, loading } = useSearch({
    size,
    ready,
    currentPage,
    query,
    sort,
  })

  const hasMore = useMemo(() => options?.length < data?.total, [data, options])
  const total = useMemo(() => dropdownAliasTotal || data?.total, [data?.total, dropdownAliasTotal])

  useEffect(() => {
    if (data?.hits) {
      let integrations
      let newOptions = data?.hits.map((option) => ({
        ...option,
        value: option[queryKey] || option.id,
        label: option[queryKey] || option.id,
      }))
      // If filter is account id, then fetch aliases
      if (filter.fetchAlias) {
        ;(async () => {
          setLoadingAlias(true)
          integrations = await filter.fetchAlias({ orgId: activeOrg.orgId })
          const accountIds = Object.keys(integrations)
          newOptions = newOptions
            ?.filter((options) => accountIds.includes(options.id))
            ?.map((opt) => ({
              ...opt,
              label: integrations?.[opt?.account_id],
            }))
          setDropdownAliasTotal(newOptions?.length)
          setOptions(newOptions)
          setLoadingAlias(false)
        })()
      } else {
        /**
         * Sort options to show current selected at the top, and combine paginated results without duplicates.
         */

        setOptions((prev) =>
          sortBy(unionBy(newOptions, prev, 'id'), [
            (option) => !selected?.some((n) => n.value === option.value),
            'label',
          ])
        )
      }
    }
  }, [data?.hits])

  const fetchData = () => {
    setPage((prev) => prev + 1)
  }

  /**
   * Toggle collapsed state
   */
  const toggle = () => {
    if (!collapsable) return
    toggleExpandedFilter(filter.filter)
    setFilterText('')
  }

  const isCurrentOptionChecked = (option) =>
    some(
      selected,
      (n) => (isArray(n?.value) && n?.value?.includes(option.value)) || n?.value === option.value
    )

  const onOptionSelect = (option) => {
    onChange(option)
    if (!allowMulti) {
      toggleExpandedFilter(filter.filter)
    }
  }

  return (
    <DropdownWrapper
      isOpen={isOpen}
      options={options}
      total={total}
      isLoading={loadingAlias || loading}
      onToggle={toggle}
      onDelete={(selectedValue) => onChange(allowMulti ? selectedValue : undefined)}
      icon={icon}
      selectedItems={selected}
      renderChipLabel={(selectedValue) => (loadingAlias ? 'loading...' : selectedValue?.label)}
      title={filter.label}
      collapsable={collapsable}
    >
      {searchable && (
        <TextField
          sx={{ padding: '0 10px 10px 10px' }}
          placeholder={`Search ${allowAddNew ? 'or add new tag' : ''}`}
          value={filterText}
          InputProps={{
            style: {
              paddingLeft: 10,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search size={18} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {filterText && (
                  <IconButton onClick={() => setFilterText('')}>
                    <X size={16} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setOptions(initialOptions)
            setPage(1)
            setFilterText(e.target.value)
          }}
        />
      )}
      <Divider />

      <Box
        sx={{
          overflow: 'auto',
          height,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <InfiniteScroll
          dataLength={options.length}
          next={fetchData}
          hasMore={hasMore}
          loader={<LoadingSpinner minHeight="100%" />}
          height={height}
        >
          {!options?.length &&
          !loading &&
          !loadingAlias &&
          filterText === debouncedQuery?.filterText ? (
            allowAddNew ? (
              <ValueItem
                onClick={(event) => {
                  const newValue = slugifyString({ str: filterText })
                  onChange({ label: newValue, value: newValue })
                  if (!allowMulti) {
                    toggleExpandedFilter(filter.filter)
                  }
                }}
              >
                Add "{slugifyString({ str: filterText })}" tag
                <Plus size={20} style={{ marginRight: 18 }} />
              </ValueItem>
            ) : (
              <EmptyFilterArray options={options} style={{ height: '40%' }} />
            )
          ) : null}

          {uniqBy(options, 'value').map((option, i) => (
            <DropdownOption
              key={`filters-${filter?.name}-options-${i}`}
              option={option}
              onClick={() => onOptionSelect(option)}
              isCurrentOptionChecked={isCurrentOptionChecked}
              icon={icon}
            />
          ))}
        </InfiniteScroll>
      </Box>
    </DropdownWrapper>
  )
}

const DropdownOption = ({ option, onClick, isCurrentOptionChecked, icon }) => {
  const isSelected = isCurrentOptionChecked(option)
  return (
    <ValueItem onClick={onClick} sx={{ color: isSelected ? 'text.primary' : 'text.secondary' }}>
      <Checkbox checked={isSelected} />
      {icon}
      <Typography variant="textPrimary">{option?.label}</Typography>
    </ValueItem>
  )
}

const ValueItem = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  width: '100%',
  padding: '5px 10px',
  transition: 'all 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    color: props.theme.palette.text.primary,
    backgroundColor: props.theme.palette.grey.dark,
  },
}))
