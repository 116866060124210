import { isValidElement, memo, useContext } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { Button } from 'common/components/Button'
import { SettingLayout } from 'settings/components/SettingLayout'
import { AppContext } from 'app/context/AppContext'
import { Info, Mail } from 'lucide-react'
import { styled, useTheme } from '@mui/styles'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { getEventInfo } from 'common/utils/getEventInfo'
import { useConfirm } from 'material-ui-confirm'
import { format, formatDistance } from 'date-fns'
import { Chip } from 'common/components/Chip'
import { isArray, isObject, keys, range } from 'lodash'
import { motion } from 'framer-motion'
import { CountTag } from 'common/components/CountTag'
import pluralize from 'pluralize'
import config from 'config'
import { useSnackbar } from 'notistack'
import { AlertDialogButton, useAlertDialog } from './useAlertDialog'

const sortAlerts = (alerts) =>
  alerts.sort((d1, d2) => new Date(d2.updatedAt).getTime() - new Date(d1.updatedAt).getTime())

const getFiltersCount = (filtersObject = {}, latestCount = 0) => {
  return keys(filtersObject).reduce((acc, key) => {
    if (isObject(filtersObject?.[key])) {
      return getFiltersCount(filtersObject?.[key], acc)
    } else if (isArray(filtersObject?.[key])) {
      return acc + filtersObject?.[key]?.length
    }
    return acc + 1
  }, latestCount)
}

export const Alerts = () => {
  const {
    activeOrg: { orgId },
  } = useContext(AppContext)
  const confirm = useConfirm()
  const theme = useTheme()
  const { setIsOpen } = useAlertDialog()
  const { enqueueSnackbar } = useSnackbar()
  const { data, error, mutate } = useSWR(`/alerts/${orgId}`, () =>
    coreApiClient({
      baseURL: config.platform.alerts,
      url: `/alerts/${orgId}`,
    })
  )
  const alerts = data?.alerts
  const loading = !data && !error

  const deleteAlert = (alert) => {
    confirm({
      title: 'Delete Alert',
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
      content: (
        <Typography variant="textPrimary" color="text.secondary">
          Are you sure you want to delete "
          <span style={{ color: theme.palette.text.primary }}>{alert?.name}</span>"
        </Typography>
      ),
    })
      .then(async () => {
        await coreApiClient({
          baseURL: config.platform.alerts,
          url: `/alerts/${orgId}/${alert?.alertId}`,
          method: 'delete',
        })
        mutate()
        enqueueSnackbar(`Deleted alert successfully`)
      })
      .catch(() => {
        /* ... */
      })
  }
  return (
    <SettingLayout style={{ flexDirection: 'column', height: '90%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Typography variant="h2">Alerts</Typography>
          <Typography variant="textSecondary" color="text.secondary">
            Create and manage alerts based on specific events and conditions
          </Typography>
        </Box>
        <AlertDialogButton showLabel />
      </Box>
      <Box width="100%">
        {loading ? (
          range(10)?.map((num) => (
            <Box
              key={`resources-skeleton-loading-${num}`}
              component={motion.div}
              initial={{ y: 5, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { duration: 0.2, delay: `0.1${num}`, ease: 'easeInOut' },
              }}
            >
              <Skeleton variant="rect" width="100%" height="80px" sx={{ marginBottom: '20px' }} />
            </Box>
          ))
        ) : !alerts?.length ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '20px',
              minHeight: '70vh',
              color: 'text.secondary',
            }}
          >
            <Info size={30} />
            <Typography variant="textPrimary" color="text.secondary" sx={{ lineHeight: 1 }}>
              No alerts created yet
            </Typography>
            <Typography
              variant="textPrimary"
              color="text.secondary"
              sx={{ lineHeight: 1, mb: '10px' }}
            >
              Click to create your first alert
            </Typography>
            <AlertDialogButton showLabel />
          </Box>
        ) : (
          sortAlerts(alerts)?.map((alert, idx) => {
            const dates = {
              createdAt:
                new Date(alert?.createdAt) &&
                format(new Date(alert?.createdAt), 'MMM dd, yyyy hh:mm aa'),
              updatedAt:
                new Date(alert?.updatedAt) &&
                formatDistance(new Date(alert?.updatedAt), new Date(), {
                  addSuffix: true,
                }),
            }
            const filtersCount = getFiltersCount(alert?.eventAlert?.filters)

            return (
              <AlertRow
                key={`alerts-list-${alert?.alertId}`}
                onClick={() => setIsOpen(alert?.alertId)}
                component={motion.div}
                initial={{ y: 5, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: { duration: 0.2, delay: `0.1${idx}`, ease: 'easeInOut' },
                }}
              >
                <Box
                  marginLeft="0"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Mail size={30} />
                  <Box width="220px">
                    <Typography
                      variant="h4"
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '100%',
                      }}
                    >
                      {alert?.name}
                    </Typography>
                    <Typography
                      variant="textSecondary"
                      color="text.secondary"
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '100%',
                      }}
                    >
                      First occurrence in 24 hrs
                    </Typography>
                  </Box>
                </Box>

                <TagSection
                  title="Created"
                  value={dates?.createdAt}
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      display: 'none',
                    },
                  }}
                />

                <TagSection
                  title="Updated"
                  value={dates?.updatedAt}
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      display: 'none',
                    },
                  }}
                />

                <TagSection
                  title="Errors & Warnings"
                  value={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '5px',
                      }}
                    >
                      {alert?.eventAlert?.eventTypes?.slice(0, 1)?.map((type) => (
                        <Box
                          key={`alerts-list-${alert?.alertId}-events-${type}`}
                          sx={{ maxWidth: 120 }}
                        >
                          <Chip label={getEventInfo({ type })?.typeFullName} />
                        </Box>
                      ))}
                      {alert?.eventAlert?.eventTypes?.length > 1 ? (
                        <Typography variant="textTertiary" color="text.secondary">
                          +{alert?.eventAlert?.eventTypes?.length - 1}
                        </Typography>
                      ) : null}
                    </Box>
                  }
                />

                <TagSection
                  title="Notifications"
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      display: 'none',
                    },
                  }}
                  value={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '5px',
                      }}
                    >
                      {alert?.notificationConfigurations?.[0]?.emails?.slice(0, 1)?.map((email) => (
                        <Box
                          key={`alerts-list-${alert?.alertId}-notifications-${email}`}
                          sx={{ maxWidth: 120 }}
                        >
                          <Chip label={email} />
                        </Box>
                      ))}
                      {alert?.notificationConfigurations?.[0]?.emails?.length > 1 ? (
                        <Typography variant="textTertiary" color="text.secondary">
                          +{alert?.notificationConfigurations?.[0]?.emails?.length - 1}
                        </Typography>
                      ) : null}
                    </Box>
                  }
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    width: '140px',
                    [theme.breakpoints.down('xl')]: {
                      display: 'none',
                    },
                  }}
                >
                  <CountTag>{filtersCount}</CountTag>
                  <Typography variant="textSecondary">
                    Additional {pluralize('Filters', filtersCount)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      minWidth: 70,
                    }}
                    onClick={(e) => {
                      e?.stopPropagation()
                      deleteAlert(alert)
                    }}
                  >
                    Remove
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ margin: 'auto 0' }}
                    onClick={() => setIsOpen(alert?.alertId)}
                  >
                    Edit
                  </Button>
                </Box>
              </AlertRow>
            )
          })
        )}
      </Box>
    </SettingLayout>
  )
}

export const TagSection = ({ title, value, sx = {} }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '125px',
        textAlign: 'left',
        ...sx,
      }}
    >
      <Typography
        variant="textTertiary"
        color="text.secondary"
        sx={{
          lineHeight: 1,
          marginBottom: '8px',
        }}
      >
        {title}
      </Typography>

      {isValidElement(value) ? (
        value
      ) : (
        <Typography
          sx={{
            lineHeight: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
          }}
          variant="textSecondary"
        >
          {value}
        </Typography>
      )}
    </Box>
  )
}

const AlertRow = memo(
  styled(Box)(({ theme }) => ({
    padding: '15px 20px',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.border.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
    gap: '20px',

    marginBottom: '20px',
    transition: 'all .3s ease-in-out',
    '& .affected-resources-external-link': {
      transition: 'all .3s ease-in-out',
      opacity: 0,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      '& .affected-resources-external-link': {
        opacity: 1,
      },
    },
  }))
)
