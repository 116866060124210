import { SvgIcon } from '@mui/material'

const IconPencil = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <path d="M19.7274 8.54719L16.1914 5.01219L17.6064 3.59819C18.2364 2.96819 19.6794 2.84319 20.4344 3.59819L21.1414 4.30519C21.8964 5.06019 21.7724 6.50319 21.1414 7.13419L19.7274 8.54719ZM18.3134 9.96219L8.41338 19.8622L3.81738 20.9222L4.87738 16.3262L14.7774 6.42619L18.3134 9.96219V9.96219Z" />
    </SvgIcon>
  )
}

export { IconPencil }
