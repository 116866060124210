import { Box, Divider, Slider, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import {
  SectionHeader,
  animateProps,
  usd,
  supportedTiers,
  number,
  initialTiers,
} from './billingUtils'
import { styled } from '@mui/styles'
import { useContext, useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { BillingDetails } from './BillingDetails'
import Button from 'common/components/Button'
import { SettingLayout } from '../SettingLayout'
import { AppContext } from 'app/context/AppContext'
import { useUpgradeDialog } from 'settings/hooks/useUpgradeDialog'
import { every, findIndex } from 'lodash'
import { TierBox } from './TierBox'
import { Check } from 'lucide-react'
import { Collapsible } from 'common/components/Collapsible'
import { Link } from 'common/components/Link'
import { DowngradePlanDialog } from './DowngradePlanDialog'
import { UsageBars } from './UsageBars'
import { useOrgUsage } from 'common/hooks/useOrgUsage'

const SLIDER_MIN = 0
const SLIDER_MAX = supportedTiers?.length - 1

const marks = supportedTiers
  .map((tier, index) => ({ value: index, label: tier.label, traces: tier.value }))
  .filter((tier) => tier.value % 4 === 0 || tier.value === 0 || tier.value === SLIDER_MAX)

export const Billing = () => {
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}
  const [openDowngradeDialog, setOpenDowngradeDialog] = useState(false)
  const [sliderIndex, setSliderIndex] = useState(SLIDER_MIN)
  const [selectedTier, setSelectedTier] = useState({})
  const { openDialog } = useUpgradeDialog()
  const { orgUsage, currentTier, enrichTier, orgSubscription } = useOrgUsage({ orgId })
  const isCustomSelected = every(initialTiers, (n) => n.value !== selectedTier?.traces)
  const isFreeTier = !selectedTier?.total
  const isTierChanged = selectedTier?.traces !== currentTier?.traces
  const isUsageOverTier = !isFreeTier && orgUsage?.traces > selectedTier?.traces && isTierChanged
  const changePlanButtonDisabled = !isTierChanged || isUsageOverTier

  /**
   * Get slider tooltip label
   */
  const getLabel = (index) =>
    `${supportedTiers[index]?.label}/${usd(
      enrichTier(supportedTiers[index]?.value)?.volumeTotal
    )}/mo`
  /**
   * Set current tier if org has a subscription or set to free if no subscription or is canceled
   */
  const setCurrentTier = (tier, sliderIndex) => {
    setSelectedTier(tier)

    setSliderIndex(sliderIndex)
  }
  /**
   * On first load set current tier
   */
  useEffect(() => {
    const initialSliderIndex = findIndex(
      supportedTiers,
      (tier) => tier.value === currentTier?.traces
    )
    setCurrentTier(currentTier, initialSliderIndex)
  }, [orgSubscription])

  const columns = [
    {
      headerName: '',
      flex: 1,
      field: 'title',
      renderHeader: () => null,
    },
    {
      headerName: '',
      flex: 1,
      field: 'count',
      renderHeader: () => null,
    },
    {
      headerName: '',
      flex: 1,
      field: 'price',
      renderHeader: () => null,
    },
  ]
  const rows = [
    {
      id: 1,
      title: 'Usage tier',
      count: `${number(selectedTier?.traces)} / ${number(selectedTier?.metrics)} / ${number(
        selectedTier?.invocations
      )}`,
      price: `${usd(selectedTier?.subtotal)} / mo`,
    },
  ]
  /**
   * Add available discounts to table rows, these discounts will be volume discount + any other discounts
   * added via the admin dashboard
   */
  if (selectedTier?.discounts?.length) {
    selectedTier?.discounts?.forEach((discount, idx) => {
      rows.push(
        {
          id: rows?.length + 1,
          title: discount?.title,
          count: `${discount?.percentDiscount}%`,
          price: `${usd(discount?.discountValue)} / mo`,
        },
        {
          id: rows?.length + 2,
          title: 'Subtotal After Discount',
          count: '',
          name: 'subtotal',

          price: `${usd(discount?.totalAfterDiscount)} / mo`,
        }
      )
    })
  }
  /**
   * Add total row
   */
  const totalRow = {
    id: rows?.length + 1,
    title: 'Total',
    count: ``,
    name: 'total',

    price: `${usd(selectedTier?.total)} / mo`,
  }
  rows.push(totalRow)
  /**
   * On change plan button click, either open the downgrade dialog if selected tier is free, or open
   * the upgrade dialog
   */
  const changePlan = async () => {
    if (isFreeTier) {
      setOpenDowngradeDialog(true)
    } else {
      openDialog(selectedTier?.traces)
    }
  }
  /**
   * List tiers to show tier boxes and add final custom tier box
   */
  const tiers = initialTiers
    .map(({ value }) => ({
      ...enrichTier(value),
      isSelected: selectedTier?.traces === value,
      isCurrent: currentTier?.traces === value,
    }))
    .concat([
      {
        ...enrichTier(isCustomSelected ? selectedTier?.traces : marks?.[0]?.traces),
        isSelected: isCustomSelected,
        isCurrent: every(initialTiers, (n) => n.value !== currentTier?.traces),
        isCustom: true,
      },
    ])

  return (
    <SettingLayout sx={{ flexDirection: 'column', padding: 0 }}>
      <DowngradePlanDialog
        openDowngradeDialog={openDowngradeDialog}
        setOpenDowngradeDialog={setOpenDowngradeDialog}
      />
      <Box
        component={motion.div}
        {...animateProps()}
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '25% 75%',
          height: 'calc(100vh - 100px)',
          overflow: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Section
          sx={{
            overflowY: 'auto',
          }}
        >
          <SubSection>
            <SectionHeader title="Current Usage" mb={0} />
            <UsageBars />
          </SubSection>
          <SubSection>
            <SectionHeader title="Billing Details" mb={0} />
            <BillingDetails />
          </SubSection>
        </Section>
        <Section
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'auto auto',
            gridTemplateColumns: '1fr',

            borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
          }}
        >
          <Box
            sx={{
              overflowY: 'scroll',
              paddingBottom: '120px',
              height: 'calc(100vh - 100px)',
            }}
          >
            <SubSection>
              <SectionHeader title="Modify Plan" mb={0} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  gap: '20px',
                }}
              >
                {tiers.map((tier, idx) => [
                  <TierBox
                    key={`billing-tiers-${tier.traces}-${idx}`}
                    tier={tier}
                    onClick={() => setCurrentTier(tier, SLIDER_MIN)}
                  />,
                ])}
              </Box>
              <Collapsible collapsed={!isCustomSelected}>
                <Box
                  sx={{
                    textAlign: 'center',
                    mt: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px',
                  }}
                >
                  <Box>
                    <Typography variant="h1">{number(selectedTier?.traces)}</Typography>
                    <Typography variant="textPrimary" color="text.secondary">
                      Traces
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" sx={{ height: '30px' }} />
                  <Box>
                    <Typography variant="h1">{number(selectedTier?.metrics)}</Typography>
                    <Typography variant="textPrimary" color="text.secondary">
                      Metrics
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" sx={{ height: '30px' }} />

                  <Box>
                    <Typography variant="h1">{number(selectedTier?.invocations)}</Typography>
                    <Typography variant="textPrimary" color="text.secondary">
                      Invocations
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Slider
                    marks={marks}
                    value={sliderIndex}
                    min={SLIDER_MIN}
                    step={1}
                    max={SLIDER_MAX}
                    onChange={(e, newIndex) => {
                      const newTier = { ...enrichTier(supportedTiers?.[newIndex]?.value) }
                      setCurrentTier(newTier, newIndex)
                    }}
                    size="large"
                    valueLabelDisplay="auto"
                    getAriaValueText={getLabel}
                    valueLabelFormat={getLabel}
                    aria-labelledby="billing-tier-slider"
                    sx={{
                      width: '80%',
                      margin: '40px auto 0px auto',
                      '& .MuiSlider-rail': {
                        height: '6px',
                      },
                    }}
                  />
                </Box>
              </Collapsible>
            </SubSection>
            <SubSection>
              <SectionDivider sx={{ marginTop: '30px !important' }} />
              <TextWrapper>
                <Check size={16} />
                <Typography variant="h4">All plans & limits are month-to-month.</Typography>
              </TextWrapper>
              <TextWrapper>
                <Check size={16} />
                <Typography variant="h4">
                  20% Trace Sampling automatically kicks in after 1M AWS Lambda invocations.
                </Typography>
              </TextWrapper>
              <TextWrapper>
                <Check size={16} />
                <Typography variant="h4">Cancel at any time via a button click.</Typography>
              </TextWrapper>
              <TextWrapper>
                <Check size={16} />
                <Typography variant="h4">
                  We employ engineers, not salespeople. If you still want to chat...{' '}
                  <Link
                    to="https://info.serverless.com/meetings/learnmore/serverless-console?utm_medium=console_ui_billing_042023&utm_source=console_ui_billing_042023"
                    target="_blank"
                  >
                    schedule a meeting here.
                  </Link>
                </Typography>
              </TextWrapper>
              <TextWrapper>
                <Check size={16} />
                <Typography variant="h4">
                  By clicking “Change Plan” you agree to our{' '}
                  <Link to="https://www.serverless.com/legal/terms" target="_blank">
                    terms & conditions.
                  </Link>
                </Typography>
              </TextWrapper>
            </SubSection>
            <SubSection>
              <SectionDivider />
              <SectionHeader title="Pricing Details" mb={0} />
              <DataGridPro
                rows={rows}
                columns={columns}
                autoHeight
                hideFooter
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                disableRowSelectionOnClick
                rowSelection={false}
                getRowClassName={(params) =>
                  params.row.name ? `billing-table-${params.row.name}-price-row` : ''
                }
                sx={{
                  '& .MuiDataGrid-cell': {
                    paddingLeft: 3,
                  },
                  '& .MuiDataGrid-row:not(.MuiDataGrid-row--lastVisible):hover': {
                    background: 'none',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    '& .MuiDataGrid-cellContent': {
                      color: 'text.primary',
                    },
                    /**
                     * Add special styling to 'total' rows
                     */
                    '&.billing-table-total-price-row': {
                      backgroundColor: 'grey.dark',
                      '&:hover': {
                        backgroundColor: 'grey.dark',
                      },
                      '& .MuiDataGrid-cellContent': {
                        color: 'text.primary',
                        fontSize: '1.3rem',
                      },
                    },
                    '&.billing-table-subtotal-price-row': {
                      backgroundColor: 'grey.dark',
                      '&:hover': {
                        backgroundColor: 'grey.dark',
                      },
                      '& .MuiDataGrid-cell:last-child .MuiDataGrid-cellContent': {
                        color: 'text.secondary',
                        textDecoration: 'line-through',
                      },
                      '& .MuiDataGrid-cell:first-child .MuiDataGrid-cellContent': {
                        color: 'text.secondary',
                      },
                    },
                    '&.billing-table-subtotal-price-row:nth-last-child(2)': {
                      '& .MuiDataGrid-cell:last-child .MuiDataGrid-cellContent': {
                        textDecoration: 'none',
                      },
                    },
                  },
                }}
              />
            </SubSection>
          </Box>

          <Footer>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={setCurrentTier}
              disabled={!isTierChanged}
            >
              Reset
            </Button>
            {isUsageOverTier && (
              <Typography variant="textSecondary" color="error">
                Please select a tier that supports your current traces usage of{' '}
                {number(orgUsage?.traces)}
              </Typography>
            )}
            <Stack direction="row" spacing={2} alignItems="center">
              {selectedTier?.subtotal !== selectedTier?.total ? (
                <Typography
                  variant="h4"
                  color="text.secondary"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {usd(selectedTier?.subtotal)}
                </Typography>
              ) : null}
              <Typography variant="h2">{usd(selectedTier?.total)} / mo</Typography>
              <Button
                size="small"
                color="primary"
                onClick={changePlan}
                sx={{ minWidth: 200 }}
                disabled={changePlanButtonDisabled}
              >
                Change Plan
              </Button>
            </Stack>
          </Footer>
        </Section>
      </Box>
    </SettingLayout>
  )
}

const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0px',
  position: 'relative',
  justifyContent: 'flex-start',
  padding: '15px 0',
}))

const Footer = styled('div')(({ theme }) => {
  return {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: 'calc(75% - 62px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.default,
    padding: '10px 25px',
    borderTop: `1px solid ${theme.palette.border.main}`,
  }
})

const SubSection = styled(Box)(({ theme }) => ({
  padding: '10px 25px',
}))

const TextWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  marginBottom: '10px',
}))
const SectionDivider = styled(Divider)(() => ({
  margin: '0 0 30px 0',
}))
