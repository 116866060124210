import { Box, Divider, Skeleton, Typography } from '@mui/material'
import { ROW_HEIGHT } from '../context/Trace.context'
import { random, range } from 'lodash'
import { styled } from '@mui/styles'
import { motion } from 'framer-motion'

export const TraceSkeletonLoading = ({ message }) => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '300px 1.5fr 2fr',
        position: 'relative',
      }}
    >
      {message && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'absolute',
            margin: 'auto',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backdropFilter: 'blur(1px)',
            zIndex: 10,
            transition: 'all 0.2s ease',
          }}
        >
          <Typography>{message}</Typography>
        </Box>
      )}
      <Section>
        {range(15).map((num) => (
          <Skeleton
            key={`trace-skeleton-loading-first-${num}`}
            variant="rect"
            width={`75%`}
            height={20}
            sx={{ marginLeft: num === 0 ? 0 : num <= 3 ? '30px' : '50px' }}
          />
        ))}
      </Section>
      <Section>
        {range(15).map((num) => (
          <Box key={`trace-skeleton-loading-second-${num}`}>
            <Skeleton
              variant="rect"
              width={`${random(0, 90)}%`}
              height={5}
              sx={{ marginBottom: 0.5, marginLeft: num === 0 ? 0 : num <= 3 ? '30px' : '50px' }}
            />
            <Skeleton
              variant="rect"
              width="20px"
              height={15}
              sx={{ marginLeft: num === 0 ? 0 : num <= 3 ? '30px' : '50px' }}
            />
          </Box>
        ))}
      </Section>
      <Section style={{ padding: 0 }}>
        <Section style={{ padding: '20px 20px 0 20px' }}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="rect" width={200} height={20} />
        </Section>
        <Divider />
        <Section style={{ padding: '0 10px' }}>
          <Skeleton variant="rect" width={200} height={15} />
          <Section
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '2vw',
              borderRight: 'none',
              paddingTop: 0,
            }}
          >
            {range(10).map((num) => (
              <Box key={`trace-skeleton-loading-second-${num}`}>
                <Skeleton variant="rect" width={50} height={10} sx={{ marginBottom: 1 }} />
                <Skeleton variant="rect" width={100} height={15} />
              </Box>
            ))}
          </Section>
        </Section>
        <Divider />
        <Section style={{ padding: '0 10px' }}>
          <Skeleton variant="rect" width={200} height={15} />
          <Section
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '2vw',
              borderRight: 'none',
              paddingTop: 0,
            }}
          >
            {range(10).map((num) => (
              <Box key={`trace-skeleton-loading-second-${num}`}>
                <Skeleton variant="rect" width={50} height={10} sx={{ marginBottom: 1 }} />
                <Skeleton variant="rect" width={100} height={15} />
              </Box>
            ))}
          </Section>
        </Section>
      </Section>
    </Box>
  )
}

const Section = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.border.main}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  justifyContent: 'flex-start',
  padding: '20px',
  paddingTop: ROW_HEIGHT,
}))
