import config from 'config'
import { isAfter } from 'date-fns'
import { useLocalStorage } from 'react-use'

const announcementObject = config.platform.announcements.prod
const bypassKey = Object.keys(announcementObject)
  .filter((key) => isAfter(new Date(), new Date(key)))
  .sort((a, b) => {
    const aDate = new Date(a).getTime()
    const bDate = new Date(b).getTime()
    return bDate - aDate
  })[0]

export const useProdAnnouncement = ({ orgId }) => {
  const [shouldBypass, setShouldBypass] = useLocalStorage(`prod-announcement-${bypassKey}`, {})
  const announcement = announcementObject[bypassKey]

  return {
    announcement: {
      ...announcement,
      // Remove newlines and white space from the config file so it doesn't mess up the UI formatting :)
      description: announcement.description
        .split('\n')
        .map((x) => x.trim())
        .join(''),
    },
    shouldBypass: shouldBypass[orgId],
    setShouldBypass: (value) => {
      setShouldBypass({
        ...shouldBypass,
        [orgId]: value,
      })
    },
  }
}
