import { useContext, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import config from 'config'
import { DevModeContext } from '../context/DevMode.context'
import { StillWorking } from './StillWorking'
import { Inspector } from 'inspector/components/Inspector'
import { ActivityStreamFooter } from './ActivityStreamFooter'
import { ActivityStream as DevModeActivityStream } from './../../common/packages/ActivityStream'
import { Allotment } from 'allotment'
import { useTheme } from '@mui/styles'
import { FilterContext } from 'filter/context/FilterContext'
import { BulkEnableDevMode } from './BulkEnableDevMode'
import { useEnableBulkInstrumentation } from 'common/hooks/useEnableBulkInstrumentation'
import { getEventInfo } from 'common/utils/getEventInfo'

const Pane = Allotment.Pane

const convertFilters = (filters) =>
  (filters || [])
    .filter(({ value }) => value)
    .reduce(
      (obj, filter) => ({
        ...obj,
        [filter.queryApiTagName]: filter.value,
      }),
      {}
    )

const ActivityStreamer = () => {
  const theme = useTheme()
  const {
    eventToken,
    user,
    orgId,
    setStatus,
    headerHeight,
    socketToken,
    zoom,
    clearLogs,
    selectedActivity,
    setSelectedActivity,
    openConnection,
    setOpenConnection,
    openStillWorking,
    closeStillWorking,
    logInteraction,
    publishSidebarToggle,
  } = useContext(DevModeContext)
  const {
    currentlyInstrumenting,
    canShowWelcome,
    doesNotHaveInstrumentation,
    setDoesNotHaveInstrumentation,
    setCanShowWelcome,
    selectedFunctionCount,
    allResources,
    setResources,
    waitingForInstrumentation,
    instrumentationProgress,
    loadingFunctionCount,
    compatibleFunctionCount,
    enableTargetFunctions,
    beginCheckForFunctions,
  } = useEnableBulkInstrumentation({
    orgId,
  })

  const { filters } = useContext(FilterContext)
  const [pageLoaded, setPageLoaded] = useState(false)

  /**
   * Pause the connection if we are prompting to
   * enable dev mode on all functions.
   */
  useEffect(() => {
    if ((doesNotHaveInstrumentation || canShowWelcome) && !currentlyInstrumenting) {
      setOpenConnection(false)
    }
  }, [doesNotHaveInstrumentation, canShowWelcome])

  /**
   * Check if any integration has a dev mode enabled on functions
   */
  useEffect(() => {
    if (orgId) {
      beginCheckForFunctions()
    }
  }, [orgId])

  const appliedFilters = useMemo(() => convertFilters(filters), [filters])
  const [expandLogs, setExpandLogs] = useState(false)

  // Send event when inspector is opened or closed
  const openedInspector = useMemo(() => !!selectedActivity, [selectedActivity])
  useEffect(() => {
    logInteraction()
    if (pageLoaded) {
      publishSidebarToggle({ opened: openedInspector })
    } else {
      setPageLoaded(true)
    }
  }, [openedInspector])
  // Get selected span name for inspector header
  const selectedChildSpanName = useMemo(() => {
    if (selectedActivity?.selectedItem?.type === 'event') {
      const { typeFullName } = getEventInfo(selectedActivity?.selectedItem)
      return typeFullName
    }
    return selectedActivity?.selectedItem?.name
  }, [selectedActivity?.selectedItem])

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: `calc(var(--app-height) - ${headerHeight}px)`,
          width: '100%',
          '& .split-view-view': {
            '&:before': {
              zIndex: '3 !important',
            },
          },
        }}
      >
        <Allotment horizontal onVisibleChange={(_index, value) => setExpandLogs(value)}>
          <Pane snap preferredSize="60%" visible={!expandLogs}>
            <DevModeActivityStream
              eventToken={eventToken}
              eventsUrl={config.platform.eventsUrl}
              socketUrl={config.platform.logSocketUrl}
              zoom={zoom}
              clickLogItem={(event) => setSelectedActivity(event)}
              setReadyState={setStatus}
              token={socketToken}
              theme={theme}
              containerStyle={{ height: 'calc(100% - 50px)' }}
              clearLogs={clearLogs}
              filters={appliedFilters}
              openConnection={openConnection}
              orgId={orgId}
              userId={user?.userId}
              logInteraction={logInteraction}
            />
            <ActivityStreamFooter
              waitingForInstrumentation={waitingForInstrumentation}
              instrumentationProgress={instrumentationProgress}
              openConnection={openConnection}
              historicalMode={false}
            />
          </Pane>

          {selectedActivity && (
            <Pane snap preferredSize="40%">
              <Inspector
                customTags={selectedActivity?.selectedItem?.customTags}
                isSpan={selectedActivity?.selectedItem?.type === 'span'}
                parentSpanName={'aws.lambda'}
                childSpanName={
                  selectedActivity?.selectedItem?.name !== 'aws.lambda'
                    ? selectedChildSpanName
                    : null
                }
                selectedSpanName={selectedActivity?.selectedItem?.name}
                timestamp={selectedActivity?.selectedItem?.timestamp}
                startTime={selectedActivity?.selectedItem?.startTime}
                endTime={selectedActivity?.selectedItem?.endTime}
                tags={selectedActivity?.selectedItem?.tags}
                durations={
                  selectedActivity?.selectedItem?.durationFormatted
                    ? [selectedActivity?.selectedItem?.durationFormatted]
                    : null
                }
                activityGroup={selectedActivity?.groupedItems}
                input={selectedActivity?.selectedItem?.input}
                output={selectedActivity?.selectedItem?.output}
                reselectItems={(selectedItem) => {
                  setSelectedActivity((current) => ({
                    ...current,
                    selectedItem,
                  }))
                }}
                onClose={() => setSelectedActivity(null)}
              />
            </Pane>
          )}
        </Allotment>
      </Box>
      {/* Create a popup to enable dev mode on all functions labeled with dev environment variable */}
      <BulkEnableDevMode
        selectedFunctionCount={selectedFunctionCount}
        waitingForInstrumentation={waitingForInstrumentation}
        instrumentationProgress={instrumentationProgress}
        allResources={allResources}
        setResources={setResources}
        open={(doesNotHaveInstrumentation && !currentlyInstrumenting) || canShowWelcome}
        compatibleFunctionCount={compatibleFunctionCount}
        loadingFunctionCount={loadingFunctionCount}
        close={({ enableDevMode = false } = {}) => {
          setDoesNotHaveInstrumentation(false)
          setCanShowWelcome(false)
          if (enableDevMode) {
            setOpenConnection(true)
            enableTargetFunctions()
          }
        }}
      />
      <StillWorking open={openStillWorking} close={closeStillWorking} />
    </>
  )
}

/**
 * ActivityStream
 */
export const ActivityStream = () => {
  const { socketToken } = useContext(DevModeContext)
  return <ActivityStreamer socketToken={socketToken} />
}
