import { Box, styled, Typography } from '@mui/material'
import { LoadingSoundwave } from 'common/components/LoadingSoundwave'

/**
 *
 */

export const ActivityStreamFooter = ({
  openConnection,
  historicalMode,
  waitingForInstrumentation,
  instrumentationProgress,
}) => {
  return (
    <Footer>
      {openConnection ? (
        <LoadingSoundwave />
      ) : (
        <Typography
          variant="textSecondary"
          color="text.secondary"
          sx={{ fontFamily: "'Roboto Mono', monospace" }}
        >
          {historicalMode ? 'Viewing historical activity.' : 'Streaming paused.'} Press play to
          enable log streaming.
        </Typography>
      )}
      {waitingForInstrumentation && instrumentationProgress.total !== 0 ? (
        <>
          <Typography
            variant="textSecondary"
            color="text.secondary"
            sx={{ fontFamily: "'Roboto Mono', monospace" }}
          >
            Enabled {instrumentationProgress.instrumented} of {instrumentationProgress.total}{' '}
            functions for dev mode
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Footer>
  )
}

const Footer = styled(Box)(({ theme, zoom }) => ({
  position: 'absolute',
  zIndex: '100',
  left: '0px',
  bottom: '0px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  height: '50px',
  width: '100%!important',
  margin: '0',
  padding: '0 0 0 44px',
  background: theme.palette.secondary.main,
  borderTop: `1px solid ${theme.palette.border.main}`,
  boxShadow: `0 -6px 25px 4px ${theme.palette.secondary.main}`,
}))
