import { format, isBefore, sub } from 'date-fns'

export const getIntervalDate = ({ currentDate, index, formatString, data }) => {
  let startDate
  let endDate

  try {
    // if last item
    if (index === data?.length - 1) {
      startDate = new Date(currentDate)
      endDate = new Date()
      // if only one item left then set start and end dates to be the same since it will be within one minute
      if (data?.length === 1) {
        endDate = startDate
      }
    } else {
      startDate = new Date(currentDate)
      const nexDate = data?.[index + 1]?.time || data?.[index + 1]

      endDate = new Date(nexDate)
      const subtracted = sub(endDate, { minutes: 1 })
      if (!isBefore(subtracted, startDate)) {
        endDate = subtracted
      }
    }

    if (formatString) {
      startDate = format(startDate, formatString)
      endDate = format(endDate, formatString)
    }
  } catch (err) {}
  return {
    startDate,
    endDate,
  }
}
