import { useContext, useEffect, useState } from 'react'
import useVirtual from 'react-cool-virtual'
import useWebSocket from 'react-use-websocket'
import classes from './ActivityStream.module.css'
import { ActivityItem } from '../ActivityItem/ActivityItem'
import {
  attachMetaTags,
  collectRootSpanTags,
  createInitialLog,
  formatMessages,
  MAX_META,
  omitInvalidData,
  omitNonWhitelistedData,
  sortActivityItems,
} from './../../utils/helper'
import {
  ActivityStreamContext,
  ActivityStreamProvider,
} from './../../context/ActivityStream.context'
import { defaultTheme } from './../../utils/theme'
import { debounce } from 'lodash'

const errorTypes = {
  connection: 'connection',
  messageError: 'messageError',
}

let isScrolling = false
let AUTO_SCROLL_THRESHOLD = 350

const Stream = ({
  filters = {},
  socketUrl,
  openConnection = true,
  setReadyState = () => {},
  clickLogItem,
  clearLogs,
  logInteraction,
}) => {
  const [appliedFilters, setAppliedFilters] = useState(filters)
  const { counterRef, token } = useContext(ActivityStreamContext)
  const [, setError] = useState()
  const [shouldSticky, setShouldSticky] = useState(true)
  const [userScrolling, setUserScrolling] = useState(false)
  const [messages, setMessages] = useState(createInitialLog())
  const [metaTags, setMetaTags] = useState({})

  const { outerRef, innerRef, items, scrollToItem, scrollTo } = useVirtual({
    // Provide the number of messages
    itemCount: messages.length,
    // You can speed up smooth scrolling
    scrollDuration: 50,
    onScroll: debounce(({ userScroll, scrollOffset }) => {
      logInteraction()
      setUserScrolling(userScroll)
      const shouldContinueSticky =
        outerRef.current.scrollHeight - outerRef.current.offsetHeight <=
        scrollOffset + AUTO_SCROLL_THRESHOLD
      if (userScroll && shouldContinueSticky) {
        setShouldSticky(true)
      } else if (userScroll && !isScrolling) {
        // If the user scrolls and isn't automatically scrolling, cancel stick to bottom
        setShouldSticky(false)
      }
    }, 50),
  })

  // Automatically stick to bottom, using smooth scrolling for better UX
  useEffect(() => {
    if (!userScrolling && shouldSticky) {
      isScrolling = true
      scrollToItem({ index: messages.length, smooth: true }, () => {
        isScrolling = false
        // Not sure why we need this but if we don't use this method to
        // scroll the last few pixels it won't perfectly scroll to the bottom
        // for whatever reason 🤷‍♂️
        scrollTo(outerRef.current?.scrollHeight, () => {
          isScrolling = false
        })
      })
    }
  }, [messages.length, shouldSticky, userScrolling])

  // Listen to websockdte on socket URL
  const { lastMessage, readyState, sendMessage, sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      onError: (e) => {
        setError({
          type: errorTypes.connection,
        })
      },
      onOpen: () => {
        setError(undefined)
        // Apply filters when a new connection is opened
        if (filters && Object.keys(filters).length > 0) {
          sendMessage(
            JSON.stringify({
              filters: appliedFilters,
            })
          )
        }
      },
      queryParams: {
        Auth: token,
      },
      reconnectAttempts: 10,
    },
    !!token && openConnection
  )

  // Keep this components filters in
  // sync with parent component
  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(appliedFilters)) {
      setAppliedFilters(filters)
      sendJsonMessage({ filters })
    }
  }, [filters, appliedFilters])

  // Update parent with websocket ready state
  useEffect(() => {
    setReadyState(readyState)
  }, [readyState])

  useEffect(() => {
    if (clearLogs) {
      setMessages(createInitialLog())
      clickLogItem(null)
    }
  }, [clearLogs])

  // Set messages list
  useEffect(() => {
    // filter out initialization
    if (lastMessage === null) {
      return
    }

    try {
      const message = JSON.parse(lastMessage.data)
      // Handle error messages from log socket
      if (message?.error) {
        return setMessages((currentMessages) => [
          ...currentMessages,
          {
            type: 'local',
            timestamp: new Date().getTime(),
            sortTimestamp: new Date().getTime(),
            includeDots: false,
            error: true,
            message:
              'Dev Mode Error: Too many messages\n  · There are too many activity message to stream with your current filter selection, so Dev Mode is paused.\n  · To fix, adjust the filters above to something more specific, like a “dev” Environment or a specific Namespace.',
          },
        ])
      }

      const rootSpanTags = collectRootSpanTags(Array.isArray(message) ? message : [message])
      if (rootSpanTags) {
        setMetaTags((c) => {
          const allTags = Object.keys(c).reduce((arr, key) => {
            return [...arr, { requestId: key, timestamp: c[key].timestamp }]
          }, [])
          const sorted = sortActivityItems({
            items: allTags,
          })
          if (sorted.length > MAX_META) {
            const idsToRemove = sorted
              .splice(sorted.length - MAX_META, sorted.length)
              .map(({ requestId }) => requestId)
            idsToRemove.forEach((id) => {
              delete c[id]
            })
          }
          return {
            ...c,
            ...rootSpanTags,
          }
        })
      }

      // Ignore span data we don't want to process
      const newMessages = (Array.isArray(message) ? message : [message])
        .filter((m) => !!omitNonWhitelistedData(m) && !!omitInvalidData(m))
        .map(formatMessages)

      // Do nothing if we do not have any messages
      if (newMessages.length === 0) return

      // Increment counts for event publish
      counterRef.current.logs += newMessages.filter(({ type }) => type === 'log').length
      counterRef.current.requests += newMessages.filter(
        ({ type }) => type === 'aws-lambda-request'
      ).length
      counterRef.current.responses += newMessages.filter(
        ({ type }) => type === 'aws-lambda-response'
      ).length
      counterRef.current.spans += newMessages.filter(({ type }) => type === 'span').length

      setMessages((currentMessages) =>
        sortActivityItems({
          items: [...currentMessages, ...newMessages],
        })
      )
      setUserScrolling(false)
    } catch (error) {
      console.error(error)
    }
  }, [lastMessage])

  return (
    <div ref={outerRef} className={classes.container}>
      <div className={classes.inner} ref={innerRef}>
        {items
          .filter(({ index }) => !!messages[index])
          .map(({ index, measureRef }) => (
            <div
              key={JSON.stringify(messages[index])}
              style={clickLogItem && messages[index]?.type !== 'local' ? { cursor: 'pointer' } : {}}
              ref={measureRef} // Used to measure the unknown item size
            >
              <ActivityItem
                padBottom={messages[index]?.type !== 'local' && index === messages.length - 1}
                onClick={(selectedItem) => {
                  if (clickLogItem && selectedItem.traceId) {
                    const traceId = selectedItem?.traceId
                    const items = sortActivityItems({
                      items: messages
                        .filter((message) => message?.traceId === traceId)
                        .map((activity) =>
                          attachMetaTags(activity, metaTags[messages[index]?.tags?.aws?.requestId])
                        )
                        .filter(Boolean),
                      noLimit: true,
                    })

                    clickLogItem({
                      selectedItem,
                      groupedItems: items,
                    })
                  } else if (clickLogItem) {
                    const requestId = selectedItem?.tags?.aws?.requestId
                    const items = messages.filter(
                      (message) => message?.tags?.aws?.requestId === requestId
                    )
                    clickLogItem({
                      selectedItem,
                      groupedItems: items,
                    })
                  }
                }}
                activity={messages[index]}
                metaTags={metaTags[messages[index]?.tags?.aws?.requestId]}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export const ActivityStream = ({
  socketUrl,
  eventsUrl,
  eventToken,
  token,
  theme = defaultTheme.dark,
  filters = {},
  setReadyState = () => {},
  clickLogItem,
  containerStyle,
  zoom = 1,
  clearLogs = false,
  openConnection = true,
  userId,
  orgId,
  logInteraction,
}) => (
  <ActivityStreamProvider
    eventsUrl={eventsUrl}
    theme={theme}
    token={token}
    containerStyle={containerStyle}
    openConnection={openConnection}
    userId={userId}
    orgId={orgId}
    eventToken={eventToken}
  >
    <span style={{ zoom }}>
      <Stream
        clearLogs={clearLogs}
        socketUrl={socketUrl}
        clickLogItem={clickLogItem}
        setReadyState={setReadyState}
        filters={filters}
        openConnection={openConnection}
        logInteraction={logInteraction}
      />
    </span>
  </ActivityStreamProvider>
)
