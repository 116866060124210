import { Box } from '@mui/material'
import { Loading } from './Loading'

export const LoadingSpinner = ({ minHeight }) => (
  <Box
    minHeight={minHeight}
    height="100%"
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Loading />
  </Box>
)
