import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

import { MetricsContext } from 'metrics/context/MetricsContext'
import { ErrorMessage } from 'common/components/ErrorMessage'
import { Button } from 'common/components/Button'

export const MetricsViewDropdownDelete = ({ onClose, onDelete, metricsViewToDelete }) => {
  const { deleteMetricsView } = useContext(MetricsContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  /**
   * Deletes a Metrics View in the API
   * @returns
   */
  const deleteMetricsViewWrapper = async () => {
    setIsLoading(true)
    setErrorMessage(null)

    try {
      await deleteMetricsView(metricsViewToDelete.id)
    } catch (error) {
      setErrorMessage('Sorry, something went wrong.  Please try again.')
      setIsLoading(false)
      return
    }

    setIsLoading(false)

    onDelete()
  }

  return (
    <DeleteDialogue
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.2, ease: 'easeOut' } }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '13px',
        }}
      >
        <Typography variant="h4">Delete this view?</Typography>

        <DialogueControls>
          <Button size={'small'} color={'secondary'} onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            size={'small'}
            color={'primary'}
            onClick={deleteMetricsViewWrapper}
            sx={{ marginLeft: 1 }}
          >
            Delete
          </Button>
        </DialogueControls>
      </Box>

      <ErrorMessage message={errorMessage} />

      <StyledValue>
        {metricsViewToDelete?.icon}
        <Typography variant="textPrimary">{metricsViewToDelete?.name}</Typography>
      </StyledValue>
    </DeleteDialogue>
  )
}

const DeleteDialogue = styled(motion.div)(({ theme }) => ({
  padding: '20px',
  border: `1px solid ${theme.palette.grey.light}`,
  borderRadius: '4px',
}))

const DialogueControls = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '& > * + *': {
    marginLeft: '5px',
  },
}))

const StyledValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  marginTop: '10px',
  boxSizing: 'border-box',
  marginBottom: 20,
  color: theme.palette.primary.main,
  '& svg': {
    marginRight: '5px !important',
  },
}))
