import { Tooltip, Chip as MuiChip } from '@mui/material'
import { merge } from 'lodash'
import { Copy } from 'lucide-react'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useCopyToClipboard, useWindowSize } from 'react-use'

export const Chip = ({
  label = '',
  tooltipProps,
  autoTruncate = true,
  sx = {},
  showCopy,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [, copyToClipboard] = useCopyToClipboard()
  const ref = useRef()
  const [isTruncated, setIsTruncated] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    const chipWidth = ref?.current?.offsetWidth
    const parentWidth = ref?.current?.parentElement?.offsetWidth

    setIsTruncated(autoTruncate && chipWidth >= parentWidth)
  }, [ref, ref?.current?.parentElement?.clientWidth, width])

  const copyValue = useCallback(() => {
    if (showCopy) {
      copyToClipboard(label)
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success',
        autoHideDuration: 1000,
      })
    }
  }, [showCopy, label])

  /**
   * Either truncate or wrap overflow text based on 'autoTruncate'
   */
  const labelStyles = isTruncated
    ? {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
      }
    : {
        whiteSpace: 'pre-wrap',
        overflowX: 'hidden',
        wordBreak: 'break-all',
        lineHeight: '14px',
      }

  const copyStyleOverrides = showCopy
    ? {
        '& .MuiChip-deleteIcon': {
          fill: 'transparent!important',
          marginRight: '8px',
          fontSize: '12px',
        },
      }
    : {}
  const deleteIcon = showCopy ? <Copy size={12} /> : props.deleteIcon
  const onClick = showCopy ? copyValue : props.onClick
  const onDelete = showCopy ? copyValue : props.onClick
  return (
    <Tooltip title={isTruncated ? label : ''} {...tooltipProps}>
      <MuiChip
        sx={merge(
          {
            ...copyStyleOverrides,
            '& .MuiChip-label': {
              ...labelStyles,
            },
          },
          sx
        )}
        label={label}
        ref={ref}
        deleteIcon={deleteIcon}
        {...(showCopy || props.onDelete ? { onDelete } : {})}
        {...(showCopy || props.onClick ? { onClick } : {})}
        {...props}
      />
    </Tooltip>
  )
}
