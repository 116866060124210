import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

export const SettingLayout = ({ children, ...props }) => {
  return (
    <ContentScrollableArea
      {...props}
      initial={{ y: 15, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.25, duration: 0.4, ease: 'easeOut' } }}
    >
      {children}
    </ContentScrollableArea>
  )
}

const ContentScrollableArea = styled(motion.div)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  width: '100%',
  margin: '100px 0 0 0',
  padding: '20px 30px 50px 30px',
  boxSizing: 'border-box',
  // overflowY: 'scroll',
  height: '90%',
}))
