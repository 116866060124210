export const IconHTTP = ({ style = {} }) => {
  return (
    <svg style={style} focusable="false" aria-hidden viewBox="-1 -1 22 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.576 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm3.393-6.278a4.931 4.931 0 0 1-1.346 1.022c.29-.596.525-1.313.688-2.112h1.44a5.24 5.24 0 0 1-.781 1.09Zm1.283-2.406a5.619 5.619 0 0 0 0-2.632h-1.747a13.96 13.96 0 0 1 0 2.632h1.747Zm-.502-3.948h-1.44a8.993 8.993 0 0 0-.687-2.112c.495.254.95.599 1.347 1.022.306.328.567.695.78 1.09Zm-2.7 0a7.404 7.404 0 0 0-.481-1.432c-.386-.825-.799-1.2-1.073-1.2-.275 0-.687.375-1.073 1.2a7.41 7.41 0 0 0-.48 1.432h3.107Zm-4.369 0a8.99 8.99 0 0 1 .688-2.112 4.929 4.929 0 0 0-1.347 1.022 5.237 5.237 0 0 0-.78 1.09h1.44ZM5.74 8.684h1.747a13.967 13.967 0 0 0 0 2.632H5.74a5.617 5.617 0 0 1 0-2.632Zm.502 3.948c.213.395.474.761.78 1.09a4.93 4.93 0 0 0 1.347 1.022 8.99 8.99 0 0 1-.688-2.112h-1.44Zm2.7 0c.125.532.287 1.016.481 1.432.386.825.799 1.2 1.073 1.2.275 0 .687-.374 1.073-1.2.194-.416.356-.9.48-1.432H8.943Zm3.328-1.316a12.529 12.529 0 0 0 0-2.632H8.722a12.522 12.522 0 0 0 0 2.632h3.548ZM4.356 10c0 3.634 2.749 6.579 6.14 6.579 3.391 0 6.14-2.945 6.14-6.579s-2.749-6.579-6.14-6.579c-3.391 0-6.14 2.945-6.14 6.579Z"
      />
    </svg>
  )
}
