export const IconAWSS3 = ({ style = {} }) => {
  return (
    <svg style={style} focusable="false" aria-hidden viewBox="-1 -1 22 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.576 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm5.064-9.995-.02.121c.12.025.23.045.32.059a5.247 5.247 0 0 0-.3-.18Zm-5.08-.666c.21 0 .38-.16.38-.356 0-.196-.17-.355-.38-.355s-.381.16-.381.355c0 .196.171.356.38.356Zm3.396 4.433c.112-.04.238-.084.298-.116l.508-3.006c-1.072-.297-2.332-.827-3.18-1.2-.186.353-.573.6-1.023.6-.63 0-1.142-.479-1.142-1.067s.513-1.065 1.142-1.065c.523 0 .96.33 1.096.777.788.352 2.114.926 3.227 1.246l.53-3.14c-1.19.543-3.279.761-4.853.761-1.753 0-3.589-.24-4.651-.75l.953 6.867c.026.15.748.61 3.597.61.908 0 1.863-.088 2.553-.236.276-.058.517-.126.72-.2l.2-.072.025-.009Zm1.63-7.997c-.02-.314-1.778-1.065-5.027-1.065-3.09 0-4.78.701-4.794 1.066l.001.014c.024.366 1.712 1.062 4.793 1.062 3.235 0 4.988-.743 5.024-1.06l.003-.017Zm.761.062-.58 3.414c.804.422 1.156.724 1.222 1.041a.493.493 0 0 1-.12.439c-.094.103-.228.199-.58.199-.188 0-.448-.032-.789-.101L15 13.78c.003.382-.405.525-.8.664l-.19.068a6.21 6.21 0 0 1-.829.233c-.743.16-1.761.254-2.723.254-4.137 0-4.297-.916-4.35-1.218L5.003 5.827l-.001-.018L5 5.781C5 4.558 7.882 4 10.56 4c2.328 0 5.792.474 5.792 1.781a.32.32 0 0 1-.005.056Z"
      />
    </svg>
  )
}
