import { Accordion, AccordionSummary, AccordionDetails, LinearProgress } from '@mui/material'
import { TraceContext } from '../context/Trace.context'
import { memo, useContext, useMemo } from 'react'
import { useTheme } from '@mui/styles'
import { getSpanDetails, getSpanId } from '../util/getSpanDetails'
import classes from './TraceSpans.module.css'
import clsx from 'clsx'
import { get } from 'lodash'

const SpanChartItem = memo(({ currentSpan }) => {
  const { palette } = useTheme()
  const { maxSpanVal, tooltipAnchor, selectedSpan, setTooltipAnchor, onSpanClick } =
    useContext(TraceContext)

  const { id, isEvent, hasError, color } = currentSpan

  const selectedSpanId = getSpanId(selectedSpan)
  const hoveredSpanId = getSpanId(tooltipAnchor?.currentSpan)

  const isSelected = selectedSpanId === id
  const isHovered = hoveredSpanId === id

  /**
   * On span item hover check if text is overflown or not and set ref accordingly
   * This is to be used in showing the popper and the background hover color.
   */
  const onMouseEnter = () => {
    try {
      setTooltipAnchor({
        currentSpan,
      })
    } catch (err) {}
  }
  const onMouseLeave = () => {
    setTooltipAnchor(null)
  }

  const { paddingStartPercent, paddingEndPercent } = useMemo(() => {
    const offset =
      100 -
        (currentSpan?.chart?.[1] / maxSpanVal) * 100 +
        (currentSpan?.chart?.[0] / maxSpanVal) * 100 >
      99
        ? 1.5
        : 0

    const paddingStartPercent = (currentSpan?.chart?.[0] / maxSpanVal) * 100
    const paddingEndPercent = 100 - (currentSpan?.chart?.[1] / maxSpanVal) * 100 - offset
    return { paddingStartPercent, paddingEndPercent }
  }, [maxSpanVal, currentSpan])

  return (
    <div
      className={clsx(
        classes.traceSpansChartItem,
        isHovered || isSelected ? classes.isActive : null,
        currentSpan?.label === 'ColdStart' ? classes.isColdStartSpan : null
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        onSpanClick(id)
      }}
      style={{
        '--padding-start': `${paddingStartPercent - 1}%`,
        '--padding-end': `${paddingEndPercent}%`,
      }}
    >
      {isEvent ? (
        <div
          className={classes.eventChartDot}
          style={{
            '--color': get(palette, color),
          }}
        />
      ) : (
        <LinearProgress
          classes={{
            root: classes.traceSpansChartItemProgressBar,
          }}
          variant="determinate"
          color={hasError ? 'error' : 'primary'}
          value={100}
        />
      )}

      <span
        className={classes.traceSpansChartItemProgressDurationText}
        style={{
          '--color': get(palette, color === 'text.primary' ? 'text.secondary' : color),
        }}
      >
        {currentSpan.durationFormatted}
      </span>
    </div>
  )
})

export const RenderSpansChart = ({ span }) => {
  const { timelineSpans, expanded, traceEvents } = useContext(TraceContext)

  const currentSpan = getSpanDetails({ span, timelineSpans, traceEvents })
  const { id } = currentSpan
  const isSpanExpanded = expanded?.includes(id)

  if (!currentSpan) {
    return null
  }

  if (currentSpan?.spans?.length) {
    return (
      <Accordion
        expanded={isSpanExpanded}
        classes={{
          root: classes.traceSpansChartAccordion,
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls="spans-chart"
          classes={{
            root: classes.MuiAccordionSummary,
            content: classes.MuiAccordionSummaryContent,
            expandIconWrapper: classes.MuiAccordionSummaryExpandIconWrapper,
          }}
        >
          <SpanChartItem currentSpan={currentSpan} />
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.MuiAccordionDetails,
          }}
        >
          {currentSpan?.spans?.map((newCurrentSpan) => (
            <RenderSpansChart
              span={newCurrentSpan}
              key={`trace-spans-chart-span-${newCurrentSpan?.eventId || newCurrentSpan?.spanId}`}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    )
  } else {
    return <SpanChartItem currentSpan={currentSpan} />
  }
}
