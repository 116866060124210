import { memo, useContext } from 'react'
import classes from './ActivityItem.module.css'
import { Header } from '../MessageHeader/MessageHeader'
import { LogMessage } from '../LogMessage/LogMessage'
import { ReqResMessage } from '../ReqResMessage/ReqResMessage'
import { SpanMessage } from '../SpanMessage/SpanMessage'
import { LocalMessage } from '../LocalMessage/LocalMessage'
import { EventMessage } from '../EventMessage/EventMessage'
import { ActivityStreamContext } from '../../context/ActivityStream.context'
import { attachMetaTags } from '../../utils/helper'

export const ActivityItem = memo(function ActivityItem({ activity, metaTags, onClick, padBottom }) {
  const { theme } = useContext(ActivityStreamContext)
  // Attach root lambda tags so we can display meta data :)
  if (metaTags) {
    activity = attachMetaTags(activity, metaTags)
  }

  if (activity?.type === 'local') {
    return (
      <div
        className={`${classes.logItem} ${classes.local} ${
          activity?.fullHeight ? classes.fullHeight : ''
        }`}
      >
        <LocalMessage activity={activity} />
      </div>
    )
  }
  if (activity?.type === 'span') {
    return (
      <div
        onClick={() => {
          onClick(activity)
        }}
        className={classes.logItem}
      >
        <Header activity={activity} />
        <SpanMessage activity={activity} />
      </div>
    )
  } else if (activity?.type === 'log') {
    return (
      <div
        onClick={() => {
          setTimeout(() => {
            onClick(activity)
          }, 10)
        }}
        className={classes.logItem}
      >
        <Header activity={activity} />
        <LogMessage log={activity} />
      </div>
    )
  } else if (activity?.type === 'aws-lambda-request' || activity?.type === 'aws-lambda-response') {
    return (
      <div onClick={() => onClick(activity)} className={classes.logItem}>
        <Header activity={activity} />
        <ReqResMessage activity={activity} />
      </div>
    )
  } else if (activity?.type === 'event') {
    return (
      <div
        onClick={() => {
          setTimeout(() => {
            onClick(activity)
          }, 10)
        }}
        className={classes.logItem}
      >
        <Header activity={activity} />
        <EventMessage activity={activity} theme={theme} />
      </div>
    )
  }
  return <></>
})
