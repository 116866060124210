import { ResponsiveContainer } from 'recharts'

function CustomResponsiveContainer({ children, ...props }) {
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <ResponsiveContainer {...props}>{children}</ResponsiveContainer>
      </div>
    </div>
  )
}

export default CustomResponsiveContainer
