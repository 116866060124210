import { sumBy } from 'lodash'
import { useMemo } from 'react'

export const useMetricsCount = (payload = []) => {
  const invocations = useMemo(() => sumBy(payload, 'invocations') || 0, [payload])
  const uncaughtErrors = useMemo(() => sumBy(payload, 'uncaught_errors') || 0, [payload])
  const caughtErrors = useMemo(() => sumBy(payload, 'caught_errors') || 0, [payload])

  const sdkUserErrors = useMemo(() => sumBy(payload, 'sdk_user_errors') || 0, [payload])
  const warnings = useMemo(() => sumBy(payload, 'warnings') || 0, [payload])
  const sdkWarnings = useMemo(() => sumBy(payload, 'sdk_user_warnings') || 0, [payload])

  const totalErrors = uncaughtErrors + caughtErrors || 0
  const sdkErrors = sdkUserErrors || 0
  const events = caughtErrors + warnings + sdkErrors + sdkWarnings

  const okInvocations = Math.max(0, invocations - uncaughtErrors)
  const successRate = Math.floor((okInvocations / invocations) * 100) || 0

  return {
    invocations,
    invocationsFormatted: invocations?.toLocaleString('en-US') || '-',
    uncaughtErrors,
    uncaughtErrorsFormatted: uncaughtErrors?.toLocaleString('en-US') || '-',
    caughtErrors,
    caughtErrorsFormatted: caughtErrors?.toLocaleString('en-US') || '-',
    totalErrors,
    totalErrorsFormatted: totalErrors?.toLocaleString('en-US') || '-',
    okInvocations,
    okInvocationsFormatted: okInvocations?.toLocaleString('en-US') || '-',
    warnings,
    warningsFormatted: warnings?.toLocaleString('en-US') || '-',
    sdkErrors,
    sdkErrorsFormatted: sdkErrors?.toLocaleString('en-US') || '-',
    sdkWarnings,
    sdkWarningsFormatted: sdkWarnings?.toLocaleString('en-US') || '-',
    events,
    eventsFormatted: events?.toLocaleString('en-US') || '-',
    successRate,
    successRateFormatted: `${successRate}%`,
  }
}
