import { useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

import { Check } from 'lucide-react'
import { colors } from 'theme/colors'
import { styled } from '@mui/material'

export const IntegrationCreateSteps = ({ steps }) => {
  const [allParams, setAllQueryParams] = useQueryParams({
    integrationStep: StringParam,
    integrationId: StringParam,
    integrationType: StringParam,
    redirectUrl: StringParam,
    createIntegration: BooleanParam,
    editIntegration: BooleanParam,
  })
  const { integrationStep } = allParams
  const foundStepIndex = steps.findIndex(({ stepAlias }) => stepAlias === integrationStep)
  const [activeStep, setActiveStep] = useState(
    steps.length > 0 && foundStepIndex !== -1 ? foundStepIndex : 0
  )

  const handleNext = ({ params = {}, endMe } = { endMe: false }) => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep + 1 < steps.length && !endMe) {
        const { stepAlias } = steps[prevActiveStep + 1]
        setAllQueryParams({
          ...params,
          integrationStep: stepAlias,
        })
        return prevActiveStep + 1
      }
      setAllQueryParams({
        integrationStep: undefined,
        integrationId: undefined,
        integrationType: undefined,
        redirectUrl: undefined,
        createIntegration: false,
        editIntegration: undefined,
      })
      return prevActiveStep
    })
  }

  const hideStepper = steps[activeStep]?.hiddenStepper

  const handleReset = () => {
    setActiveStep(0)
  }

  const Component = steps[activeStep].Component

  return (
    <Box width="100%">
      {!hideStepper && (
        <Box margin="0 auto" maxWidth="800px" marginBottom="60px">
          <Stepper activeStep={activeStep} alternativeLabel connector={<CustomConnector />}>
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <Component handleNext={handleNext} />
      )}
    </Box>
  )
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.blackDark,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.blackDark,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : colors.greyDark,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : colors.greyDark,
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: colors.blackDark,
  }),
  '& .CustomStepIcon-completedIcon': {
    color: colors.blackDark,
    zIndex: 1,
    fontSize: 18,
  },
  '& .CustomStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function CustomStepIcon(props) {
  const { active, completed, className } = props

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check size={15} /> : <div className="CustomStepIcon-circle" />}
    </CustomStepIconRoot>
  )
}
