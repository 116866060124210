import { SvgIcon } from '@mui/material'

export const IconServerlessConsole = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74023 8.06022C1.74023 7.1173 2.39871 6.298 3.33109 6.08083L19.3496 2.34967C20.4709 2.08851 21.5524 2.77582 21.8263 3.8139L23.5268 8.03951C23.6678 8.39401 23.7402 8.77136 23.7402 9.15207V16.0529C23.7402 16.5376 23.6229 17.0155 23.3978 17.4467L21.8795 20.3562C21.7788 20.5491 21.6478 20.7242 21.4921 20.8754C21.0447 21.473 20.276 21.8157 19.4644 21.674L3.44588 18.8756C2.45915 18.7032 1.74023 17.8591 1.74023 16.8728V8.06022ZM21.8926 18.6614L22.7083 17.0982C22.8771 16.7748 22.9651 16.4164 22.9651 16.0529V9.15207C22.9651 8.86654 22.9108 8.58353 22.805 8.31765L21.8926 6.02466V18.6614ZM10.349 7.47619C10.0241 7.56326 9.79608 7.87618 9.79608 8.23517V15.7688C9.79608 16.1487 10.0506 16.4736 10.3997 16.5394C10.8466 16.6235 11.2561 16.2551 11.2561 15.7688V8.23517C11.2561 7.7262 10.8098 7.35275 10.349 7.47619ZM8.32833 10.1427C8.05667 10.2071 7.86328 10.4651 7.86328 10.7633V15.5019C7.86328 15.8196 8.08222 16.0886 8.37598 16.1316C8.7322 16.1839 9.04953 15.8872 9.04953 15.5019V10.7633C9.04953 10.3569 8.69859 10.0549 8.32833 10.1427ZM12.0443 11.5205C12.0443 11.0881 12.3375 10.7196 12.736 10.6514C13.2349 10.566 13.6868 10.9791 13.6868 11.5205V15.8824C13.6868 16.4211 13.2392 16.8334 12.7422 16.7525C12.3409 16.6872 12.0443 16.3174 12.0443 15.8824V11.5205Z"
      />
    </SvgIcon>
  )
}
