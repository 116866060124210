const getFirstItem = (arr) => {
  return Array.isArray(arr) ? arr[0] : arr
}

const objectify = (query = window.location.search) => {
  if (!query) return {}

  const params = new URLSearchParams(query)
  const entries = Array.from(params.entries())
  return entries.reduce((a, c) => {
    if (Array.isArray(c)) {
      a[c[0]] = c.slice(1)
    } else {
      a[c] = entries[c]
    }

    return a
  }, {})
}

const parameterise = (obj) => {
  if (!obj || Object.keys(obj).length < 1) return ''

  return new URLSearchParams(obj).toString()
}

const tryGetParam = (key, query = window.location.search) => {
  const parseVal = (val) => {
    if (Array.isArray(val) && val.length < 1) return null

    const data = val[0]
    if (data.indexOf(',') > -1) return data.split(',')
    return data
  }

  const obj = objectify(query)
  const val = obj[key]
  if (!val) return null
  return parseVal(val)
}

export { getFirstItem, objectify, parameterise, tryGetParam }
