const updateName = (str) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })

export const handleApiError = ({
  error,
  defaultMessage = 'Something went wrong. Please try again.',
}) => {
  const errorDetails = Object.keys(error?.errors || {}).map((key) =>
    error?.errors[key].map((message) => `${updateName(key)} ${message}`)
  )
  if (Array.isArray(errorDetails) && errorDetails.length > 0) {
    const messages = errorDetails.reduce((arr, messages) => [...arr, ...messages], [])
    const message = messages.join('\n')
    return (message || '').trim() !== '' ? message : defaultMessage
  } else {
    return error.message || defaultMessage
  }
}
