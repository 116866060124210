import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { colors } from 'theme/colors'

export const TraceNotFound = ({ close }) => {
  let errorMessage = "We're not able to load details for this Trace"

  return (
    <Box
      component={motion.div}
      sx={{
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      bgcolor="background.default"
    >
      <Box>
        <Box
          height="100%"
          minHeight={300}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <NotFoundTitle variant="h2">Trace not found</NotFoundTitle>
          <Typography variant="textPrimary" color="text.secondary">
            {errorMessage}
          </Typography>
          <NotFoundLink variant="textPrimary" onClick={close}>
            Back to explorer
          </NotFoundLink>
        </Box>
      </Box>
    </Box>
  )
}

const NotFoundTitle = styled(Typography)({
  color: colors.redPrimary,

  margin: 0,
})

const NotFoundLink = styled(Typography)({
  cursor: 'pointer',
  opacity: 0.55,
  textDecoration: 'underline',
  margin: 0,
})
