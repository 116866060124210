const formatBytes = {
  format: (bytes, as = 'MB') => {
    let working = bytes * 1.0

    const b = 1.0
    const kb = 1024.0
    const mb = kb * 1024.0
    const gb = mb * 1024.0
    const tb = gb * 1024.0

    switch (as) {
      case 'tb':
      case 'TB':
        working /= tb
        break
      case 'gb':
      case 'GB':
        working /= gb
        break
      case 'mb':
      case 'MB':
        working /= mb
        break
      case 'kb':
      case 'KB':
        working /= kb
        break
      case 'b':
      case 'B':
      default:
        working /= b
        break
    }

    return `${working < 1 ? 1 : parseInt(working)} ${as.toUpperCase()}`
  },
}

const formatCount = Intl.NumberFormat('en-GB', {
  notation: 'compact',
})

const formatInterval = {
  format: (interval, labelSamples = [], round = false, secondsThreshold = 0.0166) => {
    let result = ''
    let resultNum = 0.0
    let working = interval

    let minutes = 0
    let seconds = 0
    let milliseconds = 0

    // determine components
    minutes = Math.floor(working / 60000.0)
    working -= minutes * 60000.0

    seconds = Math.floor(working / 1000.0)
    working -= seconds * 1000.0

    milliseconds = working

    // reassemble as a fraction of a minute
    if (milliseconds) resultNum += parseFloat(milliseconds) / 60000.0
    if (seconds) resultNum += parseFloat(seconds) / 60.0
    if (minutes) resultNum += parseFloat(minutes) / 1.0

    // determine printable representation
    if (resultNum <= 1.0 / 60000.0) {
      const prefix = resultNum === 0 ? '0' : '<1'
      result = `${prefix}ms`
    } else if (resultNum > 1) {
      result = round ? `${parseInt(Math.round(resultNum))}m` : `${resultNum.toFixed(1)}m`
    } else if (resultNum > secondsThreshold) {
      // only print seconds if we have 10 seconds or more
      resultNum = resultNum * 60.0
      result = round ? `${parseInt(Math.round(resultNum))}s` : `${resultNum.toFixed(1)}s`
    } else {
      resultNum = resultNum * 60000.0
      result = round ? `${parseInt(Math.round(resultNum))}ms` : `${parseInt(resultNum)}ms`
    }

    return result
  },
}

const formatTimestamp = new Intl.DateTimeFormat('en-GB', {
  timeZone: 'UTC',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  fractionalSecondDigits: 3,
})

const formatTotal = new Intl.NumberFormat('en-GB')

/**
 * Get a formatted duration that includes "s", "ms", etc.
 * @param {*} milliseconds
 * @returns
 */
const formatDuration = (milliseconds) => {
  let seconds = milliseconds / 1000
  let minutes = seconds / 60

  if (milliseconds <= 999) {
    return `${Math.round(milliseconds)}ms`
  } else if (seconds <= 59) {
    return `${Math.floor(seconds * 100) / 100}s`
  } else if (minutes >= 1) {
    return `${Math.floor(minutes * 100) / 100}min`
  }
}

/**
 * Format Org and Username correctly for the Serverless Platform backend
 */
const formatOrgAndUsername = (name = '') => {
  name = name
    .toString()
    .toLowerCase()
    .replace(/[^a-z\d-]+/gi, '-')

  // Remove multiple instances of hyphens
  name = name.replace(/-{2,}/g, '-')

  if (name.length > 40) {
    name = name.substring(0, 40)
  }

  return name
}

/**
 * Formats error.stack in a pretty way
 * @param {*} err
 */
const formatErrorStack = (err) => {
  if (!err) {
    return null
  }
  if (err.includes('Uncaught Exception ')) {
    err = err.split('Uncaught Exception ')
    err[1] = err[1].trim()
    err = err.join('Uncaught Exception\n\n')
  }
  if (err.includes('    at')) {
    err = err.split('    at')
    err[0] += '\n'
    err = err.join('\n   at')
  }
  return err
}

export {
  formatBytes,
  formatCount,
  formatInterval,
  formatTimestamp,
  formatTotal,
  formatDuration,
  formatOrgAndUsername,
  formatErrorStack,
}
