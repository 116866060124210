import clsx from 'clsx'
import { forwardRef, memo } from 'react'
import classes from './GlobalSearchDialog.module.css'
import { Skeleton } from '@mui/material'

export const SkeletonLoading = memo(
  forwardRef((item, outerRef) => (
    <div ref={outerRef} className={classes.RowWrapper}>
      <div className={clsx(classes.RowContent)}>
        <div className={classes.RowIconWrapper}>
          <Skeleton variant="react" width={30} height={30} className={classes.mainIcon} />
        </div>
        <div className={classes.RowText}>
          <Skeleton variant="text" width="300px" height={15} />
          <div className={classes.RowTags}>
            <Skeleton variant="text" width="80px" height={15} />
            <Skeleton variant="text" width="80px" height={15} />
            <Skeleton variant="text" width="80px" height={15} />
            <Skeleton variant="text" width="80px" height={15} />
          </div>
        </div>
        <div className={classes.RowActions}>
          <Skeleton variant="rect" width={30} height={30} />
          <Skeleton variant="rect" width={30} height={30} />
          <Skeleton variant="rect" width={30} height={30} />
        </div>
      </div>
    </div>
  ))
)
