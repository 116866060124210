import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { FilterContext } from 'filter/context/FilterContext'
import { debounce, isEmpty } from 'lodash'
import { formatTraceForTimeline } from '../util/span-chart'
import { formatDuration } from 'util/format'
import { getMaxSpanVal } from '../util/maxSpanVal'
import { sortSpans } from '../util/sortSpans'
import config from 'config'
import { AppContext } from 'app/context/AppContext'
import useSWR from 'swr'
import { coreApiClient } from 'util/coreApiClient'
import { enrichSpan } from '../util/enrichSpan'
import { isAfter, parseISO, subDays, subHours } from 'date-fns'
import { Popper } from '@mui/material'
import { styled } from '@mui/styles'

export const ROW_HEIGHT = 35
const maxRetryTimeLimit = 1000 * 60 * 2.5 // 2.5 minutes
const { queryUrl, integrationsUrl } = config.platform
const waitForTraceErrorMessage = 'ConsoleUI: Waiting for trace'
export const TraceContext = createContext()
export const TraceProvider = ({ children }) => {
  const { getFilterValue, setFilterValue } = useContext(FilterContext)
  const { activeOrg } = useContext(AppContext)
  const { orgId } = activeOrg || {}
  const traceId = getFilterValue('explorerTraceId')
  const timestampParam = getFilterValue('explorerTraceTime')

  const [trace, setTrace] = useState(null)
  const [timelineSpans, setTimelineSpans] = useState([])
  const [selectedSpan, setSelectedSpan] = useState(null)
  const [selectedSpanDuration, setSelectedSpanDuration] = useState(null)

  const [tooltipAnchor, setTooltipAnchor] = useState()
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipArrowRef, setTooltipArrowRef] = useState()

  const debouncedHandleMouseEnter = debounce(() => {
    setShowTooltip(true)
  }, 1000)
  useEffect(() => {
    if (tooltipAnchor?.anchorEl) {
      debouncedHandleMouseEnter()
    } else {
      setShowTooltip(false)
      debouncedHandleMouseEnter.cancel()
    }
  }, [tooltipAnchor?.anchorEl])

  const [logs, setLogs] = useState(null)
  const [expanded, setExpanded] = useState([])
  const [isWaitingForTrace, setIsWaitingForTrace] = useState(false)
  const traceIdIsExpired = useMemo(
    () => isAfter(subDays(new Date(), 30), parseISO(timestampParam)),
    [timestampParam]
  )

  const tracesDetailsUrl = `${queryUrl}/${orgId}/traces/${traceId}`

  const traceEventsUrl = `/activity/${orgId}/events/detail`
  const firstRequest = useMemo(() => Date.now(), [traceId])

  // Make sure trace timestamp is not after current time
  let timestamp
  try {
    timestamp = isAfter(parseISO(timestampParam), new Date())
      ? new Date().toISOString()
      : timestampParam
  } catch (err) {}

  const { data: traceDetails, error: traceDetailsError } = useSWR(
    tracesDetailsUrl,
    async (url) => {
      const res = await coreApiClient({
        url,
        params: {
          timestamp,
        },
      })
      const hasRootSpan = res?.data?.span?.name === 'aws.lambda'
      if (hasRootSpan || Date.now() - firstRequest >= maxRetryTimeLimit || traceIdIsExpired) {
        return res
      }
      throw new Error(waitForTraceErrorMessage)
    },
    {
      shouldRetryOnError: true,
      onSuccess: () => {
        setIsWaitingForTrace(false)
      },
      onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
        if (error.message !== waitForTraceErrorMessage) return
        setIsWaitingForTrace(true)
        setTimeout(() => revalidate({ retryCount }), 1000)
      },
      // Only refresh if we are within an hour of the trace timestamp. This way we can ensure that eventually all spans will be loaded
      refreshInterval: isAfter(subHours(new Date(), 1), parseISO(timestampParam)) ? 0 : 10000,
    }
  )

  const { data: traceEvents, error: traceEventsError } = useSWR(
    [traceEventsUrl, traceId],
    () =>
      coreApiClient({
        url: traceEventsUrl,
        method: 'post',
        data: {
          traceId,
        },
      }),
    {
      // Only refresh if we are within an hour of the trace timestamp. This way we can ensure that eventually all spans will be loaded
      refreshInterval: isAfter(subHours(new Date(), 1), parseISO(timestampParam)) ? 0 : 10000,
    }
  )
  const { region, accountId, lambda } = traceDetails?.data?.span?.tags?.aws || {}
  const shouldFetchLogs = traceDetails?.data
  const tracesLogsUrl = shouldFetchLogs && `${integrationsUrl}/aws/logs`

  const logsStartTimeUnix = lambda?.logsStartTimeUnix
  const logsEndTimeUnix = lambda?.logsEndTimeUnix
  const logTimesProvided = logsStartTimeUnix != null && logsEndTimeUnix != null
  const times = logTimesProvided
    ? {
        startTimeUnix: Number(logsStartTimeUnix),
        endTimeUnix: Number(logsEndTimeUnix),
      }
    : {
        startTimeUnix: new Date(traceDetails?.data?.span?.startTime).getTime(),
        endTimeUnix: new Date(traceDetails?.data?.span?.endTime).getTime(),
      }

  const { data: traceLogs, error: traceLogsError } = useSWR(tracesLogsUrl, (url) =>
    coreApiClient({
      url,
      params: {
        region,
        accountId,
        functionName: lambda?.name,
        logStreamName: lambda?.logStreamName,
        ...times,
      },
    })
  )

  const selectedSpanId = getFilterValue('explorerTraceSpanId') || traceDetails?.data?.span?.spanId

  const isLoadingTraceDetails = !traceDetails && !traceDetailsError
  const isLoadingTraceEvents = !traceEvents && !traceEventsError
  const isLoadingTraceLogs = shouldFetchLogs && !traceLogs && !traceLogsError

  const isLoading = isLoadingTraceDetails || isLoadingTraceEvents || isLoadingTraceLogs

  // Expand all spans by default
  useEffect(() => {
    if (timelineSpans?.length) {
      setExpanded(timelineSpans?.map((span) => span?.spanId))
    }
  }, [timelineSpans])

  const isNotFound =
    !isLoading && !isWaitingForTrace && (!traceDetails?.data || isEmpty(traceDetails?.data))

  useEffect(() => {
    if (traceDetails?.data) {
      // Enrich
      setTrace(enrichSpan(traceDetails?.data?.span, null))
    }
  }, [traceDetails])
  // Handle formatting Trace for timeline
  useEffect(() => {
    if (!trace) {
      return
    }
    const formattedTrace = formatTraceForTimeline(trace, traceEvents?.data)
    setTimelineSpans(sortSpans(formattedTrace))
  }, [trace, traceEvents])

  // Handle setting selected Span
  useEffect(() => {
    if (!trace || !selectedSpanId) {
      setSelectedSpan(null)

      return
    }
    const findSpan = (span, id) => {
      if (span.spanId === id) return span
      let foundSpan
      const spans = span?.spans || []
      for (const subSpan of spans || []) {
        if (foundSpan) continue
        foundSpan = findSpan(subSpan, id)
      }
      return foundSpan
    }
    const eventSpan = traceEvents?.data?.find((event) => event?.eventId === selectedSpanId)
    let foundSpan = eventSpan ? eventSpan : findSpan(trace, selectedSpanId)
    foundSpan = foundSpan || trace // Default to root span
    setSelectedSpan(foundSpan)

    // If the Span is "aws.lambda", provide better Duration information
    if (foundSpan && foundSpan.name === 'aws.lambda') {
      /**
       * "aws.lambda": Duration
       * Currently, AWS Lambda "duration" numbers are highly variable.
       * AWS Lambda's "duration" number itself does not appear to contain "Init" or "Shutdown" durations,
       * as well as the START and STOP timestamps of AWS Lambda requests in Cloudwatch.
       * So, we make the best of what we can here.
       */

      const durations = []

      const spanInitialization =
        foundSpan.spans && foundSpan.spans.length
          ? foundSpan.spans.find((span) => span.name === 'aws.lambda.initialization')
          : null
      const spanInvocation =
        foundSpan.spans && foundSpan.spans.length
          ? foundSpan.spans.find((span) => span.name === 'aws.lambda.invocation')
          : null
      const billedDuration =
        foundSpan.tags?.aws?.lambda?.duration > -1
          ? Math.ceil(foundSpan.tags.aws.lambda.duration)
          : null // Round up to get actual Lambda Billed Duration
      const initializationDuration = spanInitialization
        ? spanInitialization.duration
        : foundSpan.tags?.aws?.lambda?.initialization?.initializationDuration

      // Create a special metric which shows what end-users actually experience
      if (spanInvocation) {
        durations.push({
          title: 'User Experience',
          value: formatDuration(
            initializationDuration
              ? initializationDuration + spanInvocation.duration
              : spanInvocation.duration
          ),
          description:
            'User Experience – This is the time it took to get a response from your AWS Lambda function. It combines our precise measurement of the "Invocation" phase with any "Initialization" duration.',
        })
      }
      if (billedDuration) {
        durations.push({
          title: 'Billed',
          value: formatDuration(billedDuration),
          description:
            'Billed – The total duration of this AWS Lambda invocation that you are billed for.  This is not the performance your users experience when using your AWS Lambda-based application because it includes "Shutdown" time which happens after your Lambda returns a response (if you are using an Extension). If your Billed Duration is much longer than your "Invocation", you may be experiencing a large "Shutdown" time in Extension(s).',
        })
      }
      // If "aws.lambda.initialization" Span was included, add an "Init" duration.
      if (initializationDuration) {
        durations.push({
          title: 'Init',
          value: spanInitialization?.durationFormatted
            ? spanInitialization.durationFormatted
            : formatDuration(initializationDuration),
          description:
            'Initialization – The duration of the "Initialization" phase of your AWS Lambda function (aka cold-start time), which runs before your code runs. This affects the time it takes for your function to respond.',
        })
      }
      // Add a Span for "aws.lambda.invocation"
      if (spanInvocation) {
        durations.push({
          title: 'Invoke',
          value: spanInvocation.durationFormatted
            ? spanInvocation.durationFormatted
            : formatDuration(spanInvocation.duration),
          description:
            'Invocation – The duration of the "Invocation" phase of your AWS Lambda function. This is the time it takes for your logic to run. This affects the time it takes for your function to respond. You are billed for this.',
        })
      }
      // Create a "Post-Processing" Span estimate by subtracting the invocation duration from the billed duration from
      if (billedDuration && spanInvocation) {
        durations.push({
          title: 'Post Processing',
          value: formatDuration(billedDuration - spanInvocation.duration),
          description:
            'Post-Processing – This is the estimated duration of post-processing that happened in your AWS Lambda function performed after Invocation, within any AWS Lambda External Extensions you have installed. Most post-processing happens after your function returned a response, so this will not affect performance, but you are billed for it.',
        })
      }
      setSelectedSpanDuration(durations)
    } else {
      setSelectedSpanDuration([
        {
          title: 'Duration',
          value: foundSpan.durationFormatted
            ? foundSpan.durationFormatted
            : formatDuration(foundSpan.duration),
          description: 'The total duration of this Span.',
        },
      ])
    }
  }, [trace, selectedSpanId])

  // Handle setting Logs, only if the root Span is selected
  useEffect(() => {
    if (!trace || selectedSpanId !== traceDetails?.data?.span?.spanId || !traceLogs?.length) {
      setLogs(null)
    } else if (traceLogs?.length) {
      const logsArray = traceLogs?.map((log) => {
        let body = log.message
        // Try to parse log message
        try {
          body = body.split(`${traceDetails?.data?.span?.tags?.aws?.requestId}	INFO	`)?.[1] || body
        } catch (err) {}
        return {
          ...log,
          body,
          type: 'log',
          tags: traceDetails?.data?.span?.tags,
        }
      })
      setLogs(logsArray)
    }
  }, [traceLogs, selectedSpanId])

  const maxSpanVal = getMaxSpanVal(timelineSpans)
  const onSpanClick = (spanId) => {
    if (!spanId) {
      return
    }
    setFilterValue('explorerTraceSpanId', spanId)
  }
  return (
    <TraceContext.Provider
      value={{
        traceEvents,
        expanded,
        setExpanded,
        isLoading,
        isWaitingForTrace,
        isNotFound,
        trace,
        maxSpanVal,
        selectedSpan,
        selectedSpanDuration,
        logs,
        onSpanClick,
        timelineSpans,
        tooltipAnchor,
        setTooltipAnchor,
      }}
    >
      {showTooltip && tooltipAnchor?.anchorEl && (
        <StyledPopper
          open={showTooltip && !!tooltipAnchor?.anchorEl}
          anchorEl={showTooltip && tooltipAnchor?.anchorEl}
          onClick={(e) => e?.stopPropagation()}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: tooltipArrowRef,
              },
            },
          ]}
        >
          <PopperArrow ref={setTooltipArrowRef} />

          {tooltipAnchor?.label}
        </StyledPopper>
      )}
      {children}
    </TraceContext.Provider>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  borderRadius: 4,
  zIndex: theme.zIndex.modal,
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  padding: '5px 10px',
  fontWeight: 'bold',
  fontSize: theme.typography.textSecondary.fontSize,
}))

export const PopperArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  fontSize: 14,
  top: '-10px !important',
  zIndex: 100,
  left: 7,
  height: '8px',
  width: '3px',
  marginBottom: '42px',

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0.6em 0.6em 0.6em 0',
    transform: 'rotateZ(90deg)',
    borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
  },
}))
