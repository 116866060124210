import { memo, useContext } from 'react'
import classes from './LogMessage.module.css'
import ReactJson from 'react-json-view'
import { ActivityStreamContext } from '../../context/ActivityStream.context'
import { formatErrorStack } from '../../utils/helper'

export const LogMessage = memo(function LogMessage({ log }) {
  const { theme } = useContext(ActivityStreamContext)
  try {
    const parsedBody = JSON.parse(log.body)
    if (typeof parsedBody === 'string') {
      throw new Error('Not a JSON object')
    }
    return (
      <pre className="log-stream-log clickable">
        <ReactJson
          theme={
            log?.severityText === 'ERROR'
              ? {
                  base00: theme.palette.text.red,
                  base01: theme.palette.text.red,
                  base02: theme.palette.text.red,
                  base03: theme.palette.text.red,
                  base04: theme.palette.text.red,
                  base05: theme.palette.text.red,
                  base06: theme.palette.text.red,
                  base07: theme.palette.text.red,
                  base08: theme.palette.text.red,
                  base09: theme.palette.text.red,
                  base0A: theme.palette.text.red,
                  base0B: theme.palette.text.red,
                  base0C: theme.palette.text.red,
                  base0D: theme.palette.text.red,
                  base0E: theme.palette.text.red,
                  base0F: theme.palette.text.red,
                }
              : {
                  base00: theme.palette.text.secondary,
                  base01: theme.palette.text.secondary,
                  base02: theme.palette.border.main,
                  base03: theme.palette.text.secondary,
                  base04: theme.palette.text.secondary,
                  base05: theme.palette.text.secondary,
                  base06: theme.palette.text.secondary,
                  base07: theme.palette.text.primary,
                  base08: theme.palette.text.secondary,
                  base09: theme.palette.text.secondary,
                  base0A: theme.palette.text.secondary,
                  base0B: theme.palette.text.secondary,
                  base0C: theme.palette.text.secondary,
                  base0D: theme.palette.text.secondary,
                  base0E: theme.palette.text.secondary,
                  base0F: theme.palette.text.secondary,
                }
          }
          name={null}
          indentWidth={4}
          iconStyle="triangle"
          displayDataTypes={false}
          enableClipboard={true}
          displayObjectSize={false}
          collapsed={2}
          quotesOnKeys={false}
          style={{
            backgroundColor: 'transparent',
          }}
          src={parsedBody}
        />
      </pre>
    )
  } catch (error) {
    if (log.severityText === 'ERROR') {
      return (
        <pre className={`${classes.rawMessage} ${classes.error}`}>{formatErrorStack(log.body)}</pre>
      )
    }
    // Could not parse json return log as string
    return <pre className={classes.rawMessage}>{log.body}</pre>
  }
})
