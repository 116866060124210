import { useMemo, useState } from 'react'
import { Box, FormControl, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { motion } from 'framer-motion'
import pluralize from 'pluralize'
import Button from './Button'
import { Search, X } from 'lucide-react'

const gridVariants = {
  visible: {
    opacity: 1,
    display: 'block',
    y: 0,
    transition: { duration: 0.1, ease: 'easeIn' },
  },
  hidden: {
    opacity: 0,
    display: 'none',
    y: 100,
    transition: { duration: 0.1, ease: 'easeOut' },
  },
}

const buttonVariants = {
  visible: {
    transform: 'rotate(180deg)',
  },
  hidden: {
    transform: 'rotate(0deg)',
  },
}

export const BulkInstrumentationSelect = ({
  selectedFunctionCount,
  compatibleFunctionCount,
  waitingForInstrumentation,
  instrumentationProgress,
  enableTargetFunctions,
  columns,
  allResources,
  alwaysShow = false,
  setResources = () => {},
  setBypass = () => {},
  selectHeight = '380px',
  cancelText = 'No Thanks',
  hideCancel = false,
  expandChange = () => {},
  parentExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(alwaysShow ? true : false)
  const [searchText, setSearchText] = useState('')

  const filteredResources = useMemo(
    () =>
      allResources.filter(({ resourceKey }) =>
        resourceKey.toLowerCase().includes(searchText.toLowerCase())
      ),
    [allResources, searchText]
  )

  const isExpanded = !parentExpanded && expanded

  return (
    <>
      {compatibleFunctionCount > 0 && (
        <>
          <Box
            onClick={() => {
              if (!alwaysShow) {
                expandChange(!isExpanded)
                setExpanded(!isExpanded)
              }
            }}
            sx={{
              margin: '20px 0',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: alwaysShow ? 'default' : 'pointer',
            }}
          >
            <Typography variant="body1">
              Select Resources to Instrument ({selectedFunctionCount})
            </Typography>
            {!alwaysShow && (
              <motion.div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                initial={alwaysShow ? 'visible' : 'hidden'}
                animate={isExpanded ? 'visible' : 'hidden'}
                variants={buttonVariants}
              >
                <ExpandMore />
              </motion.div>
            )}
          </Box>
          <motion.div
            initial={alwaysShow ? 'visible' : 'hidden'}
            animate={isExpanded ? 'visible' : 'hidden'}
            variants={gridVariants}
            style={{
              height: selectHeight,
              overflowY: 'hidden',
              width: '100%',
            }}
          >
            <FormControl sx={{ width: '100%', marginBottom: '10px' }} variant="standard">
              <TextField
                id="search-input"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText || ''}
                InputProps={{
                  style: {
                    paddingLeft: 10,
                    paddingRight: 10,
                  },
                  placeholder: 'Search for anything...',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={16} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <X size={16} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                height: `${parseInt(selectHeight.replace('px', '')) - 47}px`,
              }}
            >
              <StyledDataGrid
                expanded={isExpanded}
                rows={filteredResources}
                columns={columns}
                autoHeight={false}
                disableColumnMenu
                disableColumnSelector
                rowHeight={70}
                getRowId={(row) => row.resourceKey}
                pageSize={100}
                checkboxSelection
                hideFooter
                hideFooterPagination
                disableSelectionOnClick={!isExpanded}
                onSelectionModelChange={(newSelection) => {
                  if (isExpanded) {
                    const filteredKeys = filteredResources.map((r) => r.resourceKey)
                    const selectedNotInFilter = allResources
                      .filter(
                        ({ resourceKey, selected }) =>
                          !filteredKeys.includes(resourceKey) && selected
                      )
                      .map((r) => r.resourceKey)
                    setResources(
                      allResources.map((r) => {
                        r.selected = [...newSelection, ...selectedNotInFilter].includes(
                          r.resourceKey
                        )
                        return r
                      })
                    )
                  }
                }}
                selectionModel={filteredResources
                  .filter((r) => r.selected)
                  .map((r) => r.resourceKey)}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          </motion.div>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        {compatibleFunctionCount > 0 ? (
          <Button
            fullWidth
            loading={waitingForInstrumentation}
            disabled={selectedFunctionCount === 0}
            onClick={enableTargetFunctions}
          >
            Instrument {selectedFunctionCount} {pluralize('Resources', selectedFunctionCount)}
          </Button>
        ) : (
          <Button fullWidth onClick={enableTargetFunctions}>
            Manage your inventory
          </Button>
        )}
        {!hideCancel && (
          <Button fullWidth variant="outlined" onClick={setBypass}>
            {cancelText}
          </Button>
        )}
      </Box>
      {waitingForInstrumentation && (
        <Typography variant="subtitle1" color="grey.700">
          Instrumented {instrumentationProgress?.instrumented} of {instrumentationProgress?.total}{' '}
          Resources
        </Typography>
      )}
    </>
  )
}

const StyledDataGrid = styled(DataGrid)(({ theme, expanded }) => ({
  cursor: expanded ? 'pointer !important' : 'default !important',
  '& .MuiDataGrid-columnHeader--sortable': {
    cursor: expanded ? 'pointer !important' : 'default !important',
  },
  '& .MuiDataGrid-checkboxInput': {
    cursor: expanded ? 'pointer !important' : 'default !important',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell': {
    cursor: expanded ? 'pointer !important' : 'default !important',
  },

  '& .MuiDataGrid-columnSeparator': {
    minHeight: '35px !important',
    opacity: 1,
    marginRight: '11px',
    borderLeft: `1px solid ${theme.palette.grey.light}`,
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },

  '& .MuiDataGrid-cell--withRenderer': {
    paddingLeft: '0px',
  },

  // Hide the last column separator in the grid
  '& .MuiDataGrid-columnHeadersInner > div .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnSeparator':
    {
      display: 'none !important',
    },
}))
