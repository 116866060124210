import { useLocalStorage } from 'react-use'
import { isBefore } from 'date-fns'

/**
 * This will track recently instrumented modes for 30 seconds
 *
 * @returns
 */
export const useIsInstrumenting = ({ specificModes } = {}) => {
  const [instrumentingStatus, setInstrumentationStatus] = useLocalStorage('instrumentationStatus', {
    instrumenting: false,
    expires: new Date().getTime(),
  })

  /**
   * This will update the instrumentation status for the next 30 seconds
   *
   * @param {array["dev" | "prod" | "none"]} modes The array of modes being instrumented
   */
  const setInstrumenting = (modes) => {
    setInstrumentationStatus({
      instrumenting: true,
      expires: new Date().getTime() + 30000,
      modes,
    })
  }

  return {
    instrumenting:
      instrumentingStatus.instrumenting &&
      (specificModes
        ? specificModes.some((mode) => instrumentingStatus.modes.includes(mode))
        : true) &&
      isBefore(new Date(), new Date(instrumentingStatus.expires)),
    setInstrumenting,
  }
}
