import { Typography } from '@mui/material'
import { ChevronLeft, ChevronRight, RefreshCw } from 'lucide-react'
import { memo, useState } from 'react'
import { useDebounce } from 'react-use'
import Button from 'common/components/Button'
import { isNil } from 'lodash'

export const SimplePagination = memo(function Pagination({
  setCurrentPage,
  currentPage,
  refresh,
  isValidating,
  currentSize,
  size,
  loading,
  debounceDelay,
  footerMessage,
}) {
  const [page, setPage] = useState(currentPage)

  useDebounce(
    () => {
      setPage(currentPage)
    },
    debounceDelay,
    [currentPage]
  )

  const hasNext = currentSize && currentSize === size

  const from = (page === 1 && currentSize ? 1 : (page - 1) * size) || 0
  const to = page === 1 ? currentSize : from + size || 0

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '100%',
        padding: '0 20px 0 60px',
        gap: '15px',
      }}
    >
      {footerMessage}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '15px',
          marginLeft: 'auto',
        }}
      >
        {!loading && !isNil(from) && !isNil(to) && (
          <Typography variant="textSecondary" color="text.secondary">
            Showing {`${from?.toLocaleString('en-US')}-${to?.toLocaleString('en-US')}`}
          </Typography>
        )}
        <Button
          size="small"
          variant="outlined"
          disabled={currentPage === 1 || loading}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage((prev) => prev - 1)
            }
          }}
          sx={{
            minWidth: '30px',
            color: 'text.secondary',
          }}
        >
          <ChevronLeft size={15} />
        </Button>
        <Button
          size="small"
          variant="outlined"
          disabled={!hasNext || loading}
          onClick={() => {
            setCurrentPage((prev) => prev + 1)
          }}
          sx={{
            minWidth: '30px',
            color: 'text.secondary',
          }}
        >
          <ChevronRight size={15} />
        </Button>
        {refresh && (
          <Button
            loading={isValidating}
            variant="text"
            sx={{
              color: 'text.secondary',
              minWidth: 30,
              padding: '5px',
            }}
            onClick={refresh}
          >
            <RefreshCw size={15} />
          </Button>
        )}
      </div>
    </div>
  )
})
