import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useQuery } from '../hooks/useQuery'
import CountUp from 'react-countup'
import { motion } from 'framer-motion'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FilterContext } from 'filter/context/FilterContext'
import { AppContext } from 'app/context/AppContext'
import { formatNumber } from 'widgets/helpers/graph-helpers'
import { Link } from 'common/components/Link'
import { IconAwsLambda } from 'common/icons/IconAwsLambda'
import { Loading } from 'common/components/Loading'
import ErrorState from 'common/components/ErrorState'
import { useMetricsCount } from '../hooks/useMetricsCount'
import { ExternalLink } from 'lucide-react'
import { stringifyUrl } from 'query-string'
import { useLocation } from 'react-router-dom'

const StatWidget = ({
  title,
  value = 0,
  description,
  isError,
  loading,
  suffix = '',
  summaryError,
  clickable = false,
  onReload,
  events,
}) => {
  const { getFilterValue } = useContext(FilterContext)
  const { activeOrg } = useContext(AppContext)
  const { orgName } = activeOrg
  const location = useLocation()
  const [lastValue, setLastValue] = useState(0)
  const [newValue, setNewValue] = useState(0)
  useEffect(() => {
    if (value !== newValue) {
      setLastValue(newValue)
      setNewValue(value)
    }
  }, [value])

  const formatFn = useCallback(
    (newValue) => {
      return `${formatNumber(newValue)}${suffix}`
    },
    [newValue]
  )

  const link =
    clickable && !summaryError
      ? stringifyUrl({
          url: `/${orgName}/explorer${location.search}`,
          query: {
            awsLambdaEvents: events,
            globalTimeFrame: getFilterValue('globalTimeFrame'),
            explorerSubScope: 'invocations',
            globalScope: 'awsLambda',
          },
        })
      : ''
  return (
    <Link style={{ cursor: clickable ? 'pointer' : 'default' }} underline="none" to={link}>
      <Box
        component={motion.div}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'relative',
          zIndex: 2,
          height: '100px',
          cursor: clickable ? 'pointer' : 'auto',

          '&:hover': {
            button: {
              opacity: clickable ? 1 : 0,
            },
          },
        }}
      >
        <Tooltip title={description}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '10px',
              position: 'relative',
            }}
          >
            <IconAwsLambda size={14} />
            <Typography variant="h4">{title}</Typography>

            {link && (
              <IconButton
                sx={{
                  color: 'text.secondary',
                  display: 'flex',
                  opacity: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  width: '20px',
                  height: '20px',
                  padding: '3px',
                  transition: 'all 0.2s ease',
                }}
                onClick={(e) => {
                  e?.preventDefault()
                  window.open(link)
                }}
              >
                <ExternalLink size={12} />
              </IconButton>
            )}
          </Box>
        </Tooltip>
        {loading ? (
          <Loading size={20} thickness={4} />
        ) : summaryError ? (
          <Box sx={{ height: '100%', width: '100%' }}>
            <ErrorState onReload={onReload} />
          </Box>
        ) : (
          <Typography
            variant="h1"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1], transition: { duration: 0.65, ease: 'easeOut' } }}
            sx={{
              margin: '0',
              color: isError ? 'error.main' : 'text.primary',
            }}
          >
            <CountUp
              start={lastValue}
              end={newValue}
              delay={0}
              duration={0.65}
              suffix={suffix}
              decimal={'.'}
              useEasing={true}
              preserveValue
              formattingFn={formatFn}
            >
              {({ countUpRef }) => (
                <div>
                  <span ref={countUpRef} />
                </div>
              )}
            </CountUp>
          </Typography>
        )}
      </Box>
    </Link>
  )
}

export const MetricsSummary = () => {
  const { data, error, refresh, loading } = useQuery({ query: 'aws_lambda_summary' })

  const { invocations, uncaughtErrors, caughtErrors, successRate, okInvocations } = useMetricsCount(
    [data || {}]
  )

  const widgets = [
    {
      title: 'Invocations',
      value: invocations,
      clickable: true,
      description: 'Total AWS Lambda invocations count within the specified time frame',
    },
    {
      title: 'Successful',
      value: okInvocations,
      clickable: true,
      description: 'Total AWS Lambda invocations minus those with Uncaught Errors',
    },
    {
      title: 'Uncaught Errors',
      value: uncaughtErrors,
      isError: true,
      events: ['ERROR_TYPE_UNCAUGHT'],
      clickable: true,
      description: 'AWS Lambda invocations that failed due to errors not handled by your code',
    },
    {
      title: 'Caught Errors',
      value: caughtErrors,
      clickable: true,
      events: ['ERROR_TYPE_CAUGHT_SDK_USER', 'ERROR_TYPE_CAUGHT_USER'],
      description: 'AWS Lambda caught errors count within the specified time frame',
    },
    {
      title: 'Success Rate',
      value: successRate,
      suffix: '%',
      clickable: true,
      description:
        'That percentage of AWS Lambda invocations that did not fail due to Uncaught Errors.',
    },
  ]
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {widgets?.map((widget) => (
        <StatWidget
          key={`metrics-widget-${widget.title}`}
          {...widget}
          loading={loading}
          summaryError={error}
          onReload={refresh}
        />
      ))}
    </Box>
  )
}
