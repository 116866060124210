import clsx from 'clsx'
import { debounce } from 'lodash'
import { memo, useEffect, useRef, useState } from 'react'
import classes from './ExplorerTable.module.css'
import { Popper } from '@mui/material'
import { isContentOverflown } from 'common/utils/isContentOverflown'

export const GridCell = memo(function GridCellExpand(props) {
  const {
    value,
    field,
    colDef: { cellValueClassName, width },
  } = props
  const wrapper = useRef(null)
  const cellDiv = useRef(null)
  const cellValue = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showFullCell, setShowFullCell] = useState(false)
  const [showPopper, setShowPopper] = useState(false)
  const [arrowRef, setArrowRef] = useState()

  const debouncedHandleMouseEnter = debounce(() => handleShowPopper(), 800)

  const handleShowPopper = () => {
    const isCurrentlyOverflown = isContentOverflown(cellValue.current)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv?.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
    debouncedHandleMouseEnter.cancel()
  }

  useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    return () => {}
  }, [setShowFullCell, showFullCell])

  if (!value) return '-'

  return (
    <div
      ref={wrapper}
      className={clsx(classes.explorerTableCellWrapper)}
      onMouseEnter={debouncedHandleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Overlay popper div */}
      <div ref={cellDiv} className={clsx(classes.explorerTableCellDiv)} />
      <div
        ref={cellValue}
        className={clsx(
          cellValueClassName || classes.explorerTableCellValue,
          field === 'resource_name' && classes.isPrimaryColor,
          value >= 500 && 'isError'
        )}
      >
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement="top"
          onClick={(e) => e?.stopPropagation()}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
          style={{ width, zIndex: 10000 }}
        >
          <div className={classes.explorerTablePopperArrow} ref={setArrowRef} />
          <div className={classes.explorerTablePopperWrapper}>{value}</div>
        </Popper>
      )}
    </div>
  )
})
