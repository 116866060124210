import React, { useState, forwardRef, useCallback } from 'react'
import { ChevronDown, X } from 'lucide-react'
import { useSnackbar, SnackbarContent } from 'notistack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { InstrumentationFailureList } from './InstrumentationFailureList'

const InstrumentationFlowFailureSnackbar = forwardRef(
  ({ id, message, incompleteInventories }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded)
    }, [])

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent className="SnackbarContainer-root" ref={ref}>
        <Card className="SnackbarItem-contentRoot" color="primary">
          <CardActions>
            <Typography>{message}</Typography>
            <Box
              className="SnackbarItem-action"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton
                aria-label="Show more"
                style={expanded ? { transform: 'rotate(180deg)' } : null}
                onClick={handleExpandClick}
              >
                <ChevronDown size={18} />
              </IconButton>
              <IconButton onClick={handleDismiss}>
                <X size={18} />
              </IconButton>
            </Box>
          </CardActions>
          <InstrumentationFailureList
            expanded={expanded}
            incompleteInventories={incompleteInventories}
            includeLeftPad
            invertColors
            maxWidth="360px"
          />
        </Card>
      </SnackbarContent>
    )
  }
)

export default InstrumentationFlowFailureSnackbar
