import { Dialog, DialogContent, DialogTitle, Slide, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { AnimatePresence, motion } from 'framer-motion'
import { loadStripe } from '@stripe/stripe-js'
import React, { useContext, useState } from 'react'
import { BooleanParam, useQueryParams } from 'use-query-params'
import { AppContext } from 'app/context/AppContext'
import config from 'config'
import { PaymentCheckoutForm } from './PaymentCheckoutForm'
import { useOrgUsage } from 'common/hooks/useOrgUsage'
import { SuccessfulMessage } from '../../../common/components/SuccessfulMessage'

const stripePromise = loadStripe(config.stripe.apiKey)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpgradePlanDialog = () => {
  const { activeOrg } = useContext(AppContext)
  const { orgName, orgId } = activeOrg || {}
  const [query, setQuery] = useQueryParams({
    upgradePlan: BooleanParam,
    isUpdatePayment: BooleanParam,
  })
  const [isDone, setIsDone] = useState(false)
  const { loadingSubscription } = useOrgUsage({ orgId })

  const { upgradePlan: openPaymentDialog, isUpdatePayment } = query

  const closeDialog = () => {
    setQuery({
      upgradePlan: undefined,
      isUpdatePayment: undefined,
      discountCode: undefined,
      status: undefined,
      traces: undefined,
    })
    setIsDone(false)
  }

  if (!openPaymentDialog || loadingSubscription) {
    return null
  }
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      transitionDuration={200}
      open={!!openPaymentDialog}
      onClose={closeDialog}
      aria-labelledby="billing-dialog-title"
      aria-describedby="billing-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {!isDone && (
        <>
          {isUpdatePayment ? (
            <DialogTitle>Update payment method</DialogTitle>
          ) : (
            <DialogTitle>Change {orgName} plan</DialogTitle>
          )}
        </>
      )}
      <DialogContent mt={2}>
        <AnimatePresence>
          {!isDone && (
            <motion.div
              key="child"
              initial={{ x: 0 }}
              exit={{ x: -300, opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <Elements stripe={stripePromise}>
                <PaymentCheckoutForm
                  closeDialog={closeDialog}
                  setIsDone={setIsDone}
                  isUpdatePayment={isUpdatePayment}
                />
              </Elements>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isDone && (
            <motion.div key="child" initial={{ x: 100 }} animate={{ x: 0 }} exit={{ x: -50 }}>
              <SuccessfulMessage
                closeDialog={closeDialog}
                isDone={isDone}
                message={
                  isUpdatePayment ? (
                    <Typography color="text.secondary">
                      Updated payment method successfully
                    </Typography>
                  ) : (
                    <Typography color="text.secondary">Successfully updated</Typography>
                  )
                }
              />
            </motion.div>
          )}
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  )
}
