import { styled } from '@mui/material'
import { HelpCircle } from 'lucide-react'
import { Link } from '../../../common/components/Link'
const to = 'https://www.serverless.com/console/docs/integrations/aws/'

export const InfoBox = () => (
  <InfoContainer
    to={to}
    target="_blank"
    publishEventProps={{
      elementId: 'integration-learn-more',
      action: 'link_external',
      actionDetails: {
        href: to,
      },
    }}
  >
    <HelpCircle size={18} style={{ marginRight: '10px' }} />
    Learn how we securely connect to your AWS account.
  </InfoContainer>
)

export const InfoContainer = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: theme.palette.grey.dark,
  borderRadius: '4px',
  padding: '20px 40px',
  color: theme.palette.text.secondary,
  transition: 'all ease 0.3s',

  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.text.primary,
  },
}))
