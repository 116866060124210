import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import Button from './Button'
import { RefreshCw } from 'lucide-react'

export const CustomPagination = ({
  size,
  total = 0,
  pageCount,
  currentPage,
  isValidating,
  loading,
  onChange,
  refresh,
  siblingCount,
}) => {
  const currentItemsCount = total > size ? size : total
  const from = (currentPage === 1 && total ? 1 : (currentPage - 1) * size) || 0
  const to =
    currentPage === 1 ? currentItemsCount : currentPage === pageCount ? total : from + size || 0

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <CircularProgress size={11} thickness={5} />
      ) : (
        <Typography variant="textSecondary" color="text.secondary">
          Showing{' '}
          {`${from?.toLocaleString('en-US')}-${to?.toLocaleString(
            'en-US'
          )} of ${total?.toLocaleString('en-US')} `}
        </Typography>
      )}
      <Pagination
        siblingCount={siblingCount}
        count={pageCount}
        shape="rounded"
        page={currentPage}
        onChange={(event, page) => onChange(page)}
      />
      {refresh && (
        <Button
          loading={total && isValidating}
          variant="text"
          sx={{
            color: 'text.secondary',
            minWidth: 30,
            padding: '5px',
          }}
          onClick={refresh}
        >
          <RefreshCw size={15} />
        </Button>
      )}
    </Box>
  )
}
