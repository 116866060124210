import { styled, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ChevronRight } from 'lucide-react'
import { Activity } from 'dev-mode/components/Activity'
import { useMemo } from 'react'

export const SectionActivityGroup = ({
  activityGroup = null,
  stylePadding = '0px',
  scrollToIndex,
  reselectItems,
  zoom,
  ...props
}) => {
  const activityItems = useMemo(
    () => (Array.isArray(activityGroup) ? activityGroup : []),
    [activityGroup]
  )

  return (
    <StyledAccordion {...props}>
      <StyledAccordionSummary>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>Logs</Typography>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        {!activityItems?.length ? (
          <Typography
            variant="textTertiary"
            color="text.secondary"
            sx={{
              padding: '0px 20px 20px 40px',
            }}
          >
            No logs found.
          </Typography>
        ) : (
          <LogItems>
            {activityItems.map((activity, index) => (
              <div key={JSON.stringify(activity + index)}>
                <Activity
                  activity={activity}
                  isClickable={!!reselectItems}
                  stylePadding={'10px 10px 40px 15px'}
                  index={index}
                  zoom={zoom}
                  onClick={reselectItems}
                />
              </div>
            ))}
          </LogItems>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: '0',
  margin: '0',
  maxHeight: '85%',
  overflow: 'hidden',
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& .MuiCollapse-entered': {
    height: '100%!important',
    overflow: 'hidden',
  },
  '& .MuiCollapse-wrapper': {
    height: '100%!important',
    padding: '0',
    overflow: 'hidden',
  },
  '& .MuiCollapse-wrapperInner': {
    height: '100%!important',
    padding: '0',
    overflow: 'hidden',
  },
  '& .MuiAccordion-region': {
    height: '100%!important',
    padding: '0',
    overflow: 'hidden',
  },
}))

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRight size={14} />} {...props} />
))(({ theme }) => ({
  minHeight: '40px',
  padding: '0 0 0 20px',
  margin: '0',
  flexDirection: 'row-reverse',
  fontFamily: "'Roboto Mono', monospace",
  '& svg': {
    color: theme.palette.text.secondary,
  },
  '& p': {
    fontSize: '13px',
    fontFamily: "'Roboto Mono', monospace",
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 6px',
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  height: '100%',
  padding: '0px',
}))

const LogItems = styled('div')(({ theme }) => ({
  height: '93%',
  width: '100%',
  padding: '20px 20px 60px 24px',
  boxSizing: 'border-box',
  overflowY: 'scroll',
}))
