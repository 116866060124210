import { useCallback, useContext, useRef, useState } from 'react'
import { MetricsProvider, MetricsContext } from 'metrics/context/MetricsContext'
import { MetricsNav } from 'metrics/components/Nav/MetricsNav'
import { FilterContext, FilterProvider } from 'filter/context/FilterContext'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { motion } from 'framer-motion'
import { MetricsSummary } from './MetricsSummary'
import InvocationsGraph from 'widgets/components/InvocationsGraph'
import LatencyGraph from 'widgets/components/LatencyGraph'
import { FunctionsList } from 'widgets/components/FunctionsList'
import { AppContext } from 'app/context/AppContext'
import { EnableBulkInstrumentation } from 'common/components/EnableBulkInstrumentation'
import { Box, Typography } from '@mui/material'
import { useQuery } from '../hooks/useQuery'
import { useTheme } from '@mui/styles'
import { Allotment } from 'allotment'
import { Activities } from 'widgets/components/Activities'
import { Inspector } from 'inspector/components/Inspector'
import { Link } from 'common/components/Link'
import { stringifyUrl } from 'query-string'
import { useLocation } from 'react-router-dom'
import { getEventInfo } from 'common/utils/getEventInfo'

const Pane = Allotment.Pane

const MetricsInternal = () => {
  const {
    activeOrg: { orgId, orgName },
  } = useContext(AppContext)
  const theme = useTheme()
  const { data: hasData } = useQuery({ query: 'metrics_exist' })
  const location = useLocation()
  const { metricsView } = useContext(MetricsContext)
  const { getFilterValue } = useContext(FilterContext)
  const [selectedActivity, setSelectedActivity] = useState(null)
  usePageTitle(metricsView?.name ? `${metricsView.name || ''} - Metrics` : 'Metrics')
  const padding = '30px'

  const ref = useRef()

  const getLink = useCallback(() => {
    if (!selectedActivity) return ''
    return stringifyUrl({
      url: `/${orgName}/explorer${location.search}`,
      query: {
        globalTimeFrame: getFilterValue('globalTimeFrame'),
        explorerSubScope: 'invocations',
        explorerTracesFingerprints: selectedActivity?.fingerprint,

        globalScope: 'awsLambda',
      },
    })
  }, [selectedActivity])

  return (
    <EnableBulkInstrumentation
      bypassKey="sls-prod-mode-prompt"
      hasExistingData={hasData}
      orgId={orgId}
      instrumentationType="prod"
    >
      <MetricsNav />

      <Box
        component={motion.div}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 'calc(100vh - 75px)',

          // Add transition to panes and disable on dragging

          ' .split-view .split-view-view': {
            transition: 'all 0.2s ease',
          },
          ' .split-view:has( > .sash-container > .sash-active)  .split-view-view': {
            transition: 'none',
          },
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{
          opacity: [0, 1],
          y: [20, 0],
          transition: { duration: 0.35, ease: 'easeOut' },
        }}
      >
        <Allotment horizontal ref={ref}>
          <Pane minSize={300}>
            <Box
              sx={{
                padding,
                width: '100%',
                minWidth: selectedActivity ? 'auto' : '850px',
                gap: 5,
                height: '100%',
                display: 'flex',
                overflow: selectedActivity ? 'hidden' : 'scroll',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRight: `1px solid ${theme.palette.border.main}`,
                position: 'relative',
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent',
                  transition: 'all 2s ease',
                },
              }}
            >
              {/* Blur overlay to hide metrics if panes are open */}
              <Box
                onClick={() => setSelectedActivity(null)}
                sx={{
                  display: selectedActivity ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  position: 'absolute',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  backdropFilter: 'blur(10px)',
                  zIndex: 10,
                  transition: 'all 0.2s ease',
                }}
              >
                <Typography>Click here to see Metrics</Typography>
              </Box>

              <MetricsSummary />
              <InvocationsGraph useCustomContainer />
              <LatencyGraph useCustomContainer />
              <FunctionsList />
            </Box>
          </Pane>

          <Pane snap maxSize={400} preferredSize={400}>
            <Activities
              selectedActivity={selectedActivity}
              setSelectedActivity={(activity) => {
                setSelectedActivity(activity)
              }}
            />
          </Pane>
          {!!selectedActivity && (
            <Pane snap visible={!!selectedActivity} minSize={400}>
              <Box sx={{ paddingTop: '22px' }}>
                <Inspector
                  isSpan
                  parentSpanName={getEventInfo(selectedActivity)?.typeFullName}
                  selectedSpanName=""
                  timestamp={selectedActivity?.first_seen}
                  startTime={selectedActivity?.first_seen}
                  endTime={selectedActivity?.last_seen}
                  tags={null}
                  onClose={() => setSelectedActivity(null)}
                  span={selectedActivity}
                  defaultExpanded={'event'}
                  cta={
                    <Link
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        marginBottom: '15px',
                      }}
                      to={getLink()}
                    >
                      <Typography variant="textSecondary">Go to Explorer</Typography>
                    </Link>
                  }
                />
              </Box>
            </Pane>
          )}
        </Allotment>
      </Box>
    </EnableBulkInstrumentation>
  )
}

const Metrics = () => {
  return (
    <ErrorBoundary>
      <FilterProvider page="metric">
        <MetricsProvider>
          <MetricsInternal />
        </MetricsProvider>
      </FilterProvider>
    </ErrorBoundary>
  )
}

export default Metrics

/**
 * Widgets
 */
