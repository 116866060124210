import { useState, useEffect, useMemo } from 'react'
import { useTheme } from '@mui/styles'
import ReactJson from 'react-json-view'
import { randomFromRange } from 'util/random'
import { formatErrorStack } from 'util/format'

export const LogItem = ({ logBody, logSeverityText, isClickable }) => {
  const theme = useTheme()

  const [content, setContent] = useState(null)

  useEffect(() => {
    // Handle errors
    if (logSeverityText === 'ERROR') {
      setContent({
        type: 'error',
        content: formatErrorStack(logBody),
      })
    }
    // Handle objects
    let parsed
    try {
      parsed = JSON.parse(logBody)
    } catch (err) {}
    if (parsed && typeof parsed !== 'string') {
      // If API Gateway and response body is stringified, parse it
      if (parsed.headers && parsed.body) {
        let parsedBody
        try {
          parsedBody = JSON.parse(parsed.body)
        } catch (err) {}
        if (parsedBody) {
          parsed.body = parsedBody
        }
      }
      setContent({
        type: 'object',
        content: parsed,
      })
      return
    }
    // Handle strings
    setContent({
      type: 'string',
      content: logBody,
    })
  }, [logBody, logSeverityText])

  return useMemo(() => {
    if (content && content.type === 'error') {
      return (
        <pre
          className={`log-stream-log error ${!isClickable ? '' : 'clickable'}`}
          key={`logkey-${randomFromRange(5, 20)}`}
        >
          {content.content}
        </pre>
      )
    } else if (content && content.type === 'string') {
      return (
        <pre
          className={`log-stream-log ${!isClickable ? '' : 'clickable'}`}
          key={`logkey-${randomFromRange(5, 20)}`}
        >
          {content.content}
        </pre>
      )
    } else if (content && content.type === 'object') {
      return (
        <pre
          className={`log-stream-log ${!isClickable ? '' : 'clickable'}`}
          key={`logkey-${randomFromRange(5, 20)}`}
        >
          <ReactJson
            key={`logspan-${randomFromRange(5, 20)}`}
            theme={
              logSeverityText === 'ERROR'
                ? {
                    base00: '#FD5750',
                    base01: '#FD5750',
                    base02: '#FD5750',
                    base03: '#FD5750',
                    base04: '#FD5750',
                    base05: '#FD5750',
                    base06: '#FD5750',
                    base07: '#FD5750',
                    base08: '#FD5750',
                    base09: '#FD5750',
                    base0A: '#FD5750',
                    base0B: '#FD5750',
                    base0C: '#FD5750',
                    base0D: '#FD5750',
                    base0E: '#FD5750',
                    base0F: '#FD5750',
                  }
                : {
                    base00: theme.palette.text.secondary,
                    base01: theme.palette.text.secondary,
                    base02: theme.palette.border.main, // borders
                    base03: theme.palette.text.secondary,
                    base04: theme.palette.text.secondary,
                    base05: theme.palette.text.secondary,
                    base06: theme.palette.text.secondary,
                    base07: theme.palette.text.primary, // keys
                    base08: theme.palette.text.secondary,
                    base09: theme.palette.text.secondary, // values
                    base0A: theme.palette.text.secondary,
                    base0B: theme.palette.text.secondary,
                    base0C: theme.palette.text.secondary,
                    base0D: theme.palette.text.secondary,
                    base0E: theme.palette.text.secondary,
                    base0F: theme.palette.text.secondary,
                  }
            }
            name={null}
            indentWidth={4}
            iconStyle="triangle"
            displayDataTypes={false}
            enableClipboard={true}
            displayObjectSize={false}
            collapsed={2}
            quotesOnKeys={false}
            style={{
              backgroundColor: 'transparent',
            }}
            src={content.content || {}}
          />
        </pre>
      )
    }

    return null
  }, [logBody, logSeverityText, isClickable, content, theme])
}
