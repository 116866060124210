import { useContext, useState } from 'react'
import { Box, ClickAwayListener, Tooltip } from '@mui/material'
import { DropdownIcon } from 'common/components/DropdownIcon'
import { NavBarItemDropdown, NavBarItemDropdownInner } from 'common/components/NavBarItem'
import { Button } from '../../../common/components/Button'
import { ItemsCounter } from 'common/components/ItemsCounter'
import { FiltersWidget } from 'filter/components/FiltersWidget'
import { FilterContext } from 'filter/context/FilterContext'

export const ResourcesFilterDropdown = () => {
  const { setAllFilterValues, filtersCount } = useContext(FilterContext)
  const [isOpen, setIsOpen] = useState(false)

  const saveFilters = (newFilters) => {
    setAllFilterValues(newFilters)
    /**
     * Close filters menu
     */
    setIsOpen(false)
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Tooltip title="Filter Resources">
        <Box>
          <Button
            variant="outlined"
            endIcon={
              filtersCount ? (
                <ItemsCounter count={filtersCount} />
              ) : (
                <DropdownIcon isOpen={isOpen} />
              )
            }
            onClick={() => setIsOpen(true)}
          >
            Filter
          </Button>
        </Box>
      </Tooltip>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <NavBarItemDropdown
            width="380px"
            top={70}
            initial={{ height: '0' }}
            animate={{ height: 'auto', transition: { duration: 0.35, ease: 'easeOut' } }}
          >
            <NavBarItemDropdownInner>
              <FiltersWidget
                onSave={(newFilters) => {
                  saveFilters(newFilters)
                }}
                onCancel={() => setIsOpen(false)}
                allowMulti={false}
                showHeader
                filterListContainerStyles={{
                  padding: '0px 30px 30px 30px',
                }}
              />
            </NavBarItemDropdownInner>
          </NavBarItemDropdown>
        </ClickAwayListener>
      )}
    </Box>
  )
}
