import { AlertCircle } from 'lucide-react'
import { styled, Typography } from '@mui/material'

const GridBox = styled('div')(({ iscolumndirection }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: iscolumndirection === 'true' ? 'column' : 'row',
  justifyContent: 'center',
  flex: iscolumndirection === 'true' ? 1 : undefined,
  opacity: 0.4,
  transition: 'all ease 0.25s',

  '&:hover': {
    opacity: 1,
    cursor: 'pointer',
  },
}))

const ReloadIconButton = styled('button')(({ theme }) => ({
  padding: 0,
  outline: 'none',
  border: 0,
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  margin: '3px 0 0 0',
}))

const LabelWrapper = styled('div')(({ iscolumndirection }) => ({
  marginLeft: iscolumndirection === 'true' ? 0 : 10,
  marginTop: iscolumndirection === 'true' ? 5 : 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: iscolumndirection === 'true' ? 'center' : 'flex-start',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
}))

const ErrorState = ({
  label = 'Error Loading',
  onReload,
  reloadButtonLabel = 'Click to try again',
  isColumnDirection,
  fullWidth,
  ...rest
}) => {
  return (
    <GridBox iscolumndirection={isColumnDirection ? 'true' : 'false'} onClick={onReload} {...rest}>
      <ReloadIconButton>
        <AlertCircle size={20} />
      </ReloadIconButton>
      <LabelWrapper iscolumndirection={isColumnDirection ? 'true' : 'false'}>
        <Typography
          variant="textPrimary"
          iscolumndirection={isColumnDirection ? 'true' : 'false'}
          fullwidth={fullWidth ? 'true' : 'false'}
        >
          {label}
        </Typography>
        <Typography variant="textTertiary">{reloadButtonLabel}</Typography>
      </LabelWrapper>
    </GridBox>
  )
}

export default ErrorState
