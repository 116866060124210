import { useContext, useMemo, useState } from 'react'
import { AppBar, Box, Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { RenderSpansChart } from './RenderSpansChart'
import { formatLongDateTime } from 'util/date'
import { useTheme } from '@mui/styles'
import { has, range, truncate } from 'lodash'
import { Inspector } from 'inspector/components/Inspector'
import { Allotment } from 'allotment'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { TraceNotFound } from './TraceNotFound'
import CloseButton from 'common/components/CloseButton'
import { ROW_HEIGHT, TraceContext, TraceProvider } from '../context/Trace.context'
import { RenderSpansTree } from './RenderSpansTree'
import { FilterContext } from 'filter/context/FilterContext'
import { formatDuration } from 'util/format'
import { CopyText } from 'common/components/CopyText'
import { Link } from 'common/components/Link'
import { IconDangerTriangle } from 'common/icons/IconDangerTriangle'
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react'
import { TraceSkeletonLoading } from './TraceSkeletonLoading'
import { getEventInfo } from 'common/utils/getEventInfo'
import { AlertDialogButton } from 'settings/components/alerts/useAlertDialog'

const CustomDivider = () => <Divider sx={{ height: '30px', width: '3px' }} orientation="vertical" />
const HeaderItem = ({ title, value, autoTruncate = false }) => (
  <Box>
    <Typography variant="textSecondary" color="text.secondary">
      {title}
    </Typography>
    {autoTruncate ? (
      <CopyText value={value} showOnHoverOnly>
        <Typography variant="textSecondary" color="text.primary">
          {truncate(value, { length: 35, separator: '...' })}
        </Typography>
      </CopyText>
    ) : (
      <Typography variant="textSecondary" color="text.primary">
        {value}
      </Typography>
    )}
  </Box>
)
const RenderTrace = ({ close }) => {
  const theme = useTheme()
  const {
    trace,
    maxSpanVal,
    selectedSpan,
    selectedSpanDuration,
    logs,
    isLoading,
    isWaitingForTrace,
    isNotFound,
    traceEvents,
  } = useContext(TraceContext)
  const { setFilterValue } = useContext(FilterContext)

  const [expandLogs, setExpandLogs] = useState(false)

  // Set Page Title
  usePageTitle(`Trace`)

  const selectedSpanErrorEventId =
    has(selectedSpan, 'parentSpanId') &&
    !selectedSpan?.parentSpanId &&
    traceEvents?.data?.find((event) => event?.tags?.error?.type === 'ERROR_TYPE_UNCAUGHT')?.eventId

  // Set Trace Headers
  const headerItems = []
  if (trace?.startTime) {
    headerItems.push({
      title: 'Date',
      value: formatLongDateTime(trace?.startTime),
    })
  }
  if (trace?.tags?.environment) {
    headerItems.push({
      title: 'Environment',
      value: trace?.tags?.environment,
    })
  }
  if (trace?.tags?.namespace) {
    headerItems.push({
      title: 'Namespace',
      value: trace?.tags?.namespace,
    })
  }
  if (trace?.tags?.aws?.lambda?.name) {
    headerItems.push({
      title: 'Resource Name',
      value: trace.tags.aws.lambda.name,
    })
  }
  // Get selected span name for inspector header
  const selectedChildSpanName = useMemo(() => {
    if (!!selectedSpan?.eventName) {
      const { typeFullName } = getEventInfo(selectedSpan)
      return typeFullName
    }
    return selectedSpan?.name
  }, [selectedSpan])

  const renderHttpInfo = () => {
    const httpHeaderItems = []
    if (trace?.tags?.aws?.lambda?.http?.host) {
      httpHeaderItems.push({
        title: 'Host',
        value: trace?.tags?.aws?.lambda?.http?.host,
        autoTruncate: true,
      })
    }
    if (trace?.tags?.aws?.lambda?.http?.path || trace?.tags?.aws?.lambda?.httpRouter?.path) {
      httpHeaderItems.push({
        title: 'Path',
        autoTruncate: true,
        value: trace?.tags?.aws?.lambda?.http?.path || trace?.tags?.aws?.lambda?.httpRouter?.path,
      })
    }
    if (trace?.tags?.aws?.lambda?.http?.method) {
      httpHeaderItems.push({
        title: 'Method',
        value: trace?.tags?.aws?.lambda?.http?.method,
      })
    }

    if (trace?.tags?.aws?.lambda?.http?.statusCode) {
      httpHeaderItems.push({
        title: 'Status',
        value: trace?.tags?.aws?.lambda?.http?.statusCode,
      })
    }

    if (!httpHeaderItems.length) return null
    return (
      <>
        <CustomDivider />
        {httpHeaderItems.map((data, index) => (
          <HeaderItem key={`trace-header-item-${index}`} {...data} />
        ))}
      </>
    )
  }
  const Pane = Allotment.Pane

  const maxValMilliseconds = maxSpanVal * 0.001
  return (
    <BodyBackground>
      <Body
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.3, ease: 'easeOut' },
          borderTop: `1px solid ${theme.palette.border.main}`,
          borderRight: `1px solid ${theme.palette.border.main}`,
          borderBottom: `1px solid ${theme.palette.border.main}`,
          borderRadius: '0 6px 6px 6px',
        }}
      >
        <StyledAppBar position="sticky">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="40px">
              <Typography variant="h3" color="textPrimary">
                Trace
              </Typography>
              <CustomDivider />
              {headerItems.map((headerData, index) => (
                <HeaderItem
                  key={`trace-header-item-${index}`}
                  title={headerData.title}
                  value={headerData.value}
                />
              ))}
              {renderHttpInfo()}
              {!isLoading && (
                <AlertDialogButton
                  params={{
                    globalEnvironments: trace?.tags?.environment,
                    globalNamespace: trace?.tags?.namespace,
                    awsLambdaFunctionNames: trace?.tags?.aws?.lambda?.name,
                  }}
                  sx={{
                    marginLeft: 'auto',
                    marginRight: '20px',
                  }}
                />
              )}
            </Box>
            <CloseButton onClick={close} aria-label="close details" size="medium" />
          </Box>
        </StyledAppBar>
        <AnimatePresence>
          {isLoading || isWaitingForTrace ? (
            <TraceSkeletonLoading
              message={isWaitingForTrace ? 'Waiting for trace details' : undefined}
            />
          ) : isNotFound ? (
            <TraceNotFound close={close} />
          ) : (
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              sx={{
                height: `calc(var(--app-height) - 74px)`,
              }}
            >
              <ScrollSync>
                <Allotment horizontal onVisibleChange={(_index, value) => setExpandLogs(value)}>
                  {/* Spans items tree */}
                  <Pane snap preferredSize={300} visible={!expandLogs}>
                    <ScrollSyncNode group="a">
                      <Box
                        height="100%"
                        sx={{
                          paddingTop: `${ROW_HEIGHT}px`,
                          overflow: 'scroll',
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                        }}
                      >
                        <RenderSpansTree />
                      </Box>
                    </ScrollSyncNode>
                  </Pane>
                  {/* Spans progress bars chart */}
                  <Pane minSize={1} visible={!expandLogs}>
                    <ScrollSyncNode group="a">
                      <Box
                        position="relative"
                        height="100%"
                        sx={{
                          paddingTop: `${ROW_HEIGHT}px`,
                          overflowY: 'scroll',
                          paddingLeft: '2px',
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                        }}
                      >
                        <Box
                          style={{
                            height: ROW_HEIGHT,
                            width: '100%',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Intervals left="5px">0ms</Intervals>
                          <Intervals left="25%">
                            {formatDuration(maxValMilliseconds * 0.25)}
                          </Intervals>
                          <Intervals left="50%">
                            {formatDuration(maxValMilliseconds * 0.5)}
                          </Intervals>
                          <Intervals left="75%">
                            {formatDuration(maxValMilliseconds * 0.75)}
                          </Intervals>
                          <Intervals paddingRight={'5px'} alignSelf="flex-end">
                            {formatDuration(maxValMilliseconds)}
                          </Intervals>
                        </Box>

                        <RenderSpansChart span={trace} />
                        {range(3).map((num) => (
                          <Box
                            key={`timeline-line-${num}`}
                            position="absolute"
                            borderLeft={`1px solid ${theme.palette.grey.dark}`}
                            height="100%"
                            top={0}
                            left={`${(num + 1) * 25}%`}
                            zIndex={3}
                          />
                        ))}
                      </Box>
                    </ScrollSyncNode>
                  </Pane>
                  <Pane minSize={300}>
                    {selectedSpan && (
                      <Inspector
                        isSpan
                        parentSpanName={'aws.lambda'}
                        childSpanName={
                          selectedChildSpanName !== 'aws.lambda' ? selectedChildSpanName : null
                        }
                        selectedSpanName={selectedSpan.name || selectedSpan.eventName}
                        timestamp={selectedSpan.timestamp}
                        startTime={selectedSpan.startTime}
                        endTime={selectedSpan.endTime}
                        tags={selectedSpan.tags}
                        durations={selectedSpanDuration}
                        activityGroup={logs}
                        input={selectedSpan.input}
                        output={selectedSpan.output}
                        customTags={selectedSpan.customTags}
                        span={selectedSpan}
                        defaultExpanded={selectedSpan.eventId && 'event'}
                        cta={
                          selectedSpanErrorEventId && (
                            <Link
                              sx={{
                                textDecorationColor: theme.palette.error.main,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                              }}
                              onClick={() => {
                                if (selectedSpanErrorEventId) {
                                  setFilterValue('explorerTraceSpanId', selectedSpanErrorEventId)
                                }
                              }}
                            >
                              <IconDangerTriangle
                                style={{
                                  fill: theme.palette.colors.redPrimary,
                                  fontSize: '15px',
                                  marginTop: '3px',
                                }}
                              />
                              <Typography variant="textSecondary" color="error.main">
                                Go to Error
                              </Typography>
                            </Link>
                          )
                        }
                      />
                    )}
                  </Pane>
                </Allotment>
              </ScrollSync>
            </Box>
          )}
        </AnimatePresence>
      </Body>
    </BodyBackground>
  )
}
export const Trace = ({ close }) => {
  return (
    <TraceProvider>
      <RenderTrace close={close} />
    </TraceProvider>
  )
}
const Intervals = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'absolute',
  color: theme.palette.text.secondary,
  height: '100%',
  zIndex: 10,
  fontSize: theme.typography.textSecondary.fontSize,
  paddingBottom: 10,
  backgroundColor: theme.palette.secondary.main,
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderBottom: `1px solid ${theme.palette.border.main}`,
  zIndex: '5',
  padding: '15px 25px 20px 25px',
  boxShadow: 'none',
}))

const BodyBackground = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'var(--app-height)',
  width: '100%',
  overflow: 'hidden',
  background: theme.palette.background.default,
}))

const Body = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 'var(--app-height)',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
}))
