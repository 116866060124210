export const IconAWSLambda = ({ style = {} }) => {
  return (
    <svg style={style} focusable="false" aria-hidden viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7402 22C18.2631 22 22.7402 17.5228 22.7402 12C22.7402 6.47715 18.2631 2 12.7402 2C7.21739 2 2.74023 6.47715 2.74023 12C2.74023 17.5228 7.21739 22 12.7402 22ZM11.0849 10.2786C11.2177 10.2786 11.3388 10.3516 11.397 10.4666L12.7139 13.0786C12.7596 13.1693 12.76 13.2756 12.7153 13.3666L11.2774 16.2876C11.2198 16.4039 11.0977 16.4783 10.9635 16.4783H8.33712C8.21782 16.4783 8.10718 16.4196 8.04372 16.3226C7.98025 16.2259 7.97262 16.1046 8.0243 16.0013L10.7721 10.4686C10.8293 10.3529 10.9503 10.2789 11.0839 10.2786H11.0849ZM16.8666 13.7699H17.6434C17.8352 13.7699 17.9902 13.9189 17.9902 14.1033V16.1449C17.9902 16.3293 17.8352 16.4783 17.6434 16.4783H15.1845C15.0496 16.4783 14.9272 16.4029 14.8703 16.2856L11.4327 9.1866H10.057C9.9647 9.1866 9.87661 9.1516 9.81176 9.08894C9.74656 9.02627 9.71014 8.9416 9.71014 8.85294L9.71222 6.81127C9.71257 6.62727 9.86759 6.47827 10.059 6.47827H13.1307C13.266 6.47827 13.3884 6.55394 13.4456 6.6716L16.8666 13.7699Z"
      />
    </svg>
  )
}
