import { useCallback, useState } from 'react'
import { FormControl, styled, TextField, Box } from '@mui/material'
import { Button } from 'common/components/Button'
import { IconServerlessConsole } from 'common/icons/IconServerlessConsole'
import { motion } from 'framer-motion'
import { requestPasswordReset } from 'app/util/auth'
import { colors } from 'theme/colors'
import { useNavigate } from 'react-router-dom'

const ResetRequestPage = () => {
  const [errorMessage, setErrorMessage] = useState()
  const [hasRequestSent, setRequestSent] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onSubmitForm = useCallback(async (email) => {
    setLoading(true)
    setErrorMessage(undefined)
    try {
      await requestPasswordReset({ email })
      setRequestSent(true)
    } catch (error) {
      setErrorMessage(error.description)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Root>
      <Main initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }}>
        <Box sx={{ margin: 0 }}>
          <IconServerlessConsole size={50} />
        </Box>
        {!hasRequestSent ? (
          <RequestForm
            errorMessage={errorMessage}
            onSubmitForm={onSubmitForm}
            isLoading={isLoading}
          />
        ) : (
          <RequestSent />
        )}
      </Main>
    </Root>
  )
}

const RequestForm = ({ onSubmitForm, isLoading, errorMessage }) => {
  const [username, setUsername] = useState('')

  return (
    <Form>
      <FormControl sx={{ width: '100%', mt: 0, mb: 2 }} variant="standard">
        <TextField
          label="Enter your email to reset password"
          onChange={(event) => setUsername(event.target.value)}
          placeholder="my@email.com"
          value={username}
        />
      </FormControl>
      <Button
        sx={{ margin: '10px 0' }}
        color={'primary'}
        size={'medium'}
        loading={isLoading}
        fullWidth
        onClick={() => onSubmitForm(username)}
      >
        {isLoading ? 'Requesting...' : 'Reset Password'}
      </Button>
      <Button sx={{ margin: '10px 0' }} variant="text" href="/?view=login" fullWidth>
        Back To Login
      </Button>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Form>
  )
}

const RequestSent = () => {
  const navigate = useNavigate()

  return (
    <>
      <Box sx={{ textAlign: 'center', margin: '20px 0' }}>
        Check your email, a reset confirmation has been dispatched
      </Box>
      <Form sx={{ margin: '0' }}>
        <SubmitButton>
          <Button onClick={() => navigate('/')} variant="contained" color="primary" fullWidth>
            Back to Login
          </Button>
        </SubmitButton>
      </Form>
    </>
  )
}

const ErrorMessage = styled('p')(() => ({
  color: colors.redPrimary,
  textAlign: 'center',
}))

const Root = styled('div')(() => ({
  minHeight: 'var(--app-height)',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '20vh',
}))

const Main = styled(motion.div)(() => ({
  maxWidth: 390,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 20,
  paddingLeft: 20,
}))

const Form = styled('div')(() => ({
  width: '100%',
  marginTop: 50,
}))

const SubmitButton = styled('div')(() => ({
  marginTop: 10,
  marginBottom: 10,
}))

export default ResetRequestPage
