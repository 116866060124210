import { SvgIcon } from '@mui/material'

const IconDevMode = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <path d="M20.1402 10.7876C21.0736 11.3264 21.0736 12.6736 20.1402 13.2124L7.84023 20.3138C6.9069 20.8527 5.74023 20.1791 5.74023 19.1014L5.74023 4.89859C5.74023 3.82087 6.9069 3.1473 7.84023 3.68616L20.1402 10.7876Z" />
    </SvgIcon>
  )
}

export { IconDevMode }
