import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { DialogIntro } from 'settings/components/integrations/steps/aws/DialogIntro'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'

import { IconBox } from 'settings/components/integrations/IntegrationRow'
import { IconServerlessConsole } from 'common/icons/IconServerlessConsole'
import { IconAws } from 'common/icons/IconAws'
import { ArrowRight } from 'lucide-react'
import IconServerlessFramework from 'common/icons/IconServerlessFramework'
import { InfoContainer } from 'settings/components/integrations/InfoBox'
import { AppContext } from '../context/AppContext'
import Button from 'common/components/Button'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { Link } from 'common/components/Link'
import { every } from 'lodash'
import { usePageTitle } from 'common/hooks/usePageTitle'
import { defaultMetricsFilterString } from 'filter/util/filters'
import { coreApiClient } from 'util/coreApiClient'
import config from 'config'

const OnboardingPage = ({ upgrade }) => {
  const { toggleSupport, activeOrg, isConfigMissing, setIsConfigMissing } = useContext(AppContext)
  const { orgId, orgName } = activeOrg || {}
  const navigate = useNavigate()
  const [showBrowserOnboarding, setShowBrowserOnboarding] = useState(false)

  const search = window.location.search
  const params = new URLSearchParams(search)
  const client = upgrade ? 'cli' : params.get('client')
  const clientOriginCommand = params.get('clientOriginCommand')
  const comingFromCli = client === 'cli:serverless' && !showBrowserOnboarding

  usePageTitle('Connect AWS account')

  const [, setIntegrationParams] = useQueryParams({
    integrationStep: StringParam,
    integrationId: StringParam,
    redirectUrl: StringParam,
    createIntegration: BooleanParam,
  })

  /**
   * Check if an integration is added, in case of on boarding happens via the CLI
   *
   */
  const { data: integrationsData } = useSWR(
    ['integrations', orgId],
    () =>
      coreApiClient({
        baseURL: config.platform.integrationsBase,
        url: `/integrations/?orgId=${orgId}`,
      }),
    {
      refreshInterval: 5000,
    }
  )
  const { integrations } = integrationsData || {}
  const hasIntegrations = !!integrations?.length

  const isSettingUpIntegration = every(
    integrations,
    (integration) => integration?.status === 'settingup' && integration?.syncStatus !== 'incomplete'
  )

  const goToMetrics = () => navigate(`/${orgName}/metrics/awsLambda?${defaultMetricsFilterString}`)

  useEffect(() => {
    if (hasIntegrations) {
      /**
       * If integrations has 'syncStatus' complete then redirect to metrics page
       * otherwise open 'Setting up integration' view
       */
      if (!isConfigMissing) {
        // Let the app context know that we have at least one integration configured
        setIsConfigMissing(false)
        // Redirect to metrics page
        goToMetrics()
      } else if (isSettingUpIntegration) {
        const integrationId = integrations.find(
          (integration) => integration?.syncStatus !== 'complete'
        )?.integrationId
        openIntegrationDialog({
          params: {
            integrationId,
            deployRoleView: 'resources',
          },
        })
      }
    }
  }, [hasIntegrations, isConfigMissing])
  // Give the other component time to write query params
  const openIntegrationDialog = ({ params }) =>
    setIntegrationParams({
      ...params,
      integrationStep: 'deploy-role',
      createIntegration: true,
    })

  if (comingFromCli) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 'var(--app-height)',
          gap: '25px',
          maxWidth: '650px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <IconBox style={{ height: '55px', width: '55px' }}>
            <IconServerlessFramework size={28} />
          </IconBox>
          <ArrowRight size={18} style={{ margin: '0 8px' }} />
          <IconBox style={{ height: '55px', width: '55px' }}>
            <IconServerlessConsole size={28} />
          </IconBox>
          <ArrowRight size={18} style={{ margin: '0 8px' }} />
          <IconBox style={{ height: '55px', width: '55px' }}>
            <IconAws size={28} />
          </IconBox>
        </Box>
        <Box mt={2}>
          <Typography variant="h1">Welcome, CLI successfully logged in</Typography>
          {clientOriginCommand === 'onboarding' ? (
            <Box my={5}>
              <Typography variant="h4" sx={{ mb: 5 }}>
                Close this window and continue set up in your terminal
              </Typography>
              <Typography variant="h4">
                or,{' '}
                <Link
                  onClick={() => {
                    setShowBrowserOnboarding(true)
                  }}
                >
                  set up your integration from the web
                </Link>
              </Typography>
            </Box>
          ) : (
            <Box>
              <Button
                size="large"
                variant="outlined"
                sx={{
                  width: '100%',
                  my: 5,
                }}
                onClick={goToMetrics}
              >
                Continue to metrics view
              </Button>
              <Typography variant="h4">
                or, just close this window and go back to your terminal
              </Typography>
            </Box>
          )}
        </Box>

        <InfoContainer sx={{ textAlign: 'left', mt: 8 }}>
          <ul>
            <li>
              <Link
                to="https://github.com/serverless/console/blob/main/instrumentation/aws/iam-role-cfn-template.yaml"
                target="_blank"
              >
                Review the IAM Role permissions in our Github account, with helpful comments.
              </Link>
            </li>
            <li>
              <Link to="https://www.serverless.com/console/docs/" target="_blank">
                Read our full documentation on how to set up Console via Serverless Framework.
              </Link>
            </li>
            <li>
              <Link onClick={toggleSupport}>Ask us for assistance.</Link>
            </li>
          </ul>
        </InfoContainer>
      </Box>
    )
  }

  /**
   * Show if no client or client is CLI
   */
  return (
    <DialogIntro
      title="Welcome."
      subTitle="Connect your AWS account and we'll set up everything."
      description="Follow this flow to securely and automatically instrument and tag enable AWS Lambda, API Gateway & more."
      handleNext={openIntegrationDialog}
    />
  )
}

export default OnboardingPage
