import { useState, useCallback, useEffect } from 'react'

export const useZoom = () => {
  const [zoom, setZoom] = useState(1)

  const incrementZoom = () => {
    if (zoom < 2) {
      setZoom(zoom + 0.1)
    }
  }

  const decrementZoom = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.1)
    }
  }

  const keySelected = useCallback(
    (e) => {
      const cmdSelected = e.ctrlKey || e.metaKey
      if (cmdSelected && e.key === '-') {
        e.preventDefault()
        decrementZoom()
      } else if (cmdSelected && e.key === '=') {
        e.preventDefault()
        incrementZoom()
      }
    },
    [zoom]
  )

  useEffect(() => {
    window.addEventListener('keydown', keySelected)

    return () => {
      window.removeEventListener('keydown', keySelected)
    }
  }, [keySelected])

  return {
    zoom,
    incrementZoom,
    decrementZoom,
  }
}
