import { useContext } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { IntegrationContext } from '../../context/integration.context'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { isEqual, omit } from 'lodash'
import { ModeSelect } from './ModeSelect'

const SortIcon = ({ sortKey, handleSortChange, getSortKey }) => (
  <IconButton
    sx={{
      color: getSortKey(sortKey) ? 'text.primary' : 'text.secondary',
      cursor: 'pointer',
      marginLeft: '5px',
    }}
    onClick={() => handleSortChange(sortKey)}
  >
    {getSortKey(sortKey)?.order === 'desc' ? <ArrowUp size={14} /> : <ArrowDown size={14} />}
  </IconButton>
)

export const LabelsRow = ({ maxWidth }) => {
  const {
    resourceRowStyles,
    setChangedResources,
    resources,
    setSort,
    sort,
    resourceCompatibility,
  } = useContext(IntegrationContext)

  const onCheckboxChange = (value) => {
    setChangedResources((prevChanged) => {
      return resources.reduce((acc, resource) => {
        const resourceToCompare = {
          ...resource,
          ...(prevChanged?.[resource.id] || {}),
          instrument_mode: value,
        }

        const canEnable = resourceCompatibility?.mode?.[value]
          ? resourceCompatibility?.mode?.[value]?.runtimes?.includes(resource?.aws_lambda_runtime)
          : true

        if (isEqual(resource, resourceToCompare) || !canEnable) {
          return omit(acc, resource.id)
        } else {
          return {
            ...acc,
            [resource?.id]: { ...resource, ...prevChanged?.[resource.id], instrument_mode: value },
          }
        }
      }, prevChanged)
    })
  }

  const getSortKey = (key) => sort?.[0]?.[key]

  const handleSortChange = (key) => {
    setSort([
      {
        [key]: {
          order: getSortKey(key)?.order === 'desc' ? 'asc' : 'desc',
        },
      },
    ])
  }

  const sortItemSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }

  return (
    <Box
      sx={{
        ...resourceRowStyles?.wrapper,
        padding: '0px 15px',
        width: 'calc(100% - 3px)', // Full width minus scrollbar width
        maxWidth,

        border: '1px solid transparent',
      }}
    >
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Box
          component="span"
          sx={{
            ...sortItemSx,
          }}
        >
          <Typography variant="textTertiary" color="text.secondary">
            Name
          </Typography>
          <SortIcon
            sortKey="aws_lambda_name.keyword"
            handleSortChange={handleSortChange}
            getSortKey={getSortKey}
          />
        </Box>
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Box
          component="span"
          sx={{
            ...sortItemSx,
          }}
        >
          <Typography variant="textTertiary" color="text.secondary">
            Region
          </Typography>

          <SortIcon
            sortKey="tag_region.keyword"
            handleSortChange={handleSortChange}
            getSortKey={getSortKey}
          />
        </Box>
        <Box
          component="span"
          sx={{
            ...sortItemSx,
          }}
        >
          <Typography variant="textTertiary" color="text.secondary">
            Runtime
          </Typography>

          <SortIcon
            sortKey="aws_lambda_runtime.keyword"
            handleSortChange={handleSortChange}
            getSortKey={getSortKey}
          />
        </Box>
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Box
          component="span"
          sx={{
            ...sortItemSx,
          }}
        >
          <Typography variant="textTertiary" color="text.secondary">
            Modified
          </Typography>
          <SortIcon
            sortKey="aws_lambda_last_modified"
            handleSortChange={handleSortChange}
            getSortKey={getSortKey}
          />
        </Box>
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <Box
          component="span"
          sx={{
            ...sortItemSx,
          }}
        >
          <Typography variant="textTertiary" color="text.secondary">
            Environment
          </Typography>

          <SortIcon
            sortKey="tag_environment.keyword"
            handleSortChange={handleSortChange}
            getSortKey={getSortKey}
          />
        </Box>
        <Box
          component="span"
          sx={{
            ...sortItemSx,
          }}
        >
          <Typography variant="textTertiary" color="text.secondary">
            Namespace
          </Typography>
          <SortIcon
            sortKey="tag_namespace.keyword"
            handleSortChange={handleSortChange}
            getSortKey={getSortKey}
          />
        </Box>
      </Box>
      <Box
        sx={{
          ...resourceRowStyles?.children,
        }}
      >
        <ModeSelect onChange={onCheckboxChange} showTooltip canUseProd canUseDev />
      </Box>

      <Box
        sx={{
          ...resourceRowStyles?.children,
          justifyContent: 'flex-end',
        }}
      >
        <Typography variant="textTertiary" color="text.secondary">
          Details
        </Typography>
      </Box>
    </Box>
  )
}
