import { useContext, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { cloneDeep } from 'lodash'
import snakeCase from 'lodash.snakecase'
import config from 'config'
import { AppContext } from 'app/context/AppContext'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'
import { useMemoCompare } from '../hooks/useMemoCompare'

const { WebPageViewedV1Event } = WebEvents

export const PathReporter = () => {
  const { activeOrg, user } = useContext(AppContext)
  const { pathname } = useLocation()
  const params = useParams()
  const finalParams = useMemoCompare(
    params,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
  )
  const queryParams = useMemo(
    () => queryString.parse(window.location.search),
    [window.location.search]
  )

  useEffect(() => {
    const run = async () => {
      try {
        // Convert path to relative path
        const path = Object.keys(finalParams).reduce((str, key) => {
          const value = finalParams[key]
          return str.replace(value, `:${key}`)
        }, pathname)

        if (!activeOrg?.isOrgMember) return

        /**
         * Remove some query parameters, that we don't want to log/track
         */
        const parsedQs = cloneDeep(queryParams)
        delete parsedQs.code
        delete parsedQs.transactionId
        const session = JSON.parse(window.localStorage.getItem(config.localStorageKey) || '{}')
        const slsCoreEventsClient = new SlsCoreEventsClient(
          config.platform.eventsUrl,
          session?.idToken
        )
        // Send event
        const pageViewEvent = new WebPageViewedV1Event({
          orgUid: activeOrg?.orgId,
          path: path,
          url: pathname,
          userId: user?.userId,
          params: Object.keys(finalParams || {}).reduce(
            (obj, key) => ({
              ...obj,
              [snakeCase(key)]: finalParams[key],
            }),
            {}
          ),
          queryParameters: Object.keys(parsedQs || {}).reduce(
            (obj, key) => ({
              ...obj,
              [snakeCase(key)]: parsedQs[key],
            }),
            {}
          ),
        })

        await slsCoreEventsClient.publish(pageViewEvent)
      } catch (error) {
        console.error('Could not publish event', error)
      }
    }
    run()
  }, [pathname, finalParams, queryParams])

  return <></>
}
